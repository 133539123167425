import React from 'react'
import Collapsible from '../../../components/Collapsible/Collapsible'
import HeaderSmall from '../../../components/Header/HeaderSmall/HeaderSmall'
import setting from '../../../services/setting'
import './SupportFAQ.css'
import { getDateStr } from '../../../hook/utils/getDateStr'
const baseURL = process.env.REACT_APP_BASE_URL;

function SupportFAQ() {
  const [choice, setChoice] = React.useState(0)
  const [item, setItem] = React.useState([])
  const [tableItem, setTableItem] = React.useState([])


  React.useEffect(() => {
    getData(choice)
  }, [choice])



  const getData = async (categoryPk) => {

    const category = moreList.menu[categoryPk].title

    const res = await setting.getFAQ(category)
    setItem(res.list)
  }

  React.useEffect(() => {
    const myItem = []
    if (item.length === 0) {
      setTableItem(myItem)
      return
    }

    item.map(data => {
      myItem.push(
        <div key={data.pk}>
          <Collapsible title={data.question}>
            <div className="sf-col-item__open">
              <p>
                <span style={{whiteSpace: 'pre-line'}}>
                  {data.answer}
                </span>
              </p>
              <h4>{getDateStr(data.time, '.')} update</h4>
            </div>
            {data.faqFileTbList.map(item => {
              return (
                <div className='file_button_container'>
                  <div className='file_button_session'>
                    {item.fileName}
                    <form
                      target={'_blank'}
                      method="get"
                      action={`${baseURL}/upload/faq/${item.fileName}`}
                    >
                      <button type="submit" className='file_button'>다운로드</button>
                    </form>

                  </div>
                </div>
              );
            })}
          </Collapsible>
        </div>
      )
    })
    setTableItem(myItem)
  }, [item])

  const moreList = {
    menu: [
      {
        title: 'Qart',
      },
      {
        title: 'Sign Up',
      },
      {
        title: 'Art Database',
      },
      {
        title: 'Q-CoA',
      },
      {
        title: 'Marketplace',
      },
      {
        title: 'NFT',
      },
      {
        title: '멤버십',
      },
    ],
  }
  return (
    <div className="support-faq">
      <HeaderSmall
        moreList={moreList}
        active={true}
        choice={choice}
        setMenu={setChoice}
      />
      <div className="sf-content container">
        {tableItem}
      </div>
    </div>
  )
}
export default SupportFAQ
