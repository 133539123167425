import * as ActionTypes from './actionTypes';
import setting from '../../../services/setting';
import cookie from 'js-cookie'

// 전체
export const SET_WORK_TYPE_LIST = () => {
    return async(dispatch) => {
        const res = await setting.getWorkTypeList();
        dispatch({type: ActionTypes.SET_WORK_TYPE_LIST, data: res.type_list})
    }
}


export const SET_EXHIBIT_LIST = () => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SET_EXHIBIT_LIST})
    }
}

export const SET_COMPANY_INFO = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SET_COMPANY_INFO, data: data})
    }
}

export const SET_BANNER_LIST = () => {
    return async(dispatch) => {
        const res = await setting.getBanner();
        const data = res.list;

        let dataList = [];
        for(let i = 0; i < data.length; i ++){
            if(data[i].state === 1){
                dataList.push(data[i])
            }else{
            }
        }

        dispatch({type: ActionTypes.SET_BANNER_LIST, data: dataList})
    }
}

export const SET_POPUP_LIST = () => {
    return async(dispatch) => {
        const res = await setting.getUsebList();
        const data = res.data.content;

        for(let i = 0; i < data.length; i ++){
            if (cookie.get(`QPOPUP${data[i].pk}`) === undefined){
                data[i].open = true;
            }else{
                data[i].open = false;
            }
        }
        dispatch({type: ActionTypes.SET_POPUP_LIST, data: data})
    }
}

export const CHECK_ADMIN_NODE00 = (email) => {
    return async(dispatch) => {
        dispatch({type: ActionTypes.CHECK_ADMIN_NODE00, email: email})
    }
}
