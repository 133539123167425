import React, { useEffect } from 'react';
import QRCode from "react-qr-code";
import swal from 'sweetalert';
import scan from '../../services/myArchive/work/scan';
import './QrModal.css';

function QrModal({ setModalOpen }) {

  const [password, setPassword] = React.useState('');
  const [alphabet, setAlphabet] = React.useState('');

  const [isCheckTime, setIsCheckTime] = React.useState(false);
  const [isPerfect, setIsPerfect] = React.useState(false);
  const [passwordForCheck, setPasswordForCheck] = React.useState('');
  const [alphabetForCheck, setAlphabetForCheck] = React.useState('');


  const nextFocus = React.useRef(null);
  const prevFocus = React.useRef(null);


  const [next, setNext] = React.useState(false);
  const [qr, setQr] = React.useState('');

  useEffect(()=>{
    if(password.length === 4 && alphabet.toUpperCase().length === 1){
      setIsCheckTime(true)
    }
  },[password, alphabet])

  useEffect(()=>{
    if(passwordForCheck.length === 4 && alphabetForCheck.length === 1){
      // console.log(password, passwordForCheck)
      // console.log(alphabet, alphabetForCheck)
      if (password === passwordForCheck && alphabet.toUpperCase() === alphabetForCheck){
        setIsPerfect(true)
      }
      else {
        setPassword('');
        setAlphabet('');
        setPasswordForCheck('');
        setAlphabetForCheck('');
        setIsCheckTime(false)
        swal('비밀번호가 일치하지 않습니다. 처음부터 다시 입력해주세요.')
      }

    }
  },[passwordForCheck, alphabetForCheck])

  useEffect(()=> {
    if(isPerfect){
      createQr()
    }
  }, [isPerfect] )

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
  }


  const enterPress = (e) => {
    if (e.key === "Enter") {
      createQr();
    }
  };

  const createQr = async() => {
    if (password.length === 4 && alphabet.toUpperCase().length === 1) {
      await getData()
      setNext(true)
    }else{
      swal("정확하게 입력해주세요.")
    }
  }


  const getData = async() => {
    const res = await scan.createQr(`${password}${alphabet.toString().toUpperCase()}`, '')
    setQr(res.data)
  }

  return (
    <div className="qr-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1>QR 생성</h1>

          {
            !next ?
              <>

                <div className="qr-content" style={{marginTop: '80px'}}>
                  {isCheckTime ? '비밀번호 확인' : '4 Number + 1 Alphabet'}
                </div>
                <div>
                  <input
                    id="password"
                    className='qr-input num'
                    // autoComplete="new-password" // off 대비 모든 브라우저에서 자동완성을 막아준다고 한다
                    // autoComplete="off"
                    value={
                      isCheckTime
                        ? passwordForCheck
                        : password
                    }
                    ref={prevFocus}
                    // type="password"
                    placeholder="****"
                    maxLength={4}
                    minLength={4}
                    pattern="[0-9]+"
                    onKeyPress={(e) => {
                      // 정규표현식 /[0-9]/ 이 아니면 press X
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={
                      isCheckTime
                        ? (e) => { setPasswordForCheck(e.target.value);
                          if (e.target.value.length === 4) {
                            nextFocus.current.focus();
                          } }
                        : (e) => { setPassword(e.target.value);
                          if (e.target.value.length === 4) {
                            nextFocus.current.focus();
                          } }
                    }
                    required
                  />
                  <input
                    id="alphabet"
                    // autoComplete="new-password"
                    // autoComplete="off"
                    className='qr-input alp'
                    value={
                      isCheckTime
                        ? alphabetForCheck
                        : alphabet
                    }
                    ref={nextFocus}
                    // type="password"
                    placeholder="*"
                    maxLength={1}
                    minLength={1}
                    pattern="[A-Za-z]+"
                    onKeyPress={(e) => {
                      // 정규표현식 ^[A-Za-z]+$/i 이 아니면 press X
                      if (!/^[A-Za-z]+$/i.test(e.key)) {
                        e.preventDefault();
                      }
                      enterPress(e);
                    }}
                    onChange={
                      isCheckTime
                        ? (e) => { setAlphabetForCheck(e.target.value.toUpperCase());
                          if (e.target.value.length === 1) {
                            prevFocus.current.focus();
                          } }
                        : (e) => { setAlphabet(e.target.value.toUpperCase());
                          if (e.target.value.length === 1) {
                            prevFocus.current.focus();
                          } }
                    }
                    required
                  />
                </div>
              </>

              :
              <>
                <div className="qr-content">
                  QR코드를 스캔해주세요.
                </div>
                <QRCode value={qr} />
              </>
          }

          <div className="qr-btn">
            {
              !next ?
                <div style={{marginTop: '80px'}}>
                  <button onClick={closeModal}>취소</button>
                  {/*{*/}
                  {/*  isPerfect*/}
                  {/*    ?      <button onClick={createQr}>생성</button>*/}
                  {/*    // ? createQr()*/}
                  {/*    : null*/}
                  {/*}*/}

                </div>
                :
                <>
                  <button onClick={closeModal}>확인</button>
                </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default QrModal
