import * as ActionTypes from './actionTypes';

export const PAY = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.PAY, data: data})
    }
}

export const RESET = () => {
    return (dispatch) => {
        dispatch({type: ActionTypes.RESET})
    }
}