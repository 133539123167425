import React, { useContext, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import swal from 'sweetalert'
import * as settingAction from '../../../../../store/reducers/settings/actions'

import { useNavigate, useParams } from 'react-router-dom'
import DropDown from '../../../../../components/DropDown/DropDown'
import Footer from '../../../../../components/Footer/Footer'
import Header from '../../../../../components/Header/Header'
import SectionHeader from '../../../../../components/SectionHeader/SectionHeader'
import { checkNumber, onChangePoints, setComma } from '../../../../../hook/utils/comma'
import './MyWorkAdd.css'
import './MyWorkEdit.css'

import { workMainImage, workSubImage } from '../../../../../services/imageRoute'
import work from '../../../../../services/myArchive/work/work'


import HistoryModal from '../../../../../components/Modal/HistoryModal'
import ModalPortal from '../../../../../components/Modal/ModalPortal'
import PinModal from '../../../../../components/Modal/PinModal'
import RestoreCodeModal from '../../../../../components/Modal/RestoreCodeModal'
import { LoadingState } from '../../../../../context/loadingStateProvider'
import { WalletState } from '../../../../../context/walletStateProvider'
import { checkMainImageSize, checkSubImageSize, workImageExtension, workImageExtensionList } from '../../../../../hook/utils/fileSize'
import { setBidTime } from '../../../../../hook/utils/getDateStr'
import { checkDate, emailCheck, handleInputMaxLength } from '../../../../../hook/utils/validation'
import profile from '../../../../../services/auth/profile'
import trade from '../../../../../services/myArchive/work/trade'
import market from '../../../../../services/public/market/market'
import metamask from '../../../../../services/web3/metamask'


function MyWorkEdit() {

  const walletState = useContext(WalletState);
  const loadingState = React.useContext(LoadingState);
  const { artist } = useSelector(v => v.auth, shallowEqual)
  const dispatch = useDispatch()

  const { workId } = useParams();
  const navigate = useNavigate();
  const { undefinedList, undefinedDateList, timeSetList, rowWorkTypeList, marketList, editionList, archiveList, unitList, saleTypeList, exhibitList } = useSelector(v => v.setting.work, shallowEqual)
  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const [workTypeList, setWorkTypeList] = useState([])


  const [offLinePinModal, setOffLinePinModal] = useState(false)
  const [presentPinModal, setPresentPinModal] = useState(false)
  const [dEmail, setDEmail] = useState('')
  const [data, setData] = useState(null);

  const [newPinModal, setNewPinModal] = useState(false);
  const [sellCheckModal, setSellCheckModal] = useState(false);
  const [auctionCheckModal, setAuctionCheckModal] = useState(false);


  const [newPin, setNewPin] = useState('')
  const [restorePinModal, setRestorePinModal] = useState(false);
  const [restoreNewPinModal, setRestoreNewPinModal] = React.useState(false)
  const [restorePinToken, setRestorePinToken] = React.useState('')

  const [modalOpen, setModalOpen] = React.useState(false)
  const [exhibition, setExhibition] = React.useState([])
  const [exhibitionTable, setexhibitionTable] = React.useState(null)
  const [exhibitionTableMobile, setexhibitionTableMobile] = React.useState(null)


  React.useEffect(() => {
    if (data === null) return; 

    if(data.transactionTb !== null){
      swal('판매되어 구매확정 대기중인 작품입니다.');
      navigate(-1);
      return;
    }

    if(data.sale_ALLOW === "1"){
      swal('선물받기 이후 가능합니다.');
      navigate(-1);
      return;
    }

    checkPin()
  }, [data])


  const checkAdminNode00 = () => {
      dispatch(settingAction.CHECK_ADMIN_NODE00(artist.email))
  }

  const checkPin = async () => {
    const res = await trade.sell.checkPin(data.unique_KEY)
    setNewPinModal(res.data)
  }

  React.useEffect(() => {
    const item = [];
    for (let i = 0; i < rowWorkTypeList.length - 1; i++) {
      item.push(rowWorkTypeList[i][`${lang}`])
    }
    setWorkTypeList(item)
  }, [rowWorkTypeList])

  const [mainImage, setMainImage] = useState(null)
  const [mainImageShow, setMainImageShow] = useState(null)
  const [subImage, setSubImage] = useState([])
  const [subImageShow, setSubImageShow] = useState([])
  const [newSubImage, setNewSubImage] = useState([])
  const [oldSubImage, setOldSubImage] = useState([])

  const [koTitle, setKoTitle] = useState('')
  const [enTitle, setEnTitle] = useState('')
  const [timeSet, setTimeSet] = useState()
  const [workType, setWorkType] = useState()
  const [know, setKnow] = useState('')
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [material, setMaterial] = useState('')
  const [unit, setUnit] = useState('')
  const [edition, setEdition] = useState('')
  const [totalEdition, setTotalEdition] = useState('')
  const [totalIssue, setTotalIssue] = useState('')
  const [vertical, setVertical] = useState('')
  const [horizontal, setHorizontal] = useState('')
  const [width, setWidth] = useState('')
  const [collection, setCollection] = useState('')
  const [detail, setDetail] = useState('')
  const [saleType, setSaleType] = useState('')
  const [price, setPrice] = useState('')
  const [view, setView] = useState('')
  const [sale, setSale] = useState('')
  // const [history, setHistory] = useState([])
  const [coa, setCoa] = useState(false)
  const [count, setCount] = useState(0)

  const [bidStart, setBidStart] = useState('')
  const [bidEnd, setBidEnd] = useState('')



  React.useEffect(() => {
    checkAdminNode00()
    getDetail(workId)
  }, [workId])

  const getDetail = async (pk) => {
    const res = await work.getDetail(pk)
    setData(res.work)
  }

  React.useEffect(() => {
    if (data === null) {
      return
    }

    if (data.image === null) {
      setMainImage(null)
      setMainImageShow(null)
    } else {
      setMainImage(`${workMainImage}/${data.image}`)
      setMainImageShow(`${workMainImage}/${data.image}`)
    }



    const sub = []
    for (let i = 0; i < data.sub_IMAGE.length; i++) {
      sub.push(`${workSubImage}/${data.sub_IMAGE[i].image}`)
    }
    setSubImage(sub)
    setSubImageShow(sub)
    setKoTitle(data.title)
    setEnTitle(data.eng_TITLE)
    setTimeSet(timeSetList[data.date_TYPE - 1])
    setWorkType(data.work_TYPE)


    if (data.start_CREATE === undefinedDateList[2]) {
      setKnow(undefinedDateList[2])
    } else {
      if (data.start_CREATE.length > 5) {
        setKnow(undefinedDateList[0])
      } else {
        setKnow(undefinedDateList[1])
      }
    }

    setStart(data.start_CREATE)
    setEnd(data.end_CREATE)

    setMaterial(data.material)
    setUnit(data.unit)
    setEdition(data.edition === 0 ? '없음' : '있음')
    setTotalEdition(data.edition)
    setTotalIssue(data.edition_TOTAL)
    setVertical(data.size_X)
    setHorizontal(data.size_Y)
    setWidth(data.size_W === 0 ? '' : data.size_W)
    setCollection(data.collection)
    setDetail(data.detail_NOTE)
    setSaleType(data.price_UNIT === 1 ? '원화 (원)' : 'USD ($)')
    setPrice(data.price)
    setView(data.view === 0 ? '비공개' : '공개')
    setSale(marketList[data.market])
    setCoa(data.coa === 'true')
    // setHistory(data.exhibit_HISTORY)
    setExhibition(data.exhibit_HISTORY)

    if (data.market === 2) {
      bidData()
    }
  }, [data])

  const toTimeStamp = (str) => {
    const date = new Date(str);
    const timestamp = date.getTime();
    return timestamp.toString()
  }

  const bidData = async () => {
    const res = await market.getWorkBid(workId)
    setBidStart(setBidTime(res.auction.start_TIME))
    setBidEnd(setBidTime(res.auction.end_TIME))
  }

  React.useEffect(() => {
    if (price !== '' && price !== data.price) {
      setPrice('')
    }
  }, [saleType])


  React.useEffect(() => {
    const table = []
    const tableMobile = []
    if (exhibition.length === 0) {
      setexhibitionTable(table)
      setexhibitionTableMobile(tableMobile)
      return
    }
    // console.log(`exhi :: ${JSON.stringify(exhibition)}`)
    exhibition.map((item, index) => {
      table.push(
        <li className="ap-list" key={index}>
          <span>{index + 1}</span>
          <span>{item.exhi_NAME}</span>
          <span>{item.place}</span>
          <span>{item.country} </span>
          <span>{item.start_DATE}<br />{item.end_DATE}</span>
          <span>{item.type}</span>

          <div className="gp-liat--button">
            <button onClick={() => { setExhibition(exhibition.filter(data => data.pk !== item.pk)) }}>삭제</button>
          </div>
        </li>
      )
      tableMobile.push(
        <li className="ap-list-mb">
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전시명</span>
            <span>{item.exhi_NAME}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">개최장소</span>
            <span>
              {item.place}
            </span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">국가</span>
            <span>{item.country}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전시일</span>
            <span>{item.start_DATE}~{item.end_DATE}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전시유형</span>
            <span>{item.type}</span>
          </div>
          <div className="ap-list-mb-button">
          <button onClick={() => { setExhibition(exhibition.filter(data => data.pk !== item.pk)) }}>삭제</button>
          </div>
        </li>
      )
    })
    setexhibitionTable(table)
    setexhibitionTableMobile(tableMobile)
  }, [exhibition])

  const saveImgFile = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkMainImageSize(file.size)
    if (!check) return

    setMainImage(file);
    setMainImageShow(URL.createObjectURL(file));
  }

  const saveSubImgFile = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkSubImageSize(file.size)
    if (!check) return

    setSubImage([...subImage, file]);
    setSubImageShow([...subImageShow, URL.createObjectURL(file)]);
    // 새로운 서브 이미지
    setNewSubImage([...newSubImage, file]);
  }

  const deleteSubImgFile = (e, i) => {
    e.preventDefault();
    setSubImage(subImage.filter((img, index) => index !== i))
    setSubImageShow(subImageShow.filter((img, index) => index !== i))
    // 삭제할 서브 이미지
    setOldSubImage([...oldSubImage, subImageShow[i].toString().replace(`${workSubImage}/`, '')])
  }


  React.useEffect(() => {
    if (count === 0) return
    setStart('')
    setEnd('')
    setCount(1)
  }, [know])

  const editWork = async () => {
    if (mainImage === null) {
      swal('대표 이미지를 설정해주세요.')
      return
    }
    if (koTitle === '' && enTitle === '') {
      swal('작품명을 입력해주세요.')
      return
    }
    const checkKnow = know ?? undefinedList[0]
    if (checkKnow !== undefinedDateList[2]) {
      if (start === '') {
        swal('제작 연도를 선택해주세요.')
        return
      }
    }

    const sendStart = know === undefinedDateList[2] ? undefinedDateList[2] : start
    const sendEnd = know === undefinedDateList[2] ? '' : end
    if (material === '') {
      swal('재질을 입력해주세요.')
      return
    }
    if(unit  === unitList[4]){
      setVertical('0');
      setHorizontal('0');
      setWidth('0');
    }else if(unit === unitList[3]){
      if(
        !(vertical !== '' || vertical !== '0' ||
        horizontal !== '' || horizontal !== '0' ||
        width !== '' || width !== '0')){
          swal('시, 분, 초 중 한 가지 이상을 입력해 주시기 바랍니다.')
          return;
        }
    }else{
      if (vertical === '' || horizontal === '') {
        swal('사이즈를 입력해주세요.')
        return
      }
    }
    


    const sendTimeSet = timeSet ?? timeSetList[0]
    const sendTimeSetIndex = timeSetList.indexOf(sendTimeSet) + 1


    const sendMyWorkType = workType ?? workTypeList[0]
    const setSendWorkTypePk = rowWorkTypeList[workTypeList.indexOf(sendMyWorkType)].pk
    const sendSaleType = saleType === saleTypeList[0] ? 1 : 2
    const sendView = archiveList.indexOf(view)
    const sendSale = 0 // 마켓플레이스 미등록
    const sendEdition = edition === editionList[0]
    let sendTotalEdition = totalEdition
    let sendTotalIssue = totalIssue

    if (sendEdition) {
      sendTotalEdition = '0'
      sendTotalIssue = '0'
    } else {
      if (totalEdition === '') {
        swal('에디션 번호를 입력해주세요.')
        return
      }
      if (totalIssue === '') {
        swal('총 발행 수를 입력해주세요.')
        return
      }
    }
    if (price === '') {
      swal('가격을 입력해주세요.')
      return
    }

    const sendWidth = width === '' ? '0' : width


    loadingState.setText('작품 수정중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);
    // setLock(true)
    // const res = await work.editByArtist(data.unique_KEY, `${workMainImage}/${data.image}` === mainImage ? '' : mainImage, newSubImage, oldSubImage, koTitle, enTitle, sendTimeSetIndex, setSendWorkTypePk, sendStart, sendEnd, material, unit, vertical, horizontal, sendWidth, collection, detail, sendSaleType, price, sendView, sendSale, history, sendTotalEdition, sendTotalIssue)
    const res = await work.editByArtist(data.unique_KEY, `${workMainImage}/${data.image}` === mainImage ? '' : mainImage, newSubImage, oldSubImage, koTitle, enTitle, sendTimeSetIndex, setSendWorkTypePk, sendStart, sendEnd, material, unit, vertical, horizontal, sendWidth, collection, detail, sendSaleType, price, sendView, sendSale, exhibition, sendTotalEdition, sendTotalIssue)
    // setLock(false)
    if (!res.resultCode) {
      swal('수정에 실패했습니다.')
      loadingState.setOpen(false);
      loadingState.setText('');
      return
    }
    swal('수정이 완료되었습니다.')
    loadingState.setOpen(false);
    loadingState.setText('');
    navigate(-1)
  }

  const register = async () => {
    
    const res = await market.fixWork(data.unique_KEY, archiveList.indexOf(view), data.market, data.price, data.price_UNIT, exhibition)
    if (!res.resultCode){
      swal("관리자에게 문의해주세요.")
      return;
    }
    swal("수정이 완료되었습니다.")
    navigate(-1)
  }

  const checkPresentEmail = async () => {

    if (data.email === dEmail) {
      swal('본인에게 판매할수 없습니다.')
      return
    }

    if (!emailCheck(dEmail)) return

    // DB에 있는 이메일 검사
    const res = await profile.search(dEmail)

    if (!res.data) {
      swal('존재하지 않은 유저이메일입니다.')
      return
    }

    setPresentPinModal(true)
  }

  const checkSellEmail = async () => {

    if (data.email === dEmail) {
      swal('본인에게 판매할수 없습니다.')
      return
    }


    if (!emailCheck(dEmail)) return

    // DB에 있는 이메일 검사
    const res = await profile.search(dEmail)

    if (!res.data) {
      swal('존재하지 않은 유저이메일입니다.')
      return
    }

    setOffLinePinModal(true)
  }

  const present = async (pin) => {

    if(data.rental === '1'){
      swal('대여중인 작품입니다.\n반납받은 후 시도해주세요.');
      setPresentPinModal(false)
      return;
    }
    if(data.rental === '2'){
      swal('대여중인 예약중인 작품입니다.\n반납받은 후 시도해주세요.');
      setPresentPinModal(false)
      return;
    }
    if(data.market === 1){
      swal('판매중인 작품입니다.\n판매 취소 후 시도해주세요.')
      setPresentPinModal(false)
      return;
    }
    if(data.market === 2){
      swal('경매중인 작품입니다.\n경매 취소 후 시도해주세요.')
      setPresentPinModal(false)
      return;
    }
    loadingState.setText('소유권을 이전중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)
    const res = await trade.present.pRequst(dEmail, data.unique_KEY, pin)
    loadingState.setOpen(false)
    loadingState.setText('')
    if (!res.data) {
      swal('관리자에게 문의해주세요.')
      return
    }
    swal('선물하기가 완료되었습니다.')
    navigate(-1)
  }

  const offLindSale = async (pin) => {
    if (!emailCheck(dEmail)) {
      return
    }
    if(data.rental === '1'){
      swal('대여중인 작품입니다.\n반납받은 후 시도해주세요.');
      setPresentPinModal(false)
      return;
    }
    if(data.rental === '2'){
      swal('대여중인 예약중인 작품입니다.\n반납받은 후 시도해주세요.');
      setPresentPinModal(false)
      return;
    }
    if(data.market === 1){
      swal('판매중인 작품입니다.\n판매 취소 후 시도해주세요.')
      setPresentPinModal(false)
      return;
    }
    if(data.market === 2){
      swal('경매중인 작품입니다.\n경매 취소 후 시도해주세요.')
      setPresentPinModal(false)
      return;
    }
    loadingState.setText('소유권을 이전중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)
    const res = await trade.sell.offlineSell(dEmail, data.unique_KEY, price, pin)
    loadingState.setOpen(false)
    loadingState.setText('')
    if (!res.data) {
      swal('관리자에게 문의해주세요.')
      return
    }
    swal('오프라인 판매가 완료되었습니다.')
    navigate(-1)
  }

  // Q-CoA 초기화 되었을시 사용
  const resetNewPin = async (pin) => {
    loadingState.setText('간편패스워드 설정중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)
    const res = await trade.sell.registerPin(data.pk, data.unique_KEY, pin)
    if (!res.data) {
      swal('관리자에게 문의해주세요.')
      return
    }
    swal('간편패스워드 설정이 완료되었습니다.')
    loadingState.setOpen(false)
    loadingState.setText('')
  }


  //  Q-CoA 핀 변경 1단계
  const restorePin = async () => {
    loadingState.setText('이메일을 보내는 중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true)

    const res = await work.coaSendEmail();

    if (!res.resultCode) {
      swal('관리자에게 문의해주세요.');
      loadingState.setText('');
      loadingState.setOpen(false)
      return;
    }
    swal('이메일로 받은 인증코드를 입력해주세요.');
    loadingState.setText('');
    loadingState.setOpen(false)
    setRestorePinModal(true);
  }

  //  Q-CoA 핀 변경 2단계
  const restorePinCheckCode = async (code) => {
    loadingState.setText('인증코드 확인중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true)

    const res = await work.coaCheckCode(code);

    if (!res.resultCode) {
      swal('인증번호를 다시 확인해주세요.');
      loadingState.setText('');
      loadingState.setOpen(false)
      return false;
    }
    setRestorePinToken(res.token);

    swal('인증되었습니다.');
    loadingState.setText('');
    loadingState.setOpen(false)
    setRestorePinModal(true);
    return true;
  }

  //  Q-CoA 핀 변경 3단계
  const restoreNewPin = async (pin) => {
    loadingState.setText('간편패스워드 설정중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)

    const res = await work.coaChangePassword(workId, pin, restorePinToken)

    if (!res.resultCode) {
      swal('관리자에게 문의해주세요.');
      loadingState.setText('');
      loadingState.setOpen(false)
      return;
    }

    swal('간편패스워드 변경이 완료되었습니다.')
    loadingState.setOpen(false)
    loadingState.setText('')
  }

  const checkUnit = (type) => {
    const check = saleTypeList.indexOf(saleType);
    if(check === 1){
      swal('USD 판매는 추후 업데이트 예정입니다.')
      return
    }    
    if(type === 'sell'){
      if(data.rental === '1'){
        swal('대여중인 작품입니다.');
        return;
      }
      if(data.rental === '2'){
        swal('대여중인 예약중인 작품입니다.');
        return;
      }
      if(data.market === 1){
        swal('판매중인 작품입니다.')
        return;
      }
      if(data.market === 2){
        swal('경매중인 작품입니다.')
        return;
      }
      if(price.toString() === ''){
        swal('가격을 입력해주세요.')
        return;
      }
      if(price.toString() === '0'){
        swal('0원으로 판매는 불가능합니다.')
        return;
      }
      return setSellCheckModal(true)
    }else{
      if(data.rental === '1'){
        swal('대여중인 작품입니다.');
        return;
      }
      if(data.rental === '2'){
        swal('대여중인 예약중인 작품입니다.');
        return;
      }
      if(data.market === 1){
        swal('판매중인 작품입니다.')
        return;
      }
      if(data.market === 2){
        swal('경매중인 작품입니다.')
        return;
      }
      if(price === ''){
        swal('시작가를 입력해주세요.')
        return;
      }
      if(bidStart === ''){
        swal('경매 시작 시간을 선택해주세요.')
        return;
      }
      if(bidEnd === ''){
        swal('경매 마감 시간을 선택해주세요.')
        return;
      }
      return setAuctionCheckModal(true)
    }
  }

  const setCancel = async() => {
    const res = await market.setCancel(workId);
    if(!res.resultCode){
      if(res.message === 'NoPermission'){
        swal('해당 권한이 없습니다.');
        return;
      }
      if(res.message === "600"){
        swal('입찰자가 있어 불가능합니다.');
        return;
      }
      swal('관리자에게 문의해주세요.');
      return;
    }
    swal(data.market === 1 ? '판매 취소되었습니다.' : '경매 취소되었습니다.')
    // window.location.reload();
    navigate(-1);
    return;
  }

  const setSell = async(pin) => {

    if (artist.status !== 2) {
      swal('e-KYC 인증이 필요합니다.');
      navigate('/mypage/artist');
      return
    }

    loadingState.setText('잠시만 기다려주세요.')
    loadingState.setOpen(true)
    const type = saleTypeList.indexOf(saleType) + 1
    const res = await market.setSell(workId, price, type, pin)
    if(!res.resultCode){
      if(res.message === 'no match'){
        swal('간편패스워드가 일치하지 않습니다.')
        loadingState.setOpen(false)
        loadingState.setText('')
        return false;
      }
      swal("관리자에게 문의해주세요.")
      loadingState.setOpen(false)
      loadingState.setText('')
      return false;
    }
    swal('판매등록이 완료되었습니다.')
    loadingState.setOpen(false)
    loadingState.setText('')
    // window.location.reload();
    navigate(-1);
    return true;
  }

  const setAuction = async(pin) => {
      if (artist.status !== 2) {
        swal('e-KYC 인증이 필요합니다.');
        navigate('/mypage/artist');
        return
      }

    loadingState.setText('잠시만 기다려주세요.')
    loadingState.setOpen(true)
    const type = saleTypeList.indexOf(saleType) + 1
    const res = await market.setAuction(workId, price, toTimeStamp(bidStart), toTimeStamp(bidEnd), pin, type)
    if(!res.resultCode){
      if(res.message === 'no match'){
        swal('간편패스워드가 일치하지 않습니다.')
        loadingState.setOpen(false)
        loadingState.setText('')
        return false;
      }
      swal("관리자에게 문의해주세요.")
      loadingState.setOpen(false)
      loadingState.setText('')
      return false;
    }
    swal('경매등록이 완료되었습니다.')
    loadingState.setOpen(false)
    loadingState.setText('')
    // window.location.reload();
    navigate(-1);
    return true;
  }

  return (
    <>
      {
      sellCheckModal &&
        <ModalPortal>
          <PinModal
            time={1}
            title={'판매 등록'}
            setModalOpen={setSellCheckModal}
            setMyPin={setNewPin}
            afterAction={true}
            action={setSell}
          />
        </ModalPortal>
      }
      {
      auctionCheckModal &&
        <ModalPortal>
          <PinModal
            time={1}
            title={'경매 등록'}
            setModalOpen={setAuctionCheckModal}
            setMyPin={setNewPin}
            afterAction={true}
            action={setAuction}
          />
        </ModalPortal>
      }
      {
        newPinModal &&
        <ModalPortal>
          <PinModal
            time={2}
            title={'간편패스워드 초기화가 필요합니다.\n사용하실 간편패스워드를 입력해주세요.'}
            setModalOpen={setNewPinModal}
            setMyPin={setNewPin}
            afterAction={true}
            action={resetNewPin}
          />
        </ModalPortal>
      }
      <div className="ma-work-add">
        <Header login={true} colored="black" detail={true} />
        <nav className="search">
          <div className="container search__container">
            <h2 className="search__title">My Archive</h2>
          </div>
        </nav>
        <SectionHeader title={'Edit Work'} />
        <div className="ma-edit-image">
          <div className="container ma-edit-image-container">
            <h2 className="ma-img-tlt">대표 이미지 *
              {data?.coa !== 'true' &&
          <span className="ma-img-tlt--sub">*5MB 이하<br />({workImageExtensionList.join()} 사용가능)</span>
        }
            </h2>
            <div className="main-image-container">
              <img
                className={mainImage ? 'ma-img-show' : 'ma-img-hide'}
                src={mainImageShow}
                alt="선택 이미지"
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <button
                className={coa ? 'edit-photo ma-img-hide' : mainImage ? 'edit-photo' : 'edit-photo ma-img-hide'}
                onClick={() => { setMainImage(null); setMainImageShow(null) }}
              ></button>
              <form method="post" encType="multipart/form-data">
                <div className="button">
                  <label className="image-button" htmlFor="mainImage"></label>
                </div>
                <input
                  type="file"
                  value={""}
                  id="mainImage"
                  name="mainImage"
                  accept={workImageExtension}
                  onChange={saveImgFile}
                  style={{ display: 'none' }}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="ma-edit-image-02">
          <div className="container ma-edit-image-container">
            <div className="ma-img-tlt-container">
              <h2 className="ma-img-tlt">
                서브 이미지
                {data?.coa !== 'true' &&
                  <>
                    <span className="ma-img-tlt--sub">*최대 8개까지 가능</span>
                    <span className="ma-img-tlt--sub">*3MB 이하<br />({workImageExtensionList.join()} 사용가능)</span>
                  </>
                }
              </h2>
            </div>
            <div className="ma-sub-img-container">
              {
                subImage.map((item, i) => {
                  return (
                    <div className="sub-image-container">
                      <img
                        className={subImage[i] ? 'ma-img-show' : 'ma-img-hide'}
                        src={subImageShow[i]}
                        alt="선택 이미지"
                        onContextMenu={
                          (e) => {
                              e.preventDefault();
                              
                          }
                        }
                      />
                      <button
                        className={coa ? 'edit-photo ma-img-hide' : subImage[i] ? 'edit-photo' : 'edit-photo ma-img-hide'}
                        onClick={e => deleteSubImgFile(e, i)}
                      ></button>
                    </div>
                  )
                })
              }
              {coa ? <></> :
                subImage.length < 8 &&
                <div className="sub-image-container">
                  <img
                    className={subImage[subImage.length] ? 'ma-img-show' : 'ma-img-hide'}
                    src={''}
                    alt="선택 이미지"
                    onContextMenu={
                      (e) => {
                          e.preventDefault();
                          
                      }
                    }
                  />
                  <form method="post" encType="multipart/form-data">
                    <div className="button">
                      <label className="image-button" htmlFor={`subImage_${subImage.length}`}></label>
                    </div>
                    <input
                      type="file"
                      value={""}
                      id={`subImage_${subImage.length}`}
                      name={`subImage_${subImage.length}`}
                      accept={workImageExtension}
                      onChange={saveSubImgFile}
                      style={{ display: 'none' }}
                    />
                  </form>
                </div>
              }
            </div>
          </div>
        </div>
        <ul className="ma-add-info ma-add-info">
          <div className="container">
            <li>
              <h2>작품명 *</h2>
              <input autoComplete='off' type="text" placeholder='한글' disabled={coa} value={koTitle} onChange={e => setKoTitle(e.target.value)}></input>
            </li>
            <li>
              <h2> </h2>
              <input autoComplete='off' type="text" placeholder='ENG' disabled={coa} value={enTitle} onChange={e => setEnTitle(e.target.value)}></input>
            </li>
            <li>
              <h2>구분 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={timeSetList} disabled={coa} select={timeSetList.indexOf(timeSet)} setValue={setTimeSet} />
              </div>
            </li>
            <li>
              <h2>유형 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={workTypeList} disabled={coa} select={workTypeList.indexOf(workType)} setValue={setWorkType} />
              </div>
            </li>
            <li>
              <h2>제작 연도 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={undefinedDateList} disabled={coa} select={undefinedDateList.indexOf(know)} setValue={setKnow} />
              </div>
            </li>
            {know === undefinedDateList[0] &&
              <li>
                <h2> </h2>
                <div className="ma-add-date-mb" style={{ flex: 1 }}>
                  <input autoComplete='off'
                    disabled={coa}
                    type="date"
                    id="date"
                    maxDetail="decade"
                    min={min}
                    max={today}
                    className="date-input"
                    value={start}
                    onChange={e => checkDate(setStart, e)}
                  ></input>
                  <input
                    disabled={coa}
                    autoComplete='off'
                    type="date"
                    id="date"
                    min={min}
                    max={today}
                    className="date-input"
                    value={end}
                    onChange={e => checkDate(setEnd, e)}
                  ></input>
                </div>
              </li>
            }
            {know === undefinedDateList[1] &&
              <li>
                <h2> </h2>
                <div className="ma-add-date-mb" style={{ flex: 1 }}>
                  <input autoComplete='off'
                    disabled={coa}
                    onKeyPress={checkNumber}
                    inputMode="numeric"
                    maxLength="4"
                    placeholder='YYYY *'
                    className="date-input"
                    value={start}
                    onChange={e => setStart(e.target.value)}
                  ></input>
                  <input
                    disabled={coa}
                    autoComplete='off'
                    onKeyPress={checkNumber}
                    inputMode="numeric"
                    maxLength="4"
                    placeholder='YYYY'
                    min={min}
                    max={today}
                    className="date-input"
                    value={end}
                    onChange={e => setEnd(e.target.value)}
                  ></input>
                </div>
              </li>
            }
            <li>
              <h2>재질 *</h2>
              <input autoComplete='off' type="text" value={material} disabled={coa} onChange={e => setMaterial(e.target.value)}></input>
            </li>
            <li>
              <h2>단위 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={unitList} disabled={coa} select={unitList.indexOf(unit)} setValue={setUnit} />
              </div>
            </li>
            
            {unit !== '가변' && <>
            <li>
              <h2>사이즈 *</h2>
              <div className="ma-info-02-input">
                <input
                  disabled={coa}
                  autoComplete='off'
                  style={{ textAlign: 'end' }}
                  type="text"
                  onKeyPress={checkNumber}
                  // inputMode="numeric"
                  placeholder={unit === unitList[3] ? '시 *' : '높이(세로) *'}
                  value={setComma(vertical)}
                  onChange={e => setVertical(onChangePoints(e))}></input>
                <span>{unit === unitList[3] ? 'H' : unit}</span>
              </div>
            </li>
            <li>
              <h2> </h2>
              <div className="ma-info-02-input">
                <input
                  disabled={coa}
                  autoComplete='off'
                  style={{ textAlign: 'end' }}
                  type="text"
                  onKeyPress={checkNumber}
                  // inputMode="numeric"
                  placeholder={unit === unitList[3] ? '분 *' : '너비(가로) *'}
                  value={setComma(horizontal)}
                  onChange={e => setHorizontal(onChangePoints(e))}></input>
                <span>{unit === unitList[3] ? 'M' : unit}</span>
              </div>
            </li>
            <li>
              <h2> </h2>
              <div className="ma-info-02-input">
                <input
                  disabled={coa}
                  autoComplete='off'
                  style={{ textAlign: 'end' }}
                  type="text"
                  onKeyPress={checkNumber}
                  // inputMode="numeric"
                  placeholder={unit === unitList[3] ? '초' : '폭'}
                  value={width === '0' ? '' :setComma(width)}
                  onChange={e => setWidth(onChangePoints(e))}></input>
                <span>{unit === unitList[3] ? 'S' : unit}</span>
              </div>
            </li></>}
            <li>
              <h2>에디션 *</h2>
              <div className="ma-add-date-mb">
                <DropDown disabled={coa} options={editionList} select={editionList.indexOf(edition)} setValue={setEdition} />
              </div>
            </li>
            {edition === editionList[1] &&
              <>
                <li>
                  <h2> </h2>
                  <div className="ma-add-date-mb" style={{ flex: 1 }}>
                    <input autoComplete='off'
                      type="text"
                      readOnly={coa}
                      onKeyPress={checkNumber}
                      inputMode="numeric"
                      maxLength="5"
                      className="edtion-input"
                      placeholder='2'
                      value={setComma(totalEdition)}
                      onChange={e => setTotalEdition(onChangePoints(e))}
                      required
                    ></input>
                    <span className="date-bar">/</span>
                    <input autoComplete='off'
                      type="text"
                      readOnly={coa}
                      onKeyPress={checkNumber}
                      inputMode="numeric"
                      maxLength="5"
                      className="edtion-input"
                      placeholder='10'
                      value={setComma(totalIssue)}
                      onChange={e => setTotalIssue(onChangePoints(e))}
                    ></input>
                  </div>
                </li>
                <li>
                  <h2> </h2>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#2f80ed' }}>
                    예시) 총 에디션 10개중 2번째 작품은 2/10으로 입력
                  </div>
                </li>
              </>
            }
            <li>
              <h2>소장처</h2>
              <input disabled={coa} autoComplete='off' type="text" value={collection} onChange={e => setCollection(e.target.value)}></input>
            </li>
            <li>
              <h2>상세 설명</h2>
              <div className='content-text'>
                    <textarea
                      maxLength={500} disabled={coa} placeholder="상세한 설명입니다." value={detail} onChange={e =>handleInputMaxLength(setDetail,e)}></textarea>
                        <span>{detail === null ? '0/500' : `${detail.length}/500`}</span>
                    {/* <span>{content.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length}/2000</span> */}
                  </div>
            </li>
          </div>
        </ul>
        {coa &&
          <ul className="ma-add-info ma-add-info-02">
            <div className="container">
              <li>
                <h2>판매 방식 *</h2>
                <DropDown options={marketList} select={marketList.indexOf(sale)} setValue={setSale} />
              </li>

              {sale === '오프라인' &&
                <li>
                  <h2>구매자 이메일 *</h2>
                  <input autoComplete='off' type="text" value={dEmail} onChange={e => setDEmail(e.target.value)}></input>
                </li>
              }
              {sale === '선물하기' &&
                <li>
                  <h2>받는유저의 이메일 *</h2>
                  <input autoComplete='off' type="text" value={dEmail} onChange={e => setDEmail(e.target.value)}></input>
                </li>
              }
              {sale === '경매' &&
                <>
                  <li>
                    <h2>경매 기간 *</h2>
                    <div className="ma-add-date-mb" style={{ flex: 1 }}>
                      <input
                        autoComplete='off'
                        type="datetime-local"
                        id="date"
                        min={today}
                        className="date-input-nft"
                        value={bidStart}
                        onChange={e => checkDate(setBidStart, e)}
                      ></input>
                    </div>
                  </li>
                  <li>
                    <h2> </h2>
                    <div className="ma-add-date-mb" style={{ flex: 1 }}>
                      <input
                        autoComplete='off'
                        type="datetime-local"
                        id="date"
                        min={today}
                        className="date-input-nft"
                        value={bidEnd}
                        onChange={e => checkDate(setBidEnd, e)}
                      ></input>
                    </div>
                  </li>
                </>
              }
              {sale !== '선물하기' &&
              <>
              <li>
                <h2>{marketList.indexOf(sale) === 2 ? '시작가' : '가격'} *</h2>
                <div className="ma-add-date-mb">
                  <DropDown options={saleTypeList} select={saleTypeList.indexOf(saleType)} setValue={setSaleType} />
                </div>
              </li>
              <li>
                <h2> </h2>
                <div className="ma-info-02-input">
                  <input autoComplete='off'
                    style={{ textAlign: 'end' }}
                    type="text"
                    onKeyPress={checkNumber}
                    inputMode="numeric"
                    placeholder="1,000,000"
                    value={setComma(price)}
                    onChange={e => setPrice(onChangePoints(e))}></input>
                  <span>{saleType === saleTypeList[0] ? '원' : 'USD'}</span>
                </div>
              </li>
              </>
              }
               {sale === '비매' &&
               data.market !== 0 &&
                <>
                  <li>
                    <h2> </h2>
                    <div className="ma-add-info-03 button-container">
                      <button onClick={setCancel}>{data.market === 1 ? '판매 취소' : '경매 취소'}</button>
                    </div>
                  </li>
                </>
              }
              {sale === '판매' &&
                <>
                  <li>
                    <h2> </h2>
                    <div className="ma-add-info-03 button-container">
                      {
                        data.market === 1
                        ? <button disabled>{'판매 중'}</button>
                        : <button onClick={() => checkUnit('sell')}>{'판매 등록'}</button>
                      }
                    </div>
                  </li>
                </>
              }
              {sale === '경매' &&
                <>
                  <li>
                    <h2> </h2>
                    <div className="ma-add-info-03 button-container">
                    {
                        data.market === 2
                        ? <button disabled>{'경매 중'}</button>
                        : <button onClick={() => checkUnit('auction')}>{'경매 등록'}</button>
                      }
                    </div>
                  </li>
                </>
              }
              {sale === '선물하기' &&
                <>
                  <li>
                    <h2> </h2>
                    <div className="ma-add-info-03 button-container">
                      <button onClick={checkPresentEmail}>{'선물하기'}</button>
                    </div>
                  </li>
                </>
              }
              {sale === '오프라인' &&
                <>
                  <li>
                    <h2> </h2>
                    <div className="ma-add-info-03 button-container">
                      <button onClick={checkSellEmail}>{'오프라인 판매'}</button>
                    </div>
                  </li>
                </>
              }
              {presentPinModal &&
                <ModalPortal>
                  <PinModal
                    time={1}
                    title={'작품의 간편패스워드를 입력해주세요.'}
                    setModalOpen={setPresentPinModal}
                    // setMyPin={setDPin}
                    afterAction={true}
                    action={present}
                  />
                </ModalPortal>
              }
              {offLinePinModal &&
                <ModalPortal>
                  <PinModal
                    time={1}
                    title={'작품의 간편패스워드를 입력해주세요.'}
                    setModalOpen={setOffLinePinModal}
                    // setMyPin={setDPin}
                    afterAction={true}
                    action={offLindSale}
                  />
                </ModalPortal>
              }
            </div>
          </ul>
        }
        <ul className="ma-add-info-03 ma-add-info">
          <div className="container">
            <li>
              <h2>아트 아카이브 공개 *</h2>
              <DropDown options={archiveList} select={archiveList.indexOf(view)} setValue={setView} />
            </li>
            {coa &&
              <>
                <li>
                  <h2>NFT 민팅</h2>
                  <div className="button-container">
                    {walletState.address === null ?
                      <button onClick={() => metamask.connect(walletState)}>지갑 연결하기</button>
                      :
                      <button onClick={() => metamask.minting(walletState, data, navigate)}>민팅하기</button>
                    }
                  </div>
                </li>
                <li>
                  <h2>간편 패스워드 변경</h2>
                  <div className="button-container">
                    <button onClick={restorePin}>변경</button>
                  </div>
                </li>
                {restorePinModal && (
                  <ModalPortal>
                    <RestoreCodeModal
                      setModalOpen={setRestorePinModal}
                      setPinOpen={setRestoreNewPinModal}
                      action={restorePinCheckCode}
                    />
                  </ModalPortal>
                )}
                {
                  restoreNewPinModal &&
                  <ModalPortal>
                    <PinModal
                      time={2}
                      title={'사용하실 간편패스워드를 입력해주세요.'}
                      setModalOpen={setRestoreNewPinModal}
                      setMyPin={setNewPin}
                      afterAction={true}
                      action={restoreNewPin}
                    />
                  </ModalPortal>
                }
              </>}
          </div>
        </ul>
        {modalOpen && (
          <ModalPortal>
            <HistoryModal setModalOpen={setModalOpen} data={exhibition} setData={setExhibition} />
          </ModalPortal>
        )}
        <section className="gallery-profile-exhibition">
          <div className="container gallery-profile-exhibition-container">
            <div className="gp-edit-header">
              <div className="ap-edit-header-mb">
                <h1>전시이력</h1>
                <button onClick={() => setModalOpen(true)}>전시 추가</button>
              </div>
            </div>
            <ul className="gp-exhibition-list gp-history-list">
              <li className="gp-list--header">
                <span>번호</span>
                <span>전시명</span>
                <span>개최장소</span>
                <span>국가</span>
                <span>개최기간</span>
                <span>전시유형</span>
                <div className="gp-liat--button"></div>
              </li>
              {exhibitionTable}
              {exhibitionTableMobile}
            </ul>
          </div>
        </section>
        <div className="ma-add--button">
          <div className="container">
            <button onClick={() => navigate(-1)}>취소</button>
            <button onClick={() => coa ? register() : editWork()}>저장</button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default MyWorkEdit
