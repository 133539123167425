import React from 'react'
import './ArtDetailInfo.css'
import { Link, useNavigate } from 'react-router-dom'
import { handleImgError } from '../../services/imageRoute'

function ArtDetailInfo(props) {

  const navigate = useNavigate()
  const item = props.item
  const [info, setInfo] = React.useState([])
  const [lastInfo, setLastInfo] = React.useState([])

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  React.useEffect(() => {
    const myItem = []
    const lastItem = []
    if (item === null || item === undefined) {
      return setInfo(myItem)
    }

    for (let i = 0; i < item.infos.length; i++) {
      if (i == item.infos.length - 1) {
        lastItem.push(
          <li key={i}>
            <span className="art-detail__name">{item.infos[i].title}</span>
            {/* 이걸 왜 했을까?? */}
            {/* <span className="art-detail__content" style={ {whiteSpace : 'pre-line' , color: (item.infos[i].content == '미등록' ? '#828282' : '#219653')}}>{item.infos[i].content}</span> */}
            <span className="art-detail__content" style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>{item.infos[i].content}</span>
          </li>
        )
      } else if (item.infos[i].link) {
        myItem.push(
          <li key={i}>
            <span className="art-detail__name">{item.infos[i].title}</span>
            {/* <a href={item.infos[i].content} target="_blank" rel="noreferrer" className="art-detail__content" style={ {whiteSpace : 'pre-line', cursor: 'pointer', color: '#000' }}>
              {item.infos[i].content}
            </a> */}
            <div className='art-detail__content'>
              {hyperlink(item.infos[i]).map((link) => {
                return (
                  <a href={link.link} target='_blank' rel="noreferrer">
                    <span className="art-detail__content" style={!matches ? { lineHeight: '35rem', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', whiteSpace: 'nowrap' } : { lineHeight: '20rem', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', whiteSpace: 'nowrap' }}>{link.text}</span>
                  </a>
                )
              })}
            </div>
          </li>
        )
      } else if (item.infos[i].title === '재질') {
        myItem.push(
          <li key={i}>
            <span className="art-detail__name">{item.infos[i].title}</span>
            <span className="art-detail__content" style={{wordBreak: 'auto-phrase'}}>{item.infos[i].content}</span>
          </li>
        )
      } else {
        myItem.push(
          <li key={i}>
            <span className="art-detail__name">{item.infos[i].title}</span>
            <span className="art-detail__content" style={{ whiteSpace: 'nowrap', }}>{item.infos[i].content}</span>
          </li>
        )
      }
    }
    setInfo(myItem)
    setLastInfo(lastItem)
  }, [item])

  const findHttp = (temp) => {
    if (temp.indexOf('http') === -1) {
      return `http://${temp}`
    }
    return temp
  }

  const hyperlink = (item) => {
    const list = [];
    if (item.content === null) return list
    if (item.content.indexOf(';') === -1) {
      const json = {
        text: item.content.replaceAll(' ', ''),
        link: findHttp(item.content.replaceAll(' ', ''))
      }
      list.push(json)
      return list
    }

    const links = item.content.split(';');
    for (let i = 0; i < links.length; i++) {
      const json = {
        text: links[i].replaceAll(' ', ''),
        link: findHttp(links[i].replaceAll(' ', ''))
      }
      list.push(json)
    }
    return list
  }

  const moveOrigin = () => {
    const author = item.author;
    if (author.user_type === 2) {
      navigate(`/artdb/venue/${author.user_id}`)
    } else if (author.user_type === 1) {
      navigate(`/artdb/artist/${author.user_id}`)
    } else {
      navigate(`/artdb/collection/${author.user_id}`)
    }
  }

  return (
    item === null || item === undefined ? <></> :
      <div className="art-detail">
        <section className="container">
          <div className="art-detail-container">
            <section className="art-detail-section">
              <img
                className="art-detail__image"
                alt=''
                src={item.image}
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                    e.preventDefault();

                  }
                }
              ></img>

              <ul className="art-detail__list">
                {info}
                {lastInfo}
                {item.author ? (
                  <div className="art-detail__profile" style={{ cursor: 'pointer' }} onClick={() => moveOrigin()}>
                    <img alt='' src={item.author.image} onError={handleImgError}
                      onContextMenu={
                        (e) => {
                          e.preventDefault();

                        }
                      }></img>
                    <article className="art-detail__profile__info" >
                      <h2>
                        <span className="ad-profile__info-k">
                          {item.author.name}
                        </span>{' '}
                        {item.author.e_name}
                      </h2>
                      <h4>{item.author.email}</h4>
                    </article>
                    {/* <button className="art-detail__profile__move">팔로우</button> */}
                  </div>
                ) : (
                  <></>
                )}
              </ul>

              <div className="side-arrow-slider__list">
                {
                  item.subImage === null || item.subImage === undefined ? <></> :
                    // 작품 서브 이미지
                    item.subImage.map(src => {
                      return <div className="side-arrow-slider__item" key={src}>
                        <img
                          onContextMenu={
                            (e) => {
                              e.preventDefault();

                            }
                          }
                          className="side-arrow-image"
                          alt=''
                          src={src}
                        ></img>
                      </div>
                    })
                }
              </div>
            </section>
          </div>
        </section>
      </div>
  )
}
export default ArtDetailInfo