import React from 'react'
import { useState } from 'react'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import './SearchSlide.css'
import DicModal from '../Modal/DicModal'
import ModalPortal from '../Modal/ModalPortal'
import PageSlide from '../PageSlide/PageSlide'

function SearchSlide(props) {
  // 모달창 노출 여부 state
  const [modalOpen, setModalOpen] = useState(false)

  // 모달창 노출
  const showModal = () => {
    setModalOpen(true)
    document.body.style.overflow = 'hidden' // 모달 창 오픈 시 스크롤 불가
  }

  const koList = [
    {
      label: 'ㄱ',
      value: 'ㄱ'
    },
    {
      label: 'ㄴ',
      value: 'ㄴ'
    },
    {
      label: 'ㄷ',
      value: 'ㄷ'
    },
    {
      label: 'ㄹ',
      value: 'ㄹ'
    },
    {
      label: 'ㅁ',
      value: 'ㅁ'
    },
    {
      label: 'ㅂ',
      value: 'ㅂ'
    },
    {
      label: 'ㅅ',
      value: 'ㅅ'
    },
    {
      label: 'ㅇ',
      value: 'ㅇ'
    },
    {
      label: 'ㅈ',
      value: 'ㅈ'
    },
    {
      label: 'ㅊ',
      value: 'ㅊ'
    },
    {
      label: 'ㅋ',
      value: 'ㅋ'
    },
    {
      label: 'ㅍ',
      value: 'ㅍ'
    },
    {
      label: 'ㅌ',
      value: 'ㅌ'
    },
    {
      label: 'ㅎ',
      value: 'ㅎ'
    },
    {
      label: '기타',
      value: '1'
    }
  ]

  const enList = [
    {
      label: 'A',
      value: 'A'
    },
    {
      label: 'B',
      value: 'B'
    },
    {
      label: 'C',
      value: 'C'
    },
    {
      label: 'D',
      value: 'D'
    },
    {
      label: 'E',
      value: 'E'
    },
    {
      label: 'F',
      value: 'F'
    },
    {
      label: 'G',
      value: 'G'
    },
    {
      label: 'H',
      value: 'H'
    },
    {
      label: 'I',
      value: 'I'
    },
    {
      label: 'J',
      value: 'J'
    },
    {
      label: 'K',
      value: 'K'
    },
    {
      label: 'L',
      value: 'L'
    },
    {
      label: 'M',
      value: 'M'
    },
    {
      label: 'N',
      value: 'N'
    },
    {
      label: 'O',
      value: 'O'
    },
    {
      label: 'P',
      value: 'P'
    },
    {
      label: 'Q',
      value: 'Q'
    },
    {
      label: 'R',
      value: 'R'
    },
    {
      label: 'S',
      value: 'S'
    },
    {
      label: 'T',
      value: 'T'
    },
    {
      label: 'U',
      value: 'U'
    },
    {
      label: 'V',
      value: 'V'
    },
    {
      label: 'W',
      value: 'W'
    },
    {
      label: 'X',
      value: 'X'
    },
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'Z',
      value: 'Z'
    },
    {
      label: 'etc',
      value: '2'
    }
  ]

  const searchClick = (item, lang) => {
    props.setCurrentPage(0)
    if(props.search === item.value){
      props.setCheckLang('ko')
      props.setSearch('')
      return
    }
    props.setCheckLang(lang)
    props.setSearch(item.value)
  }

  return (
    <section className="search-slide">
      <header className="search-slide__header">
        <div className="container search-slide__header-container">
          <div className="search__header__info">
            <h2 className="search__header__info-k">{props.title_k}</h2>
            <h3 className="search__header__info-e">{props.title_e}</h3>
          </div>
          <div className="search__header__dic">
            <ul>
              {koList.map((ko, i) => {
                return (
                  <li key={ko.value}>
                    <button
                      className={props.search === ko.value ? "click" : ""}
                      onClick={() => searchClick(ko, 'ko')}
                    >{ko.label}</button>
                  </li>
                )
              })}
            </ul>
            <ul>
            {enList.map((en, i) => {
                return (
                  <li key={en.value}>
                    <button 
                      className={props.search === en.value ? "click" : ""}
                      onClick={() => searchClick(en, 'en')}
                      >{en.label}</button>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="search-modal">
            <button onClick={showModal}>
              <span>{props.option_title}</span>
              <span className="botton-arrow"></span>
            </button>
            {modalOpen && (
              <ModalPortal>
                <DicModal setModalOpen={setModalOpen} search={props.search} searchClick={searchClick}/>
              </ModalPortal>
            )}
          </div>
        </div>
      </header>

      <div className="container search-slider">
        {/* <div className="search-slider__list">
          <Slider {...settings}>{slider_items(props.items)}</Slider>
        </div> */}
        <PageSlide
          breakRows={props.breakRows}
          breakSlidesPerRow={props.breakSlidesPerRow}
          rows={props.rows}
          slidesPerRow={props.slidesPerRow}
          items={props.items}
          divItem={true}
          total={props.total}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
        />
      </div>
    </section>
  )
}
export default SearchSlide