import * as ActionTypes from './actionTypes';

export const LOGIN = () => {
    return (dispatch) => {
        dispatch({type: ActionTypes.LOGIN})
    }
}

export const LOGOUT = () => {
    return (dispatch) => {
        dispatch({type: ActionTypes.LOGOUT})
    }
}

// export const SET_GENERAL_INFO = (info) => {
//     return (dispatch) => {
//         dispatch({type: ActionTypes.SET_GENERAL_INFO, data: info})
//     }
// }

export const SET_ARTIST_INFO = (info) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SET_ARTIST_INFO, data: info})
    }
}


export const SET_GALLERY_INFO = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SET_GALLERY_INFO, data: data})
    }
}

export const SET_TYPE = (type) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SET_TYPE, data: type})
    }
}


export const SET_KYC = (type) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SET_KYC, data: type})
    }
}