import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import SectionHeader from '../SectionHeader/SectionHeader'
import './SignUpEnd.css'

import MembershipList from '../../pages/MyPage/Membership/MembershipList'
import auth from '../../services/auth/auth'
import * as authAction from '../../store/reducers/auth/actions'

function SignUpEnd() {

  const dispatch = useDispatch()
  const data = useLocation().state;
  // const data = {
  //   token: 'token',
  //   next: 2,
  //   email: 'gallery@gmail.com',
  //   phone: '01057039770',
  //   name: '이만희',
  //   type: 'LOCAL'
  // }

  React.useEffect(() => {
    autoLogin()
  }, [])

  const autoLogin = () => {
    dispatch(authAction.LOGIN());
    dispatch(authAction.SET_TYPE(data.next));
    getInfo(data.next)
  }

  const getInfo = async (type) => {
    let res;
    if (type === 2) {
      res = await auth.getAgencyInfo()
      dispatch(authAction.SET_GALLERY_INFO(res))
    } else {
      res = await auth.getArtistInfo()
      dispatch(authAction.SET_ARTIST_INFO(res.data))
    }
  }

  return (
    <div className="sign">
      <Header detail={true} />
      <SectionHeader title={"회원가입 완료"}></SectionHeader>
      <div className="container sign-container" style={{border: 'none'}}>
        {/* <div className='text-container'>
          <div className='text-area'>
            <h1 className='text-title'>반갑습니다.</h1>

            <h3><span className='check' />마이페이지로 이동해서 나만의 소장품을 관리하고</h3>
            <h3><span className='check' />큐아트 마켓플레이스에서 판매해보세요.</h3>


            <h1 className='text-title'>서비스 체험하기</h1>
            <h3><span className='check'>√</span> 총 10점의 작품을 무료로 등록하기</h3>
            <h3><span className='check'>√</span> 맴버쉽 변경 또는 중지 가능</h3>
            <h3><span className='check'>√</span> 기관회원 별도 문의 (node00@artsynapse.art)</h3>
          </div>
        </div> */}
        <MembershipList isSignup={true} level={-1}/>
        {/* <SubscribeInfo setOption={setOption} />
        <section className="sign-next">
          <button className='active' onClick={subscribeEnd}>결제하기</button>
        </section> */}
      </div>
      <Footer />
    </div>
  )
}
export default SignUpEnd
