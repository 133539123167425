import React, {useEffect, useState} from 'react';
import { Stepper } from 'react-form-stepper';

export const FindAccountStepper = (props) => {

  const {type, active} = props
  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  
  return (
    <>
    <Stepper
    steps={type}
    activeStep={active}
    connectorStateColors={true}
    style={{width: matches?'100%':'50%'}}
  
    connectorStyleConfig= {matches ? {
        completedColor: '#000000',
        activeColor: '#000000',
        size: 2,
        disabledColor: '#eee',
        
      }: 
      {
        completedColor: '#000000',
        activeColor: '#000000',
        size: 2,
        disabledColor: '#eee',
        // stepSize: '10rem'
      }
    }
    styleConfig={matches ? {
        activeBgColor: '#000000',
        activeTextColor: '#e0e0e0',
        

        completedBgColor: '#000000',
        completedTextColor: '#e0e0e0',
        size: '40rem',
        fontWeight: 400,
        circleFontSize: 12,
        labelFontSize: 10
        
      }:
      {
        activeBgColor: '#000000',
        activeTextColor: '#e0e0e0',

        completedBgColor: '#000000',
        completedTextColor: '#e0e0e0',
        size: '80rem',
        fontWeight: 400,
        circleFontSize: 18
    }
    }
/>
    </>
  )
}
