import React from 'react'
import './Footer.css'
import { useNavigate } from 'react-router-dom'
import setting from '../../services/setting'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as SettingAction from '../../store/reducers/settings/actions'
import useMobileDetect from 'use-mobile-detect-hook'

const Footer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { lang, company } = useSelector(v => v.setting, shallowEqual)

  const [cName, setCName] = React.useState('')
  const [regNum, setRegNum] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [text, setText] = React.useState('')

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);
  const isMobile = useMobileDetect().isMobile();

  React.useEffect(() => {
    if (company === null) {
      getData()
    } else {
      if (company.lang !== lang) {
        getData()
      }
    }
  }, [lang])

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  React.useEffect(() => {
    if (company === undefined) {
      return
    }
    if (company === null) {
      return
    }
    setCName(company.name)
    setRegNum(company.regNum)
    setAddress(company.address)
    setText(company.text)

  }, [company])

  const getData = async () => {
    const res = await setting.getCompany()
    const data = res.company_list
    for (let i = 0; i < data.length; i++) {
      if (data[i].lang === lang) {
        dispatch(SettingAction.SET_COMPANY_INFO(data[i]))
        break
      }
    }
  }

  return (
    <footer>
      <div className="footer-phone-header">
        <div className="footer-container">
          <span className="ir_pm">Qart</span>
        </div>
      </div>
      <div className="footer__menu">
        <div className="footer-container">
          <div className="footer__menu__item">
            <div className="footer__logo" onClick={() => navigate('/')}></div>
            <div className="item-container" >
              <ul>
                <h3>ABOUT</h3>
                {/* <li>Qart 소개</li> */}
                <li style={{ cursor: 'pointer' }} onClick={() => navigate('/support/')}>Qart 소개</li>
                <li style={{ cursor: 'pointer' }} onClick={() => navigate('/terms/condition')}>이용약관</li>
                <li style={{ cursor: 'pointer' }} onClick={() => navigate('/terms/privacy')}>개인정보취급방침</li>
                {/* <li style={{ cursor: 'pointer' }} onClick={() => navigate('/terms/promotion')}>홍보 및 마케팅 동의</li> */}
              </ul>
              <ul>
                <h3>FAQ</h3>
                <li style={{ cursor: 'pointer' }} onClick={() => navigate('/support/faq')} >FAQ</li>
                <li style={{ cursor: 'pointer' }} onClick={() => navigate('/support/inquiry')} >1 : 1 문의</li>
              </ul>
              <ul>
                <h3 >공지사항</h3>
                <li style={{ cursor: 'pointer' }} onClick={() => navigate('/support/notice')}>공지사항</li>
              </ul>
            </div>
          </div>
        </div>

        {company === null ? <></> :


          isMobile ?
            (
              <div className="footer__detail" style={{ paddingLeft: '10rem' }}>
                <div className="footer-container">
                  <div className="footer__detail__item" style={{ paddingLeft: '46rem', paddingRight: '46rem' }}>
                    <p className="detail__location">
                      {/* <span className="footer-bold"> {'(주)아트시냅스'} </span> */}
                      <span className="footer-bold"> {`${company.name}`} </span>
                    </p>
                    <p className="detail__location">
                      대표이사 :{' '}
                      {/* <span className="footer-bold">{'황빛나'}</span> */}
                      <span className="footer-bold">{company.ceoName}</span>
                    </p>
                    <p className="detail__location">
                      사업자등록번호  :{' '}
                      {/* <span className="footer-bold">{'436-86-02273'}</span> */}
                      <span className="footer-bold">{company.regNum}</span>
                    </p>
                    <p className="detail__location">
                      통신판매업신고번호 :{' '}
                      {/* <span className="footer-bold">{'제2023-부산중구-0191호'}</span> */}
                      <span className="footer-bold">{company.businessNum}</span>
                    </p>

                    <p className="detail__location">
                      주소 :{' '}
                      <span className="footer-bold">
                        {/* {'부산광역시 해운대구 센텀중앙로48, 13층 1304호'} */}
                        {company.address}
                      </span>
                    </p>
                    <div style={{ paddingTop: '24rem', whiteSpace: "break-spaces" }}>
                      <p>
                      {company.text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )

            :
            (
              <div className="footer__detail">
                <div className="footer-container">
                  <div className="footer__detail__item" >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <p style={{ width: '130rem' }}>
                        {/* <span className="footer-bold"> {'(주)아트시냅스'} </span> */}
                        <span className="footer-bold"> {company.name} </span>
                      </p>
                      <p className="detail__location" style={{ width: '160rem', paddingLeft: '40rem' }}>
                        대표이사 :{' '}
                        {/* <span className="footer-bold">{'황빛나'}</span> */}
                        <span className="footer-bold">{company.ceoName}</span>
                      </p>
                      <p className="detail__location" style={{ paddingLeft: '40rem' }}>
                        통신판매업신고번호 :{' '}
                        {/* <span className="footer-bold">{'제2023-부산중구-0191호'}</span> */}
                        <span className="footer-bold">{company.businessNum}</span>
                      </p>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <p className="detail__location" style={{ width: '240rem' }}>
                        사업자등록번호  :{' '}
                        {/* <span className="footer-bold">{'436-86-02273'}</span> */}
                        <span className="footer-bold">{company.regNum}</span>
                      </p>
                      <p className="detail__location">
                        사업자 주소 :{' '}
                        <span className="footer-bold">
                          {/* {'부산광역시 해운대구 센텀중앙로48, 13층 1304호'} */}
                          {company.address}
                        </span>
                      </p>
                    </div>

                    {/* <p style={{ paddingTop: '28rem', width: '650rem' }}>
                      {'㈜아트시냅스가 판매 당사자인 경우를 제외하고 큐아트 상의 모든 상품정보 및 거래에 대해 본 사는 통신판매중개자이며 통신판매의 당사자가 아닙니다. 이에 해당 상품 및 상품에 관한 정보와 거래에 대해 책임을 지지 않습니다.'}
                    </p>
                    <p style={{ width: '650rem' }}>
                      {'본 사이트에서 제공하는 작가, 작품, 갤러리, 기관정보를 포함한 모든 컨텐츠의 무단복제, 전송, 배포, 스크래핑 등에 대한 행위는 저작권법 및 컨텐츠산업진흥법 등 관련 법령에 의하여 엄격히 금지됩니다.'}
                    </p> */}
                    <p style={{ paddingTop: '28rem', width: '650rem', whiteSpace: "break-spaces" }}>
                      {company.text}
                    </p>
                  </div>
                </div>
              </div>
            )

        }


      </div>
    </footer>
  )
}
export default Footer
