import React from 'react'
import { Link } from 'react-router-dom'
import './AuctionHeader.css'

function AuctionHeader(props) {
  const changeMenu = e => {
    props.setMenu(e)
  }
  return (
    <header
      className="auction-header"
      style={!props.active ? { display: 'none' } : {}}
    >
      <div className="container auction-header-container">
        <ul className="auction-header-list">
          {props.moreList.menu.map((item, i) => {
            if(props.moreList.menu.length -1 === i){
                return (
                    <li
                      className={props.detail === 1 ? 'auction-header--detail' : ''}
                      key={i}
                     
                    >
                      <Link to={item.link}
                        className={i === props.choice ? 'active' : ''}  style={{color:'#e0e0e0'}}
                        >
                          {item.title}
                      </Link>
                    </li>
                  )
            }else{
                return (
                    <li
                      className={props.detail === 1 ? 'auction-header--detail' : ''}
                      key={i}
                    >
                      <Link to={item.link}
                        onClick={() => changeMenu(i)}
                        className={i === props.choice ? 'active' : ''}
                        >
                          {item.title}
                      </Link>
                    </li>
                  )
            }
            
          })}
        </ul>
      </div>
    </header>
  )
}
export default AuctionHeader
