import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import swal from 'sweetalert'
import { LoadingState } from '../../context/loadingStateProvider'
import { checkDate } from '../../hook/utils/validation'
import delivery from '../../services/myArchive/work/delivery'
import './DeliveryInfoModal.css'

function DeliveryInfoModal({ setModalOpen, data, item, setItem }) {
  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const [sendDate, setSendDate] = useState('')
  const [sendWillDate, setSendWillDate] = useState('')
  const [deliverName, setDeliverName] = useState('')
  const [trackingNumber, setTrackingNumber] = useState('')
  const [contact, setContact] = useState('')

  const loadingState = React.useContext(LoadingState);


  const [state, setState] = useState(0)

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
  }


  
  const callFunc = async() => {
    if(sendDate === ''){
      swal('발송일자를 선택해주세요.')
      return;
    }
    if(sendWillDate === ''){
      swal('배송예정일자를 선택해주세요.')
      return;
    }
    if(deliverName === ''){
      swal('배송업체명을 입력해주세요.')
      return;
    }
    if(trackingNumber === ''){
      swal('송장번호를 입력해주세요.')
      return;
    }
    if(contact === ''){
      swal('연락처를 입력해주세요.')
      return;
    }

    loadingState.setText("잠시만 기다려주세요.");
    loadingState.setOpen(true);
    const res = await delivery.deliveryConfirmed(data.transactionPK, deliverName, sendDate, sendWillDate, trackingNumber, contact)

  
    if(!res.resultCode){
      swal('관리자에게 문의해주세요.');
      loadingState.setOpen(false);
      loadingState.setText("");
      return;
    }


    const newData = {
      deliveryCompany: deliverName,
      sendDate: sendDate,
      scheduledDate: sendWillDate,
      invoiceNumber: trackingNumber,
      contact: contact
    }
    setItem(
      item.map(v =>
        v.transactionPK === data.transactionPK ? { ...v, sendDeliveryInfo: newData } : v
      )
    );

    loadingState.setOpen(false);
    loadingState.setText("");
    setState(1)
  }
  
  return (
    state === 0 ?
    <div className="delivery-info-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()} >
          <h1 style={{ whiteSpace: 'pre-wrap', textAlign: 'center'}}>발송정보 입력</h1>
          <ul>
          <li>
            <h2>발송일자</h2>
            <input
              type="date"
              id="date"
              className="date-input"
              value={sendDate}
              onChange={e => checkDate(setSendDate, e)}
              min={min}
              max={today}
            ></input>
           </li>
           <li>
            <h2>배송예정일자</h2>
            <input
              type="date"
              id="date"
              className="date-input"
              value={sendWillDate}
              onChange={e => checkDate(setSendWillDate, e)}
              min={sendDate === '' ? min : sendDate}
            ></input>
           </li>
           <li>
            <h2>배송업체명</h2>
            <input autoComplete='off' type="text" value={deliverName} onChange={e => setDeliverName(e.target.value)} style={{width:'0rem'}}></input>
           </li>
           <li>
            <h2>송장번호</h2>
            <input autoComplete='off' type="text" value={trackingNumber} onChange={e => setTrackingNumber(e.target.value)} style={{width:'0rem'}}></input>
           </li>
           <li>
            <h2>연락처</h2>
            <input
            autoComplete='off'
            type="number"
            value={contact}
            onKeyPress={(e) => {
              // 정규표현식 /[0-9]/ 이 아니면 press X
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            placeholder="-를 제외하고 입력해주세요."
            onChange={e => setContact(e.target.value)}
            style={{width:'0rem'}}></input>
           </li>
          </ul>

          <div className="delivery-btn">
            <button onClick={closeModal}>취소</button>     
            <div style={{width:12}}></div>
            <button onClick={callFunc}>확인</button>
          </div>
        </div>
      </div>
    </div>
    : 
    <div className='delivery-done-modal'>
        <div className="modal-container" onClick={closeModal}>
            <div className="modal-done-body" onClick={e => e.stopPropagation()}>
                <div className='delivery-done-modal-about'>작품번호 : {data.deliveryTb.workInfo.unique_KEY.slice(0,13)}</div>
                <h2>
                    위 작품에 대한 발송정보 등록이 완료되었습니다. <br></br>
                    이메일로 발송된 정보를 확인해주세요.
                </h2>
                <div className="delivery-done-btn">
                <button onClick={closeModal}>확인</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DeliveryInfoModal
