import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import SearchHeader from '../../../components/SearchHeader/SearchHeader'


function QartsTalkMain(props){

    return (
    <div>
        <Header active="2" colored="black" />
        <SearchHeader
        title="Marketplace"
        active={props.search}
        placeholder="작가, 작품, 콜렉션을 검색하세요"
        />
        {props.children}
        <Footer />
    </div>
    )
}

export default QartsTalkMain