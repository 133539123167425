import { setComma } from "./comma";

export const artViewDate = (startDate, endDate) => {
    if (startDate === '미상') {
      return `${startDate}`;
    } else if (endDate === '') {
      return `${startDate}`;
    } else if (startDate !== '미상') {
      return `${startDate} ~ ${endDate}`;
    } else {
      // 여기는 작동 안함
      return `${startDate.replaceAll("-", ".")} - ${endDate.replaceAll(
        "-",
        "."
      )}`;
    }
  };

  export const tableDate = (startDate, endDate) => {
    if (startDate === '미상') {
      return `${startDate}`;
    } else if (startDate !== '미상') {
      return `${startDate}\n${endDate}`;
    } else {
      // 여기는 작동 안함
      return `${startDate.replaceAll("-", ".")} - ${endDate.replaceAll(
        "-",
        "."
      )}`;
    }
  };
  
  export const artViewSize = (sizeX, sizeY, sizeW, unit) => {
    if (+sizeX === 0 && +sizeY === 0 && +sizeW === 0) {
      return "Variable Size, 가변";
    } else {
      if (+sizeX > 0 && +sizeY > 0 && +sizeW === 0) {
        const twoSize = `${setComma(sizeX)}×${setComma(sizeY)}`;
        switch (unit) {
          case "inch":
            return `${twoSize} ㏌`;
          case "cm":
            return `${twoSize} ㎝`;
          case "시간":
            return `${sizeX}h : ${sizeY}m`;
          default:
            return `${twoSize} px`;
        }
      } else {
        const threeSize = `${setComma(sizeX)} × ${setComma(sizeY)} × ${setComma(sizeW)}`;
        switch (unit) {
          case "inch":
            return `${threeSize} ㏌`;
          case "cm":
            return `${threeSize} ㎝`;
          case "시간":
            return `${sizeX}h : ${sizeY}m : ${sizeW}s`;
          default:
            return `${threeSize} px`;
        }
      }
    }
  };
  

  export const artViewPrice = (unit, price) => {

    if( unit === 2){
      return `${setComma(price)} USD`
    }
    return `${setComma(price)} 원`
  };