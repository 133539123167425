import React from "react";
  import QrReader from "react-web-qr-reader";
  
  const WebScanner = (props) => {
    const delay = 500;
  
    const previewStyle = {
      height: 500,
      width: 500
    };

    const {setValue, step, setStep, done, doneFunc} = props;

    const handleScan = (data) => {
      setValue(data.data)
      setStep(step)
      if(done === 'done'){
        doneFunc()
      }
    };
  
    const handleError = (error) => {
      console.log(error);
    };
  
    return (
      <>
        <QrReader
          delay={delay}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          facingMode="user"
        />
      </>
    );
  };
  
  export default WebScanner;
  