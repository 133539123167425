import React, { useEffect, useState } from 'react';
import video from '../../../assets/qcoa_explain.mp4';
import './QCoAAboutNew.css';


function QCoAAbout() {

    const mediaMatch = window.matchMedia('(max-width: 480px)');
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    return (
        <div className="qcoa-about">
            <div className="container">
                <article className="qcoa-01">
                    <h2>Q-CoA</h2>
                    <p>
                        <span className='color-blue'> Qart Certificate of Art Registry</span>
                    </p>
                    <div className='rectangle'></div>
                    <p><span className='p-size-32'> 세상에 없었던 작품이력증서 </span></p>
                    <div className='rectangle'></div>
                    <p>
                        큐아트의 아트데이터베이스에 작품을 등록하고 Q-CoA를 발급받으면 <br></br>
                        소유권 이전이나 전시 출품으로 인한 <br></br>
                        작품의 이동 기록이 모두 Q-CoA에 등록됩니다.
                    </p>
                </article>
                <article className="qcoa-02" >
                    <p>
                        <span className='bold-12'>Q-CoA(큐-씨오에이)란?</span>
                    </p>
                    <br></br>

                    {
                        matches ?
                            (
                                <p>
                                    {/* <span className="bold">Q-CoA</span> */}
                                    큐아트만의 독자적인 블록체인 작품 등록 시스템을 적용한 <br></br>차별화된 작품이력증서입니다.<br></br><br></br>
                                    Q-CoA는 작가, 갤러리, 미술관, 콜렉터 등 미술계의 다양한 참여자를 위한 혁신적인 시스템입니다. 큐아트는 작품 아카이빙,<br></br>
                                    소장품 관리, 작품 판매를 획기적으로 개선시킨 Q-CoA를 통해 건강하고<br></br> 투명한 미술 생태계를 만들어가고자 합니다.
                                </p>
                            )
                            :
                            (
                                <p>
                                    {/* <span className="bold">Q-CoA</span> */}
                                    큐아트만의 독자적인 블록체인 작품 등록 시스템을 적용한 차별화된 작품이력증서입니다.<br></br>
                                    Q-CoA는 작가, 갤러리, 미술관, 콜렉터 등 미술계의 다양한 참여자를 위한 혁신적인 시스템입니다. 큐아트는 작품 아카이빙,<br></br>
                                    소장품 관리, 작품 판매를 획기적으로 개선시킨 Q-CoA를 통해 건강하고 투명한 미술 생태계를 만들어가고자 합니다.
                                </p>
                            )
                    }


                </article>
            </div>
            <div className='grid-container'>
                <div className="grid-square">
                    <p>
                        훼손 위험X
                    </p>
                </div>
                <div className="grid-square">
                    <p>
                        위변조 X
                    </p>
                </div>
                <div className="grid-square">
                    <p>
                        부정확성, 번거로움X
                    </p>
                </div>
                <div className="grid-square" style={{ background: '#006CFE' }}>
                    <p style={{ color: '#ffffff' }}>
                        작품 전시이력 확인 O
                    </p>
                </div>
            </div>
            <div className="container">
                <video
                    width={matches ? 320 : 800}
                    height={matches ? 180 : 470}
                    preload="metadata"
                    controls
                    playsInline >
                    {/* <source src={video} type="video/mp4" /> */}
                        <source src={`${video}#t=0.1`} type="video/mp4" />
                </video>
            </div>
            <div style={{ height: 24 }}></div>
            <div className='container2'>
                <div className='long-rectangle'></div>
                <article className='qcoa-03'>
                    <p style={{ flex: 1, textAlign: 'left', }}>
                        <span className='bold'>Q-CoA의 기술적 차별성</span>
                    </p>
                    <p style={{ flex: 2, textAlign: 'left' }}>
                        Q-CoA는 생성된 순간부터 해당 작품의 이력을 추적하여 블록체인에 기록하고, 해당 정보를 통해 모든 소유권을 확인할 수 있도록 하는 블록체인 작품이력증서입니다.<br></br>
                        Q-CoA에 부착된 QR코드를 스캔하면 실제로 발급된 보증서를 큐아트 홈페이지에서 확인할 수 있으며 블록체인 에어 갭이 최소화되도록 하는 기술적 특징을 보유하고 있습니다. 이를 통해 실물 미술품의 온라인 판매와 오프라인 판매를 완벽히 지원합니다.
                    </p>
                </article>
                <div className='long-rectangle'></div>
                <article className='qcoa-03'>
                    <p style={{ flex: 1, textAlign: 'left', }}>
                        <span className='bold'>Q-CoA의 편리성</span>
                    </p>
                    <p style={{ flex: 2, textAlign: 'left' }}>
                        Q-CoA는 작품의 등록, 전시, 판매 시 간단한 핀 번호(PIN) 설정으로 소유권과 작품의 이동을 관리할 수 있습니다. 장소와 시간에 관계없이 언제 어디서든 작품을 사고 팔 수 있으며, 전시와 아트 페어 출품 시 필요한 대여 기능과 반납 기능을 지원합니다.
                        Q-CoA에는 소유권 이전과 작품 이동이 모두 기록되므로 작품의 거래와 전시 이력을 한 눈에 확인할 수 있습니다.
                    </p>
                </article>
                <div className='long-rectangle'></div>
                <article className='qcoa-03'>
                    <p style={{ flex: 1, textAlign: 'left', }}>
                        <span className='bold'>Q-CoA의 보안성</span>
                    </p>
                    <p style={{ flex: 2, textAlign: 'left' }}>
                        허가형 블록체인을 이용하는 Q-CoA는 큐아트가 허가한 참여자만이 블록체인 합의 과정에 참여할 수 있어 악의적인 접근이 불가능합니다. Q-CoA 생성 및 작품 소유권 인증을 위해 사용하는 핀 번호(PIN)는 시스템 내부에 저장되지 않으므로 유출 및 악용 가능성이 현저히 낮습니다.
                        또한 사용자의 개인정보는 블록체인에 저장되지 않으므로 개인정보 유출 우려가 없습니다.
                    </p>
                </article>

                <div className='bottom-height'></div>
            </div>
        </div>
    )
}
export default QCoAAbout

