import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import './SignUp.css'
import ModalPortal from '../Modal/ModalPortal'
import CheckModal from '../Modal/CheckModal'

function SignUp() {
  const navigate = useNavigate();

  const [select, setSelect] = useState(5)
  const data = useLocation().state;

  const [modal, setModal] = useState(false)



  const next = () => {
    setModal(false)
    navigate('/auth/cert/kyc', {state : {
      next: select, email: data?.email, token: data?.token, type: data?.type === undefined ? 'LOCAL' : data.type
    }})
  }


  return (
    <div className="sign">
      <Header detail={true} />
      <div className="container sign-container">
        <div className="sign-list">
          <a
            className={select === 1 ? 'sign-item sign-active' : 'sign-item'}
            onClick={() => setSelect(1)}
          >
            <div className="sign-item__content">
              <img
                alt='Artist'
                src={require('../../assets/auth_icon.jpg')}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
              <section>
                <h1>작가</h1>
                <span>Artist</span>
              </section>
            </div>
            <div className="sign-item__hover">
              <span>
              창작한 작품을 등록, 관리, 판매하고 싶은 개인 및 단체는 작가로 등록해주세요.
              </span>
            </div>
          </a>
          <a
            className={select === 2 ? 'sign-item sign-active' : 'sign-item'}
            onClick={() => setSelect(2)}
          >
            <div className="sign-item__content">
              <img
                alt='Collector / Museum,Agency'
                src={require('../../assets/auth_icon.jpg')}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
              <section>
                <h1>기관</h1>
                <span>Gallery & Museum</span>
              </section>
            </div>
            <div className="sign-item__hover">
              <span>
              소장작품을 등록, 관리, 판매하고 싶은 갤러리 및 미술관은 기관으로 등록해주세요.
              </span>
            </div>
          </a>
          <a
            className={select === 0 ? 'sign-item sign-active' : 'sign-item'}
            onClick={() => setSelect(0)}
          >
            <div className="sign-item__content">
              <img
                alt='General'
                src={require('../../assets/auth_icon.jpg')}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
              <section>
                <h1>개인</h1>
                <span>Collection</span>
              </section>
            </div>
            <div className="sign-item__hover">
              <span>
              작가나 기관이 아닌 분들은 개인으로 등록해주세요.
              </span>
            </div>
          </a>
        </div>
        <section className="sign-next">
            {/* <Link to="/auth/cert/kyc" state={{ next: select, email: data?.email, token: data?.token, type: data?.type }}> */}
            <button onClick={() => setModal(true)} className={select !== 5 ? 'active' : ''}>다음</button>
            {/* </Link>   */}

            {modal && (
            <ModalPortal>
              <CheckModal
          setModalOpen={setModal}
          setFunc={next}
          title={'성인인증'}
          // content={'본인이 맞으십니까?'}
          content={'만 19세 이상만 가입 가능합니다. \n본인이 맞으십니까?'}
          isAuth={true}
          ></CheckModal>
            </ModalPortal>
          )}
        </section>
      </div>
      <Footer />
    </div>
  )
}
export default SignUp
