import React from 'react';
import swal from 'sweetalert';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import ModalPortal from '../../../../components/Modal/ModalPortal';
import SectionHeader from '../../../../components/SectionHeader/SectionHeader';
import './GalleryAgency.css';

import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropDown from '../../../../components/DropDown/DropDown';
import AddressModal from '../../../../components/Modal/AddressModal';
import { checkDate } from '../../../../hook/utils/validation';
import profile from '../../../../services/auth/profile';

function GalleryAgency() {
  const [date, setDate] = React.useState('');
  const [type, setType] = React.useState(null);
  const [name, setName] = React.useState('');
  const [detail1, setDetail1] = React.useState('');
  const [detail2, setDetail2] = React.useState('');
  const [addressOpen, setAddressOpen] = React.useState(false);


  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const navigate = useNavigate();

  const done = async () => {
    if (date === '') {
      swal('일자을 입력해주세요.')
      return
    }

    if(type === typeList[1]){
      if(name === ''){
        swal('분지점명을 입력해주세요.');
        return;
      }
    }
    
    if (detail1 === '') {
      swal('내용을 입력해주세요.')
      return
    }

    if ((type === typeList[1] || type === typeList[2]) && detail2 === '') {
      swal('내용을 입력해주세요.')
      return
    }

    let detail;

    if(type === typeList[1]){
      detail = `${name},\n${detail1}\n${detail2}`
    }else if(type === typeList[2]){
      detail = `${detail1}\n${detail2}`
    }else{
      detail = detail1
    }
    
    const res = await profile.v2_addAgency(type, date, detail);

    if (!res.resultCode) {
      swal('저장에 실패했습니다.')
      return
    }
    swal('저장 완료 했습니다.')
    navigate('/mypage/gallery')
  }

  const typeList = ['기관명 변경', '분지점 설립', '주소 이전', '대표자 변경']
  const typeShowList = ['변경 기관명', '분지점 설립', '주소 이전', '변경 대표자명']

  React.useEffect(() => {
    setDate('')
    setDetail1('')
    setDetail2('')
    setName('')
  },[type])

  return (
    <div className="gallery-agency">
      <Header login={true} colored="black" detail={true} />
      <nav className="search">
        <div className="container search__container">
          <h2 className="search__title">마이페이지</h2>
        </div>
      </nav>
      <SectionHeader title={'기관 이력'} />
      <div style={matches ? {} :{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', minWidth: '1920rem', margin: '0 auto' }}>
        <div className="gallery-agency-content">
          <div className="container">
            {addressOpen && (
              <ModalPortal>
                <AddressModal setModalOpen={setAddressOpen} setAddress={setDetail1} />
              </ModalPortal>
            )}
            <ul>
            <li>
                <h2>내용 *</h2>
                <div className="ma-add-info">
                <DropDown
                    options={typeList}
                    select={typeList.indexOf(type)}
                    // select={type === null ? typeList[0] : type}
                    setValue={setType}
                  />
                </div>
              </li>
              <li>
                <h2>일자 *</h2>
                <input
                  value={date}
                  onChange={e => checkDate(setDate, e)}
                  type="date"
                  id="date"
                  className="date-input"
                  min={min}
                  max={today}
                ></input>
              </li>
              
              {
                type !== null &&  type !== typeList[2] && type !== typeList[1] &&
                <li>
                  <h2>{typeShowList[typeList.indexOf(type)]} *</h2>
                  <input value={detail1} onChange={e => setDetail1(e.target.value)} type="text"></input>
                </li>
              }

{
                type === typeList[1] &&
                <li>
                  <h2>분지점명 *</h2>
                  <input value={name} onChange={e => setName(e.target.value)} type="text"></input>
                </li>
              }

              {
                type !== null && (type === typeList[1] || type === typeList[2]) &&
                (<>
                  <li>
                    <h2>주소 *</h2>
                    <div className="search__block">
                      <input
                        type="text"
                        value={detail1}
                        className="search__block"
                        readOnly
                      ></input>
                      <button className="search__block__button" onClick={() => setAddressOpen(true)}>
                        <span className="ir_pm">검색</span>
                      </button>
                    </div>
                  </li>
                  <li>
                    <h2> </h2>
                    <input value={detail2} onChange={e => setDetail2(e.target.value)} type="text"></input>
                  </li>
                </>)
              }
            </ul>
            <div className="a-ex-edit--button">
              <button onClick={() => navigate(-1)}>취소</button>
              <button onClick={done}>저장</button>

            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}
export default GalleryAgency
