import React, { useEffect, useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import CardInfoModal from '../../../components/Modal/CardInfoModal';
import ModalPortal from '../../../components/Modal/ModalPortal';
import OkCancelModal from '../../../components/Modal/OkCancelModal';
import { LoadingState } from '../../../context/loadingStateProvider';
import { checkWorkCountByMembership } from '../../../hook/utils/checkWorkCountByMembership';
import { setComma } from '../../../hook/utils/comma';
import auth from '../../../services/auth/auth';
import pay2pay from '../../../services/pay2pay/pay';
import * as authAction from '../../../store/reducers/auth/actions';
import './MembershipList.css';


function MembershipList(props) {
    const { isSignup, level } = props
    const loadingState = React.useContext(LoadingState);
    const dispatch = useDispatch();


    const navigate = useNavigate();
    const mediaMatch = window.matchMedia('(max-width: 480px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const { gallery, artist, type } = useSelector(v => v.auth, shallowEqual)

    const [modal, setModal] = useState(false)
    const [selected, setSelected] = useState()
    const [upgradeModal, setUpgradeModal] = useState(false)
    const [unsubscribeModal, setUnsubscribeModal] = useState(false)
    const [downgradeModal, setDowngradeModal] = useState(false)
    const [upgradeFee, setUpgradeFee] = useState(0)


    const subscribe = async (selected) => {

        if (selected === 'STARTER') {
            
            if (isSignup) {
                swal('맴버십 적용이 완료되었습니다.')
                return navigate('/');
            } else {
                return setUnsubscribeModal(true);
            }
        }
        if (isSignup) {
            setSelected(selected)
            setModal(true);
            return;
        }
        let check;
        if (type === 2) {
            check = gallery.userLevelTb === null;
        } else {
            check = artist.userLevelTb === null;
        }

        if (!check) {
            // 빌링키가 있음
            const orderTyp = selected === 'BASIC' ? 1 : selected === "PREMIUM" ? 2 : 0;
            let myType;
            if (type === 2) {
                myType = gallery.userLevelTb.userLevel;
            } else {
                myType = artist.userLevelTb.userLevel;
            }

            if (orderTyp === 0) {
                // 맴버십 해지
                setUnsubscribeModal(true);
                return;
            }else if (+myType > orderTyp) {
                //다운 그래이드
                setDowngradeModal(true);
                return;
            } else {
                // 업그레이드
                const fee = await pay2pay.checkUpgradeFee();
                setUpgradeFee(fee.amount)
                setUpgradeModal(true);
                return;
            }
        }else{
            // 멤버십 최초 결제
            setSelected(selected)
            setModal(true);
        }
    }

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });


    const unsubscribe = async () => {
        const checkCount = await checkWorkCountByMembership(selected);
                if(!checkCount){
                    return;
                }
                loadingState.setText('처리중입니다.\n잠시만 기다려주세요.');
                loadingState.setOpen(true);
                const exit = await pay2pay.unsubscribe();
                await setInfo()
                window.location.reload();
                loadingState.setOpen(false);
                loadingState.setText('');
                // setChangePage(0)
    }

    const downgrade = async () => {
        const checkCount = await checkWorkCountByMembership(selected);
                if(!checkCount){
                    return;
                }
                loadingState.setText('처리중입니다.\n잠시만 기다려주세요.');
                loadingState.setOpen(true);
                const downgrade = await pay2pay.downgrade()
                // console.log(downgrade)
                
                await setInfo()
                window.location.reload();
                loadingState.setOpen(false);
                loadingState.setText('');
                return;
    }
    const upgrade = async () => {
        loadingState.setText('처리중입니다.\n잠시만 기다려주세요.');
        loadingState.setOpen(true);
        const upgrade = await pay2pay.upgrade()
        if (!upgrade.resultCode) {
            swal(upgrade.RESULTMSG.replaceAll(' ', ''));
            loadingState.setOpen(false);
            loadingState.setText('');
            return;
        }

        await setInfo()
        window.location.reload();
        loadingState.setOpen(false);
        loadingState.setText('');
        return;
    }

    const setInfo = async () => {
        if (type === 2) {
            const agency = await auth.getAgencyInfo()
            dispatch(authAction.SET_GALLERY_INFO(agency))
        } else {
            const artist = await auth.getArtistInfo()
            dispatch(authAction.SET_ARTIST_INFO(artist.data))
        }
    }

    return (
        <>
            {modal && (
                <ModalPortal>
                    <CardInfoModal
                        setModalOpen={setModal}
                        selected={selected}
                        isSignup={isSignup} />
                </ModalPortal>
            )}

            {unsubscribeModal && (
                <ModalPortal>
                    <OkCancelModal
                        setModalOpen={setUnsubscribeModal}
                        setFunc={unsubscribe}
                        title={'멤버십 해지'}
                        content={`적용중인 멤버십 기간이 끝나면 해지됩니다.`}
                        showCancel={unsubscribeModal}
                    ></OkCancelModal>
                </ModalPortal>
            )}

            {downgradeModal && (
                <ModalPortal>
                    <OkCancelModal
                        setModalOpen={setDowngradeModal}
                        setFunc={downgrade}
                        title={'멤버십 다운그래이드'}
                        content={`적용중인 멤버십 기간이 끝나면 적용됩니다.`}
                        showCancel={downgradeModal}
                    ></OkCancelModal>
                </ModalPortal>
            )}
            {upgradeModal && (
                <ModalPortal>
                    <OkCancelModal
                        setModalOpen={setUpgradeModal}
                        setFunc={upgrade}
                        title={'멤버십 업그레이드'}
                        content={`멤버십 업그레이드 비용은\n${setComma(upgradeFee)}원 입니다.`}
                        showCancel={upgradeModal}
                    ></OkCancelModal>
                </ModalPortal>
            )}

            <div className='member-ship-list'
                style={isSignup ? { minWidth: 0 } : {}}>
                <div className={isSignup ? '' : 'container'}>

                    {matches
                        ? <></>
                        : isSignup ?
                            <>
                                <h1>반갑습니다!</h1>
                                <h2 style={{ textAlign: 'left', marginTop: '20rem', fontWeight: 500 }}>마이페이지로 이동해서 나만의 소장품을 관리하고, 큐아트 마켓플레이스에서 판매해보세요!</h2>
                            </>
                            : <h1>나의 멤버십 변경</h1>
                    }


                    <div className={'grid-container'}>
                        <div className='member-ship-list-box'>
                            <p style={{ paddingTop: '32rem' }}>Starter</p>
                            <h3>스타터</h3>
                            <h2 style={{ paddingTop: '40rem' }}><span className='color-blue bold'>무료로 체험</span></h2>
                            <div>
                                <p style={{ paddingTop: '40rem' }}><BiCheck color='#BDBDBD' />큐아트 작품 관리시스템 체험하기<span className='color-blue'> (+10점)</span></p>
                                <p style={{ paddingTop: '14rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />큐아트 블록체인에<br></br>   Q-CoA 등록하고 증서 만들기<span className='color-blue'> (+10점)</span></p>
                                <p style={{ paddingTop: '14rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />마켓플레이스에서<br></br>   등록한 작품 판매해 보기<span className='color-blue'> (+10점)</span></p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />상세정보 보기와 작품 구매하기<span className='color-blue'> (+무제한)</span></p>
                            </div>
                            <button className='do-pay' style={{ marginTop: '184rem' }} onClick={() => subscribe('STARTER')} disabled={level === 0}>{level === 0 ? "이용중" : !isSignup ? "변경하기" : "계속하기"}</button>
                        </div>
                        <div className='member-ship-list-box'>
                            <p style={{ paddingTop: '32rem' }}>Basic</p>
                            <h3>베이직</h3>
                            <h2 style={{ paddingTop: '40rem' }}><span className='color-blue bold'>99,000원/년</span></h2>
                            <div>
                                <p style={{ paddingTop: '40rem' }}><BiCheck color='#BDBDBD' />내 작품 클라우드 생성</p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />큐아트 클라우드에 내 작품 저장 <span className='color-blue'> (+30점)</span></p>
                                <p style={{ paddingTop: '14rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />블록체인 기반 <br></br>   Q-CoA 등록과 증명 발급 <span className='color-blue'> (+30점)</span></p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />작품 입출고 관리시스템 제공 <span className='color-blue'> (+30점)</span></p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />큐픽 Qart’s pick 연 2회</p>
                                <p style={{ paddingTop: '14rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />언제 어디서나<br></br>   안전하고 편리한 작품 통합 관리</p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />24시간 마켓플레이스</p>
                            </div>
                            <button className='do-pay' style={{ marginTop: '82rem' }} onClick={() => subscribe('BASIC')} disabled={level === 1}>{level === 1 ? "이용중" : !isSignup ? "변경하기" : "결제하기"}</button>
                        </div>
                        <div className='member-ship-list-box'>
                            <p style={{ paddingTop: '32rem' }}>Premium</p>
                            <h3>프리미엄</h3>
                            <h2 style={{ paddingTop: '40rem' }}><span className='color-blue bold'>199,000원/년</span></h2>
                            <div>
                                <p style={{ paddingTop: '40rem' }}><BiCheck color='#BDBDBD' />내 작품 클라우드 생성</p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />큐아트 클라우드에 내 작품 저장 <span className='color-blue'> (+100점)</span></p>
                                <p style={{ paddingTop: '14rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />블록체인 기반 <br></br>   Q-CoA 등록과 증명 발급 <span className='color-blue'> (+100점)</span></p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />작품 입출고 관리시스템 제공 <span className='color-blue'> (+100점)</span></p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />큐픽 Qart’s pick 연 4회</p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />큐픽 Qart’s talk 연 1회</p>
                                <p style={{ paddingTop: '14rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />언제 어디서나<br></br>   안전하고 편리한 작품 통합 관리</p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />24시간 마켓플레이스</p>
                            </div>
                            <button className='do-pay' style={{ marginTop: '47rem' }} onClick={() => subscribe('PREMIUM')} disabled={level === 2}>{level === 2 ? "이용중" : !isSignup ? "변경하기" : "결제하기"}</button>
                        </div>
                        <div className='member-ship-list-box'>
                            <p style={{ paddingTop: '32rem' }}>Business</p>
                            <h3>비즈니스</h3>
                            <h2 style={{ paddingTop: '40rem' }}><span className='color-blue bold'>문의</span></h2>
                            <div>
                                <p style={{ paddingTop: '40rem' }}><BiCheck color='#BDBDBD' />대량작품 등록</p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />미술품 관리 인증 맞춤 시스템 구축</p>
                                <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />기타 문의</p>
                            </div>
                            <button className='inquire' style={{ marginTop: '255rem' }} onClick={() => navigate('/support/inquiry')}>문의하기</button>
                        </div>
                    </div>
                    {
                        !matches ? <p style={{ margin: '10rem 0' }}>*모든 이용요금은 부가세(10%) 포함입니다.</p> : <></>
                    }

                </div>
                <div className='grid-mb-container'>
                    <div className='member-ship-list-mb-box' style={{ height: '250rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                            <h2>스타터<span style={{ fontSize: '12rem', whiteSpace: 'pre-wrap', fontWeight: 500 }}> Starter</span> </h2>
                            <button className='do-pay' onClick={() => subscribe('STARTER')} disabled={level === 0}>{level === 0 ? "이용중" : "시작하기"}</button>
                        </div>
                        <h1 style={{ paddingTop: '0rem' }}><span className='color-blue bold'>무료로 체험하기</span></h1>
                        <div>
                            <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />큐아트 작품 관리시스템 체험하기<span className='color-blue'> (+10점)</span></p>
                            <p style={{ paddingTop: '10rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />큐아트 블록체인에<br></br>   Q-CoA 등록하고 증서 만들기<span className='color-blue'> (+10점)</span></p>
                            <p style={{ paddingTop: '10rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />마켓플레이스에서<br></br>   등록한 작품 판매해 보기<span className='color-blue'> (+10점)</span></p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />상세정보 보기와 작품 구매하기<span className='color-blue'> (+무제한)</span></p>
                        </div>
                    </div>
                    <div className='member-ship-list-mb-box' style={{ height: '280rem', marginTop: 0 }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>

                            <h2>베이직<span style={{ fontSize: '12rem', whiteSpace: 'pre-wrap', fontWeight: 500 }}> Basic</span> </h2>
                            <button className='do-pay' onClick={() => subscribe('BASIC')} disabled={level === 1}>{level === 1 ? "이용중" : !isSignup ? "변경하기" : "결제하기"}</button>
                        </div>
                        <h1 style={{ paddingTop: '0rem' }}><span className='color-blue bold'>99,000원/년</span></h1>
                        <div>
                            <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />내 작품 클라우드 생성</p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />큐아트 클라우드에 내 작품 저장 <span className='color-blue'> (+30점)</span></p>
                            <p style={{ paddingTop: '10rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />블록체인 기반 Q-CoA 등록과 증명 발급 <span className='color-blue'> (+30점)</span></p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />작품 입출고 관리시스템 제공 <span className='color-blue'> (+30점)</span></p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />큐픽 Qart’s pick 연 2회</p>
                            <p style={{ paddingTop: '10rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />언제 어디서나 안전하고 편리한 작품 통합 관리</p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />24시간 마켓플레이스</p>
                        </div>
                    </div>
                    <div className='member-ship-list-mb-box' style={{ height: '340rem', marginTop: 0 }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>

                            <h2>프리미엄<span style={{ fontSize: '12rem', whiteSpace: 'pre-wrap', fontWeight: 500 }}> Premium</span> </h2>
                            <button className='do-pay' onClick={() => subscribe('PREMIUM')} disabled={level === 2}>{level === 2 ? "이용중" : !isSignup ? "변경하기" : "결제하기"}</button>
                        </div>                        <h1 style={{ paddingTop: '0rem' }}><span className='color-blue bold'>199,000원/년</span></h1>
                        <div>
                            <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />내 작품 클라우드 생성</p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />큐아트 클라우드에 내 작품 저장 <span className='color-blue'> (+100점)</span></p>
                            <p style={{ paddingTop: '10rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />블록체인 기반 <br></br>   Q-CoA 등록과 증명 발급 <span className='color-blue'> (+100점)</span></p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />작품 입출고 관리시스템 제공 <span className='color-blue'> (+100점)</span></p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />큐픽 Qart’s pick 연 4회</p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />큐픽 Qart’s talk 연 1회</p>
                            <p style={{ paddingTop: '10rem', whiteSpace: 'pre-wrap' }}><BiCheck color='#BDBDBD' />언제 어디서나<br></br>   안전하고 편리한 작품 통합 관리</p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />24시간 마켓플레이스</p>
                        </div>
                    </div>
                    <div className='member-ship-list-mb-box' style={{ height: '180rem', marginTop: 60 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>

                            <h2>비즈니스<span style={{ fontSize: '12rem', whiteSpace: 'pre-wrap', fontWeight: 500 }}> Business</span> </h2>
                            <button className='inquire' onClick={() => navigate('/support/inquiry')} >문의하기</button>
                        </div>
                        <h1 style={{ paddingTop: '0rem' }}><span className='color-blue bold'>문의</span></h1>
                        <div>
                            <p style={{ paddingTop: '14rem' }}><BiCheck color='#BDBDBD' />대량작품 등록</p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />미술품 관리 인증 맞춤 시스템 구축</p>
                            <p style={{ paddingTop: '10rem' }}><BiCheck color='#BDBDBD' />기타 문의</p>
                        </div>
                    </div>

                    {
                        matches ? <p style={{ paddingLeft: '30rem', paddingBottom: '30rem' }}><span className='bold'> *모든 이용요금은 부가세(10%) 포함입니다.</span></p> : <></>
                    }
                </div>
                <div style={{ paddingLeft: '30rem', paddingBottom: '30rem' }}>
                    <button className='next'>다음</button>
                </div>
            </div>
        </>
    )
}

export default MembershipList