import client from '../../index'

/**
    판매자) 배송완료
   * @param {number} pk
   * @param {String} company 배송업체명
   * @param {String} fromDate 보낸 날짜
   * @param {String} toDate 배송 예정일
   * @param {String} invoice 송장번호
   * @param {String} contact 연락처
*/

const deliveryConfirmed = async(pk, company, fromDate, toDate, invoice, contact) => {

    const postData = {
        deliveryCompany: company,
        sendDate: fromDate,
        scheduledDate: toDate,
        invoiceNumber: invoice,
        contact: contact
    }
    const res = await client.json.post(`/user/notification/delivery/${pk}`, postData);
    return res.data;
}


/**
    배송이 확정된 후 구매 확정(블록체인 상 소유권 이전)
   * @param {number} pk
   * @param {String} pin 사용할 pin
*/

const purchaseConfirmed = async(pk, pin) => {
    const postData = {
        NEW_SHORT_PASSWORD: pin
    }
    const res = await client.json.post(`/user/work/market/${pk}`, postData);
    return res.data;
}


/**
    반품 승인
   * @param {number} pk
*/

const allowReturned = async(pk) => {
    const res = await client.json.patch(`/user/work/market/return/${pk}`);

    return res.data;
}

/**
    반품 거부
   * @param {number} pk
*/

const denyReturned = async(pk) => {
    const res = await client.json.delete(`/user/work/market/return/${pk}`);
    return res.data;
}





const delivery = {
    deliveryConfirmed, purchaseConfirmed,
    allowReturned, denyReturned
}
export default delivery
