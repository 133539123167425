import React, { useState } from 'react';

import swal from 'sweetalert';

function RestoreCodeModal({ setModalOpen, setPinOpen, action }) {
  const [code, setCode] = useState('');

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }

  const done = async () => {
    if (code === '') {
      swal('인증코드를 입력해주세요.')
      return
    }
    const res = await action(code);
    if(!res) return;

    closeModal()
    setPinOpen(true);
  }



  return (
    <div className="history-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <header className="history-header">
            <h1>이메일 인증</h1>
            <button className="history-close" onClick={closeModal}></button>
          </header>
          <ul className="history-content">
            <li>
              <h2>인증코드 *</h2>
            <input type="text" value={code}  onChange={e => setCode(e.target.value.toUpperCase())}></input>
            </li>
          </ul>
          <button className="history-add" onClick={done}>
            <span>확인</span>
          </button>
        </div>
      </div>
    </div>
  )
}
export default RestoreCodeModal
