import React from 'react'
import Slider from 'react-slick'
import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import './New.css'
import publicWork from '../../../services/public/publicWork'
import { useSelector, shallowEqual } from 'react-redux'
import { workMainImage } from '../../../services/imageRoute'
import { checkUseName } from '../../../hook/utils/checkUseName'
import useMobileDetect from 'use-mobile-detect-hook'
import { getTitle } from '../../../hook/utils/getTitle'

const New = () => {
  const total = 5;
  const { lang } = useSelector(v => v.setting, shallowEqual)

  const [items, setItems] = React.useState([]);
  const [newItems, setNewItems] = React.useState([]);
  const detectMobile = useMobileDetect();

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
  }

  React.useEffect(() => {
    getList(total)
  }, [])

  const getList = async (total) => {
    const res = await publicWork.getNewWorkList(total)
    setItems(res.recent_list)
  }


  React.useEffect(() => {
    if (items.length === 0) return
    const itemList = [];
    for (let i = 0; i < items.length; i++) {
    
      const item = {
        pk: items[i].pk,
        info: `${workMainImage}/${items[i].image}`,
        author: checkUseName(lang, 0, items[i].ko_artist, items[i].eng_artist, items[i].ko_artist, items[i].eng_artist,),
        // author: items[i].user_type === 2 
        //   ? checkUseName(lang, 0, items[i].ko_artist, items[i].eng_artist, '', '') 
        //   : checkUseName(lang, items[i].use_name, items[i].ko_name, items[i].eng_name, items[i].ko_nickname, items[i].eng_nickname),
        title: getTitle(lang, items[i].ko_title, items[i].eng_title),
      }

      itemList.push(item);
    }
    setNewItems(itemList)
  }, [items, lang])


  const slider_items = imgList => {
    return imgList.map((item, i) => {
      return (
        <div className="new-slider__item" key={i} onContextMenu={
          (e) => {
              e.preventDefault();
          }}>
          <div className="new-slider__item-container">
            <a href={`/artdb/work/${item.pk}`} className='banner_img'>
              <img src={item.info} alt='' onContextMenu={
          (e) => {
              e.preventDefault();
          }}></img>
              <p className='hover_text'>
                <h4>{item.author}</h4>
                <h2 style={{paddingTop:detectMobile.isMobile() ? '5rem' : '12rem'}}>{item.title}</h2>
              </p>

              {/* <div className="new-slider__item-info">
                <h4>{item.author}</h4>
                <h2>{item.title}</h2>
              </div> */}
            </a>
          </div>
        </div>
      )
    })
  }
  return (
    <div className="new">
      <SectionHeader title={'New'} />
      <section className="container new-container">
        <div className="new-slider-container">
          <div className="right-border"></div>
          <div className="new-slide">
            <div className="new-slider__list">
              <Slider {...settings}>{slider_items(newItems)}</Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default New
