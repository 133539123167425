import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ArtDetail from './components/ArtDetail/ArtDetail';
import Login from './components/Login/Login';
import SignUpAgency from './components/SignUp/Agency/SignUpAgency';
import SignUpArtist from './components/SignUp/Artist/SignUpArtist';
import SignUpGeneral from './components/SignUp/General/SignUpGeneral';
import SignUp from './components/SignUp/SignUp';
import SignUpAuth from './components/SignUp/SignUpAuth';
import SignUpCert from './components/SignUp/SignUpCert';
import SignUpPhone from './components/SignUp/SignUpPhone';
import './css/reset.css';
import ArtDatabase from './pages/ArtDatabase/ArtDatabase';
import ArtDbArtist from './pages/ArtDatabase/Artist/ArtDbArtist';
import ArtistDetail from './pages/ArtDatabase/Artist/ArtistDetail/ArtistDetail';
import Venue from './pages/ArtDatabase/Venue/Venue';
import VenueDetail from './pages/ArtDatabase/Venue/VenueDetail';
import ArtDbWork from './pages/ArtDatabase/Work/ArtDbWork';
import ArtDbWork3 from './pages/ArtDatabase/Work/ArtDbWork3';
import ArtDbMain from './pages/ArtDatabase/Work/Main/Main';
import NftMain from './pages/ArtDatabase/Work/NFT/NftMain';
import Main from './pages/Home/Main';
import MarketAll from './pages/Marketplace/All/MarketAll';
import MarketAuction from './pages/Marketplace/Auction/MarketAuction';
import MarketItem from './pages/Marketplace/Item/MarketItem';
import MarketNftItem from './pages/Marketplace/Item/MarketNftItem.js/MarketNftItem';
import MarketMain from './pages/Marketplace/MarketMain/MarketMain';
import Marketplace from './pages/Marketplace/Marketplace';
import MarketPlatform from './pages/Marketplace/PlatformQ/MarketPlatform';
import MarketQartsTalkDetail from './pages/Marketplace/QartsTalk/Item/QartsTalkDetail';
import MarketQartsTalkList from './pages/Marketplace/QartsTalk/QartsTalkList';
import MarketQartsTalk from './pages/Marketplace/QartsTalk/QartsTalkMain';
import Membership from './pages/MyPage/Membership/Membership';
import Notice from './pages/MyPage/Notice/NoticeList';
// import AuctionItem from './pages/Marketplace/Auction/Item/AuctionItem'
import Payment from './pages/Marketplace/Payment/Payment';
import MyArticleAdd from './pages/MyPage/Artist/MyArchive/Article/MyArticleAdd';
import ArtistArchive from './pages/MyPage/Artist/MyArchive/ArtistArchive';
import MyNftAdd from './pages/MyPage/Artist/MyArchive/Nft/MyNftAdd';
import MyWorkAdd from './pages/MyPage/Artist/MyArchive/Work/MyWorkAdd';
import MyPageArtist from './pages/MyPage/Artist/MyPageArtist';
import ArtistExhibition from './pages/MyPage/Artist/Profile/ArtistExhibition';
import ArtistSchool from './pages/MyPage/Artist/Profile/ArtistSchool';
import MyWorkAdd2 from './pages/MyPage/Gallery/MyArchive2/Work/MyWorkAdd2';
import MyPageGallery from './pages/MyPage/Gallery/MyPageGallery';
import GalleryAgency from './pages/MyPage/Gallery/Profile/GalleryAgency';
import GalleryTeam from './pages/MyPage/Gallery/Profile/GalleryTeam';
import MyPageGeneral from './pages/MyPage/General/MyPageGeneral';
import SupportMain from './pages/Support/SupportMain';
//import SupportAbout from './pages/Support/About/SupportAbout'
import SignUpKYC from './components/SignUp/SignUpKYC';
import QCoAMain from './pages/QCoA/QCoAMain';
import Search from './pages/Search/Search';
import SupportAbout from './pages/Support/About/SupportAboutNew';
import SupportFAQ from './pages/Support/FAQ/SupportFAQ';
import SupportInQuiry from './pages/Support/InQuiry/SuportInQuiry';
import SupportNotice from './pages/Support/Notice/SupportNotice';
//import QCoAAbout from './pages/QCoA/About/QCoAAbout'
import NFTItem from './pages/ArtDatabase/Work/NFT/NFTItem';
import ArtistLike from './pages/MyPage/Artist/Like/ArtistLike';
import AddWorkToExcel from './pages/MyPage/Artist/MyArchive/AddWorkToExcel';
import MyArticleEdit from './pages/MyPage/Artist/MyArchive/Article/MyArticleEdit';
import MyWorkEdit from './pages/MyPage/Artist/MyArchive/Work/MyWorkEdit';
import ArtistExhibitionEdit from './pages/MyPage/Artist/Profile/ArtistExhibitionEdit';
import ArtistProfile from './pages/MyPage/Artist/Profile/ArtistProfile';
import ArtistWallet from './pages/MyPage/Artist/Wallet/ArtistWallet';
import MyWorkEdit2 from './pages/MyPage/Gallery/MyArchive2/Work/MyWorkEdit2';
import GalleryProfile from './pages/MyPage/Gallery/Profile/GalleryProfile';
import GeneralProfile from './pages/MyPage/General/Profile/GeneralProfile';
import QCoAAbout from './pages/QCoA/About/QCoaAboutNew';
import QCoAList from './pages/QCoA/List/QcoAList';
import Condition from './pages/Support/Terms/Condition';
import Privacy from './pages/Support/Terms/Privacy';
// import AdminMain from './pages/Admin/AdminMain'
// import AdminManage from './pages/Admin/Main/AdminManage'
// import AdminEdit from './pages/Admin/Main/AdminEdit'
// import AdminTerm from './pages/Admin/Main/AdminTerm'
// import AdminKYC from './pages/Admin/Cert/AdminKYC'
// import AdminGeneral from './pages/Admin/Member/AdminGeneral'
// import AdminArtist from './pages/Admin/Member/AdminArtist'
// import AdminGallery from './pages/Admin/Member/AdminGallery'
// import AdminArtistDetail from './pages/Admin/Member/AdminArtistDetail'
// import AdminQCoA from './pages/Admin/Cert/AdminQCoA'
// import AdminQCoAAbout from './pages/Admin/Cert/AdminQCoaAbout'
// import AdminInquiry from './pages/Admin/Inform/AdminInquiry'
// import AdminInquiryDetail from './pages/Admin/Inform/AdminInquiryDetail'
// import AdminNotice from './pages/Admin/Inform/AdminNotice'
// import AdminNoticeEdit from './pages/Admin/Inform/AdminNoticeEdit'
// import AdminNew from './pages/Admin/New/AdminNew'
// import AdminNewEdit from './pages/Admin/New/AdminNewEdit'
// import AdminBannerMain from './pages/Admin/Banner/AdminBannerMain'
// import AdminBannerEdit from './pages/Admin/Banner/AdminBannerEdit'
// import AdminAuction from './pages/Admin/Special/AdminAuction'
// import AdminNft from './pages/Admin/NFT/AdminNft'
// import AdminWorkList from './pages/Admin/Work/AdminWorkList'
// import AdminNftEdit from './pages/Admin/NFT/AdminNftEdit'
// import AdminWorkArtist from './pages/Admin/Work/AdminWorkArtist'
// import AdminWork from './pages/Admin/Work/AdminWork'
// import AdminAuctionDetail from './pages/Admin/Work/AdminAuctionDetail'
// import AdminAuctionItem from './pages/Admin/Work/AdminAuctionItem'
// import AdminArtTok from './pages/Admin/Work/AdminArtTok'
// import AdminPlatformQ from './pages/Admin/Work/AdminPlatformQ'
// import AdminAuctionEdit from './pages/Admin/Work/AdminAuctionEdit'
// import AdminGalleryDetail from './pages/Admin/Member/AdminGalleryDetail'

import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Restore } from './components/FindAccount';
import { UserTakeOver } from './components/Login/UserTakeOver';
import { UserTakeOverPw } from './components/Login/UserTakeOverPw';
import NaverCallback from './components/Login/platform/NaverCallback';
import SignUpEnd from './components/SignUp/SignUpEnd';
import { ScrollToTop } from './hook/scrollToTop';
import CollectionDetail from './pages/ArtDatabase/Collection/CollectionDetail';
import IssueDetail from './pages/ArtDatabase/NewsIssue/IssueDetail';
import NewsDetail from './pages/ArtDatabase/NewsIssue/NewsDetail';
import AuctionNFTDetail from './pages/Marketplace/Auction/Item/AuctionNFTDetail';
import AuctionWorkDetail from './pages/Marketplace/Auction/Item/AuctionWorkDetail';
import PaymentDone from './pages/Marketplace/Payment/PaymentDone';
import MyArticleList from './pages/MyPage/Artist/MyArchive/Article/MyArticleList';
import Cert from './pages/MyPage/Artist/MyArchive/KYC/Cert';
import MyNFTManage from './pages/MyPage/Artist/MyArchive/Nft/MyNftManage';
import MyQcoAList from './pages/MyPage/Artist/MyArchive/QCoA/MyQCoAList';
import MyWorkList from './pages/MyPage/Artist/MyArchive/Work/MyWorkList';
import ArtistSchoolEdit from './pages/MyPage/Artist/Profile/ArtistSchoolEdit';
import GalleryArchive from './pages/MyPage/Gallery/MyArchive2/GalleryArchive';
import GalleryWorkList from './pages/MyPage/Gallery/MyArchive2/Work/GalleryWorkList';
import GalleryAgencyEdit from './pages/MyPage/Gallery/Profile/GalleryAgencyEdit';
import GeneralArchive from './pages/MyPage/General/GeneralArchive';
import SupportNoticeDetail from './pages/Support/Notice/Item/SupportNoticeDetail';
import Promotion from './pages/Support/Terms/Promotion';
import auth from './services/auth/auth';
import * as authAction from './store/reducers/auth/actions';
import * as settingAction from './store/reducers/settings/actions';



function App() {
  const dispatch = useDispatch();
  const { type } = useSelector(v => v.auth, shallowEqual)


  const [isNft, setIsNft] = React.useState(0);

  React.useEffect(() => {
    dispatch(settingAction.SET_WORK_TYPE_LIST())
    dispatch(settingAction.SET_BANNER_LIST())
    dispatch(settingAction.SET_POPUP_LIST())
    getInfo()
  }, [])

  const getInfo = async () => {
    let res;
    if (type === null) {
      return
    }
    if (type === 2) {
      res = await auth.getAgencyInfo()
      dispatch(authAction.SET_GALLERY_INFO(res))
    } else {
      res = await auth.getArtistInfo()
      dispatch(authAction.SET_ARTIST_INFO(res.data))
    }
  }


  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/signUp" element={<SignUp />}></Route>
        <Route path="/signUp/auth" element={<SignUpAuth />}></Route>
        <Route path="/signUp/auth/phone" element={<SignUpPhone />}></Route>
        <Route path="/signUp/auth/general" element={<SignUpGeneral />}></Route>
        <Route path="/signUp/auth/artist" element={<SignUpArtist />}></Route>
        <Route path="/signUp/auth/agency" element={<SignUpAgency />}></Route>
        <Route path="/auth/cert/kyc" element={<SignUpCert />}></Route>
        <Route path="/signUp/end" element={<SignUpEnd />}></Route>
        <Route path="/cert/kyc" element={<Cert />}></Route>
        <Route path="/signUp/auth/kyc" element={<SignUpKYC />}></Route>
        <Route path="/restore" element={<Restore />}></Route>
        <Route path="/login" element={
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <Login />
          </GoogleOAuthProvider>
        }></Route>
        <Route path="/login/callback" element={
          <NaverCallback />
        }></Route>
        <Route path="/user/take-over/account" element={
          <UserTakeOver />
        }></Route>
        <Route path="/user/take-over/password" element={
          <UserTakeOverPw />
        }></Route>
        <Route path="/search" element={<Search />}></Route>
        <Route
          path="/artdb/artist"
          element={
            <ArtDatabase search={true} active={0}>
              <ArtDbArtist />
            </ArtDatabase>
          }
        ></Route>
        <Route
          path="/artdb/artist/:artistId"
          element={<ArtistDetail />}
        ></Route>
        <Route path="/artdb/collection/:collectionId" element={<CollectionDetail />}></Route>
        <Route
          path="/artdb/work"
          element={
            <ArtDatabase search={true} active={1}>
              {/* <ArtDbWork active={0}> */}
              <ArtDbMain />
              {/* </ArtDbWork> */}
            </ArtDatabase>
          }
        ></Route>
        <Route
          path="/artdb/work/:workId"
          element={
            // <ArtDatabase search={false}>
            //   <ArtDbWork3 />
            // </ArtDatabase>
            <ArtDbWork3 />
          }
        ></Route>
        <Route
          path="/artdb/work/detail"
          element={
            // <ArtDatabase search={true} active={0}>
            //   <ArtDbWork active={0}>
            //     <ArtDetail />
            //   </ArtDbWork>
            // </ArtDatabase>
            <ArtDetail choice={0} detail={true} />
          }
        ></Route>
        <Route
          path="/artdb/work/nft"
          element={
            <ArtDatabase search={true} active={0}>
              <ArtDbWork active={1}>
                <NftMain choice={0} />
              </ArtDbWork>
            </ArtDatabase>
          }
        ></Route>
        <Route
          path="/artdb/work/nft/:nftId"
          element={
            // <ArtDatabase search={false}>
            //   <NFTItem />
            // </ArtDatabase>
            <NFTItem />
          }
        ></Route>
        <Route
          path="/artdb/venue"
          element={
            <ArtDatabase search={true} active={2}>
              <Venue />
            </ArtDatabase>
          }
        ></Route>
        <Route path="/artdb/venue/:venuId" element={<VenueDetail />}></Route>

        <Route path="/artdb/news/:id"
          element={
            <ArtDatabase search={true} active={0}>
              <NewsDetail />
            </ArtDatabase>
          }>
        </Route>
        <Route path="/artdb/issue/:id"
          element={
            <ArtDatabase search={true} active={2}>
              <IssueDetail />
            </ArtDatabase>
          }>
        </Route>

        {/* <Route
          path="/artdb/venue/display/1"
          element={
            <ArtDatabase>
              <VenueDisplay />
            </ArtDatabase>
          }
        ></Route> */}
        <Route
          path="/market"
          element={
            <Marketplace search={1}>
              <MarketMain />
            </Marketplace>
          }
        ></Route>
        <Route
          path="/market/all"
          element={
            <Marketplace search={1} active={0}>
              <MarketAll />
            </Marketplace>
          }
        ></Route>
        <Route path="/market/:marketId" element={<MarketItem />}></Route>
        <Route path="/market/nft/:nftId" element={<MarketNftItem />}></Route>
        <Route
          path="/market/platform"
          element={
            <Marketplace search={1} active={1}>
              <MarketPlatform />
            </Marketplace>
          }
        ></Route>
        <Route
          path="/market/auction/past"
          element={<MarketAuction search={true} active={1} setIsNft={setIsNft} isNft={isNft} />}
        ></Route>
        <Route
          path="/market/auction/now"
          element={<MarketAuction search={true} active={0} setIsNft={setIsNft} isNft={isNft} />}
        ></Route>
        <Route
          path="/market/auction/up-coming"
          element={<MarketAuction search={true} active={2} setIsNft={setIsNft} isNft={isNft} />}
        ></Route>
        <Route path="/market/qartstalk"
          element={<MarketQartsTalk search={1}>
            <MarketQartsTalkList />
          </MarketQartsTalk>
          }>

        </Route>
        <Route path="/market/qartstalk/:qartstalkid"
          element={<MarketQartsTalk search={1}>

            <MarketQartsTalkDetail />

          </MarketQartsTalk>}>

        </Route>
        {/* <Route
          path="/market/auction/:auctionId"
          element={<AuctionItem />}
        ></Route> */}
        <Route
          path="/market/nft/auction/:itemId"
          element={<AuctionNFTDetail />}
        // element={<AuctionDetail />}
        ></Route>
        <Route
          path="/market/work/auction/:itemId"
          element={<AuctionWorkDetail />}
        ></Route>
        <Route path="/market/payment" element={<Payment />}></Route>
        <Route path="/market/payment/result" element={<PaymentDone />}></Route>

        <Route
          path="/terms/condition"
          element={
            <Condition />
          }
        ></Route>

        <Route
          path="/terms/privacy"
          element={
            <Privacy />
          }
        ></Route>
        <Route
          path="/terms/promotion"
          element={
            <Promotion />
          }
        ></Route>
        <Route
          path="/support"
          element={
            <SupportMain search={false} active={3} choice={0}>
              <SupportAbout />
            </SupportMain>
          }
        ></Route>
        <Route
          path="/support/notice"
          element={
            <SupportMain search={false} active={3} choice={1}>
              <SupportNotice />
            </SupportMain>
          }
        ></Route>
         <Route
          path="/support/notice/:supportId"
          element={
            <SupportMain search={false} active={3} choice={1}>
            <SupportNoticeDetail />
          </SupportMain>}
          >

        </Route>
        <Route
          path="/support/faq"
          element={
            <SupportMain search={false} active={3} choice={2}>
              <SupportFAQ />
            </SupportMain>
          }
        ></Route>
        <Route
          path="/support/inquiry"
          element={
            <SupportMain search={false} active={3} choice={3}>
              <SupportInQuiry />
            </SupportMain>
          }
        ></Route>

        <Route
          path="/qcoa"
          element={
            <QCoAMain search={1} active={1} choice={0}>
              <QCoAAbout />
            </QCoAMain>
          }
        ></Route>
        <Route
          path="/qcoa/list"
          element={
            <QCoAMain search={1} active={1} choice={1}>
              <QCoAList />
            </QCoAMain>
          }
        ></Route>
        {/* 마이페이지 - 일반 */}
        <Route path="/mypage/general" element={<GeneralProfile />}></Route>
        <Route
          path="/mypage/general/wallet"
          element={
            <MyPageGeneral active={2}>
              {/* <GeneralWallet /> */}
              <ArtistWallet />
            </MyPageGeneral>
          }
        ></Route>
        <Route
          path="/mypage/general/archive"
          element={
          <GeneralArchive choice={0}>
            <GalleryWorkList type={'general'} />
          </GeneralArchive>
        }
        ></Route>
        <Route
          path="/mypage/general/archive/coa"
          element={
          <GeneralArchive choice={1}>
            <MyQcoAList />
          </GeneralArchive>
        }
        ></Route>
        <Route
          path="/mypage/general/archive/article"
          element={
          <GeneralArchive choice={2}>
            <MyArticleList />
          </GeneralArchive>
        }
        ></Route>
        <Route
          path="/mypage/general/excel"
          element={<AddWorkToExcel />}
        ></Route>
        <Route
          path="/mypage/general/archive/work"
          element={<MyWorkAdd2 />}
        ></Route>
        <Route
          path="/mypage/general/archive/article/add"
          element={<MyArticleAdd />}
        ></Route>
        <Route
          path="/mypage/general/archive/article/edit/:pk"
          element={<MyArticleEdit />}
        ></Route>
        <Route
          path="/mypage/general/archive/edit/:workId"
          element={<MyWorkEdit2 />}
        ></Route>
        <Route
          path="/mypage/general/like"
          element={
            <MyPageGeneral active={3}>
              <ArtistLike />
            </MyPageGeneral>
          }
        ></Route>
        {/* <Route
          path="/mypage/general/trade"
          element={
            <MyPageGeneral active={4}>
              <ArtistTrade />
            </MyPageGeneral>
          }
        ></Route> */}
        <Route
          path="/mypage/general/notice"
          element={
            <MyPageGeneral active={4}>
              <Notice />
            </MyPageGeneral>
          }
        ></Route>

        <Route
          path="/mypage/general/membership"
          element={
            <MyPageGeneral active={5}>
              <Membership />
            </MyPageGeneral>
          }
        ></Route>


        {/* 마이페이지 - 아티스트 */}
        <Route path="/mypage/artist" element={<ArtistProfile />}></Route>
        <Route
          path="/mypage/artist/wallet"
          element={
            <MyPageArtist active={2}>
              <ArtistWallet />
            </MyPageArtist>
          }
        ></Route>
        <Route
          path="/mypage/artist/like"
          element={
            <MyPageArtist active={3}>
              <ArtistLike />
            </MyPageArtist>
          }
        ></Route>
        {/* <Route
          path="/mypage/artist/trade"
          element={
            <MyPageArtist active={4}>
              <ArtistTrade />
            </MyPageArtist>
          }
        ></Route> */}
        <Route
          path="/mypage/artist/notice"
          element={
            <MyPageArtist active={4}>
              <Notice />
            </MyPageArtist>
          }
        ></Route>
        <Route
          path="/mypage/artist/membership"
          element={
            <MyPageArtist active={5}>
              <Membership />
            </MyPageArtist>
          }
        ></Route>

        <Route
          path="/mypage/artist/exhibition"
          element={<ArtistExhibition />}
        ></Route>
        <Route
          path="/mypage/artist/exhibition/edit"
          element={<ArtistExhibitionEdit />}
        ></Route>

        <Route path="/mypage/artist/school" element={<ArtistSchool />}></Route>
        <Route path="/mypage/artist/school/edit/:pk" element={<ArtistSchoolEdit />}></Route>
        <Route
          path="/mypage/artist/archive"
          element={
          <ArtistArchive choice={0}>
            <MyWorkList />
          </ArtistArchive>
        }
        ></Route>
        <Route
          path="/mypage/artist/archive/coa"
          element={
          <ArtistArchive choice={1}>
            <MyQcoAList />
          </ArtistArchive>
        }
        ></Route>
        <Route
          path="/mypage/artist/archive/article"
          element={
          <ArtistArchive choice={2}>
            <MyArticleList />
          </ArtistArchive>
        }
        ></Route>

        <Route
          path="/mypage/artist/archive/nft/manage"
          element={<MyNFTManage />}
        ></Route>

        <Route
          path="/mypage/artist/archive/work"
          element={<MyWorkAdd />}
        ></Route>
        <Route path="/mypage/artist/archive/edit/:workId" element={<MyWorkEdit />}></Route>
        <Route
          path="/mypage/artist/archive/article/add"
          element={<MyArticleAdd />}
        ></Route>
        <Route
          path="/mypage/artist/archive/article/edit/:pk"
          element={<MyArticleEdit />}
        ></Route>
        <Route path="/mypage/artist/school" element={<ArtistSchool />}></Route>
        <Route path="/mypage/artist/archive/nft" element={<MyNftAdd />}></Route>

        <Route path="/mypage/excel" element={<AddWorkToExcel />}></Route>


        {/* 마이페이지 - 갤러리 */}
        <Route path="/mypage/gallery" element={<GalleryProfile />}></Route>
        <Route
          path="/mypage/gallery/wallet"
          element={
            <MyPageGallery active={2}>
              <ArtistWallet />
            </MyPageGallery>
          }
        ></Route>
        <Route
          path="/mypage/gallery/like"
          element={
            <MyPageGallery active={3}>
              <ArtistLike />
            </MyPageGallery>
          }
        ></Route>
        {/* <Route
          path="/mypage/gallery/trade"
          element={
            <MyPageGallery active={4}>
              <ArtistTrade />
            </MyPageGallery>
          }
        ></Route> */}
        <Route
          path="/mypage/gallery/notice"
          element={
            <MyPageGallery active={4}>
              <Notice />
            </MyPageGallery>
          }
        ></Route>
        <Route
          path="/mypage/gallery/membership"
          element={
            <MyPageGallery active={5}>
              <Membership />
            </MyPageGallery>
          }
        ></Route>

        <Route
          path="/mypage/gallery/exhibition"
          element={<ArtistExhibition />}
        ></Route>

        <Route
          path="/mypage/gallery/archive"
          element={
          <GalleryArchive choice={0}>
            <GalleryWorkList />
          </GalleryArchive>
        }
        ></Route>
        <Route
          path="/mypage/gallery/archive/coa"
          element={
          <GalleryArchive choice={1}>
            <MyQcoAList />
          </GalleryArchive>
        }
        ></Route>
        <Route
          path="/mypage/gallery/archive/article"
          element={
          <GalleryArchive choice={2}>
            <MyArticleList />
          </GalleryArchive>
        }
        ></Route>
        <Route
          path="/mypage/gallery/excel"
          element={<AddWorkToExcel />}
        ></Route>
        <Route
          path="/mypage/gallery/archive/work"
          element={<MyWorkAdd2 />}
        ></Route>
        <Route
          path="/mypage/gallery/archive/edit/:workId"
          element={<MyWorkEdit2 />}
        ></Route>
        <Route
          path="/mypage/gallery/archive/article/add"
          element={<MyArticleAdd />}
        ></Route>
        <Route
          path="/mypage/gallery/archive/article/edit/:pk"
          element={<MyArticleEdit />}
        ></Route>
        <Route
          path="/mypage/gallery/archive/nft"
          element={<MyNftAdd />}
        ></Route>
        {/* <Route
          path="/mypage/gallery/exhibition"
          element={<GalleryExhibition />}
        ></Route> */}
        <Route path="/mypage/gallery/team" element={<GalleryTeam />}></Route>
        <Route
          path="/mypage/gallery/agency"
          element={<GalleryAgency />}
        ></Route>
        <Route path="/mypage/gallery/agency/edit/:pk" element={<GalleryAgencyEdit />}></Route>
        {/* 관리자 페이지 
        <Route
          path="/admin/"
          element={
            <AdminMain title={'운영지표'} bm={0} sm={0}>
              <AdminManage />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/main/info"
          element={
            <AdminMain title={'사이트 정보 수정'} bm={0} sm={1}>
              <AdminEdit />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/main/term"
          element={
            <AdminMain title={'약관본문 수정'} bm={0} sm={2}>
              <AdminTerm />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/member/general"
          element={
            <AdminMain title={'회원관리 - 개인 회원'} bm={1} sm={0}>
              <AdminGeneral />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/member/artist"
          element={
            <AdminMain title={'회원관리 - 작가 회원'} bm={1} sm={1}>
              <AdminArtist />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/member/artist/detail"
          element={
            <AdminMain title={'작가 회원'} bm={1} sm={1}>
              <AdminArtistDetail />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/member/gallery"
          element={
            <AdminMain title={'회원관리 - 기관 회원'} bm={1} sm={2}>
              <AdminGallery />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/member/gallery/detail"
          element={
            <AdminMain title={'기관 회원'} bm={1} sm={2}>
              <AdminGalleryDetail />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/cert/kyc"
          element={
            <AdminMain title={'인증서 발급 내역 열람 및 관리'} bm={2} sm={0}>
              <AdminKYC />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/cert/qcoa"
          element={
            <AdminMain title={'인증서 발급 내역 열람 및 관리'} bm={2} sm={1}>
              <AdminQCoA />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/cert/qcoa/about"
          element={
            <AdminMain title={'인증서 발급 내역 열람 및 관리'} bm={2} sm={1}>
              <AdminQCoAAbout />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/inform/inquiry"
          element={
            <AdminMain title={'문의 관리'} bm={3} sm={0}>
              <AdminInquiry />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/inform/inquiry/detail"
          element={
            <AdminMain title={'문의 관리'} bm={3} sm={0}>
              <AdminInquiryDetail />
            </AdminMain>
          }
        ></Route>

        <Route
          path="/admin/inform/notice"
          element={
            <AdminMain title={'공지 관리'} bm={3} sm={1}>
              <AdminNotice />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/inform/notice/edit"
          element={
            <AdminMain title={'공지 관리'} bm={3} sm={1}>
              <AdminNoticeEdit />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/banner"
          element={
            <AdminMain
              title={'팝업 및 배너 등록 변경 관리 - 메인'}
              bm={4}
              sm={0}
            >
              <AdminBannerMain />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/banner/edit"
          element={
            <AdminMain
              title={'팝업 및 배너 등록 변경 관리 - 메인'}
              bm={4}
              sm={0}
            >
              <AdminBannerEdit />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/banner/market"
          element={
            <AdminMain
              title={'팝업 및 배너 등록 변경 관리 - 마켓플레이스'}
              bm={4}
              sm={1}
            >
              <AdminBannerMain />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/banner/market/edit"
          element={
            <AdminMain
              title={'팝업 및 배너 등록 변경 관리 - 마켓플레이스'}
              bm={4}
              sm={1}
            >
              <AdminBannerEdit />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/banner/platform"
          element={
            <AdminMain
              title={'팝업 및 배너 등록 변경 관리 - 플렛폼Q'}
              bm={4}
              sm={2}
            >
              <AdminBannerMain />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/banner/platform/edit"
          element={
            <AdminMain
              title={'팝업 및 배너 등록 변경 관리 - 플렛폼Q'}
              bm={4}
              sm={2}
            >
              <AdminBannerEdit />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/special/auction"
          element={
            <AdminMain title={'자체 기획전 정보 - 기획경매'} bm={5} sm={0}>
              <AdminAuction />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/special/auction/edit"
          element={
            <AdminMain title={'자체 기획전 정보 - 기획경매'} bm={5} sm={0}>
              <AdminAuctionEdit />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/special/auction/detail"
          element={
            <AdminMain title={'자체 기획전 정보 - 기획경매'} bm={5} sm={0}>
              <AdminAuctionDetail />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/special/auction/detail/edit"
          element={
            <AdminMain title={'자체 기획전 정보 - 기획경매'} bm={5} sm={0}>
              <AdminAuctionItem />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/special/arttok"
          element={
            <AdminMain title={'자체 기획전 정보 - Art Tok!'} bm={5} sm={1}>
              <AdminArtTok />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/special/platformq"
          element={
            <AdminMain title={'자체 기획전 정보 - 플렛폼Q'} bm={5} sm={2}>
              <AdminPlatformQ />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/work/list"
          element={
            <AdminMain title={'작품 등록'} bm={6} sm={0}>
              <AdminWorkList />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/work/artist"
          element={
            <AdminMain title={'작가'} bm={6} sm={1}>
              <AdminWorkArtist />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/work"
          element={
            <AdminMain title={'작품'} bm={6} sm={2}>
              <AdminWork />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/new"
          element={
            <AdminMain title={'새소식'} bm={7}>
              <AdminNew />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/new/edit"
          element={
            <AdminMain title={'새소식'} bm={7}>
              <AdminNewEdit />
            </AdminMain>
          }
        ></Route>

        <Route
          path="/admin/nft"
          element={
            <AdminMain title={'NFT'} bm={8}>
              <AdminNft />
            </AdminMain>
          }
        ></Route>
        <Route
          path="/admin/nft/edit"
          element={
            <AdminMain title={'NFT'} bm={8}>
              <AdminNftEdit />
            </AdminMain>
          }
        ></Route> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App
