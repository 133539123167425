import React, { useState } from 'react'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import HeaderSmall from '../../../../components/Header/HeaderSmall/HeaderSmall'
import './ArtistArchive.css'
import MyArticleList from './Article/MyArticleList'
import MyNftList from './Nft/MyNftList'
import MyQcoAList from './QCoA/MyQCoAList'
import MyWorkList from './Work/MyWorkList'
import AuctionHeader from '../../../../components/Header/AuctionHeader/AuctionHeader'

function ArtistArchive(props) {
  const [choice, setChoice] = useState(props.choice)
  const moreList = {
    menu: [
      {
        title: 'My Work',
        link: '/mypage/artist/archive'
      },
      {
        title: 'My Q-CoA',
        link: '/mypage/artist/archive/coa'
      },
      {
        title: 'My News',
        link: '/mypage/artist/archive/article'
      },
      {
        title: 'My NFT',
      },
    ],
  }
  return (
    <div className="mypage-archive">
      <Header login={true} colored="black" detail={true} />
      <nav className="search">
        <div className="container search__container">
          <div className="ad-haeder__route">
            <span>마이페이지 </span>
            <span className="next"></span>
          </div>
          <h2 className="search__title">My Archive</h2>
        </div>
      </nav>
      <AuctionHeader
        moreList={moreList}
        active={true}
        choice={choice}
        setMenu={setChoice}
      />
      {props.children}
      {/* {choice === 0 ? (
        <MyWorkList />
      ) : choice === 1 ? (
        <MyQcoAList />
      ) : choice === 2 ? (
        <MyArticleList />
        ) : (
          <MyNftList />
      )} */}
      <Footer />
    </div>
  )
}
export default ArtistArchive
