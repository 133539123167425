import React from 'react'
import ArtMoveDetail from '../../../../components/ArtMoveDetail/ArtMoveDetail'
import ArtTopic from '../../../../components/ArtTopic/ArtTopic'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as settingAction from '../../../../store/reducers/settings/actions'
import publicWork from '../../../../services/public/publicWork';
import { setComma } from '../../../../hook/utils/comma';
import setting from '../../../../services/setting';
import { workMainImage } from '../../../../services/imageRoute';
import ArtDBArtTopic from '../../../../components/ArtTopic/ArtDBArtTopic';
function Main() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(settingAction.SET_WORK_TYPE_LIST())
  },[])

  const { rowWorkTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const [workTypeList, setWorkTypeList] = React.useState([])
  const [mordernWorkTypeList, setMordernWorkTypeList] = React.useState([])
  const [antiqueWorkTypeList, setAntiqueWorkTypeList] = React.useState([])
  const [menu, setMenu] = React.useState(0)
  const [antiqueMenu, setAntiqueMenu] = React.useState(0)
  const [mordernMenu, setMordernenu] = React.useState(0)
  const [todayWork, setTodayWork] = React.useState([])
  const [qWork, setQWork] = React.useState([])
  const [workListByType, setWorkListByType] = React.useState([])
  const [antiqueWorkByType, setAntiqueWorkByType] = React.useState([])
  const [antiqueWorkTotal, setAntiqueWorkTotal] = React.useState(0)
  const [mordernWorkByType, setMordernWorkByType] = React.useState([])
  const [mordernWorkTotal, setMordernWorkTotal] = React.useState(0)

  React.useEffect(() => {
    // 전체
    const item = [];

    let total = 0;
    let antiqueTotal = 0;
    let mordernTotal = 0;

    for (let i = 0; i < rowWorkTypeList.length -1; i++) {
      item.push(`${rowWorkTypeList[i][`${lang}`]}(${setComma(rowWorkTypeList[i].count)})`)
      total = total + rowWorkTypeList[i].count
      antiqueTotal = antiqueTotal + rowWorkTypeList[i].old_count
      mordernTotal = mordernTotal + rowWorkTypeList[i].resent_count
    }
    item.unshift(`전체(${setComma(total)})`)
    setAntiqueWorkTotal(antiqueTotal)
    setMordernWorkTotal(mordernTotal)
    setWorkTypeList(item)
  }, [lang, rowWorkTypeList])

  React.useEffect(() => {
    getTodayWorkList()
  }, [])
  React.useEffect(() => {
    getAntiqueWorkListByType()
  }, [antiqueMenu])
  React.useEffect(() => {
    getMordernWorkListByType()
  }, [mordernMenu])
  React.useEffect(() => {
    getWorkListByType(menu)
  }, [menu])
  
  

  const getTodayWorkList = async() => {
    const res = await setting.getTodayList(3,2)
    setTodayWork(res.work_list)
  }
  
  const getAntiqueWorkListByType = async() => {
    const res = await publicWork.getWorkListByType(0, 3, 0, 1)
    setAntiqueWorkByType(res.list)
  }
  
  const getMordernWorkListByType = async() => {
    const res = await publicWork.getWorkListByType(0, 3, 0, 2)
    setMordernWorkByType(res.list)
  }
  
  const getWorkListByType = async(workType) => {
    const res = await publicWork.getWorkListByType(0, 6, workType, 0)
    setWorkListByType(res.list)
  }


  React.useEffect(() => {
    if(todayWork.length === 0){
      return
    }


    const getAuthor = (item) => {
      if(lang === 'ko'){
        if(item.artist === ''){
          return item.eng_ARTIST
        }
        return item.artist
      }else{
        if(item.eng_ARTIST === ''){
          return item.artist
        }
        return item.eng_ARTIST
      }
    }

    const getTitle = (item) => {
      if(lang === 'ko'){
        if(item.title === ''){
          return item.eng_TITLE
        }
        return item.title
      }else{
        if(item.eng_TITLE === ''){
          return item.title
        }
        return item.eng_TITLE
      }
    }

    const myItem = []
    for(let i = 0; i < todayWork.length; i ++){
      myItem.push(
        {
          id: todayWork[i].pk,
          info: `${workMainImage}/${todayWork[i].image}`,
          link: '/artdb/work',
          author: getAuthor(todayWork[i]),
          title: getTitle(todayWork[i])
        }
      )
    }
    setQWork(myItem)
  }, [todayWork])


  const options01 = ['연도순', '작품명']
  const options02 = ['연도순', '작품명', '작가명']


  return (
    <div>
      <ArtDBArtTopic
        title_e={'Focus on'}
        title_k={'오늘의 작품'}
        items={qWork}
      />
      <ArtMoveDetail
        type={1}
        title_e={'Antique Art'}
        title_k={`고미술 (${antiqueWorkTotal})`}
        moreList={antiqueWorkTypeList}
        moreActive={false}
        items={antiqueWorkByType}
        represent={3}
        breakRows={4}
        breakSlidersPerRow={2}
        options={options01}
        moreListChange={setAntiqueMenu}
        moreMenu={antiqueMenu}
      />
      <ArtMoveDetail
        type={2}
        title_e={'Modern & Contemporary'}
        title_k={`근현대 (${mordernWorkTotal})`}
        moreList={mordernWorkTypeList}
        moreActive={false}
        items={mordernWorkByType}
        represent={3}
        breakRows={4}
        breakSlidersPerRow={2}
        options={options02}
        moreListChange={setMordernenu}
        moreMenu={mordernMenu}
      />
      <div className="art-md-with-header">
        <ArtMoveDetail
          type={0}
          title_e={'By Genre'}
          title_k={'유형별'}
          moreList={workTypeList}
          moreActive={true}
          items={workListByType}
          breakRows={4}
          breakSlidersPerRow={2}
          represent={6}
          options={options02}
          moreListChange={setMenu}
          moreMenu={menu}
        />
      </div>
    </div>
  )
}
export default Main
