import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from 'react-redux';
import { store, persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react';
import { WalletStateProvider } from "./context/walletStateProvider";
import { LoadingStateProvider } from "./context/loadingStateProvider";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <LoadingStateProvider>
  <WalletStateProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    </WalletStateProvider>
  </LoadingStateProvider>
  // </React.StrictMode>
);
