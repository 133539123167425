import React from 'react'
import './AllowModal.css'


function AllowModal({ setModalOpen, admissionFunc, denyFunc, title, content, returnType }) {
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }

  const denyState = async() => {
    await denyFunc()
    setModalOpen(false)
  }

  const admissionState = async() => {
    await admissionFunc()
    setModalOpen(false)
  }
  
  return (
    <div className="allow-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1>{title}</h1>
          <div className="check-content">{content}</div>
          <div className="check-btn">
            <button onClick={denyState}>{returnType ? '반납' : '거부'}</button>
            <button onClick={admissionState}>{returnType ? '연장' : '승인'}</button> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllowModal
