import React from 'react'
import { Link } from 'react-router-dom'
import { LoadingState } from '../../context/loadingStateProvider'
import { artViewDate } from '../../hook/utils/artdbView'
import { checkUseName } from '../../hook/utils/checkUseName'
import { handleImgError, userImage, workMainImage } from '../../services/imageRoute'
import './SearchResult.css'
import { KYCImageArtist } from '../../components/KYC/artist'
import { getTitle } from '../../hook/utils/getTitle'

const SearchResult = props => {
  // const { lang } = useSelector(v => v.setting, shallowEqual)

  const loadingState = React.useContext(LoadingState);


  React.useEffect(() => {
    // console.log(props.items)
    search_items(props.items)
  }, [props.items]);

  const [data, setData] = React.useState([]);



  const search_items = imgList => {

    let _data = [];
    if (props.type === 1) {
      imgList.map((item, i) => {
        const checkSameName = `${item.first_NAME}${item.name}` === `${item.first_ENG_NAME}${item.eng_NAME}`;
        const checkSameNickname = item.nickname === item.eng_NICKNAME;
        _data.push(
          <Link
            to={`/artdb/artist/${item.user_ID}`}
            key={`type1${item.user_ID}`}
          >
            <div className="search-item">
              <div style={{ position: 'relative', zIndex: 1 }}>
                {
                  item.status === 2 &&
                  <KYCImageArtist />
                }
                <img src={`${userImage}/${item.picture}`} alt='' onError={handleImgError} onContextMenu={
                  (e) => {
                    e.preventDefault();

                  }
                }></img>
              </div>
              <div className="search-item-info">
                <h2 id={`name--${i}`}>
                  {
                    props.lang === 0
                      ? <>
                        {checkUseName('ko', item.use_NAME, `${item.first_NAME}${item.name}`, `${item.first_NAME}${item.name}`, item.nickname, item.nickname)}
                      </>
                      : <>
                        {checkUseName('en', item.use_NAME, `${item.first_ENG_NAME} ${item.eng_NAME}`, `${item.first_ENG_NAME} ${item.eng_NAME}`, item.eng_NICKNAME, item.eng_NICKNAME)}
                      </>
                  }

                </h2>
                {/* <h3>
                  {props.lang !== 0 ? 
                  <>
                    {checkUseName('ko', item.use_NAME, `${item.first_NAME}${item.name}`, `${item.first_NAME}${item.name}`, item.nickname, item.nickname)
                      === checkUseName('en', item.use_NAME, `${item.first_ENG_NAME} ${item.eng_NAME}`, `${item.first_ENG_NAME} ${item.eng_NAME}`, item.eng_NICKNAME, item.eng_NICKNAME)
                        ? <br /> 
                        : checkUseName('ko', item.use_NAME, `${item.first_NAME}${item.name}`, `${item.first_NAME}${item.name}`, item.nickname, item.nickname)}
                  </>
                    : <>
                      {checkUseName('ko', item.use_NAME, `${item.first_ENG_NAME} ${item.eng_NAME}`, `${item.first_ENG_NAME} ${item.eng_NAME}`, item.eng_NICKNAME, item.eng_NICKNAME)
                        === checkUseName('en', item.use_NAME, `${item.first_ENG_NAME} ${item.eng_NAME}`, `${item.first_ENG_NAME} ${item.eng_NAME}`, item.eng_NICKNAME, item.eng_NICKNAME)
                        ? <br />
                        : checkUseName('en', item.use_NAME, `${item.first_ENG_NAME} ${item.eng_NAME}`, `${item.first_ENG_NAME} ${item.eng_NAME}`, item.eng_NICKNAME, item.eng_NICKNAME)}
                    </>
                  }
                </h3> */}
                <h3>
                  {props.lang !== 0 ? <>
                    {checkUseName('ko', item.use_NAME, `${item.first_NAME}${item.name}`, `${item.first_NAME}${item.name}`, item.nickname, item.nickname)}
                  </>
                    : <>
                      {checkUseName('en', item.use_NAME, `${item.first_ENG_NAME} ${item.eng_NAME}`, `${item.first_ENG_NAME} ${item.eng_NAME}`, item.eng_NICKNAME, item.eng_NICKNAME)}
                    </>
                  }
                </h3>
                
                <h4>{item.birthday === '' || item.birthday === null ? '미상' : `${item.birthday.includes('-') ? item.birthday.split('-')[0] : item.birthday}년`}</h4>
              </div>
            </div>
          </Link>
        )

                })
    } else if (props.type === 2) {
      imgList.map((item, i) => (
        _data.push(
          <Link
            to={`/artdb/work/${item.workInfo.pk}`}
            key={`type2${item.workInfo.pk}`}
          >
            <div className="search-item">
              <div>
                <img src={`${workMainImage}/${item.workInfo.image}`} alt='' onError={handleImgError} onContextMenu={
                  (e) => {
                    e.preventDefault();

                  }
                }></img>
              </div>
              <div className="search-item-info">
                <h3>
                  {/* {checkUseName(props.lang === 0 ? 'ko' : 'en', '1', item.artist, item.eng_ARTIST, item.artist, item.eng_ARTIST)} */}
                  {props.lang === 0 ? item.workInfo.artist : item.workInfo.eng_ARTIST}
                </h3>
                <h2>{getTitle(props.lang, item.workInfo.title, item.workInfo.eng_TITLE )}</h2>
                <h4>{artViewDate(item.workInfo.start_CREATE, item.workInfo.end_CREATE)}</h4>
              </div>
            </div>
          </Link>
        )
      ))
    } else if (props.type === 3) {
      _data.push(
        imgList.map((item, i) => (
          <Link
            to={item.user_TYPE === 0 ? `/artdb/collection/${item.user_ID}` : `/artdb/venue/${item.user_ID}`}
            key={`type3${item.user_id}`}
          >
            <div className="search-item">
              <div style={{ position: 'relative', zIndex: 1 }}>
                {
                  item.status === 2 &&
                  <KYCImageArtist />
                }
                <img src={`${userImage}/${item.picture}`} alt='' onError={handleImgError} onContextMenu={
                  (e) => {
                    e.preventDefault();

                  }
                }></img>
              </div>
              <div className="search-item-info">
                <h2>
                  {
                    props.lang === 0
                      ? <>
                        {item.user_TYPE === 0 ? checkUseName('ko', item.use_NAME, `${item.first_NAME}${item.name}`, `${item.first_NAME}${item.name}`, item.nickname, item.nickname) : `${item.first_NAME}`}
                      </>
                      : <>
                        {item.user_TYPE === 0 ? checkUseName('en', item.use_NAME, `${item.first_ENG_NAME} ${item.eng_NAME}`, `${item.first_ENG_NAME} ${item.eng_NAME}`, item.eng_NICKNAME, item.eng_NICKNAME) : `${item.first_ENG_NAME}`}
                      </>
                  }

                </h2>
                <h3>
                  {props.lang !== 0 ? <>
                    {item.user_TYPE === 0 ? checkUseName('en', item.use_NAME, `${item.first_NAME}${item.name}`, `${item.first_NAME}${item.name}`, item.nickname, item.nickname) : `${item.first_NAME}`}
                  </>
                    : <>
                        {item.user_TYPE === 0 ? checkUseName('ko', item.use_NAME, `${item.first_ENG_NAME} ${item.eng_NAME}`, `${item.first_ENG_NAME} ${item.eng_NAME}`, item.eng_NICKNAME, item.eng_NICKNAME) : `${item.first_ENG_NAME}`}
                    </>
                  }
                </h3>
              </div>
            </div>
          </Link>
        )
        ))
    }
    setData(_data);
    loadingState.setOpen(false);
    loadingState.setText('');
  }

  // return <div className="search-result-list">{search_items(props.items)}</div>
  return <div className="search-result-list">{data}</div>
}
export default SearchResult
