import React from 'react'
import './VenueDetailInfo.css'
import { useNavigate } from 'react-router-dom'
import { handleImgError } from '../../services/imageRoute'

function VenueDetailInfo(props) {

  const navigate = useNavigate()
  const item = props.item
  const [info, setInfo] = React.useState([])
  const [lastInfo, setLastInfo] = React.useState([])

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const findHttp = (temp) => {
    if (temp.indexOf('http') === -1) {
      return `http://${temp}`
    }
    return temp
  }


  const hyperlink = (item) => {
    const list = [];
    if (item.content === null) return list
    if (item.content.indexOf(';') === -1) {
      const json = {
        text: item.content.replaceAll(' ', ''),
        link: findHttp(item.content.replaceAll(' ', ''))
      }
      list.push(json)
      return list
    }

    const links = item.content.split(';');
    for (let i = 0; i < links.length; i++) {
      const json = {
        text: links[i].replaceAll(' ', ''),
        link: findHttp(links[i].replaceAll(' ', ''))
      }
      list.push(json)
    }
    return list
  }


  React.useEffect(() => {
    const myItem = []
    if (item === null || item === undefined) {
      return setInfo(myItem)
    }
    
    for (let i = 0; i < item.infos.length; i++) {
      myItem.push(
        <li key={`venueDetail${i}`} style={{ paddingBottom: item.infos.length - 1 === i ? 0 : matches ? '20rem' : '40rem' }}>
          {
            item.infos[i].title === '기관소개'
              ? <span className="venue-detail__name" style={{ lineHeight: '20rem' }}>{item.infos[i].title}</span>
              : item.infos[i].title === '홈페이지'
                ? <span className="venue-detail__name" style={!matches ? { lineHeight: '35rem' } : {}}>{item.infos[i].title}</span>
                : <span className="venue-detail__name">{item.infos[i].title}</span>
          }
          {
            item.infos[i].title === '기관소개'
              ? <span className="venue-detail__content" style={!matches ? { lineHeight: '35rem', wordBreak: 'break-word' } : { wordBreak: 'break-word', lineHeight: '20rem' }}>{item.infos[i].content}</span>
              : item.infos[i].title === '홈페이지'
                ? <div className='venue-detail__content'>
                  {hyperlink(item.infos[i]).map((link) => {
                    return (
                      <a href={link.link} target='_blank' rel="noreferrer">
                        <span className="venue-detail__content" style={!matches ? { lineHeight: '35rem', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' } : { overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>{link.text}</span>
                      </a>
                    )
                  })}
                </div>
                : 
                <span className="venue-detail__content" style={{ whiteSpace: 'nowrap' }}>{item.infos[i].content}</span>
          }
        </li>
      )
    }
    // }
    setInfo(myItem)
    // setLastInfo(lastItem)
  }, [item])

  const moveOrigin = () => {
    const author = item.author;
    if (author.user_type === 2) {
      navigate(`/artdb/venue/${author.user_id}`)
    } else {
      navigate(`/artdb/artist/${author.user_id}`)
    }
  }

  return (
    item === null || item === undefined ? <></> :
      <div className="venue-detail">
        <section className="container">
          <div className="venue-detail-container">
            <section className="venue-detail-section">
              <img
                className="venue-detail__image"
                alt=''
                src={item.image}
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>

              <ul className="venue-detail__list">
                {info}
              </ul>
            </section>
          </div>
          {/* {lastInfo} */}
        </section>
      </div>
  )
}
export default VenueDetailInfo