import React from 'react'
import { Link, useParams } from 'react-router-dom'
import './SupportNoticeDetail.css'

import { sanitize } from 'dompurify'
import useMobileDetect from 'use-mobile-detect-hook'
import WorkHeader from '../../../../components/WorkHeader/WorkHeader'
import { LoadingState } from '../../../../context/loadingStateProvider'
import { setBidTime } from '../../../../hook/utils/getDateStr'
import { qTalkImage } from '../../../../services/imageRoute'
import setting from '../../../../services/setting'

function SupportNoticeDetail() {
  const detectMobile = useMobileDetect();
  const loadingState = React.useContext(LoadingState);

  const { supportId } = useParams();



  React.useEffect(() => { getData(supportId) }, [supportId])
  const [data, setData] = React.useState();



  const getData = async (pk) => {
    loadingState.setText('잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await setting.getNoticeDetail(pk);
    setData(res.notice);

    loadingState.setOpen(false);
    loadingState.setText('');
  }


  return (
    <div className='support-notice-detail'>
      <WorkHeader
        notice
        type={1}
        title_k={data === undefined ? "" : data.title}
        title_e={data === undefined ? "" : setBidTime(data.creation_TIME)}
        isMobile={false}
      />
      <section className="container detail-box">
        <ul>
          <li>
            <div
              className="support-notice-detail__comment ql-editor"
              dangerouslySetInnerHTML={{
                __html: sanitize(data === undefined ? "" :
                  detectMobile.isMobile() ?
                    data.content_M : data.content)
              }
              }
            />

          </li>
        </ul>
      </section>
    </div>
  )
}

export default SupportNoticeDetail