import React from 'react';
import DropDown from '../../../../components/DropDown/DropDown';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import SectionHeader from '../../../../components/SectionHeader/SectionHeader';
import './ArtistSchool.css';

import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkDate, handleInputMaxLength } from '../../../../hook/utils/validation';
import profile from '../../../../services/auth/profile';
import swal from 'sweetalert';

function ArtistSchoolEdit() {
  const location = useLocation();
  const { data } = location.state;

  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const { countryList } = useSelector(v => v.setting, shallowEqual)
  const { degreeList, eduStateList } = useSelector(v => v.setting.education, shallowEqual)

  const [school, setSchool] = React.useState(data.school);
  const [country, setCountry] = React.useState(data.country);
  const [admission, setAdmission] = React.useState(data.admission);
  const [graduated, setGraduated] = React.useState(data.graduated);
  const [degreeType, setDegreeType] = React.useState(data.degree_TYPE);
  const [eduStateType, setEduStateType] = React.useState(eduStateList[data.status]);
  const [etc, setEtc] = React.useState(data.etc);

  const navigate = useNavigate();

  React.useEffect(()=> {
    if(degreeType === '기타' && eduStateType !== '수료'){
      setEduStateType('재학중')
    }
  }, [degreeType])

  React.useEffect(()=> {
    if(eduStateType === '수료'){
      setGraduated('수료')
    } else if (eduStateType === '재학중'){
      setGraduated('재학중')
    }
  }, [eduStateType])

  React.useEffect(()=>{
    // console.log(eduStateList.indexOf(eduStateType))
  }, [eduStateType])

  const done = async () => {

    if (school === '' ) {
      swal('기관명을 입력해주세요.')
      return
    }
    if (admission === '') {
      swal('입학시기를 입력해주세요.')
      return
    }
    if (graduated === '' ) {
      swal('졸업시기를 입력해주세요.')
      return
    }
    if (degreeType === '' ) {
      swal('학위유형을 입력해주세요.')
      return
    }

    const status = eduStateType ?? eduStateList[0]
    // console.log(`status :: ${status}`)

    const res = await profile.changeSchool(school, country, admission,
      graduated, degreeType, etc, eduStateList.indexOf(status), data.pk);

    // console.log(res.data)
    
    if(!res.resultCode){
      swal('저장에 실패했습니다.')
      return
    }
    swal('저장 완료 했습니다.')
    navigate(-1)
  }

  return (
    <div className="artist-school">
      <Header login={true} colored="black" detail={true} />
      <SectionHeader title={'학력 수정'} />
      <section className="container a-exhibition-content">
        <ul className="a-ex-edit-info">
          <li>
            <h2>국가 *</h2>
            <DropDown
              options={countryList}
              select={countryList.indexOf(country)}
              setValue={setCountry} />
          </li>
          <li>
            <h2>기관명 *</h2>
            <input type="text" value={school} onChange={e => setSchool(e.target.value)}></input>
          </li>
          <li>
            <h2>학위유형 *</h2>
            <DropDown
              select={degreeList.indexOf(degreeType)}
              options={degreeList} setValue={setDegreeType} />
          </li>
          <li>
            <h2>상태 *</h2>
            <DropDown
              select={eduStateList.indexOf(eduStateType)}
              options={degreeType === '기타' ? ['재학중', '수료'] : eduStateList}
              setValue={setEduStateType} />
          </li>
          <li>
            <h2>입학시기 *</h2>
            <input
              value={admission}
              onChange={e => checkDate(setAdmission, e)}
              type="date"
              id="date"
              className="date-input"
              min={min}
              max={today}
            ></input>
          </li>
          {eduStateType === '졸업'
            ? <li>
              <h2>졸업시기 *</h2>
              <input
                value={graduated}
                onChange={e => checkDate(setGraduated, e)}
                type="date"
                id="date"
                className="date-input"
                min={min}
                max={today}
              ></input>
            </li>
            : null
          }
          <li>
            <h2>Note</h2>
            <div className='content-text'>
                    <textarea
                      maxLength={'200'}
                      value={etc}
                      onChange={e => handleInputMaxLength(setEtc,e)}></textarea>
                        <span>{etc === null ? '0/200' : `${etc.length}/200`}</span>
                    {/* <span>{content.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length}/2000</span> */}
                  </div>          </li>
        </ul>
        <div className="a-ex-edit--button">
          <button onClick={() => navigate(-1)}>취소</button>
          <button onClick={done}>저장</button>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default ArtistSchoolEdit
