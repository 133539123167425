import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import DropDown from '../../components/DropDown/DropDown'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import publicWork from '../../services/public/publicWork'
import './Search.css'
import SearchResult from './SearchResult'
import { LoadingState } from '../../context/loadingStateProvider'
import useInterval from '../../hook/useInterval'
import { DebounceInput } from 'react-debounce-input'

function Search() {
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const loadingState = React.useContext(LoadingState);

  const [items, setItems] = useState([])
  
  const searchTypeList = ['작가명', '작품명', '콜렉션명']
  // const orderList = ['최신등록순', '작품명순', '작가명순', '제작연도순']
  // const orderList = ['가나다순', 'ABC순', '가나다역순', 'ABC역순']
  const orderList = ['가나다순', 'ABC순']
  
  
  const [search, setSearch] = React.useState('')
  
  const [searchType, setSearchType] = React.useState(searchTypeList[0])

  const myLang = lang === 'ko' ? 0 : 1;

  const [order, setOrder] = React.useState(orderList[myLang])
  const [rows, setRows] = React.useState(12);

  const [fetching, setFetching] = useState(false);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);

  React.useEffect(() => {
    const mySearch = (searchTypeList.indexOf(searchType) + 1).toString();
    const myOrder = orderList.indexOf(order).toString()
    getList(mySearch, myOrder)
  },[searchType, order])

  const beforeAction = () => {
    setItems([]);
    setTotal(0);
    setMax(0);
  }
  
  // React.useEffect(() => {
  //   console.log(total)
  //   console.log(max)
  //   console.log(items)
  // },[total, max, items])

  const getList = async(_searchType, _order) => {
    setFetching(true)
    // loadingState.setText('잠시만 기다려주세요.');
    // loadingState.setOpen(true);
    const getPage = total === 0 ? 0 : Math.ceil(total/rows)
    const res = await publicWork.search(getPage, rows, _order, search, _searchType)
    const sumData = [...items, ...res.list]
    
    const setData = sumData.filter((element, index) => {
      return sumData.indexOf(element) === index;
  });

    if(res.list.length === 0){
      // loadingState.setOpen(false);
      // loadingState.setText('');
      return
    }else{
      setItems(setData)
      setMax(res.total)
      setTotal(setData.length)
      // loadingState.setOpen(false);
      // loadingState.setText('');
      setFetching(false)
    }
  }


  const getSearchList = async(_searchType, _order, temp) => {
    setFetching(true)
    // loadingState.setText('잠시만 기다려주세요.');
    // loadingState.setOpen(true);
    const getPage = total === 0 ? 0 : Math.ceil(total/rows)
    const res = await publicWork.search(getPage, rows, _order, temp, _searchType)
    const sumData = [...items, ...res.list]
    
    const setData = sumData.filter((element, index) => {
      return sumData.indexOf(element) === index;
  });

    if(res.list.length === 0){
      // loadingState.setOpen(false);
      // loadingState.setText('');
      return
    }else{
      setItems(setData)
      setMax(res.total)
      setTotal(setData.length)
      // loadingState.setOpen(false);
      // loadingState.setText('');
      setFetching(false)
    }
  }

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight -500 && fetching === false) {
      // 페이지 끝에 도달하면 추가 데이터를 받아온다
      const mySearch = (searchTypeList.indexOf(searchType) + 1).toString();
      const myOrder = orderList.indexOf(order).toString();
      getList(mySearch, myOrder);
    }
   };


   React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });


  return (
    <div className="search-page">
      <Header active="0" colored="black" login={false} detail={true} />
      <section className="search-bar">
        <div className="container">
          <DropDown options={searchTypeList} beforeAction={beforeAction} select={searchTypeList.indexOf(searchType)} setValue={setSearchType} />
          <section className="search__block">
          <DebounceInput
            minLength={0}
            debounceTimeout={500}
            type={'text'}
            placeholder={searchTypeList.indexOf(searchType) === 0 ? '작가를 검색해주세요.' : searchTypeList.indexOf(searchType) === 1 ? '작품을 검색해주세요.' : '콜렉션명을 검색해주세요.'}
            onKeyDown={() => beforeAction()}
            onChange={e => {
              setSearch(e.target.value)
              const mySearch = (searchTypeList.indexOf(searchType) + 1).toString();
              const myOrder = orderList.indexOf(order).toString()
              getSearchList(mySearch, myOrder, e.target.value)
            }}
            className="search__block__input"
          />
            {/* <input
              type={'text'}
              placeholder={searchTypeList.indexOf(searchType) === 0 ? '작가를 검색해주세요.' : searchTypeList.indexOf(searchType) === 1 ? '작품을 검색해주세요.' : '콜렉션명을 검색해주세요.'}
              value={search}
              onKeyUp={e => {
                if(e.key === "Backspace" || e.key === "Delete"){
                  if(e.target.value === ''){
                    const mySearch = (searchTypeList.indexOf(searchType) + 1).toString();
                    const myOrder = orderList.indexOf(order).toString()
                    getSearchList(mySearch, myOrder, '')
                  }
                }
              }}
              onChange={e => {
                setSearch(e.target.value)
                beforeAction();
              }}
              className="search__block__input"
            /> */}
            <button className="search__block__button" onClick={() => {
              const mySearch = (searchTypeList.indexOf(searchType) + 1).toString();
              const myOrder = orderList.indexOf(order).toString()
              getList(mySearch, myOrder)
              }}>
              <span className="ir_pm">검색</span>
            </button>
          </section>
        </div>
      </section>
      <section className="search-result">
        <div className="container">
          <div className="search-filter">
          <DropDown options={orderList} beforeAction={beforeAction} select={orderList.indexOf(order)} setValue={setOrder} />
          </div>
          {items.length === 0 ? (
            <div className="no-result">
              <h1>검색 결과가 없습니다</h1>
            </div>
          ) : (
            <SearchResult items={items} lang={orderList.indexOf(order)} type={(searchTypeList.indexOf(searchType) + 1)}/>
          )}
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default Search
