import React from 'react'
import swal from 'sweetalert'

import { useNavigate, useParams } from 'react-router-dom'
import ArtDetailInfo from '../../../components/ArtDetailInfo/ArtDetailInfo'
import HistoryInfo from '../../../components/HistoryInfo/HistoryInfo'
import WorkHeader from '../../../components/WorkHeader/WorkHeader'

import { shallowEqual, useSelector } from 'react-redux'
import DetailInfo from '../../../components/DetailIfo/DetailInfo'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import DateModal from '../../../components/Modal/DateModal'
import ModalPortal from '../../../components/Modal/ModalPortal'
import PinModal from '../../../components/Modal/PinModal'
import { LoadingState } from '../../../context/loadingStateProvider'
import { artViewDate, artViewSize } from '../../../hook/utils/artdbView'
import { checkUseName } from '../../../hook/utils/checkUseName'
import { getTitle } from '../../../hook/utils/getTitle'
import { userImage, workMainImage, workSubImage } from '../../../services/imageRoute'
import trade from '../../../services/myArchive/work/trade'
import publicWork from '../../../services/public/publicWork'
import './ArtDbWork3.css'
import WorkTopArrowSlider from './Slider/WorkTopArrowSlider'

function ArtDbWork3() {
  const loadingState = React.useContext(LoadingState);


  const { rowWorkTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { login, type, artist, gallery } = useSelector(v => v.auth, shallowEqual)
  const { workId } = useParams();
  const [work, setWork] = React.useState();
  const [history, setHistory] = React.useState([]);
  const [another, setAnother] = React.useState([]);
  
  const [detail, setDetail] = React.useState(null); // 작품 정보
  const [historyItem, setHistoryItem] = React.useState(null); // 작품 전시 이력
  const [moreItem, setMoreItem] = React.useState(null); // 작가의 다른 작품


  const [self, setSelf] = React.useState(false);
  const [upk, setUpk] = React.useState();


  const [rentalPinModal, setRentalPinModal] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const [rentalDateModal, setRentalDateModal] = React.useState(false);
  const [date, setDate] = React.useState('');
  const [sDate, setSDate] = React.useState('');
  const [exName, setExName] = React.useState('');
  const [exPlace, setExPlace] = React.useState('');

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  const navigate = useNavigate()
  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    needLogin()
  },[])
  const needLogin = async() => {
    if(!login){
      await swal('로그인이 필요한 서비스입니다.')
      navigate('/login', {replace:true})
      return
    }
  }

  React.useEffect(() => {
    getData(workId)
  },[workId])

  const checkBySelf = () => {
    let myType;
    if(type === 2){
      myType = gallery.email !== work.email
      setUpk(gallery.user_id)
    }else{
      myType = artist.email !== work.email
      setUpk(artist.user_ID)
    }
    setSelf(myType)
  }

  const getData = async (pk) => {
    const res = await publicWork.getDetail(pk)
    setWork(res.work)
    setHistory(res.exhibition_history)
    setAnother(res.another_work)
  }

  const convertDate = (startDate) => {
    if (startDate === '미상') {
      return `${startDate}`;
    }
    
    if(startDate.includes('-')){
      startDate = startDate.split('-')[0];
    }
    
    return  `${startDate}년`;
  };

  const setInfo = (work) => {
    const checkWorkType = rowWorkTypeList.filter(t => t.ko === work.work_TYPE)
    const workType = checkWorkType.length === 0 ? [{ko: work.work_TYPE,eng: work.work_TYPE}] : checkWorkType
    let info;
    if(work.user_type !== 2){
      info = [
        {
          title: '작품 번호',
          content: work.unique_KEY.slice(0,13),
        },
        {
          title: '장르',
          content: workType[0][`${lang}`]
        },
        {
          title: '에디션',
          content: work.edition_TOTAL === 0 ? '없음' : `${work.edition}/${work.edition_TOTAL}`,
        },
        {
          title: '작품 크기',
          content: artViewSize(work.size_X, work.size_Y, work.size_W, work.unit),
        },
        {
          title: '재질',
          content: work.material,
        },
        {
          title: '제작년도',
          content: convertDate(work.start_CREATE)
        },
        {
          title: '소장처',
          content: work.coa === 'null' ? work.collection : work.ownerDto.user_CODE,
        },
        {
          title: 'Q-CoA',
          content: work.coa === 'null' ? '미등록' : '등록완료',
        },
        // {
        //   title: '보유자',
        //   content: work.email,
        // }
      ]
    }else{
      info = [
        {
          title: '작품 번호',
          content: work.unique_KEY.slice(0,13),
        },
        // {
        //   title: '작가',
        //   content: lang === 'ko' ? work.artist :work.eng_ARTIST,
        // },
        {
          title: '장르',
          content: workType[0][`${lang}`]
        },
        {
          title: '에디션',
          content: work.edition_TOTAL === 0 ? '없음' : `${work.edition}/${work.edition_TOTAL}`,
        },
        {
          title: '작품 크기',
          content: artViewSize(work.size_X, work.size_Y, work.size_W, work.unit),
        },
        {
          title: '재질',
          content: work.material,
        },
        {
          title: '제작년도',
          content: convertDate(work.start_CREATE)
        },
        {
          title: '소장처',
          content: work.coa === 'null' ? work.collection : work.ownerDto.user_CODE,
        },
        {
          title: 'Q-CoA',
          content: work.coa === 'null' ? '미등록' : '등록완료',
        },
        // {
        //   title: '보유자',
        //   content: work.email,
        // }
      ]
    }
    return info
  }
  // 작품 정보
  React.useEffect(() => {
    if(work === undefined ){
      return
    }
    const own = work.ownerDto;

    const infoItem = {
    image: `${workMainImage}/${work.image}`,
    author: {
      image: `${userImage}/${own.image}`,
      user_type: own.user_TYPE,
      user_id: own.user_ID,
      name: own.user_TYPE === 2 ?  own.first_NAME : checkUseName(lang, own.use_NAME, `${own.first_NAME}${own.name}`, `${own.first_NAME}${own.name}`, own.nickname, own.nickname),
      e_name: own.user_TYPE === 2 ?  own.first_ENG_NAME : checkUseName(lang, own.use_NAME, `${own.first_ENG_NAME} ${own.eng_NAME}`, `${own.first_ENG_NAME} ${own.eng_NAME}`, own.eng_NICKNAME, own.eng_NICKNAME),
      email: own.email,
    },
    subImage: [],
    infos: setInfo(work)
  }
  for(let i = 0; i < work.sub_IMAGE.length; i ++){
    infoItem.subImage.push(
      `${workSubImage}/${work.sub_IMAGE[i].image}`
    )
  }
  setDetail(infoItem)

  checkBySelf()
  },[work, rowWorkTypeList, lang])

  // 전시 이력
  React.useEffect(() => {
    let item = []
    if(history.length === 0){
      setHistoryItem(item)
      return
    }
    for (let i = 0; i < history.length; i++) {
      item.push({
        modal: true,
        data: history[i],
        period: `${history[i].start_DATE} ~ ${history[i].end_DATE}`,
        category: history[i].type,
        title: `${history[i].exhi_NAME}, ${history[i].place}`,
      })
    }
    setHistoryItem(item)
  },[history])

  // 작가의 다른 작품
  React.useEffect(() => {
    let item = []
    if(another.length === 0){
      setMoreItem(item)
      return
    }
    for (let i = 0; i < another.length; i++) {
      item.push({
        info: `${workMainImage}/${another[i].image}`,
        author: getTitle(lang, another[i].title, another[i].eng_TITLE),
        title: artViewDate(another[i].start_CREATE, another[i].end_CREATE),
        link: `/artdb/work/${another[i].pk}`,
      })
    }
    setMoreItem(item)
  },[another])

  const buyFunc = () => {
  }
  
  const rentalFunc = async() => {


    if(sDate === '' || sDate === undefined){
      swal('대여일을 선택해주세요.')
      setRentalDateModal(true)
      return
    }

    if(date === '' || date === undefined){
      swal('반납일을 선택해주세요.')
      setRentalDateModal(true)
      return
    }
    const toTimeStamp = (str) => {
      const date = new Date(str);
      const timestamp = date.getTime();
      return timestamp.toString()
    }

    loadingState.setText('대여 신청 중 입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)
    const res = await trade.rental.requst(pin, toTimeStamp(sDate), toTimeStamp(date), type, workId, work.unique_KEY, upk, exName, exPlace)
    loadingState.setOpen(false)
    loadingState.setText('')
    setPin('')
    setDate('')
    if(!res.data){
      if(res.message === '이미 대여 신청이 진행중입니다.'){
        swal('이미 대여 신청이 진행중입니다.')
        return
      }
      if(res.message === '거래 중인 작품'){
        swal('거래 등록된 작품입니다.')
        return
      }
      swal('관리자에게 문의해주세요.')
      return
    }
    swal('대여 신청이 완료되었습니다.')
    
  }

  return (
    <div className="artdb-work3">
       {
        rentalPinModal &&
        <ModalPortal>
           <PinModal
            time={2}
            title={'대여할 작품의 간편패스워드를 입력해주세요.'}
            setModalOpen={setRentalPinModal}
            setMyPin={setPin}
            afterAction={true}
            action={() => setRentalDateModal(true)}
          />
        </ModalPortal>
      }
      {
        rentalDateModal &&
        <ModalPortal>
           <DateModal
            title={'대여 정보를 입력해주세요.'}
            setModalOpen={setRentalDateModal}
            date={date}
            setDate={setDate}
            sDate={sDate}
            setSDate={setSDate}
            afterAction={true}
            action={rentalFunc}
            exName={exName}
            setExName={setExName}
            exPlace={exPlace}
            setExPlace={setExPlace}
          />
        </ModalPortal>
      }
      <Header active="0" colored="black" detail={true} />
      <WorkHeader
        title_e={work === undefined ? '' : lang === 'ko' ? work.artist : work.eng_ARTIST} //작가명
        title_k={work === undefined ? '' : getTitle(lang, work.title, work.eng_TITLE)} // 작품명
        // title_k={work === undefined ? '' : lang === 'ko' ? work.title === '' ? work.eng_TITLE : work.title : work.eng_TITLE === '' ? work.title : work.eng_TITLE} // 작품명
        artdb={true}
        self={self}
        buyFunc={buyFunc}
        rentalFunc={() => setRentalPinModal(true)}
        isMobile={matches}
        // isPossibleRental={work === undefined ? false : work.rental === "1" ? false : work.transactionTb !== null ? false : work.market !== 2} //대여가능여부에따라서 true false
        isPossibleRental={work === undefined ? false : work.rental === 1 ? true : work.transactionTb !== null ? true : work.market !== 2} //대여가능여부에따라서 true false
        workState = {work === undefined ? '' : work.rental === 1 ? 3 : work.transactionTb !== null ? 4 : work.market} // 0: 대여가능 1:판매중 2:경매중 3:대여중 4:거래중
        coa = {work === undefined ? 'null' : work.coa}
      />
      <ArtDetailInfo item={detail} />
      <HistoryInfo title={'전시이력'} historyItem={historyItem} />
      <DetailInfo isAuctionDetail={true} item={work === undefined ? '' : work.detail_NOTE}/>
      {/* <TopArrowSlider title={work === undefined ? '' :  work.user_TYPE === 1 ? '작가의 다른 작품' : work.user_TYPE === 2 ? '기관의 다른 작품' : '소장자의 다른 작품'} item={moreItem} /> */}
      <WorkTopArrowSlider title={work === undefined ? '' :  work.ownerDto.user_TYPE === 1 ? '작가의 다른 작품' : work.ownerDto.user_TYPE === 2 ? '기관의 다른 작품' : '콜렉션의 다른 작품'} item={moreItem} />
      <Footer />
    </div>
  )
}
export default ArtDbWork3