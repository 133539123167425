import React, { useState } from 'react';
import styled from 'styled-components';

function Tooltip({ children, text }) {
  const [show, setShow] = useState(false);

  return (
    <StyledWrap>
      <StyledBlock
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </StyledBlock>
      <StyledTooltip isShow={show}>{text}</StyledTooltip>
    </StyledWrap>
  );
}

export default Tooltip;

const StyledWrap = styled.div`
    position: relative;
`;

const StyledTooltip = styled.span`
    display: flex;
    align-items: center;
    width: max-content;
    visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
    background: rgb(235, 236, 239);
    background: url('../../../../assets/question-line.png') no-repeat center;
    position: absolute;
    padding: 0 12px;
    font-size: 14px;
    border-radius: 5px;
    left: 25rem;
    top: 0;
    bottom: 19rem;
    &:before {
        display: block;
        position: absolute;
        content: "";
        left: -18px;
    }
    @media (max-width: 480px) {
      left: 10rem;
      bottom: 12rem;
    }
    }
`;

const StyledBlock = styled.div`
    width: max-content;
`;