import React, { useContext, useRef, useState } from 'react'
import Slider from 'react-slick'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import './PageSlide2.css'
import { Link } from 'react-router-dom'
import { WalletState } from '../../context/walletStateProvider'
import metamask from '../../services/web3/metamask'
import { LoadingState } from '../../context/loadingStateProvider'
function PageSlide2(props) {
  const walletState = useContext(WalletState);
  const loadingState = React.useContext(LoadingState);


  const sliderRef = useRef()
  const PAGE_PER_ITEM = props.rows * props.slidesPerRow
  const TOTAL_PAGE = props.items.length === 0 ? 0 : Math.ceil(props.items.length / PAGE_PER_ITEM)
  const [dotArr, setDotArr] = useState(new Array(TOTAL_PAGE).fill(0))

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    rows: props.rows,
    slidesPerRow: props.slidesPerRow,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          rows: props.breakRows,
          slidesPerRow: props.breakSlidesPerRow,
        },
      },
    ],
    appendDots: dots => (
      <div>
        <ul className="slick-dots-container">
          {dots.map((item, index) => {
            // console.log(`${index} :: ${(item).toString()}`)
            // console.log(item)
            return (
              <li
                key={index}
                style={
                  dotArr[index]
                    ? { display: 'none' }
                    : { display: 'inline-block' }
                }
              >
                {item}
              </li>
            )
          })}
        </ul>
      </div>
    ),
    customPaging: i => <div className="dots-number">{i + 1}</div>,
    beforeChange: (prev, next) => {
      ShowPaginationNumbers(next + 1)
    },
  }

  React.useEffect(() => {
    ShowPaginationNumbers(1)
  }, [])

  const slider_items = imgList => {
    return imgList.map((item, i) => {
      return props.divItem ? (
        item
      ) : (
        <div key={i} className="page-slider2__item">
          <div className="page-slider2-item-row">
            <Link
              to={item.link}
              state={{
                item: item,
              }}
            >
              <img
                className="page-item-image"
                src={require('../../' + item.info)}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
            </Link>

            <div>
              {item.name ? (
                <h2 className="page-item-author">
                  {item.name} <span>{item.name_e}</span>
                </h2>
              ) : (
                <></>
              )}
              {item.author ? (
                <h2 className="page-item-author">
                  {item.author} <span>{item.author_e}</span>
                </h2>
              ) : (
                <></>
              )}
              {item.title ? (
                <h3 className="page-item-email">{item.title}</h3>
              ) : (
                <></>
              )}
              <h3 className="page-item-email">{item.email}</h3>
              <button className="page-item-button">팔로우</button>
            </div>
          </div>
        </div>
      )
    })
  }

  function ShowPaginationNumbers(currentPage) {
    let startPage
    let endPage
    if (TOTAL_PAGE <= 5) {
      startPage = 1
      endPage = TOTAL_PAGE
    } else {
      if (currentPage <= 3) {
        startPage = 1
        endPage = 5
      } else if (currentPage + 1 >= TOTAL_PAGE) {
        startPage = TOTAL_PAGE - 4
        endPage = TOTAL_PAGE
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    let numArr
    if (TOTAL_PAGE === 1) {
      numArr = [false] // 시작 - 끝 만 보이도록
    } else {
      numArr = new Array(TOTAL_PAGE).fill(1)
      for (var i = startPage - 1; i <= endPage - 1; i++) {
        numArr[i] = false // 시작 - 끝 만 보이도록
      }
    }
    setDotArr(numArr)
  }

  return (
    <section className="container page-slider2">
      <div className="page-slider2__list">
        <div style={props.total === 0 ? { display: 'none' } : {}}>
        <button
          className="slick-first"
          onClick={() => {
            sliderRef.current.slickGoTo(0)
          }}
        >
          <span className="ir_pm">처음으로</span>
        </button>

        <button
          className="slick-last"
          onClick={() => {
            sliderRef.current.slickGoTo(
              Math.ceil(props.items.length / PAGE_PER_ITEM) - 1
            )
          }}
        >
          <span className="ir_pm">끝으로</span>
        </button>
        </div>
        {props.nft && !props.notLoading ?
           
          walletState.address === null ?
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <span style={{ fontSize: '3vh' }}>지갑연결을 먼저 해주세요.</span>
              <button className='my-button' onClick={() => {
                metamask.connect(walletState);
                loadingState.setOpen(true);
                loadingState.setText('연결된 지갑의 NFT목록을 가져오는중입니다.\n잠시만 기다려주세요.');
              }}>지갑 연결하기</button>
            </div>
            :
            <Slider ref={sliderRef} {...settings}>
              {slider_items(props.items)}
            </Slider>
          :
          <Slider ref={sliderRef} {...settings}>
            {slider_items(props.items)}
          </Slider>
        }
      </div>
    </section>
  )
}
export default PageSlide2