import React from 'react'
import { Link } from 'react-router-dom'
import './MarketHeader.css'

const MarketHeader = props => {


  
  const moreListChange = i => {
    props.moreListChange(i)
  }

  const moreListChange2 = i => {
    props.moreListChange2(i)
  }

  return (
    <header
      className={
       props.moreList && props.moreActive && props.moreList2 && props.moreActive2
          ? 'work-header work-header-d work-header-d2'
       : props.moreList && props.moreActive
          ? 'work-header work-header-d'
          : 'work-header'
      }
      style={props.color === false ? { background: 'none' } : {}}
    >
      <div className="container" >
        <div className="market-header__container">
          <div className="market-header-info__container">
            <session className='market-header__info' style={{display:'flex',flexDirection:'row'}} >
              <h2>
                {props.title_k}
              </h2>
              <h2 style={{paddingLeft:4}}>
                {props.title_e ? <h4>{props.title_e}</h4> : <></>} 
              </h2>
            </session>

            <p className="market-header__move">
              {props.follow ? (
                // 팔로우 주석
                // <button className="work-header__follow">팔로우</button>
                <button onClick={props.followFunc} className={props.followState ? "artist-item--heart_active" : "artist-item--heart"}>
                  <span className="ir_pm">좋아요</span>
                </button>
              ) : props.move ? (
                <Link
                  to={props.move}
                  state={{
                    type: props.type,
                    title_e: props.title_e,
                    title_k: props.title_k,
                    moreList: props.moreList,
                    moreActive: props.moreActive,
                    options: props.options,
                    breakRows: props.breakRows,
                    breakSlidersPerRow: props.breakSlidersPerRow,
                    moreMenu: props.moreMenu,
                    // moreListChange: props.moreListChange
                  }}
                >
                  <span>더보기</span>
                  <button className="market-header__move-icon"></button>
                </Link>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
        {props.moreList && props.moreActive ? (
          <ul className="market-header__detail">
            {props.moreList.map((item, i) => {
              return (
                <li key={i}>
                  <button
                    className={i === props.moreMenu ? 'choice' : ''}
                    onClick={() => { moreListChange(i) }}
                  >
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {item}
                    </span>
                  </button>
                </li>
              )
            })}
          </ul>
        ) : (
          <></>
        )}
        {props.moreList2 && props.moreActive2 ? (
          <ul className="market-header__detail">
            {props.moreList2.map((item, i) => {
              return (
                <li key={i}>
                  <button
                    className={i === props.moreMenu2 ? 'choice' : ''}
                    onClick={() => { moreListChange2(i) }}
                  >
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {item}
                    </span>
                  </button>
                </li>
              )
            })}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </header>
  )
}
export default MarketHeader
