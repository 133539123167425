import React, { useEffect, useState } from 'react';
import DropDown from '../DropDown/DropDown'
import PageSlide from '../PageSlide/PageSlide'
import './TableSlide.css'
import PageSlide2 from '../PageSlide2/PageSlide2'
function TableSlide(props) {
  const changeMenu = e => {
    props.changeMenu(e)
  }

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  return (
    <div className="table-slide">
      {
      props.notice ? (
          <div className="container table-header-container" style={matches?{paddingTop:'4rem',paddingBottom:'10rem'}:{paddingBottom:'13rem'}}>
          {/* <div className="container table-header-container" style={matches?{paddingTop:'4rem',paddingBottom:'10rem', borderBottom: '1rem solid #acacac'}:{paddingBottom:'13rem', borderBottom: '1rem solid #acacac'}}> */}
          <div className="table-header-title">
            <a
              className={
                props.menu === 0
                  ? 'th-title-select-active th-title-select '
                  : 'th-title-select'
              }
              onClick={() => changeMenu(0)}
            >
              <h1>대여 승인</h1>
              <span></span>
            </a>
            <a
              className={
                props.menu === 1
                  ? 'th-title-select-active th-title-select '
                  : 'th-title-select'
              }
              onClick={() => changeMenu(1)}
            >
              <h1>대여 연장 승인</h1>
              <span></span>
            </a>
            <a
              className={
                props.menu === 2
                  ? 'th-title-select-active th-title-select '
                  : 'th-title-select'
              }
              onClick={() => changeMenu(2)}
            >
              <h1>대여 연장/반납 요청</h1>
              <span></span>
            </a>
            <a
              className={
                props.menu === 3
                  ? 'th-title-select-active th-title-select '
                  : 'th-title-select'
              }
              onClick={() => changeMenu(3)}
            >
              <h1>배송관리</h1>
              <span></span>
            </a>
          </div>
          </div>
      ) : (
        <header className="table-header">
        <div className="container table-header-container">
          {props.title ? (
            props.title
          ) : 
          props.nft ? (
            <div className="table-header-title">
              {/* <a
                className={
                  props.menu === 0
                    ? 'th-title-select-active th-title-select '
                    : 'th-title-select'
                }
                onClick={() => changeMenu(0)}
              >
                <h1>구매 내역</h1>
                <span></span>
              </a> */}
              <a
                className={
                  props.menu === 0
                    ? 'th-title-select-active th-title-select '
                    : 'th-title-select'
                }
                onClick={() => changeMenu(0)}
              >
                <h1>실물</h1>
                <span></span>
              </a>
              {/* <a
                className={
                  props.menu === 1
                    ? 'th-title-select-active th-title-select '
                    : 'th-title-select'
                }
                onClick={() => changeMenu(1)}
              >
                <h1>NFT</h1>
                <span></span>
              </a> */}
            </div>
          ) : <></>}
          {props.options ? <DropDown options={props.options} /> : <></>}
        </div>
      </header>
      )}

      {
        props.notice ?
        (
          <div className='rectangle' />
        ):
        (
          <></>
        )
      }

      
      <div className="table-slider-header">
        {props.slideHeader ? (
          props.slideHeader
        ) :

        props.menu === 0 ?
         (
          <ul className="container table-slider-header-container">
            <li>번호</li>
            <li>대표 이미지</li>
            <li>작품명</li>
            <li>판매자정보</li>
            <li>구매/낙찰일자</li>
            <li>결제금액</li>
            <li>구매확정</li>
          </ul>
        )
        :
        (
          <ul className="container table-slider-header-container">
            <li>번호</li>
            <li>NFT 이미지</li>
            <li>NFT 명</li>
            <li>토큰 ID</li>
            <li>낙찰 일자</li>
            <li>결제금액</li>
            <li>결제</li>
          </ul>
        )
      }
      </div>
      <div className="table-slider">
      <PageSlide
          breakRows={props.breakRows}
          breakSlidesPerRow={1}
          rows={props.rows ? props.rows : 6}
          slidesPerRow={1}
          items={props.items}
          divItem={true}
          total={props.total}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
          nft={props.nft}
          notLoading={props.notLoading}
        />
        {/* {
          props.nft ? 
          <PageSlide2
            items={props.items}
            notLoading={props.notLoading}
            divItem={true}
            nft={true}
            rows={3}
            slidesPerRow={3}
            breakRows={4}
            breakSlidesPerRow={2}
           /> 
          :
          <PageSlide
          breakRows={props.breakRows}
          breakSlidesPerRow={1}
          rows={props.rows ? props.rows : 6}
          slidesPerRow={1}
          items={props.items}
          divItem={true}
          total={props.total}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
        />
        } */}
      </div>
    </div>
  )
}
export default TableSlide
