import React from 'react'
import TableSlide from '../../../components/TableSlide/TableSlide'
import setting from '../../../services/setting'
import './SupportNotice.css'
import { useNavigate } from 'react-router-dom'

function SupportNotice() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(0)
  const [items, setItems] = React.useState([]);
  const [tableItem, setTableItem] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const row = 10;

  const titleItem = []
  titleItem.push(<div className="sn-header">공지사항</div>)

  React.useEffect(() => {
    getData(currentPage)
  },[currentPage])

  const getData = async(page) => {
    const res = await setting.getNotice(row, page)
    setItems(res.list)
    setTotal(res.total)
  }

  const getDateStr = (timestamp) => {
    const date = new Date(timestamp)

    var sYear = date.getFullYear();
    var sMonth = date.getMonth() + 1;
    var sDate = date.getDate();

    sMonth = sMonth > 9 ? sMonth : "0" + sMonth;
    sDate  = sDate > 9 ? sDate : "0" + sDate;
    return `${sYear}/${sMonth}.${sDate}`;
}

  React.useEffect(() => {
    if(items.length === 0){
      return
    }

    const myItem = []

    for(let i = 0; i < items.length; i ++){
      
      const time = getDateStr(items[i].creation_TIME)
      const year = time.split('/')[0]
      const date = time.split('/')[1]

      myItem.push(
        <div className="sn-table-item" onClick={() => navigate(`./${items[i].pk}`)}>
        <div className="sn-mb-container">
          <div className="sb-ti-date">
            <h5>{year}</h5>
            <h1>{date}</h1>
          </div>
          <div className="sb-ti-content">
            <h1>{items[i].title}</h1>
            {/* <p>
              {items[i].content}
            </p> */}
          </div>
        </div>
      </div>
      )
    }

    setTableItem(myItem)
  },[items])

  return (
    <div className="support-notice">
      <section className="container">
        <TableSlide
          breakRows={3}
          rows={10}
          title={titleItem}
          items={tableItem}
          total={total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </section>
    </div>
  )
}
export default SupportNotice
