import React from 'react'
import './DetailInfo.css'
import SectionHeader from '../SectionHeader/SectionHeader'
function DetailInfo(props) {

  return (
    <>
      <SectionHeader title="작품 설명"></SectionHeader>
      <section className="container detail-info">
        <ul>
          {
            props.isAuctionDetail ? 
            (
              <p className="detail-info__comment" style={{wordWrap:'break-word'}}>{props.item}</p>
            ):
            (
              <li>
                <p className="detail-info__comment">{props.item}</p>
              </li>
            )
          }
        </ul>
      </section>
    </>
  )
}
export default DetailInfo
