import React from "react";

import CircleLoader from "react-spinners/CircleLoader";



import './loading.css'
const LoadingState = React.createContext(true);

const LoadingStateProvider = (props) => {

    const [open, setOpen] = React.useState(false)
    const [text, setText] = React.useState("")

    return (
        <LoadingState.Provider
            value={{
                open, setOpen,
                text, setText
            }}>
            {open &&
                <div className="load-modal-container">
                    <div className="modal-container">
                        <div className="modal-flex">
                            <img src={'../assets/loading.gif'} alt="" />
                        </div>

                        <div className="modal-flex">
                            {/* <img src={require('../assets/loading.gif')} alt="" /> */}
                            <CircleLoader
                                color="#2f80ed"
                                size={100}
                                />
                        </div>

                        <div className="modal-flex">
                            <div className="modal-text">{text}</div>
                        </div>
                    </div>
                </div>
            }
            {/* <div> */}
                {props.children}
            {/* </div> */}
        </LoadingState.Provider>
    )
}

export { LoadingState, LoadingStateProvider };