export const getTitle = (lang, title, engTitle) => {
    if(lang === 'ko'){
      if(title === ''){
        return engTitle
      }
      return title
    }else{
      if(engTitle === ''){
        return title
      }
      return engTitle
    }
  }