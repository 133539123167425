import React from 'react'
import './img.css'


export const KYCImageGallery = (props) => {
  return (
    <div className={
      props.disable
        ? "disable_kyc__ic"
        : props.full
          ? 'gallery_kyc__ic_full'
          : 'gallery_kyc__ic'}>KYC</div>
  )
}
