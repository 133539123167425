import React from 'react'
import './SignModal.css'
import SignaturePad from 'react-signature-canvas'

import { Buffer } from 'buffer'
import { workImageExtension } from '../../hook/utils/fileSize'
import { businessImage } from '../../services/imageRoute'


function BusinessModal({ setModalOpen, setFunc, func, setState }) {

  const [preView, setPreView] = React.useState(null);
  const [reNew, setReNew] = React.useState(false);

  React.useEffect(() => {
    if(func !== undefined || func !== null){
      setPreView(`${businessImage}/${func}`)
    }
  },[])

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }
  const changeState = () => {
    if(reNew){
      setState('new');
    }
    setModalOpen(false);
  }

  const importSign = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]
    setReNew(true);
    setFunc(file)
    setPreView(URL.createObjectURL(file))
    // setModalOpen(false)
  }

  return (
    <div className="sign-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1>사업자/법인 등록증</h1>
          <div className="sign-content" style={{
            border: "1px solid rgb(0, 0, 0)",
            margin: "10rem",
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            height: "fit-content",
            padding: '0'
          }}>
            <div className='sigPad' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={preView} alt='' style={{maxHeight: '100%', maxWidth: '100%', objectFit: 'contain'}}></img>
            </div>
          </div>
         
          <div className="sign-btn" style={{marginTop : '10px', width: '100%'}}>
            <button onClick={closeModal}>취소</button>
            {/* <form method="post" encType="multipart/form-data"> */}
                <div className="sign-import">
                  <label className='sign-import' htmlFor="sign">새로 등록</label>
                </div>
                <input
                  type="file"
                  id="sign"
                  name="sign"
                  accept={workImageExtension}
                  onChange={importSign}
                  style={{ display: 'none' }}
                />
              {/* </form> */}
            <button onClick={changeState}>확인</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BusinessModal
