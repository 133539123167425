  /**
   *  이름 자동화
    * @param {String} lagn 현재 언어
    * @param {String} useName 작가의 use_name 값
    * @param {String} koName
    * @param {String} enName
    * @param {String} koNickname 
    * @param {String} enNickname 
   */
  export const checkUseName = (lang, useName, koName, enName, koNickname, enNickname) => {
    if(useName === '1'){
      if(lang === 'ko'){
        if(koNickname === '' || koNickname === undefined || koNickname === null){
          return enNickname
        }
        return koNickname
      }else{
        if(enNickname === '' || enNickname === undefined || enNickname === null){
          return koNickname
        }
        return enNickname
      }
      
    }else{
      if(lang === 'ko'){
        if(koName === '' || koName === undefined || koName === null){
          return enName
        }
        return koName
      }else{
        if(enName === '' || enName === undefined || enName === null){
          return koName
        }
        return enName
      }
    }
  }

  /**
   *  이름 자동화 v2
    * @param {String} lagn 현재 언어
    * @param {String} type user_TYPE
    * @param {String} useName 작가의 use_name 값
    * @param {String} koName
    * @param {String} enName
    * @param {String} koNickname 
    * @param {String} enNickname 
   */
  export const checkUseNameMarket = (lang, type, useName, koName, enName, koNickname, enNickname) => {
    // if(type === 2)
    if(useName === '1'){
      if(lang === 'ko'){
        if(koNickname === '' || koNickname === undefined || koNickname === null){
          return enNickname
        }
        return koNickname
      }else{
        if(enNickname === '' || enNickname === undefined || enNickname === null){
          return koNickname
        }
        return enNickname
      }
      
    }else{
      if(lang === 'ko'){
        if(koName === '' || koName === undefined || koName === null){
          return enName
        }
        return koName
      }else{
        if(enName === '' || enName === undefined || enName === null){
          return koName
        }
        return enName
      }
    }
  }

