import React from 'react'
import './COAModal.css'
import { LoadingState } from '../../context/loadingStateProvider';
import { shallowEqual, useSelector } from 'react-redux';
import { checkDate } from '../../hook/utils/validation';

function EmailModal({ title, setModalOpen, setData, data, afterAction, action }) {

  const loadingState = React.useContext(LoadingState);
  const { today } = useSelector(v => v.setting.date, shallowEqual)


  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
  }

  const onOk = async() => {

    loadingState.setText('잠시만 기다려주세요.')
    loadingState.setOpen(true)
    closeModal()
    if(afterAction){
      action(data)
    }
    loadingState.setOpen(false)
    loadingState.setText('')
  }

  return (
    <div className="qr-modal">
      <div className="modal-container">
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1>
            {title}
          </h1>
          <div className="ma-add-date-mb" style={{ marginTop: '160rem', }}>
          <input
          autoComplete='off'
            className="login-input"
            placeholder="이메일 주소"
            value={data}
            onChange={e => setData(e.target.value)}
            required
          ></input>
                </div>
          <div className="qr-btn">
              <div style={{marginTop: '200rem', display: 'flex', justifyContent: 'space-around', width: '100%'}}>
              <button onClick={closeModal}>취소</button>
              <button onClick={onOk}>신청</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailModal
