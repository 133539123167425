import { applyMiddleware, createStore } from 'redux';
import ThunkMiddleware from 'redux-thunk';
import reducer from './reducers/reducer';
import { persistStore } from 'redux-persist';

export const store = createStore(
  reducer,
  applyMiddleware(ThunkMiddleware),
);

export const persistor = persistStore(store);

export default store;
