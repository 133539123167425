import React from 'react'
import './SubscribeInfo.css'

function SubscribeInfo(props) {

  const [check1, setCheck1] = React.useState(false)
  const [check2, setCheck2] = React.useState(false)
  const [check3, setCheck3] = React.useState(false)
  const [check4, setCheck4] = React.useState(false)
  const [check5, setCheck5] = React.useState(false)
  
  const checkOption = [
      {
       type: 'FREE',
       date: 'FREE',
       price: 0
      },
      {
        type: 'BASIC',
        date: 'MONTH',
        price: 1_3000
       },
       {
        type: 'BASIC',
        date: 'YEAR',
        price: 100_000
       },
       {
        type: 'PREMIUM',
        date: 'MONTH',
        price: 100_000
       },
       {
        type: 'PREMIUM',
        date: 'YEAR',
        price: 1_000_000
       },
  ]

  const onClickFnc = (type) => {
  
    props.setOption(checkOption[type - 1])
    switch (type) {
      case 1:
        setCheck1(true)
        setCheck2(false)
        setCheck3(false)
        setCheck4(false)
        setCheck5(false)
        break;
      case 2:
        setCheck1(false)
        setCheck2(true)
        setCheck3(false)
        setCheck4(false)
        setCheck5(false)
        break;
      case 3:
        setCheck1(false)
        setCheck2(false)
        setCheck3(true)
        setCheck4(false)
        setCheck5(false)
        break;
      case 4:
        setCheck1(false)
        setCheck2(false)
        setCheck3(false)
        setCheck4(true)
        setCheck5(false)
        break;
      case 5:
        setCheck1(false)
        setCheck2(false)
        setCheck3(false)
        setCheck4(false)
        setCheck5(true)
        break;
      default:
        setCheck1(false)
        setCheck2(false)
        setCheck3(false)
        setCheck4(false)
        setCheck5(false)
        break;
    }
  }

  return (
    <>
      <section className="container detail-info-sub">
        <ul className='detail-info-sub-header'>
          <li>
            <span></span>
            <span>무료</span>
            <span>베이직</span>
            <span>프리미엄</span>
          </li>

        </ul>
        <ul className='detail-info-sub-list'>
          <li>
            <span>작품수</span>
            <span>10점</span>
            <span>30점</span>
            <span>무제한</span>
          </li>
          <li>
            <span>Q-CoA</span>
            <span>10점</span>
            <span>30점</span>
            <span>무제한</span>
          </li>
          <li>
            <span>서비스료</span>
            {/* <button onClick={() => onClickFnc(1)}>
              <span style={check1 ? { display: 'flex' } : { display: 'none' }} className='check'>√</span> 무료</button> */}

              <button onClick={() => onClickFnc(1)}>
              <div style={{display: 'flex'}}>
                <span style={check1 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none', height: '0rem' }} >√ 무료</span> 
                <div style={!check1 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none',height: '0rem' }} >무료</div> 
              </div>
              </button>


            <button onClick={() => onClickFnc(check2 ? 3 : 2)}>
              <div style={{display: 'flex'}}>
                <span style={check2 && !check3 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none', height: '0rem' }} >√ 월 13,000원</span> 
                <div style={!check2 && !check3 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none',height: '0rem' }} >월 13,000원</div> 
              </div>
              <br />
              <div style={{display: 'flex'}}>
              <span style={!check2 && check3 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none', height: '0rem' }} >√ 연 100,000원</span> 
                <div style={!check2 && !check3 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none', height: '0rem' }} >연 100,000원</div> 
              </div>
              </button>
              

              <button onClick={() => onClickFnc(check4 ? 5 : 4)}>
              <div style={{display: 'flex'}}>
                <span style={check4 && !check5 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none', height: '0rem' }} >√ 월 13,000원</span> 
                <div style={!check4 && !check5 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none',height: '0rem' }} >월 13,000원</div> 
              </div>
              <br />
              <div style={{display: 'flex'}}>
              <span style={!check4 && check5 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none', height: '0rem' }} >√ 연 100,000원</span> 
                <div style={!check4 && !check5 ? { display: 'flex', flexDirection: 'row', padding: '0', width: '100%' } : { display: 'none', height: '0rem' }} >연 100,000원</div> 
              </div>
              </button>
          </li>
        </ul>
      </section>
    </>
  )
}
export default SubscribeInfo
