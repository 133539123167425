import produce from 'immer';
import * as ActionTypes from './actionTypes';

const initialState = {
    info: null, // 작품 정보
    token1: null, // 1단계 완료 토큰
    token2: null, // 2단계 완료 토큰
    workQR: null, // 작품의 QR
    userQR: null, // 유저의 QR
    pin: null, // 작품 간편 패스워드
    date: null,  // 자동 반납 할 기간
    allow: null,  // 판매 승인
    price: null  //판매 가격
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CLEAR:
            return produce(state, draft => {
                draft.info = null;
                draft.token1 = null;
                draft.token2 = null;
                draft.workQR = null;
                draft.userQR = null;
                draft.pin = null;
                draft.date = null;
                draft.allow = null;
                draft.price = null;
            })
        case ActionTypes.FIRST_SCAN:
            return produce(state, draft => {
                draft.workQR = action.data
                draft.info = action.info
            })
        case ActionTypes.FIRST_PASS:
            return produce(state, draft => {
                draft.pin = action.data;
            })
        case ActionTypes.FIRST_RETURN:
            return produce(state, draft => {
                draft.token1 = action.data;
            })
        case ActionTypes.SECOND_SCAN:
            return produce(state, draft => {
                draft.userQR = action.data;
            })
        case ActionTypes.SECOND_SALE_ALLOW:
            return produce(state, draft => {
                draft.allow = action.data;
            })
        case ActionTypes.SECOND_DATE:
            return produce(state, draft => {
                draft.date = action.data;
            })
        case ActionTypes.SECOND_PRICE:
            return produce(state, draft => {
                draft.price = action.data;
            })
        case ActionTypes.SECOND_RETURN:
            return produce(state, draft => {
                draft.token2 = action.data;
            })
        default:
            return state
    }
}

export default authReducer;
