import React from 'react'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import Slider from 'react-slick'
import '../../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../../node_modules/slick-carousel/slick/slick-theme.css'
import './WorkTopArrowSlider.css'
import { Link } from 'react-router-dom'
import { handleImgError } from '../../../../services/imageRoute'

function WorkTopArrowSlider(props) {
  /* 배너 셋팅 */
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  }

  const [pc, setPc] = React.useState([])
  const [mobile, setMobile] = React.useState([])

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });


  React.useEffect(() => {
    mobile_items(props.item)
    slider_items(props.item)
  }, [props.item])

  const mobile_items = item => {
    // console.log(item)
    if (item === null) {
      return
    }
    let arr = []
    for (let i = 0; i < item.length; i++) {
      arr.push(
        props.divItem ? (
          item[i]
        ) : (
          <div className="work-top-arrow-ㅡitem" key={i} >
            <Link to={item[i].link} state={{ item: item[i] }}>
              <img
                className="work-top-arrow-image"
                src={item[i].info}
                onError={handleImgError}
                alt=''
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              />
            </Link>

            <h4 className="work-top-arrow-author">{item[i].author}&nbsp;</h4>
            <span className="work-top-arrow-title">{item[i].title}&nbsp;</span>
            <span className="work-top-arrow-price">{item[i].price}&nbsp;</span>
          </div>
        )
      )
    }
    setMobile(arr)
  }


  const slider_items = imgList => {
    if (imgList === null) {
      return
    }
    let arr = []

    for (let i = 0; i < imgList.length; i++) {
      arr.push(
        props.divItem ? (
          imgList[i]
        ) : (
          <div className="work-top-arrow-item" key={i} >
            <Link to={imgList[i].link}>
              <img
                className="work-top-arrow-image"
                src={imgList[i].info}
                onError={handleImgError}
                alt=''
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              />
            </Link>
            <h4 className="work-top-arrow-author">{imgList[i].author}&nbsp;</h4>
            <span className="work-top-arrow-title">{imgList[i].title}&nbsp;</span>
            <span className="work-top-arrow-price">{imgList[i].price}&nbsp;</span>
          </div>
        )
      )
    }
    setPc(arr)
  }

  return (
    <div>
      <section className="work-top-arrow">
        <SectionHeader title={props.title}></SectionHeader>
        <div className="work-top-arrow-container">
          {
            pc.length < 3
              ? <div className='work-top-arrow-slider ' style={{ display: 'flex', gap: '25rem' }}> {pc} </div>
              : <Slider {...settings} className="work-top-arrow-slider">
                {pc}
                </Slider>
          }

        </div>
        <div className="work-top-arrow-mb-contianer">
          {mobile}
        </div>
      </section>
    </div>

  )
}
export default WorkTopArrowSlider