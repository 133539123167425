import client from "../../index";

/**
 * 큐레이터 픽
*/
const getCurator = async () => {
  const res = await client.json.get('/user/market/curator');
  return res.data;
}

/**
 * 핫 클릭
  * @param {number} count 개수
*/
const getHot = async (count) => {
  const setData = {
    count: count
  }
  const res = await client.json.get('/user/market/hot-click', { params: setData });
  return res.data;
}

/**
 * 금주 추천작
*/
const getRecommend = async () => {
  const res = await client.json.get('/user/market/recommend',);
  return res.data;
}

/**
 * 큐플
*/
const getQPlace = async () => {
  const res = await client.json.get('/user/agency/place', { params: { count: 3 } });
  return res.data;
}

/**
 * 큐톡 리스트
 * @param {string} page 현재 페이지
 * @param {string} count 리스트 갯수
 */
const getQTalk = async (page, count) => {
  const param = {
    page: page,
    count: count
  }

  const res = await client.json.get('/user/root/q-talk/stated', { params: param });
  return res.data;
}

/**
 * 큐톡 디테일
 * @param {number} pk 큐톡 pk
 */
const qTalkDetail = async (pk) => {
  const res = await client.json.get(`/user/root/q-talk/${pk}`);
  return res.data;
}

const marketPick = {
  getCurator, getHot, getRecommend, getQPlace, getQTalk, qTalkDetail
}

export default marketPick
