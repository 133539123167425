import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import useMobileDetect from 'use-mobile-detect-hook'
import { checkCapsLock } from '../../../hook/utils/checkCapsLock'
import { workImageExtension } from '../../../hook/utils/fileSize'
import { checkDate, passCheck } from '../../../hook/utils/validation'
import auth from '../../../services/auth/auth'
import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'
import AddressModal from '../../Modal/AddressModal'
import ModalPortal from '../../Modal/ModalPortal'
import { SignupStepper } from '../SignupStepper'
import './SignUpAgency.css'

function SignUpAgency() {

  const data = useLocation().state;

  // const data = {
  //   token: 'token',
  //   next: '1',
  //   email: 'aksgml223@aver.com',
  //   phone: '01057039770',
  //   name: '이만희',
  //   type: 'LOCAL',
  //   kyc: '',
  // }

  const isMobile = useMobileDetect().isMobile();


  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const { hostList } = useSelector(v => v.setting, shallowEqual)

  const navigate = useNavigate();

  const [koName, setKoName] = React.useState('');
  const [enName, setEnName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [birthday, setBirthday] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [checkPassword, setCheckPassword] = React.useState('');

  const [agencyKoName, setAgencyKoName] = React.useState('')
  const [agencyEnName, setAgencyEnName] = React.useState('')
  const [agencySince, setAgencySince] = React.useState('')


  const [contact1, setContact1] = React.useState('');
  const [contact2, setContact2] = React.useState('');
  const [agencyEmail, setAgencyEmail] = React.useState('');
  const [agencyHost, setAgencyHost] = React.useState('');
  const [homePage, setHomePage] = React.useState('');


  const [showPassword, setShowPassword] = React.useState(false);
  const [showCheckPassword, setShowCheckPassword] = React.useState(false);

  const [agree1, setAgree1] = React.useState(false);
  const [agree2, setAgree2] = React.useState(false);
  const [showAgree2, setShowAgree2] = React.useState(false);
  const [agree3, setAgree3] = React.useState(false);
  const [showAgree3, setShowAgree3] = React.useState(false);
  const [agree4, setAgree4] = React.useState(false);
  const [showAgree4, setShowAgree4] = React.useState(false);


  const [addressOpen, setAddressOpen] = React.useState(false);
  const [address, setAddress] = React.useState('');
  const [addressDetail, setAddressDetail] = React.useState('');
  const [fileName, setFileName] = React.useState('')
  const [fileImage, setFileImage] = React.useState()

  const detectMobile = useMobileDetect();


  const ko_pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
  const en_pattern = /[a-zA-Z]/; //영어

  const [isCapsLockOn1, setIsCapsLockOn1] = React.useState(false);
  const [isCapsLockOn2, setIsCapsLockOn2] = React.useState(false);


  React.useEffect(() => {
    setUserInfo()
  }, [])

  const setUserInfo = () => {
    setKoName(`${data.firstName}${data.name}`)
    setPhone(data.phone)
    setBirthday(data.birth)
  }

  const agreeAll = e => {
    setAgree1(e.target.checked)
    setAgree2(e.target.checked)
    setAgree3(e.target.checked)
    setAgree4(e.target.checked)
  }

  const done = async (e) => {

    e.preventDefault();

    if (koName === '') {
      if (enName === '') {
        swal('한글 이름 또는 영어 이름을 입력해주세요.')
        return
      }
    }

    if (enName === '') {
      if (koName === '') {
        swal('한글 이름 또는 영어 이름을 입력해주세요.')
        return
      }
    }


    if (phone === '') {
      swal('휴대폰 번호를 입력해주세요.')
      return
    }
    if (birthday === '') {
      swal('생년월일을 입력해주세요.')
      return
    }


    if (data.type === 'LOCAL') {
      if (password === '' || checkPassword === '') {
        swal('비밀번호를 입력해주세요.')
        return
      }
      if (password !== checkPassword) {
        swal('동일한 비밀번호를 입력해주세요.');
        return;
      }
      if (!passCheck(password)) {
        setPassword('')
        setCheckPassword('')
        return
      }
    }

    if (agencyKoName === '') {
      if (agencyEnName === '') {
        swal('한글 기관명 또는 영어 기관명을 입력해주세요.')
        return
      }
    }

    if (agencyEnName === '') {
      if (agencyKoName === '') {
        swal('한글 기관명 또는 영어 기관명을 입력해주세요.')
        return
      }
    }

    if (agencySince === '') {
      swal('설립연월일을 입력해주세요.')
      return
    }
    if (address === '' || addressDetail === '') {
      swal('주소를 입력해주세요.')
      return
    }

    // if(contact1 === ''){
    //   swal('연락처1를 입력해주세요.')
    //   return
    // }

    // if(contact2 === ''){
    //   swal('연락처2를 입력해주세요.')
    //   return
    // }

    // if (agencyEmail === '' || agencyHost === '') {
    //   swal('기관 이메일을 입력해주세요.')
    //   return
    // }

    // if(homePage === ''){
    //   swal('홈페이지 주소를 입력해주세요.')
    //   return
    // }

    // if (fileName === '') {
    //   swal('사업자/법인 등록증을 업로드해주세요.')
    //   return
    // }

    if (!agree2) {
      swal('약관 동의를 해주세요.')
      return
    }
    if (!agree3) {
      swal('개인정보 수집 및 이용 동의를 해주세요.')
      return
    }

    let fullEmail = `${agencyEmail}@${agencyHost}`

    if (agencyEmail === "") {
      fullEmail = data.email
    }

    const depart = phone.split(')')
    var zip;
    var number;

    if (depart.length === 1) {
      zip = '+82';
      number = depart[0].replaceAll('-', '')
    } else {
      zip = depart[0]
      number = depart[1].replaceAll('-', '')
    }

    let res;

    if (data.type === 'LOCAL') {
      res = await auth.agencySignUp(data.token, koName, enName, number, zip, birthday, password, agencyKoName, agencyEnName, agencySince, address, addressDetail, contact1, contact2, fullEmail, homePage, fileImage, data.kyc)
    } else {
      res = await auth.agencyOauthSignUp(data.email, data.type, koName, enName, number, zip, birthday, agencyKoName, agencyEnName, agencySince, address, addressDetail, contact1, contact2, fullEmail, homePage, fileImage, data.kyc)
    }

    if (!res.resultCode) {
      swal('관리자에게 문의해주세요.')
      return
    }
    await swal('회원가입이 완료되었습니다.');
    localStorage.setItem('QART_ACCESS', res.value);
    navigate("/signUp/end", { replace: true, state: { next: data.next } });
  }



  const uploadFile = e => {
    e.preventDefault();
    setFileName(e.target.files[0].name)
    setFileImage(e.target.files[0])
    // setFileImage(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div className="sign-agency">
      <Header />
      <form onSubmit={(e) => done(e)}>
        <section className="container sign-agency-container">
          <SignupStepper type={data.type} active={data.type === "LOCAL" ? 3 : 2} />
          <div className="artist-content agency-content pt-89 first">
            <div className="agency-phone-container">
              <div className="general-item">
                <h2 className="general-title">회원 아이디</h2>
                <div className="general-input-container">
                  <input
                    type="text"
                    autoComplete='off'
                    className="general-input mr-88"
                    value={data.email}
                    readOnly

                  ></input>
                </div>
              </div>

              {data.type === 'LOCAL' &&
                <>
                  <div className="general-item mr-88">
                    <h2 className="general-title">비밀번호 *</h2>
                    <div className="general-password">
                      <input
                        type={showPassword ? "text" : "password"}
                        autoComplete='off'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyUp={e => checkCapsLock(e, setIsCapsLockOn1)}
                        className={isCapsLockOn1 ? "general-password-input-capslock" : "general-password-input"}
                        placeholder="영문, 특수문자, 숫자 포함 8~20자 이내"

                      ></input>
                      {
                        isCapsLockOn1 &&
                        <div className="capslock__icon">
                          <span className="ir_pm">CapsLock</span>
                        </div>
                      }
                      <button className="password__icon" onClick={(e) => {e.preventDefault(); setShowPassword(!showPassword)}}>
                        <span className="ir_pm">가리기</span>
                      </button>
                    </div>
                  </div>
                  <div className="general-item">
                    <h2 className="general-title">비밀번호 확인 *</h2>
                    <div className="general-password">
                      <input
                        type={showCheckPassword ? "text" : "password"}
                        autoComplete='off'
                        value={checkPassword}
                        onChange={e => setCheckPassword(e.target.value)}
                        onKeyUp={e => checkCapsLock(e, setIsCapsLockOn2)}
                        className={isCapsLockOn2 ? "general-password-input-capslock" : "general-password-input"}
                        placeholder="영문, 특수문자, 숫자 포함 8~20자 이내"

                      ></input>
                      {
                        isCapsLockOn2 &&
                        <div className="capslock__icon">
                          <span className="ir_pm">CapsLock</span>
                        </div>
                      }
                      <button className="password__icon" onClick={(e) => {e.preventDefault();setShowCheckPassword(!showCheckPassword)}}>
                        <span className="ir_pm">가리기</span>
                      </button>
                    </div>
                  </div>
                </>
              }
              <div className="general-item">
                <h2 className="general-title">이름 *</h2>
                {/* <h2 className="general-title">이름 *(실명/한글 영문 중 1개만 입력) </h2> */}
                <input
                  type="text"
                  autoComplete='off'
                  value={koName}
                  onChange={e => setKoName(e.target.value)}
                  className="general-input mr-88"
                  readOnly={`${data.firstName}${data.name}` !== ''}
                  placeholder="한글"

                ></input>
                <input
                  type="text"
                  autoComplete='off'
                  value={enName}
                  onChange={e => setEnName(e.target.value)}
                  className="general-input"
                  placeholder="ENG"

                ></input>
              </div>
              <div className="general-item mr-88">
                <h2 className="general-title">휴대폰 번호 *</h2>
                <input
                  type="text"
                  autoComplete='off'
                  value={phone}
                  readOnly
                  // maxLength={13}
                  className="general-input"
                ></input>
              </div>
              <div className="general-item">
                <h2 className="general-title">생년월일 *</h2>
                <input
                  autoComplete='off'
                  type={data.birth === '' || data.birth === undefined ? "date" : "text"}
                  min={min}
                  max={today}
                  value={birthday}
                  readOnly={!(data.birth === '' || data.birth === undefined)}
                  onChange={e => checkDate(setBirthday, e)}
                  className="general-input"

                ></input>
              </div>

            </div>
          </div>
          <div className="artist-content agency-content">
            <div className="agency-phone-container">
              <div className="pb-26">
                <h2 className="general-title">기관명 *</h2>
                <input
                  type="text"
                  autoComplete='off'
                  value={agencyKoName}
                  onChange={e => setAgencyKoName(e.target.value)}
                  className="general-input mr-88"
                  placeholder="한글"

                ></input>
                <input
                  type="text"
                  autoComplete='off'
                  value={agencyEnName}
                  onChange={e => setAgencyEnName(e.target.value)}
                  className="general-input"
                  placeholder="ENG"

                ></input>
              </div>

              <div className="general-item mr-88">
                <h2 className="general-title">설립연월일 *</h2>
                <input
                  type="date"
                  autoComplete='off'
                  id="date"
                  min={min}
                  max={today}
                  value={agencySince}
                  onChange={e => checkDate(setAgencySince, e)}
                  className="general-input date-input"
                ></input>
              </div>


              <div className="pb-26">
                <h2 className="general-title">주소 *</h2>
                <div className="general-password">
                  <div style={{ display: 'flex' }}>
                    <input
                      value={address}
                      readOnly
                      className="general-password-input"
                      placeholder="주소 검색"
                    ></input>
                    <button onClick={(e) => { e.preventDefault(); setAddressOpen(true) }} className="search__icon password__icon mr-88" />
                    <span className="ir_pm">검색하기</span>
                  </div>
                  <input
                    type="text"
                    autoComplete='off'
                    value={/*agencyAddress*/addressDetail}
                    onChange={e => setAddressDetail(e.target.value)}
                    className="general-input"
                    placeholder="상세 주소 입력"
                  ></input>
                </div>
              </div>
              {addressOpen && (
                <ModalPortal>
                  <AddressModal setModalOpen={setAddressOpen} setAddress={setAddress} />
                </ModalPortal>
              )}


            </div>
          </div>
          <div className="artist-content agency-content last">
            <div className="agency-phone-container">
              <div className="general-item">
                <h2 className="general-title">연락처1</h2>
                <input
                  type="text"
                  autoComplete='off'
                  value={contact1}
                  onChange={e => setContact1(e.target.value)}
                  className="general-input mr-88"
                ></input>
              </div>
              <div className="general-item">
                <h2 className="general-title">연락처2</h2>
                <input
                  type="text"
                  autoComplete='off'
                  value={contact2}
                  onChange={e => setContact2(e.target.value)}
                  className="general-input"
                ></input>
              </div>
              <div className="pb-26" style={isMobile ? { paddingTop: '28rem' } : {}}>
                <h2 className="general-title">기관 이메일</h2>
                <div className="email-input-container">
                  {/* <div className="general-password"> */}
                  <input
                    type="text"
                    autoComplete='off'
                    value={agencyEmail}
                    onChange={e => setAgencyEmail(e.target.value)}
                    style={isMobile ? { width: '143rem' } : { height: '56rem' }}
                    className='email-input-email'
                  // className="email-input-email"
                  ></input>
                  <span style={isMobile ? { fontSize: '14rem', paddingLeft: '10rem', paddingRight: '10rem' } : {}}>@</span>
                  <input
                    type="text"
                    autoComplete='off'
                    className="email-input-email"
                    value={agencyHost}
                    onChange={e => setAgencyHost(e.target.value)}

                  ></input>
                  {/* <DropDown options={hostList} setValue={setAgencyHost} /> */}
                </div>
              </div>
              <div className="pb-26" style={isMobile ? { paddingTop: '28rem' } : {}}>
                <h2 className="general-title">홈페이지 주소</h2>
                <input
                  type="text"
                  autoComplete='off'
                  value={homePage}
                  onChange={e => setHomePage(e.target.value)}
                  className="general-input"
                  placeholder='여러 개 입력시 ; 전체 URL 입력'
                ></input>
              </div>
              <div className="general-item" style={isMobile ? { paddingTop: '28rem' } : {}}>
                <h2 className="general-title">사업자/법인 등록증</h2>
                <div className="filebox">
                  <label htmlFor="image">파일 업로드</label>
                  <input autoComplete='off' type="file" id="image" accept={workImageExtension} onChange={uploadFile} />
                  <input autoComplete='off' className="upload-name" id="image1" value={fileName} readOnly />
                </div>
              </div>
            </div>
          </div>
          <div className="agency-phone-container" style={{ justifyContent: 'center' }}>
            <div className="artist-option">
              <label className="login-option text-option-underline">
                <input autoComplete='off' type="checkbox" checked={agree1} onChange={agreeAll} />
                <span className="login-option__check" />
                <span className="option-text" > 전체 동의</span>
              </label>
              <label className="login-option text-option-underline" style={{ paddingLeft: detectMobile.isMobile() ? '13rem' : '30rem' }}>
                <input autoComplete='off' type="checkbox" checked={agree2} onChange={e => {
                  setAgree1(false)
                  setAgree2(e.target.checked)
                }} />
                <span className="login-option__check" />
                <span className="option-text" onClick={() => {
                  // if (showAgree2) return;
                  // setShowAgree2(true);
                  window.open('/terms/condition')
                }}>
                  <span>(필수) 이용약관 동의 ></span>
                </span>

              </label>
              <label className="login-option text-option-underline" style={{ paddingLeft: detectMobile.isMobile() ? '13rem' : '30rem' }}>
                <input autoComplete='off' type="checkbox" checked={agree3} onChange={e => {
                  setAgree1(false)
                  setAgree3(e.target.checked)
                }} />
                <span className="login-option__check" />
                <span className="option-text" onClick={() => {
                  // if (showAgree3) return;
                  // setShowAgree3(true);
                  window.open('/terms/privacy')
                }}>
                  <span >(필수) 개인정보 수집 및 이용 동의 ></span>
                </span>

              </label>
              <label className="login-option text-option-underline" style={{ paddingLeft: detectMobile.isMobile() ? '13rem' : '30rem' }}>
                <input autoComplete='off' type="checkbox" checked={agree4} onChange={e => {
                  setAgree1(false)
                  setAgree4(e.target.checked)
                }} />
                <span className="login-option__check" />
                <span className="option-text" onClick={() => {
                  // if (showAgree4) return;
                  // setShowAgree4(true);
                  window.open('/terms/promotion')
                }}>
              (선택) 홍보 및 마케팅 정보 활용 및 수신 동의 >
                </span>
              </label>
            </div>
          </div>
          <div className="agency-phone-container">
            <div className="general-next">
              {/* <button onClick={() => navigate(-1)}>이전</button> */}
              <button type="submit" className="general-next-button">완료</button>
            </div>
          </div>
        </section>
      </form>
      <Footer />
    </div>
  )
}
export default SignUpAgency
