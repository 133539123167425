
import React, { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { LoadingState } from "../../context/loadingStateProvider";
import { decodeCoA } from "../../hook/utils/decodeCoA";
import './PDFViewerModal.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewerModal({ path, setPath, setModalOpen }) {
  const pdfRef = useRef(null);
  const loadingState = React.useContext(LoadingState);

  const [show, setShow] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1); // 현재 랜더링된 페이지 번호


  const [item, setItem] = useState(null)


  React.useEffect(() => {
    if (path === null || path === undefined || path === "") {
      setItem(null)
      setModalOpen(false)
      setPath(null)
      return;
    }
    decodeCoA(loadingState, setItem, path)

  }, [path, setModalOpen])

  const onLoadSuccess = ({ numPages }) => {
    loadingState.setOpen(false);
    loadingState.setText('');
    setShow(true)
    setNumPages(numPages);
    document.body.style.overflow = 'hidden' // 모달 창 오픈 시 스크롤 불가
  };

  const onPassword = (callback) => {
    callback('Qart');
  }

  const loading = () => {
    setShow(false)
  }


  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false);
    setPath(null);
    document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }


  const beforePage = () => {
    if (1 === pageNumber) {
      setPageNumber(numPages)
    } else {
      setPageNumber(page => page - 1);
    }
  }
  const nextPage = () => {
    if (numPages === pageNumber) {
      setPageNumber(1)
    } else {
      setPageNumber(page => page + 1);
    }
  }

  return (
    <div className="pdf-viewer-modal">
      <div className="modal-container">
        <div className="modal-body"
          style={{ top: '50%' }}
          onClick={e => e.stopPropagation()}>
          <button className='pdf-close' onClick={closeModal} />
          <div className="react-pdf__Page__canvas__div">
            
              <Document
                inputRef={pdfRef}
                file={item}
                onLoadSuccess={onLoadSuccess}
                loading={loading}
                onLoadError={console.error}
                onPassword={onPassword}
              >
                {show &&
                  <Page
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                }
              </Document>
              
              {/* <div style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircleLoader
                  color="#2f80ed"
                  size={100}
                />
              </div> */}
            
          </div>
          <button className='pdf-prev' onClick={beforePage} />
          <button className='pdf-next' onClick={nextPage} />
        </div>
      </div>
    </div>
  )
}

export default PDFViewerModal


