import React from 'react';
import swal from 'sweetalert';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from "react-redux";
import { LoadingState } from '../../../context/loadingStateProvider';
import auth from '../../../services/auth/auth';
import * as authAction from '../../../store/reducers/auth/actions';

function NaverCallback() {
  const loadingState = React.useContext(LoadingState);
  const location = useLocation();
  const code = location.hash.split('=')[1].split('&')[0];
  const state = location.hash.split('=')[2].split('&')[0];

  React.useEffect(() => {
    loadingState.setText('잠시만 기다려주세요.');
    loadingState.setOpen(true);
    getProfile()
  }, [])

  const getProfile = async () => {
    // console.log(code)
    // console.log(state)

    const res = await auth.naverCallback(code);

    const email = JSON.parse(res.data).response.email

    return checkSignIn(email)
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkSignIn = async (email) => {
    const res = await auth.oauthLogin(email, 'NAVER');
    if(res.available === 2){
      swal('탈퇴한 계정입니다.')
      loadingState.setOpen(false);
      loadingState.setText('');
      navigate('/login');
      return;
  }
    if (res.message === 'already') {
      swal('이미 가입된 이메일입니다.')
      loadingState.setOpen(false);
      loadingState.setText('');
      navigate('/login');
      return
    }

    loadingState.setOpen(false);
    loadingState.setText('');
    if (res.message === 'empty') {
      // 회원가입 필요
      navigate('/signUp', {
        state: {
          email: email,
          token: res.Email_token,
          type: 'NAVER'
        }
      })
      return
    }
    // 로그인 완료
    dispatch(authAction.LOGIN());
    dispatch(authAction.SET_TYPE(res.type));
    localStorage.setItem('QART_ACCESS', res.value);
    getInfo(res.type)
    navigate('/');
  }

  const getInfo = async (type) => {
    let res;
    if (type === 2) {
      res = await auth.getAgencyInfo()
      dispatch(authAction.SET_GALLERY_INFO(res))
    } else {
      res = await auth.getArtistInfo()
      dispatch(authAction.SET_ARTIST_INFO(res.data))
    }
  }


  return (
    <div></div>
  )
}
export default NaverCallback
