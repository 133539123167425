import swal from 'sweetalert';

import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';


import { checkUseName } from '../../../hook/utils/checkUseName';

import { articleMainImage, handleImgError, userImage, workMainImage } from '../../../services/imageRoute';
import publicArtist from '../../../services/public/artDB/publicArtist';

import { shallowEqual, useSelector } from "react-redux";
import ArtDetailInfo from '../../../components/ArtDetailInfo/ArtDetailInfo';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import PageSlide2 from '../../../components/PageSlide2/PageSlide2';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import WorkHeader from '../../../components/WorkHeader/WorkHeader';
import { artViewDate, artViewPrice } from '../../../hook/utils/artdbView';
import { getTitle } from '../../../hook/utils/getTitle';



function CollectionDetail(props) {

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { login } = useSelector(v => v.auth, shallowEqual);
  const navigate = useNavigate()

  const [menu, setMenu] = useState(0)
  const { collectionId } = useParams();
  const [artist, setArtist] = React.useState();
  const [school, setSchool] = React.useState();
  const [article, setArticle] = React.useState();
  const [exhibition, setExhibition] = React.useState();
  const [work, setWork] = React.useState([]);
  const [sale, setSale] = React.useState([]);
  const [datail, setDetail] = React.useState(); // 유저 정보
  const [history, setHistory] = React.useState(); // 전시 이력 정보
  const [edu, setEdu] = React.useState(); // 학력 정보
  const [news, setNews] = React.useState(); // 소식
  const [other, setOther] = React.useState([]); // 작품
  const [market, setMarket] = React.useState([]); // 작품

  const [heart, setHeart] = React.useState(false); // 좋아요

  React.useEffect(() => {
    needLogin()
  }, [])

  const needLogin = async() => {
    if (!login) {
      await swal('로그인이 필요한 서비스입니다.')
      navigate('/login', { replace: true })
      return
    }
  }

  const setLike = async (setData, pk) => {

    const res = await publicArtist.setLike(pk)
    if (!res.resultCode) {
      return
    }
    setData(res.message === 'ADD')
  }

  React.useEffect(() => { getData(collectionId) }, [collectionId])

  const getData = async (pk) => {
    const res = await publicArtist.getDetail(pk)
    setArtist(res.artist)
    setArticle(res.article_list)
    setSchool(res.edu_info)
    setExhibition(res.exhibition_history)
    setWork(res.work_info)
    setSale(res.market_work)
  }


  // 유저 정보
  React.useEffect(() => {
    if (artist === undefined) {
      return
    }

    const infoItem = {
      image: `${userImage}/${artist.picture}`,
      status: artist.status,
      author: {
        image: `${userImage}/${artist.picture}`,
        name: checkUseName(lang, artist.use_NAME, `${artist.first_NAME}${artist.name}`, `${artist.first_ENG_NAME} ${artist.eng_NAME}`, artist.nickname, artist.eng_NICKNAME),
        email: artist.email,
      },
      infos: [
        {
          title: '국적',
          // content: artist.country === 'ko' ? '대한민국' : artist.country === '' ?,
          content: artist.country === 'ko' ? '대한민국' : artist.country ,
        },
        // {
        //   title: '출생연도',
        //   content: `${artist.birthday.indexOf('-') !== -1 ? artist.birthday.split('-')[0] : artist.birthday}년`,
        // },
        {
          title: '웹/SNS',
          content: artist.sns === 'null' ? '' : artist.sns,
          link: true
        },
        {
          title: '콜렉터 소개',
          content: artist.introduce === 'null' ? '' : artist.introduce
        },
      ],
      heart: artist.heart
    }

    setDetail(infoItem)

  }, [artist, lang])

  React.useEffect(() => {
    if (exhibition === undefined) {
      return
    }

    const myItem = []

    for (let i = 0; i < exhibition.length; i++) {
      const item = {
        period: `${exhibition[i].date} ~ ${exhibition[i].end}`,
        category: exhibition[i].type,
        title: `${exhibition[i].title}, ${exhibition[i].place}`,
      }
      myItem.push(item)
    }
    setHistory(myItem)

  }, [exhibition])


  React.useEffect(() => {
    if (school === undefined) {
      return
    }

    const myItem = []

    for (let i = 0; i < school.length; i++) {
      const item = {
        period: `${school[i].admission} ~ ${school[i].graduated}`,
        category: school[i].degree_TYPE,
        title: `${school[i].school}, ${school[i].country}`,
      }
      myItem.push(item)
    }
    setEdu(myItem)

  }, [school])

  var moreList = ['전시이력']
  var detailList = [`전체작품 (${work.length})`, `마켓플레이스(${sale.length})`]


  React.useEffect(() => {
    const result = []
    if (work === undefined) {
      setOther(result)
      return
    }
    work.map((item, i) => {
      result.push(
        <div key={i} className="page-slider__item">
          <div className="page-slider-item-row">
            <Link
              to={`/artdb/work/${item.pk}`}
            >
              <img
                alt=''
                className="page-item-image"
                src={`${workMainImage}/${item.image}`}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
            </Link>
            <div>
              {/* <h2 className="page-item-author">{item.title}</h2>
              <h3 className="page-item-email">{item.eng_TITLE}</h3> */}
              <h2 className="page-item-author">{getTitle(lang, item.title, item.eng_TITLE)}</h2>
              <h3 className="page-item-email">{artViewDate(item.start_CREATE, item.end_CREATE)}</h3>
              {/* <button className="page-item-button">팔로우</button> */}
            </div>
          </div>
        </div>
      )
    })

    setOther(result)
  }, [work])

  React.useEffect(() => {
    const result = []
    if (sale === undefined) {
      setOther(result)
      return
    }
    sale.map((item, i) => {
      result.push(
        <div key={i} className="page-slider__item">
          <div className="page-slider-item-row">
            <Link
              to={`/market/${item.pk}`}
            >
              <img
                alt=''
                className="page-item-image"
                src={`${workMainImage}/${item.image}`}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
            </Link>
            <div>
              {/* <h2 className="page-item-author">{item.title}</h2>
              <h3 className="page-item-email">{item.eng_TITLE}</h3> */}
              <h2 className="page-item-author">{getTitle(lang, item.title, item.eng_TITLE)}</h2>
              <h3 className="page-item-email">{artViewDate(item.start_CREATE, item.end_CREATE)}</h3>
              <span className='page-item-price'>{artViewPrice(item.price_UNIT, item.price)}</span>
              {/* <button className="page-item-button">팔로우</button> */}
            </div>
          </div>
        </div>
      )
    })

    setMarket(result)
  }, [sale])

  const findHttp = (temp) => {
    if(temp === '' || temp === null || temp === undefined){
      return "javascript:void(0)";
    }
    if(temp.indexOf('http') === -1){
      return `http://${temp}`
    }
    return temp
  }

const hyperlink = (item) => {
    const json = {
      text : item.replaceAll(' ', ''),
      link: findHttp(item.replaceAll(' ', ''))
    }
    return json
}

  React.useEffect(() => {
    const result = []
    if (article === undefined) {
      setNews(result)
      return
    }
    article.map((item, i) => {
      result.push(
        <li className="artist-d-item" key={i}>
          <a href={hyperlink(item.source).link} target={hyperlink(item.source).link === "javascript:void(0)" ? '_self' :'_black'}>
        <img src={`${articleMainImage}/${item.image}`} alt='' onError={handleImgError} onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }/>
        <h2>{item.title}</h2>
        <p>
          {item.reference}
        </p>
      </a>
      </li>
      )
    })

    setNews(result)
  }, [article])

  return (
    <section className="artist-detail">
      <Header active="0" colored="black" detail={true} />
      <WorkHeader kyc={datail === undefined ? '' : datail.status} title_k={datail === undefined ? '' : datail.author.name} follow={true} followState={heart} followFunc={() => setLike(setHeart, collectionId)}></WorkHeader>
      <ArtDetailInfo item={datail} />
      {/* <HistoryInfo
        title={'콜렉터 이력'}
        schoolItem={edu}
        historyItem={history}
        moreList={moreList}
      /> */}

      <section className="venue-detail-info">
        <SectionHeader title={'소식'} />


        <div className="container" >
        <div style={matches? {padding: '0 20rem'} :{padding: '0 320rem'}} > 
              <ul style={matches? {overflow: 'auto', padding: '20rem 0'} :{overflow: 'auto', padding: '60rem 0'}}>
              {news}
            </ul>
          </div>
        </div>
      </section>
      <div className="artist-detail-ps">
        <WorkHeader
          moreListChange={setMenu}
          moreMenu={menu}
          moreList={detailList}
          moreActive={1}
          title_k={'소장 작품'}
        ></WorkHeader>
        <div className="container artist-d-page-slide">
          {menu === 0 ?
            <PageSlide2
              items={other}
              divItem={true}
              rows={3}
              slidesPerRow={3}
              breakRows={4}
              breakSlidesPerRow={2}
            />
            :
            <PageSlide2
              items={market}
              divItem={true}
              rows={3}
              slidesPerRow={3}
              breakRows={4}
              breakSlidesPerRow={2}
            />
          }
        </div>
      </div>
      <Footer />
    </section>
  )
}
export default CollectionDetail