import client from '../../index'

/**
  QR 생성
   * @param {String} pin 간편 패스워드
   * @param {String} fcm 
*/
const createQr = async(pin, fcm = '') => {
    const postData = {
        SHORT_PASSWORD: pin,
        FCM_TOKEN: fcm
    };
    const res = await client.json.post('/user/QRCode', postData);
    return res.data;
}






/**
  대여/반납 1단계
   * @param {String} ENC_SERIAL 작품의 QR
   * @param {String} SHORT_PASSWORD 작품의 간편 패스워드
*/
const lend1 = async(ENC_SERIAL, SHORT_PASSWORD) => {
    const postData = {
        ENC_SERIAL: ENC_SERIAL,
        SHORT_PASSWORD: SHORT_PASSWORD
    };
    const res = await client.json.post('/user/product/ownership/rent', postData);
    return res.data;
}





/**
  대여/반납 2단계
   * @param {String} token 1단계에서 준 토큰
   * @param {String} ENC_QR 유저 QR
   * @param {String} SALE_ALLOW 0: 승인 불필요, 1: 승인 필요
   * @param {String} EXPIRED_DATE 빌리는 기간
*/
const lend2 = async(token, ENC_QR, SALE_ALLOW, EXPIRED_DATE) => {
    const postData = {
        ENC_QR: ENC_QR,
        SALE_ALLOW: SALE_ALLOW,
        EXPIRED_DATE: EXPIRED_DATE
    };
    const res = await client.json.post('/user/product/ownership/rentproc', postData, { headers: { 'x-access-token': token } });
    return res.data;
}





/**
  대여/반납 3단계
   * @param {String} token 2단계에서 준 토큰
   * @param {String} ENC_QR 유저 QR
   * @param {String} SHORT_PASSWORD 작품의 간편 패스워드
*/
const lend3 = async(token, ENC_QR, SHORT_PASSWORD) => {
    const postData = {
        ENC_QR: ENC_QR,
        SHORT_PASSWORD: SHORT_PASSWORD
    };
    const res = await client.json.post('/user/product/ownership/rentproccheck', postData, { headers: { 'x-access-token': token } });
    return res.data;
}




/**
  판매 1단계
   * @param {String} ENC_SERIAL 작품의 QR
   * @param {String} SHORT_PASSWORD 작품의 간편 패스워드
*/
const sale1 = async(ENC_SERIAL, SHORT_PASSWORD) => {
    const postData = {
        ENC_SERIAL: ENC_SERIAL,
        SHORT_PASSWORD: SHORT_PASSWORD
    };
    const res = await client.json.post('/user/product/ownership/sale', postData);
    return res.data;
}





/**
  판매 2단계
   * @param {String} token 1단계에서 준 토큰
   * @param {String} ENC_QR 유저 QR
   * @param {String} PRICE 판매 가격
*/
const sale2 = async(token, ENC_QR, PRICE) => {
    const postData = {
        ENC_QR: ENC_QR,
        PRICE: PRICE
    };
    const res = await client.json.post('/user/product/ownership/saleproc', postData, { headers: { 'x-access-token': token } });
    return res.data;
}





/**
  판매 3단계
   * @param {String} token 2단계에서 준 토큰
   * @param {String} ENC_QR 유저 QR
   * @param {String} SHORT_PASSWORD 작품의 간편 패스워드
*/
const sale3 = async(token, ENC_QR, SHORT_PASSWORD) => {
    const postData = {
        ENC_QR: ENC_QR,
        SHORT_PASSWORD: SHORT_PASSWORD,
    };
    const res = await client.json.post('/user/product/ownership/saleproccheck', postData, { headers: { 'x-access-token': token } });
    return res.data;
}





const scan = {
    createQr,
    lend1, lend2, lend3,
    sale1, sale2, sale3
}
export default scan
