import client from './index';

/**
  작품의 유형별 목록 불러오기
*/
const getWorkTypeList = async() => {
    const res = await client.json.get('/user/work-type');
    return res.data;
}

/**
  약관 가져오기
*/
const getTerms = async(type) => {
  const res = await client.json.get(`/user/root/agreements/detail`, {params: {type: type}})
  return res.data;
}

/**
  useb 팝업 가져오기
*/
const getUsebList = async() => {
  const res = await client.json.get('/user/root/popup/stated');
  return res.data;
}


/**
  배너 가져오기
*/
const getBanner = async() => {
  const res = await client.json.get('/user/root/banner', {params: {CODE: ''} });
  return res.data;
}


/**
  회사정보 가져오기
*/
const getCompany = async() => {
  const res = await client.json.get('/user/root/company');
  return res.data;
}

/**
 * 공지사항 조회
 * @param {number} count 요청 갯수
 * @param {number} page 요청 페이지
*/
const getNotice = async(count, page) => {
  const setData = {
    count : count,
    page: page
  }
    const res = await client.json.get('/user/root/notice', {params: setData});
    return res.data;
}

/**
 * 공지사항 상세정보 조회
 * @param {number} id
*/
const getNoticeDetail = async(id) => {
  
    const res = await client.json.get(`/user/root/notice/${id}`);
    return res.data;
}

/**
 * faq 조회
 * @param {String} category 카테고리 이름
*/
const getFAQ = async(category) => {
  const setData = {
    category: category
  }
    const res = await client.json.get('/user/root/faq', {params: setData});
    return res.data;
}

/**
 * 오늘의 작품, 작가
 * @param {number} count 요청 갯수
 * @param {number} type 1: 작가, 2 작품
*/
const getTodayList = async(count, type) => {
  const setData = {
    count : count,
    type: type
  }
    const res = await client.json.get('/user/today-special', {params: setData});
    return res.data;
}

/**
 * 새소식, 이슈플러스 조회
 * @param {number} type 1: 새소식, 2 이슈플러스
*/
const getList = async(type) => {
  const setData = {
    type: type
  }
    const res = await client.json.get('/user/root/news', {params: setData});
    return res.data;
}

/**
 * 새소식, 이슈플러스 상세 조회
 * @param {number} pk
*/
const getListDetail = async(pk) => {
  
    const res = await client.json.get(`/user/news/${pk}`);
    return res.data;
}

/**
 * 1:1 문의하기
 * @param {String} category 질문 카테고리
 * @param {String} title 질문 제목
 * @param {String} content 질문 내용
*/
const inquiry = async(category, title, content) => {
  const setData = {
    CATEGORY: category,
    TITLE: title,
    INQUIRY: content
  }
    const res = await client.json.post('/user/root/inquiry', setData);
    return res.data;
}

/**
 * 1:1 문의 내역
*/
const getInquiryList = async() => {
    const res = await client.json.get('/user/root/inquiry');
    return res.data;
}

/**
 * useB KYC 토큰 가져오기
*/
const getToken = async() => {
  const res = await client.json.get('/user/work/useb');
  const jsonData = JSON.parse(res.data.data);
  const token = jsonData.token
  return token;
}


/**
 * 내 포인트 가져오기
*/
const getPoint = async() => {
  const res = await client.json.get('/user/point');
    return res.data;
}



const setting = {
  getToken,
  getWorkTypeList, getTodayList, getList, getListDetail,
  getTerms, getBanner, getCompany, getNotice, getNoticeDetail, getFAQ,
  inquiry, getInquiryList, getPoint, getUsebList
}
export default setting
