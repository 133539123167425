import React, { useState } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import './HistoryInfo.css'
import ModalPortal from '../Modal/ModalPortal'
import ShowHistoryModal from '../Modal/ShowHistoryModal'

function HistoryInfo(props) {
  const [isShowMore1, setIsShowMore1] = useState(false) // 더보기 열고 닫는 스위치
  const [isShowMore2, setIsShowMore2] = useState(false) // 더보기 열고 닫는 스위치
  const [menu, setMenu] = useState(0)


  const [showMenuHistory, setShowMenuHistory] = useState([])
  const [showMenuSchoolItem, setShowMenuSchoolItem] = useState([])

  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState();

  React.useEffect(() => {
    if (props.historyItem === null || props.historyItem === undefined) {
      return
    }
    let item = []
    const myLength = props.historyItem.length;
    const checkLength = myLength > 5 ? 5 : myLength

    for (let i = 0; i < checkLength; i++) {
      item.push(props.historyItem[i])
    }
    setShowMenuHistory(item)
  }, [isShowMore1, props.historyItem])



  React.useEffect(() => {
    if (props.schoolItem === null || props.schoolItem === undefined) {
      return
    }
    let item = []
    const myLength = props.schoolItem.length;
    const checkLength = myLength > 5 ? 5 : myLength
    for (let i = 0; i < checkLength; i++) {
      item.push(props.schoolItem[i])
    }
    setShowMenuSchoolItem(item)
  }, [isShowMore2, props.schoolItem])

  const MyHistoryItem = () => {
    if (props.historyItem === null || props.historyItem === undefined) {
      return
    }
    if (isShowMore1) {
      return (
        props.historyItem.map((item, i) => {
          return <li key={i} style={ item?.modal ? { cursor: 'pointer' } : {}} 
          onClick={() => {
            if(item?.modal) {
              setModalData(item.data)
              setModalOpen(true)
            }
          }}>
            <span className="display-history_info_item-period">
              {item.period}
            </span>
            <span className="display-history_info_item-category">
              [{item.category}]
            </span>
            <span className="display-history_info_item-title">
              {item.title}
            </span>
          </li>
        })
      )
    }
    return (
      showMenuHistory.map((item, i) => {
        return <li key={i} style={ item?.modal ? { cursor: 'pointer' } : {}} 
        onClick={() => {
          if(item?.modal) {
            setModalData(item.data)
            setModalOpen(true)
          }
        }}>
          <span className="display-history_info_item-period">
            {item.period}
          </span>
          <span className="display-history_info_item-category">
            [{item.category}]
          </span>
          <span className="display-history_info_item-title">
            {item.title}
          </span>
        </li>
      })
    )
  }

  const MyHistoryItemButton = () => {
    if (props.historyItem === null || props.historyItem === undefined) {
      return
    }
    if (props.historyItem.length > 5) {
      return (
        <button
          className="display-history_info_more"
          onClick={() => setIsShowMore1(!isShowMore1)}
        >
          <p>
            <span>{!isShowMore1 ? '더보기' : '닫기'}</span>
            <span className={!isShowMore1 ? "more-arrow ir_pm" : 'colse-arrow ir_pm'}>더보기버튼</span>
          </p>
        </button>
      )
    }
  }


  const MyEduItem = () => {
    if (props.schoolItem === null || props.schoolItem === undefined) {
      return
    }
    if (isShowMore2) {
      return (
        props.schoolItem.map((item, i) => {
          return <li key={i}>
            <span className="display-history_info_item-period">
              {item.period}
            </span>
            <span className="display-history_info_item-category">
              [{item.category}]
            </span>
            <span className="display-history_info_item-title">
              {item.title}
            </span>
          </li>
        })
      )
    }
    return (
      showMenuSchoolItem.map((item, i) => {
        return <li key={i}>
          <span className="display-history_info_item-period">
            {item.period}
          </span>
          <span className="display-history_info_item-category">
            [{item.category}]
          </span>
          <span className="display-history_info_item-title">
            {item.title}
          </span>
        </li>
      })
    )
  }

  const MyEduItemButton = () => {
    if (props.schoolItem === null || props.schoolItem === undefined) {
      return
    }
    if (props.schoolItem.length > 5) {
      return (
        <button
          className="display-history_info_more"
          onClick={() => setIsShowMore2(!isShowMore2)}
        >
          <p>
            <span>{!isShowMore2 ? '더보기' : '닫기'}</span>
            <span className={!isShowMore2 ? "more-arrow ir_pm" : 'colse-arrow ir_pm'}>더보기버튼</span>
          </p>
        </button>
      )
    }
  }


  return (
    <section>
      {modalOpen &&
        <ModalPortal>
          <ShowHistoryModal setModalOpen={setModalOpen} data={modalData}/>
        </ModalPortal>
      }
      <SectionHeader
        title={props.title}
        moreList={props.moreList}
        setMenu={setMenu}
        active={menu}
      ></SectionHeader>
      <ul className="display-history_info_list">
        {menu === 0 ?
          <div className="container">
            <MyHistoryItem />
            <MyHistoryItemButton />
          </div> :
          <div className="container">
            <MyEduItem />
            <MyEduItemButton />
          </div>
        }
      </ul>
    </section>
  )
}
export default HistoryInfo