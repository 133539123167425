import React from 'react'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import Slider from 'react-slick'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import './TopArrowSlider.css'
import { Link } from 'react-router-dom'

function TopArrowSlider(props) {
  /* 배너 셋팅 */
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    initialSlide: 0,
  }

  const [pc, setPc] = React.useState([])
  const [mobile, setMobile] = React.useState([])

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  

  React.useEffect(() => {
    mobile_items(props.item)
    slider_items(props.item)
  },[props.item])

  const mobile_items = item => {
    if(item === null){
      return
    }
    let arr = []
    for (let i = 0; i < item.length; i++) {
      arr.push(
        props.divItem ? (
          item[i]
        ) : (
          <div className="top-arrow-ㅡitem" key={i} >
            <Link to={item[i].link} state={{ item: item[i] }}>
              <img
                className="top-arrow-image"
                src={item[i].info}
                alt=''
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              />
            </Link>

            <h4 className="top-arrow-author">{item[i].author}</h4>
            <span className="top-arrow-title">{item[i].date}</span>
            <span className="top-arrow-price">{item[i].price}</span>
          </div>
        )
      )
    }
    setMobile(arr)
  }


  const slider_items = imgList => {
    if(imgList === null){
      return
    }
    let arr = []

    for(let i = 0; i < imgList.length; i ++){
      arr.push(
        props.divItem ? (
          imgList[i]
        ) : (
          <div className="top-arrow-item" key={i} >
          <Link to={imgList[i].link}>
            <img
              className="top-arrow-image"
              src={imgList[i].info}
              alt=''
              onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }
            />
          </Link>
          <h4 className="top-arrow-author">{imgList[i].author}</h4>
          <span className="top-arrow-title">{imgList[i].date}</span>
          <span className="top-arrow-price">{imgList[i].price}</span>
        </div>
      )
      )
    }
    setPc(arr)
  }

  return (
    <div>
      <section className="top-arrow" style={matches ? {height:'100%', paddingBottom:'10rem'} : {}}>
        <SectionHeader title={props.title}></SectionHeader>
        <div className="top-arrow-container">
          <Slider {...settings} className="top-arrow-slider">
            { pc }
          </Slider>
        </div>
        <div className="top-arrow-mb-contianer">
          <ul className="ta-mb-content" >
            { mobile }
            </ul>
          {/* <button className="ta-mb-more">더보기</button> */}
        </div>
      </section>
    </div>
    
  )
}
export default TopArrowSlider
