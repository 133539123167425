import swal from "sweetalert";

export const addComma = (price) => {
  if(price === '') return ''
  const stringToNum = price;

    const findDot = stringToNum?.toString().indexOf('.');
    const priceLength = stringToNum?.toString().length;

    let num = stringToNum?.toString().substring(0,findDot)
    let decimal = stringToNum?.toString().substring(findDot, priceLength)
    if( findDot === -1){
      num = stringToNum.toString()
      decimal = ''
    }else{
      num = stringToNum?.toString().substring(0,findDot)
      decimal = stringToNum?.toString().substring(findDot, priceLength)
      let onlyNumberDecimal = decimal.substring(1, decimal.length);
      let checkDecimalDot = onlyNumberDecimal.indexOf('.');
      if(checkDecimalDot !== -1){
        swal('소수점에 .이 올수 없습니다.');
        // return;
        decimal = '.' + onlyNumberDecimal.replaceAll('.', '');
      }
    }

    const returnNum = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const returnString = returnNum + decimal;
    return returnString;
  }

  export const setComma = (price) => {
    return addComma(price) || ''
  }

  export const checkNumber = e => {
    
    if (/[^-\.0-9]/.test(e.key)) {
    // if (!/[0-9]/.test(e.key)) {
    // if (!/[0-9]*\.?[0-9]*/.test(e.key)) {
      e.preventDefault();
    }
  }

export const onChangePoints = (e) => {
    const { value } = e.target;

    const str = setComma(value);
    const returnStr = str.replaceAll(",", "");
    return returnStr
  };


  export const overPoint = (price) => {
    if(price === '') return;


    
    const first = price.indexOf('.');

    let num = price.toString().substring(0, first)
    let decimal = price.toString().substring(first, price.length)


    let splitFirstDot = decimal.substring(first, price.length)

    const second = splitFirstDot.indexOf('.');

    if(second !== -1){
      splitFirstDot = '.' + splitFirstDot.replaceAll('.', '')
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + splitFirstDot;
  }