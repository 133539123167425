import React from 'react'
import { useParams } from 'react-router-dom'
import './NewsIssue.css'

import { sanitize } from 'dompurify'
import useMobileDetect from 'use-mobile-detect-hook'
import WorkHeader from '../../../components/WorkHeader/WorkHeader'
import { setBidTime } from '../../../hook/utils/getDateStr'
import setting from '../../../services/setting'


function NewsDetail() {
  const detectMobile = useMobileDetect();

  const { id } = useParams();

  React.useEffect(() => { getData(id) }, [id])
  const [data, setData] = React.useState();


  const getData = async (pk) => {
    const res = await setting.getListDetail(pk)
    setData(res.data);
  }


  return (
    <div className='qarts-talk-detail'>
      <WorkHeader
        type={1}
        title_k={data === undefined ? "" : data.title}
        title_e={data === undefined ? "" : setBidTime(data.creation_TIME)}
        isMobile={false}
      />
      <section className="container detail-box">
        <ul>
          <li>
            <div
              className="qarts-talk-detail__comment ql-editor"
              dangerouslySetInnerHTML={{
                __html: sanitize(data === undefined ? "" :
                  detectMobile.isMobile() ?
                    data.content_M : data.content)
              }
              }
            />

          </li>
        </ul>
      </section>

      {/* {
        detectMobile.isMobile() ?

          <WorkHeader
            type={1}
            title_k={'다른 게시물'}
            isMobile={false}
          />
          :

          <BigTopArrow
            title_k={'다른 게시물'}
            items={result}
          />
      }
      {
        detectMobile.isMobile() ?
          <section className="art-md--content" style={{ height: '500rem', overflow: 'scroll' }}>
            <div className="container">
              <section className="art-md--content__list" style={{ paddingLeft: 0 }}>
                {mobileResult}
              </section>
            </div>
          </section>
          :
          <></>
      } */}


    </div>
  )
}

export default NewsDetail