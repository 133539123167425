import client from "../../index";

/**
 * 작가 검색 가져오기
  * @param {number} currentPage 현재 페이지
  * @param {number} row 요청 갯수
  * @param {Stirng} search 검색
*/
const getArtistList = async(currentPage, row, search) => {
  const setData = {
    page : currentPage,
    count : row,
    search: search
  }
    const res = await client.json.get('/user/artist', {params: setData});
    return res.data;
}

/**
 * 작가 디테일
 * @param {number} pk 해당 작가 user_id
*/
const getDetail = async(pk) => {

    const res = await client.json.get(`/user/artist/${pk}`);
    return res.data;
}


/**
 * 작가 하트
  * @param {number} pk 작가 pk
*/
const setLike = async(pk) => {
  const res = await client.json.put(`/user/artist/heart/${pk}`,);
  return res.data;
}



const publicArtist = {
  getArtistList, getDetail, setLike
}

export default publicArtist
