import React from 'react'
import { Link } from 'react-router-dom'
import work from '../../../../services/myArchive/work/work'
import ArtDetailOption from '../../../../components/ArtDetailOption/ArtDetailOption'
import { handleImgError, userImage, workMainImage } from '../../../../services/imageRoute'
import { artViewDate, artViewPrice } from '../../../../hook/utils/artdbView'
import { shallowEqual, useSelector } from 'react-redux'
import publicWork from '../../../../services/public/publicWork'
import publicNFT from '../../../../services/public/publicNFT'
import useMobileDetect from 'use-mobile-detect-hook';
import publicArtist from '../../../../services/public/artDB/publicArtist'
import { checkUseName } from '../../../../hook/utils/checkUseName'
import publicGallery from '../../../../services/public/artDB/publicGallery'


function ArtistLike() {

  const detectMobile = useMobileDetect();
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const [items, setItems] = React.useState([])
  const [myLike, setMyLike] = React.useState([])
  const options = ['작품', '작가', '콜렉션']
  const [menu, setMenu] = React.useState(options[0])
  const [rows, setRows] = React.useState(3)
  const [slidersPerRow, setSlidersPerRow] = React.useState(3)
  const [total, setTotal] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [reroll, setReroll] = React.useState(0)

  const changeMenu = (type) => {
    setMenu(type)
    setCurrentPage(0)
  }

  React.useEffect(() => {

  },[currentPage])

  React.useEffect(() => {
    if (detectMobile.isMobile()) {
      setRows(4)
      setSlidersPerRow(2)
    }
  }, [])

  const getMyLike = async (page, count, type) => {
    const res = await work.getMyLike(page, count, type)
    setTotal(res.total)
    const data = res.list;
    const result = []
    if (type === 0) {
      for (let i = 0; i < data.length; i++) {
        result.push({
          pk: data[i].work_PK,
          info: `${workMainImage}/${data[i].image}`,
          author: lang === 'ko' ? data[i].artist : data[i].eng_ARTIST,
          // author_e: lang !== 'ko' ? data[i].artist : data[i].eng_ARTIST,
          title: lang === 'ko' ? data[i].title : data[i].eng_TITLE,
          date: artViewDate(data[i].start_CREATE, data[i].end_CREATE),
          link: '/market',
          price: artViewPrice(data[i].price_UNIT, data[i].price),
          heart: true,
          type: 'work'
        })
      }
    } else if (type === 1) {
      for (let i = 0; i < data.length; i++) {
      result.push({
        pk: data[i].user_ID,
        info: `${userImage}/${data[i].picture}`,
        author: checkUseName('ko', data[i].use_NAME, `${data[i].first_NAME}${data[i].name}`, `${data[i].first_ENG_NAME} ${data[i].eng_NAME}`, data[i].nickname, data[i].eng_NICKNAME),
        // author_e: lang !== 'ko' ? data[i].artist : data[i].eng_ARTIST,
        title: checkUseName('en', data[i].use_NAME, `${data[i].first_NAME}${data[i].name}`, `${data[i].first_ENG_NAME} ${data[i].eng_NAME}`, data[i].nickname, data[i].eng_NICKNAME),
        date: data[i].birthday === '' || data[i].birthday === null ? '미상' : `${data[i].birthday.includes('-') ? data[i].birthday.split('-')[0] : data[i].birthday}년`,
        link: '/artdb/artist',
        heart: true,
        type: 'artist'
      })
    }
    } else {
      for (let i = 0; i < data.length; i++) {
        if(data[i].user_TYPE === 0){
          result.push({
            pk: data[i].user_ID,
            info: `${userImage}/${data[i].picture}`,
            author: checkUseName('ko', data[i].use_NAME, `${data[i].first_NAME}${data[i].name}`, `${data[i].first_ENG_NAME} ${data[i].eng_NAME}`, data[i].nickname, data[i].eng_NICKNAME),
            title: checkUseName('en', data[i].use_NAME, `${data[i].first_NAME}${data[i].name}`, `${data[i].first_ENG_NAME} ${data[i].eng_NAME}`, data[i].nickname, data[i].eng_NICKNAME),
            date: data[i].birthday === '' || data[i].birthday === null ? '미상' : `${data[i].birthday.includes('-') ? data[i].birthday.split('-')[0] : data[i].birthday}년`,
            link: '/artdb/collection',
            heart: true,
            type: 'collection'
          })
        }else{
          result.push({
            pk: data[i].user_ID,
            info: `${userImage}/${data[i].agencyTb.image}`,
            author: lang === 'ko' ? data[i].first_NAME : data[i].first_ENG_NAME,
            title: lang !== 'ko' ? data[i].first_NAME : data[i].first_ENG_NAME,
            date: data[i].agencyTb.since,
            link: '/artdb/venue',
            heart: true,
            type: 'venue'
          })
        }
      
    }
  }
    setItems(result)
  }


const getMyLikeNft = async (page, count) => {
  const res = await work.getMyLikeNft(page, count)
  setTotal(res.total)
  const data = res.list;
  const result = []
  for (let i = 0; i < data.length; i++) {
    result.push({
      pk: data[i].nft_PK,
      info: data[i].image,
      author: lang === 'ko' ? data[i].ko_ARTIST : data[i].en_ARTIST,
      // author_e: lang !== 'ko' ? data[i].artist : data[i].eng_ARTIST,
      title: lang === 'ko' ? data[i].ko_TITLE : data[i].en_TITLE,
      date: data[i].made_IN,
      link: '/market/nft',
      price: artViewPrice(1, data[i].price),
      heart: true,
      type: 'nft'
    })
  }
  setItems(result)
}

const getMyLikeArtist = async (page, count) => {
  const res = await work.getMyLikeArtist(page, count)
  setTotal(res.total)
  const data = res.list;
  const result = []
  for (let i = 0; i < data.length; i++) {
    result.push({
      pk: data[i].user_ID,
      info: `${userImage}/${data[i].picture}`,
      author: checkUseName('ko', data[i].use_NAME, data[i].name, data[i].eng_NAME, data[i].nickname, data[i].eng_NICKNAME),
      // author_e: lang !== 'ko' ? data[i].artist : data[i].eng_ARTIST,
      title: checkUseName('en', data[i].use_NAME, data[i].name, data[i].eng_NAME, data[i].nickname, data[i].eng_NICKNAME),
      date: data[i].made_IN,
      link: '/artdb/artist',
      heart: true,
      type: 'artist'
    })
  }
  setItems(result)
}


React.useEffect(() => {
  getMyLike(currentPage, rows * slidersPerRow, options.indexOf(menu))
  window.scrollTo(0, 0);
}, [currentPage, rows, slidersPerRow, menu, reroll])





React.useEffect(() => {
  const result = []
  if (items.length === 0) {
    setMyLike(result)
    return
  }

  for (let j = 0; j < items.length; j++) {
    result.push(
      <div className="slider-item art-d-slider__item-container" key={`${items[j].pk}..${items[j].info}`}>
        <Link
          to={`${items[j].link}/${items[j].pk}`}
        >
          <div className="slider__item">
            <img src={items[j].info} alt="item" onError={handleImgError} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
            <h3 style={{ paddingLeft: '0rem' }}>
              {items[j].author}
            </h3>
            <h2>{items[j].title}</h2>
            {/* <h4>{items[j].date}</h4> */}
          </div>
        </Link>
        <div className="market-item">
          {items[j].type === 'work'
            ? <span className="market-item--price">{items[j].price}</span>
            : <h4>{items[j].date}</h4>
          }
          <button href="#" className="artist-item--heart_active" onClick={() => removeLike(items[j].pk, items[j].type)}>
            <span className="ir_pm">좋아요</span>
          </button>
        </div>
      </div>
    )
  }
  setMyLike(result)
}, [items])


const removeLike = async (pk, type) => {

  let res;

  if (type === 'work') {
    res = await publicWork.setLike(pk)
    if (!res.resultCode) {
      return
    }
  } else if (type === 'nft') {
    res = await publicNFT.setLike(pk)
    if (!res.resultCode) {
      return
    }
  } else if (type === 'artist') {
    res = await publicArtist.setLike(pk)
    if (!res.resultCode) {
      return
    }
  }else if (type === 'venue') {
    res = await publicGallery.setLike(pk)
    if (!res.resultCode) {
      return
    }
  }
  setReroll(reroll + 1);
}

return (
  <div className="g-like-slider">
    <ArtDetailOption
      title_k={'관심 목록'}
      options={options}
      menu={menu}
      setMenu={changeMenu}
      rows={rows}
      slidersPerRow={slidersPerRow}
      total={total}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      items={myLike}
    />
  </div>
)
}

export default ArtistLike
