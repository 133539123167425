import client from "../index";

/**
 * 전체 NFT 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {Stirng} work_type 작품 유형 pk, 0 전체
 * @param {Stirng} date_type 0:전체, 1:고미술, 2:근현대
*/
const getList = async(currentPage, row, work_type, date_type) => {
  const setData = {
    page : currentPage,
    count : row,
    work_type: work_type,
    date_type: date_type
  }
    const res = await client.json.get('/user/work', {params: setData});
    return res.data;
}


/**
  NFT 상세보기
   * @param {number} pk
*/
const getDetail = async(pk) => {
  const res = await client.json.get(`/user/nft/${pk}`);
  return res.data;
}

/**
 * nft 하트
  * @param {number} pk 작품 pk
*/
const setLike = async(pk) => {
  const res = await client.json.patch(`/user/nft/heart/${pk}`,);
  return res.data;
}

const publicNFT = {
  getList, getDetail, setLike
}

export default publicNFT
