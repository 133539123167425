import React from 'react'
import { Link } from 'react-router-dom'
import WorkHeader from '../WorkHeader/WorkHeader'
import MarketHeader from '../../pages/Marketplace/Header/MarketHeader'
import './ArtTopic.css'
import { handleImgError } from '../../services/imageRoute'
import useMobileDetect from 'use-mobile-detect-hook'

function ArtDBArtTopic(props) {
  const detectMobile = useMobileDetect();

  return (
    <section className="art-topic">
      {props.market ? (
        <MarketHeader title_e={props.title_e} title_k={props.title_k}></MarketHeader>
      ) : (
        <WorkHeader title_e={props.title_e} title_k={props.title_k}></WorkHeader>
      )

      }
      <section className="container art-topic-content">
        {
          props.items.length >= 1 &&
          <Link
            to={`${props.items[0].link}/${props.items[0].id}`}
            className="art-topic-item artTopic_img"
          >
            <img
              alt=''
              src={props.items[0].info}
              className="art-topic--main"
              onError={handleImgError}
              onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }
            />
            <p className='artTopic__main_hover_text'>
              <div className='artTopic_hover_text__div'>
                <h4>{props.items[0].author}</h4>
                <h2>{props.items[0].title}</h2>
              </div>
            </p>
          </Link>
        }
        <div className="art-topic--sub">
          {
            props.items.length >= 2 &&
            <Link
              to={`${props.items[1].link}/${props.items[1].id}`}
              className="art-topic-item artTopic_img"
            >
              <img
                alt=''
                src={props.items[1].info}
                className="art-topic--main"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <p className='artTopic__sub_hover_text'>
              <div className='artTopic_hover_text__div'>
                <h4>{props.items[1].author}</h4>
                <h2>{props.items[1].title}</h2>
              </div>
            </p>
            </Link>
          }
          {
            props.items.length >= 3 &&
            <Link
              to={`${props.items[2].link}/${props.items[2].id}`}
              className="art-topic-item artTopic_img"
            >
              <img
                alt=''
                src={props.items[2].info}
                className="art-topic--main"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <p className='artTopic__sub_hover_text'>
              <div className='artTopic_hover_text__div'>
                <h4>{props.items[2].author}</h4>
                <h2>{props.items[2].title}</h2>
              </div>
            </p>
            </Link>
          }

        </div>
      </section>
    </section>
  )
}
export default ArtDBArtTopic
