import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../node_modules/slick-carousel/slick/slick.css'
import { artViewDate } from '../../hook/utils/artdbView'
import { checkUseName } from '../../hook/utils/checkUseName'
import { setComma } from '../../hook/utils/comma'
import { handleImgError, workMainImage } from '../../services/imageRoute'
import publicWork from '../../services/public/publicWork'
import * as settingAction from '../../store/reducers/settings/actions'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import WorkHeader from '../WorkHeader/WorkHeader'
import './ArtDetail.css'
import { ScrollToTop } from '../../hook/scrollToTop'

function ArtDetail(props) {
  const location = useLocation().state
  const dispatch = useDispatch()


  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  React.useEffect(() => {
    dispatch(settingAction.SET_WORK_TYPE_LIST())
  }, [])

  const [rows, setRow] = useState(props.rows || 4)
  const [slidersPerRow, setSlidersPerRow] = useState(props.slidersPerRow || 3)
  const [originRow, setOriginRow] = useState(rows)
  const [originSlidersPerRow, setOriginSlidersPerRow] = useState(slidersPerRow)
  const { rowWorkTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const pathname = useLocation().pathname

  const {
    type,
    title_e,
    title_k,
    options,
    breakRows,
    breakSlidersPerRow,
    moreMenu,
  } = props.items ? props : location

  const [menu, setMenu] = useState(moreMenu)
  const changeMenu = i => {
    setMenu(i)
    setCurrentPage(0)
  }

  const [workTypeList, setWorkTypeList] = React.useState([])
  const [workListByType, setWorkListByType] = React.useState([])
  const [total, setTotal] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)

  React.useEffect(() => {
    const item = []
    let total = 0
    for (let i = 0; i < rowWorkTypeList.length - 1; i++) {
      item.push(
        `${rowWorkTypeList[i][`${lang}`]}(${setComma(
          rowWorkTypeList[i][
          `${type === 0 ? 'count' : type === 1 ? 'old_count' : 'resent_count'
          }`
          ]
        )})`
      )
      total =
        total +
        rowWorkTypeList[i][
        `${type === 0 ? 'count' : type === 1 ? 'old_count' : 'resent_count'}`
        ]
    }
    item.unshift(`전체(${setComma(total)})`)
    setWorkTypeList(item)
  }, [lang, rowWorkTypeList])

  React.useEffect(() => {
    getWorkListByType(currentPage, menu)
    window.scrollTo(0, 0);
  }, [currentPage, menu])

  const getWorkListByType = async (page, workType) => {
    const res = await publicWork.getWorkListByType(
      page,
      rows * slidersPerRow,
      workType,
      type
    )
    setTotal(res.total)
    setWorkListByType(res.list)
  }

  const PAGE_PER_ITEM = rows * slidersPerRow
  const [TOTAL_PAGE, SET_TOTAL_PAGE] = useState(0)

  const [dotArr, setDotArr] = useState([])

  React.useEffect(() => {
    SET_TOTAL_PAGE(Math.ceil(total / PAGE_PER_ITEM))
  }, [total])

  const slider_items = imgList => {
    return imgList.map((item, i) => {
      return props.divItem ? (
        item
      ) : (
        <Link
          to={`/artdb/work/${item.pk}`}
          className="art-d-slider__item-container"
        >
          <div key={i} className="art-d-slider__item">
            <img
              className="art-d-item-image"
              alt=""
              src={`${workMainImage}/${item.image}`}
              onError={handleImgError}
              onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }
            ></img>
            {/* <h3>{item.user_TYPE <= 1 ? checkUseName(lang, item.use_NAME ,item.ko_NAME, item.eng_NAME, item.ko_NICK_NAME, item.eng_NICK_NAME) : lang === 'ko' ? item.ko_NAME : item.eng_NAME}</h3> */}
            <h3>{lang === 'ko' ? item.artist : item.eng_ARTIST}</h3>
            {/* <h3>{item.user_TYPE === 1 ? checkUseName(lang, item.use_NAME ,item.ko_NAME, item.eng_NAME, item.ko_NICK_NAME, item.eng_NICK_NAME) : lang === 'ko' ? item.artist : item.eng_ARTIST}</h3> */}


            <h2>{lang === 'ko' ? item.title : item.eng_TITLE}</h2>
            {item.sell ? (
              <div className="nft-item">
                <p>
                  <span className="nft-item-title">제작년도</span>
                  <span className="nft-item-created">{item.created}</span>
                </p>
                <p>
                  <span className="nft-item-title">판매</span>
                  <span className="nft-item-sell">{item.sell}</span>
                </p>
              </div>
            ) : (
              <h4>{artViewDate(item.start_CREATE, item.end_CREATE)}</h4>
            )}
          </div>
        </Link>
      )
    })
  }

  React.useEffect(() => {
    ShowPaginationNumbers(currentPage + 1)
  }, [currentPage, total, TOTAL_PAGE])

  function ShowPaginationNumbers(currentPage) {
    try {
      let startPage
      let endPage

      if (TOTAL_PAGE <= 5) {
        startPage = 1
        endPage = TOTAL_PAGE
      } else {
        if (currentPage <= 3) {
          startPage = 1
          endPage = 5
        } else if (currentPage + 1 >= TOTAL_PAGE) {
          startPage = TOTAL_PAGE - 4
          endPage = TOTAL_PAGE
        } else {
          startPage = currentPage - 2
          endPage = currentPage + 2
        }
      }

      let numArr = []

      if (startPage === endPage) {
        numArr.push(1)
      } else {
        for (var i = startPage; i <= endPage; i++) {
          numArr.push(i)
        }
      }

      setDotArr(numArr)
    } catch (err) {
      console.log(err)
    }
  }

  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const resizingHandler = () => {
    if (window.innerWidth > 480) {
      setRow(originRow)
      setSlidersPerRow(originSlidersPerRow)
    }
    if (window.innerWidth <= 480) {
      setRow(breakRows)
      setSlidersPerRow(breakSlidersPerRow)
    } else {
    }
  }
  // 우선 맨 처음 480이하면 모바일 처리

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setRow(breakRows)
      setSlidersPerRow(breakSlidersPerRow)
    }

    window.addEventListener('resize', resizingHandler)
    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('resize', resizingHandler)
    }
  }, [])

  return (
    <div className="art-d">
      {props.detail ? (
        <Header active="0" colored="black" detail={true} />
      ) : (
        <></>
      )}
      <header className="art-d-header">
        {workTypeList ? (
          <WorkHeader
            moreList={pathname === '/artdb/work/nft' || pathname === '/mypage/artist/like' ? null : workTypeList}
            moreActive={true}
            moreListChange={changeMenu}
            moreMenu={menu}
            title_e={title_e}
            title_k={title_k}
            color={false}
          ></WorkHeader>
        ) : (
          <></>
        )}
        <div
          className="container art-d-header-container"
          style={workTypeList ? { height: '144rem' } : {}}
        >
          <div
            className="art-d-header__info"
            style={workTypeList ? { visibility: 'hidden' } : {}}
          >
            {title_e ? <h4>{title_e}</h4> : <></>}
            <h2>{title_k}</h2>
          </div>
          {/* <div className="art-d-header__drop">
            <DropDown
              options={props.options ? props.options : options}
              //options={props.options}
            ></DropDown>
          </div> */}
        </div>
      </header>
      <section className="container art-m-slider">
        <div className="art-d-slider__list">
        <div className="art-d-slider__item-my-row">
            {slider_items(workListByType)}
        </div>
        <div style={workListByType.length === 0 ? {display: 'none'} : {}}>
          <button
            className="slick-first"
            onClick={() => {
              setCurrentPage(0)
            }}
          >
            <span className="ir_pm">처음으로</span>
          </button>

          <button
            className="slick-prev"
            onClick={() => {
              if (currentPage !== 0) {
                setCurrentPage(currentPage - 1)
              }
            }}
          >
            <span className="ir_pm">이전</span>
          </button>

          <div className="slick-dots">
            <ul className="slick-dots-container">
              {dotArr.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => setCurrentPage(item - 1)}
                    style={{ display: 'inline-block' }}
                    className={
                      dotArr[index] === currentPage + 1 ? 'slick-active' : ''
                    }
                  >
                    <div className="dots-number">{item}</div>
                  </li>
                )
              })}
            </ul>
          </div>

          <button
            className={'slick-arrow slick-next'}
            onClick={() => {
              if (currentPage !== TOTAL_PAGE - 1) {
                setCurrentPage(currentPage + 1)
              }
            }}
          >
            <span className="ir_pm">다음</span>
          </button>

          <button
            className="slick-last"
            onClick={() => {
              setCurrentPage(TOTAL_PAGE - 1)
            }}
          >
            <span className="ir_pm">끝으로</span>
          </button>
        </div>
        </div>
      </section>
      {props.detail ? <Footer /> : <></>}
    </div>
  )
}
export default ArtDetail
