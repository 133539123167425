import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import TopArrowSlider from '../../../components/TopArrowSlider/TopArrowSlider'
import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'
import './NFT.css'
import { shallowEqual, useSelector } from 'react-redux'
import market from '../../../services/public/market/market'
import { checkUseName } from '../../../hook/utils/checkUseName'
function NFT() {
  const { rowWorkTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { lang, marketOrderList } = useSelector(v => v.setting, shallowEqual)
  const [list, setList] = React.useState([])
  const [tableList, setTableList] = React.useState([])


  /* 배너 셋팅 */
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
  }

  React.useEffect(() => {
    getNFTList()
  },[])

  const getNFTList = async () => {
    const res = await market.getAuctionList(1, 0, 10, 1, 0)
    // console.log(res.nftList)
    setList(res.nftList)
  }

  React.useEffect(() => {
    if(list.length === 0){
      return
    }

    const result = []

    for (var i = 0; i < list.length; i++) {
      result.push(
        <Link
          to={`/market/nft/auction/${list[i].nft_PK}`}
          state={{active: 1}}
          // to={`/market/nft/${list[i].nft_PK}`}
          key={i}
        >
          <div key={i} className="art-d-slider__item">
            <img
              className="art-d-item-image"
              src={list[i].image}
              alt=''
              onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }
            ></img>
            <h4>{checkUseName(lang, list[i].origin_USE_NAME, list[i].origin_KO_NAME, list[i].origin_ENG_NAME, list[i].origin_KO_NICK_NAME, list[i].origin_ENG_NICK_NAME)}</h4>
            <h3>{list[i].name}</h3>
            <div className="nft-item">
              <p>
                <span className="nft-item-title">제작년도</span>
                <span className="nft-item-created">{list[i].made_IN}</span>
              </p>
            </div>
          </div>
        </Link>
      )
    }

    setTableList(result)
  },[list])


  const slider_items = imgList => {
    return imgList.map((r_item, i) => {
      return (
        <Link
          to={`${r_item.link}/${r_item.id}`}
          state={{
            item: r_item,
          }}
          key={i}
        >
          <div className="nft-mb__item">
          <img
              className="art-d-item-image"
              src={list[i].image}
              alt=''
              onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }
            ></img>
            <h1>{checkUseName(lang, list[i].origin_USE_NAME, list[i].origin_KO_NAME, list[i].origin_ENG_NAME, list[i].origin_KO_NICK_NAME, list[i].origin_ENG_NICK_NAME)}</h1>
            <h2>{list[i].name}</h2>
          </div>
        </Link>
      )
    })
  }
  return (
    <div className="main-nft">
      <TopArrowSlider title={'NFT'} divItem={true} item={tableList} />
      <div className="main-nft-mb">
        <SectionHeader title={'NFT'}></SectionHeader>
        <div className="main-nft-mb-container">
          <Slider {...settings} className="main-nft-slider">
            {slider_items(tableList)}
          </Slider>
        </div>
      </div>
    </div>
  )
}
export default NFT
