import React, { useState, useEffect } from 'react';
import './CommingModal.css'
import cookie from 'js-cookie'


function CommingModal(props) {

  const { modalOpen, setModalOpen } = props;
  const [checked, setChecked] = React.useState(false);


  React.useEffect(() => {
    checkCookie();
  }, []);

  const checkCookie = () => {
    if (cookie.get('comming') === undefined) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }


  const setCookie = (key, value, expireDays) => {
    cookie.set(key, value, {
      expires: expireDays,
      path: ''
    })
  }


  const closeModal = () => {
    setModalOpen(false);
    if (checked) {
      setCookie('comming', 'Y', 1)
    }
  }


  return (
    modalOpen &&
    <div className="comming-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <img
            className='comming-img'
            alt=""
            src={require("../../assets/comming.png")}
            onContextMenu={
              (e) => {
                  e.preventDefault();
              }
            }
          ></img>
          <div className='info-bottom'>
            <label className="info-option"  onChange={e => setChecked(e.target.checked)}>
              <input autoComplete='off' type="checkbox" checked={checked} />
              <span className="info-option__check" />
              <span>
                하루 동안 보지않기
              </span>
            </label>
            <label className="info-option">
              <span onClick={closeModal}>
                닫기
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommingModal