import produce from 'immer';
import * as ActionTypes from './actionTypes';

const initialState = {
    data: {
        delivery: {},
          order: {},
          token: {},
          orderNo: '',
          item: {},
          nft: {},
          bid_nft: {},
          sellType: '',
          point: ''
    }
};

const payReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PAY:
            return produce(state, draft => {
                draft.data = action.data;
            })
        case ActionTypes.RESET:
            return produce(state, draft => {
                draft.data =  {
                    delivery: {},
                      order: {},
                      token: {},
                      orderNo: '',
                      item: {},
                      nft: {},
                      bid_nft: {},
                      sellType: '',
                      point: ''
                }
            })
        default:
            return state
    }
}

export default payReducer;
