import React, { useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import RestoreAccount from './RestoreAccount'
import RestorePassword from './RestorePassword'

export const Restore = () => {
    const [select, setSelect] = useState(0)

    React.useEffect(() => {localStorage.removeItem('QART_ACCESS')},[])
    return (
        <div className="restore">
            <Header />
            <section className="container restore-container">
                <div className="auth-list">
                    <button className="auth-item" onClick={() => setSelect(0)}>
                        <h1 className={select === 0 ? 'auth-select' : ''}>아이디 찾기</h1>
                        <span className={select === 0 ? 'auth-select' : ''}></span>
                    </button>
                    <button className="auth-item" onClick={() => setSelect(1)}>
                        <h1 className={select === 1 ? 'auth-select' : ''}>비밀번호 재설정</h1>
                        <span className={select === 1 ? 'auth-select' : ''}></span>
                    </button>
                </div>
            </section>
            {select === 0 ? <RestoreAccount /> : <RestorePassword />}
            <Footer />
        </div>
    )
}
