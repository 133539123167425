import { artViewDate, artViewSize } from "../../hook/utils/artdbView";
import { workMainImage } from "../imageRoute";
import nft from "../myArchive/work/nft";
import { apiKey } from "./abi";
import { NFTStorage } from 'nft.storage'

const baseURL = process.env.REACT_APP_BASE_URL;

const NFTClient = new NFTStorage({ token: apiKey })

const getToday = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (1 + date.getMonth())).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return year + "-" + month + "-" + day;
}

export const convertURLtoFile = async (image) => {

    const res = await fetch(image, {headers: {
        'Access-Control-Allow-Origin' : '*',
    }, mode: 'no-cors' });

    const data = await res.blob();
    const ext = image.split(".").pop(); // 확장자
    const filename = image.split("/").pop(); // 파일 이름
    const metadata = { type: `image/${ext}` };
    const file = new File([data], filename, metadata)
    return file
};

export const createURI = async (work, contractAddress) => {
    let nftName;

    if (work.title === work.eng_TITLE) {
        nftName = work.title
    } else {
        nftName = `${work.eng_TITLE}(${work.title})`
    }

    // const metadata = await NFTClient.store({
    const res = await nft.createJson({
        name: nftName,
        description: work.detail_NOTE ?? '',
        // image: await convertURLtoFile('https://upload.wikimedia.org/wikipedia/commons/7/77/Delete_key1.jpg'),
        // image: await convertURLtoFile(`${workMainImage}/${work.image}`),
        image: `${workMainImage}/${work.image}`,
        attributes: [
            {
                trait_type: "contract",
                value: contractAddress
            },
            {
                trait_type: "pk",
                value: work.pk
            },
            {
                trait_type: "issue",
                value: getToday()
            },
            {
                trait_type: "origin_email",
                value: work.origin_ARTIST_EMAIL
            },
            {
                trait_type: "origin_type",
                value: work.user_TYPE
            },
            {
                trait_type: "ko_artist",
                value: work.artist
            },
            {
                trait_type: "en_artist",
                value: work.eng_ARTIST
            },
            {
                trait_type: "ko_title",
                value: work.title
            },
            {
                trait_type: "en_title",
                value: work.eng_TITLE
            },
            {
                trait_type: "made_in",
                value: artViewDate(work.start_CREATE, work.end_CREATE)
            },
            {
                trait_type: "size",
                value: artViewSize(work.size_X, work.size_Y, work.size_W)
            },
            {
                trait_type: "material",
                value: work.material
            },

        ]
    })

    console.log({
        name: nftName,
        description: work.detail_NOTE ?? '',
        // image: await convertURLtoFile('https://upload.wikimedia.org/wikipedia/commons/7/77/Delete_key1.jpg'),
        // image: await convertURLtoFile(`${workMainImage}/${work.image}`),
        image: `${workMainImage}/${work.image}`,
        attributes: [
            {
                trait_type: "contract",
                value: contractAddress
            },
            {
                trait_type: "pk",
                value: work.pk
            },
            {
                trait_type: "issue",
                value: getToday()
            },
            {
                trait_type: "origin_email",
                value: work.origin_ARTIST_EMAIL
            },
            {
                trait_type: "origin_type",
                value: work.user_TYPE
            },
            {
                trait_type: "ko_artist",
                value: work.artist
            },
            {
                trait_type: "en_artist",
                value: work.eng_ARTIST
            },
            {
                trait_type: "ko_title",
                value: work.title
            },
            {
                trait_type: "en_title",
                value: work.eng_TITLE
            },
            {
                trait_type: "made_in",
                value: artViewDate(work.start_CREATE, work.end_CREATE)
            },
            {
                trait_type: "size",
                value: artViewSize(work.size_X, work.size_Y, work.size_W)
            },
            {
                trait_type: "material",
                value: work.material
            },

        ]
    })
    console.log(res)

    // if(res.status === 400){
    //     throw 'already';
    // }

    const metadata = res.data;

    const uri = `${baseURL}${metadata}`
    
    
    // const uri = `https://${metadata.ipnft}.ipfs.nftstorage.link/metadata.json`;
    return uri
}