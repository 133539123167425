import React from 'react'
import './OkCancelModal.css'


function OkCancelModal({ setModalOpen, setFunc, type, title, content, showCancel }) {
  const [save, setSave] = React.useState(false);
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }
  const changeState = async() => {

    await setFunc(type)
    setModalOpen(false)
  }
  
  return (
    <div className="ok-cancel-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1 style={{ whiteSpace: 'pre-wrap', textAlign: 'center'}}>{title}</h1>
          <div className="ok-cancel-content">{content}</div>

          <div className="check-btn">
            {
                showCancel ? (
                    <button onClick={closeModal}>취소</button>        
                ) : <></>
            }
            {
                showCancel ? (
                    <div style={{width:12}}></div>
                ) : <></>
            }
            
            
            <button onClick={setFunc ? changeState : closeModal} style={showCancel?{}:{width:'348rem',background:'#000',color:'#fff'}}>확인</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OkCancelModal
