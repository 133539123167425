
import swal from 'sweetalert';
import client from "..";
const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * 엑셀로 내보내기
 * @param {String} type Artist(edu, work, article, etc) Gallerist(exhibitionDetail, workDetail)
 * @param {Stirng} name 작가명
 * @param {Stirng} solt 나열 방법 Gallerist만 사용  Work(작가별,소장처별,연도별) Exhibition(장소별,국가별,연도별)
 * @param {Stirng} birth 생일
*/
const ownExport = async(type, name, solt, birth) => {
    const setData = {
        param: name,
        param2: solt,
        param3: birth, 
        type: type
    }
      const res = await client.json.post('/user/ExcelMaker', setData);
      if (res.status / 100 === 2) {
        const name = res.headers["content-disposition"].split("filename=")[1];
        const excelView = window.open(`${baseURL}/upload/excel/${name.toString().replaceAll(`"`,``)}`);
        if (!excelView) {
          swal('팝업을 허용해주세요.');
        }
  }
}


/**
 * (작가)엑셀로 추가하기 
 * @param {String} excelFile
*/
const ownImportArtist = async(excelFile) => {
    const formData = new FormData();
    formData.append("file", excelFile);

    const res = await client.formData.post('/user/work/my-work/excel', formData);
    return res.data;
}
  

/**
 * (일반/기관)엑셀로 추가하기 
 * @param {String} excelFile
*/
const ownImportGallery = async(excelFile) => {
    const formData = new FormData();
    formData.append("file", excelFile);

    const res = await client.formData.post('/user/agency/excel', formData);
    return res.data;
}
  


  const excel = {
    ownExport,
    ownImportArtist, ownImportGallery
  }

  export default excel