import React from 'react'
import './img.css'


export const KYCImageArtist = (props) => {
  return (
    <div className={
      props.disable
        ? "disable_kyc__ic"
        : props.full
          ? 'artist_kyc__ic_full'
          : 'artist_kyc__ic'}>KYC</div>
  )
}
