import { sanitize } from 'dompurify'
import React from 'react'
import useMobileDetect from 'use-mobile-detect-hook'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import setting from '../../../services/setting'
import './Terms.css'

const Promotion = () => {
  const detectMobile = useMobileDetect();

  const init = {
    contentM: '',
    content: '',
    type: '',
    version: ''
  }

  const [info, setInfo] = React.useState(init)

  React.useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const res = await setting.getTerms('promotion')
    setInfo(res.agreements)
  }

  return (
    <div className="main">
      <Header colored="black" active={0} />
      <div className='terms-detail'>
        <section className="container detail-box">
          <ul>
            <li>
              <div
                className="terms-detail__comment ql-editor"
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    detectMobile.isMobile() ?
                      info.contentM : info.content)
                }
                }
              />

            </li>
          </ul>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Promotion
