import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useMobileDetect from 'use-mobile-detect-hook'
import { emailCheck } from '../../hook/utils/validation'
import auth from '../../services/auth/auth'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import swal from 'sweetalert'

export const UserTakeOver = () => {
    const { need } = useLocation().state;
    const isMobile = useMobileDetect().isMobile();
    const navigate = useNavigate();


    const [select, setSelect] = useState(0)
    const [email, setEmail] = useState('')
    const [emailLock, setEmailLock] = useState(false)
    const [code, setCode] = useState('')
    const [codeLock, setCodeLock] = useState(false)
    const [token, setToken] = useState("")


    const sendEmail = async () => {

        if(!emailCheck(email)){
          return
        }
        if(emailLock){
          return
        }
        setEmailLock(true)
        const res = await auth.artsynapseChangeEmail(email)
        if(res.resultCode) {
          swal("이메일로 보낸 인증코드를 입력해주세요.");
          setToken(res.art_token)
        }else{
          swal("이미 가입한 이메일 입니다.");
          setEmailLock(false)
        }
    }

    const checkCode = async () => {
        const res = await auth.artsynapseChangeEmailCode(token, code)
        if(!emailLock){
          swal("먼저 이메일을 입력해주세요.")
          return
        }
        if(codeLock){
          swal('인증이 완료되었습니다.')
          return
        }
        if(res.resultCode){
          localStorage.setItem('QART_ACCESS', res.token);
          localStorage.removeItem('QART_EMAIL')
          swal('이메일 변경이 완료되었습니다.')
          setCodeLock(true)
        }else{
          swal("인증번호를 확인해주세요.")
        }
    }

    const skipEmail = () => {
        if(need){
            navigate('/user/take-over/password')
        }else{
            navigate('/')
        }
        return;
    }

    const changedEmail = () => {

        if(!codeLock){
            swal('이메일 인증을 완료해주세요.');
            return;
        }
        if(need){
            navigate('/user/take-over/password')
        }else{
            navigate('/')
        }
        // window.location.reload();
        return;
    }


    return (

        <div className="sign-auth">
            <Header />

            <section className="container sign-auth-container">
                <div className="auth-list">
                    <button className="auth-item" onClick={() => setSelect(0)}>
                        <h1 className={select === 0 ? 'auth-select' : ''}>이메일 인증</h1>
                        <span className={select === 0 ? 'auth-select' : ''}></span>
                    </button>
                </div>
                <h3 style={isMobile ? {padding: '10rem 0'} :{ padding: '30rem 0' }}>변경할 이메일을 입력해주세요.</h3>
                <div className="auth-content">
                    {select === 0 ? (
                        <>
                            <h2 className="email-title">이메일</h2>
                            <div className="email-input-container">
                                <input
                                    autoComplete='off'
                                    type="text"
                                    className="email-input-num"
                                    value={email}
                                    readOnly={emailLock}
                                    onChange={e => setEmail(e.target.value)}
                                    required></input>
                                <button onClick={sendEmail}>인증하기</button>
                            </div>
                            <h2 className="email-title">인증번호</h2>
                            <div className="email-input-container">
                                <input
                                    autoComplete='off'
                                    type="text"
                                    className="email-input-num"
                                    value={code}
                                    readOnly={codeLock || !emailLock}
                                    onChange={e => setCode(e.target.value.toUpperCase())}
                                    required></input>
                                <button onClick={checkCode}>확인하기</button>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="auth-next" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {!codeLock && <button onClick={skipEmail} className={false ? "auth-next-button" : ''}>다음에 변경</button>}
                    <button onClick={changedEmail} className={true ? "auth-next-button" : ''}>완료</button>
                </div>
            </section>
            <Footer />
        </div>
    )
}
