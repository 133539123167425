import React from 'react';
import KakaoLogin from "react-kakao-login";
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

import { useDispatch } from "react-redux";
import auth from '../../../services/auth/auth';
import * as authAction from '../../../store/reducers/auth/actions';

const LoginWithKakao =()=>{

    const kakaoRef = React.useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const kakaoOnSuccess = async (data)=>{
      	// console.log(data)
        // const idToken = data.response.id_token  // 인가코드
        const hasEmail = data.profile.kakao_account.has_email
        if(!hasEmail){
            swal('이메일이 등록된 계정만 사용가능합니다.')
            return
        }
        const email = data.profile.kakao_account.email
        await checkSignIn(email)
    }

    const checkSignIn = async (email) => {
        const res = await auth.oauthLogin(email, 'KAKAO');
        console.log(res)
        if(res.available === 2) {
            swal('탈퇴한 계정입니다.')
            return;
        }
        if (res.message === 'already'){
            swal('이미 가입된 이메일입니다.')
            return
        }
        if (res.message === 'empty') {
            // 회원가입 필요
            navigate('/signUp', {
                state: {
                    email: email,
                    token: res.Email_token,
                    type: 'KAKAO'
                }
            })
            return
        }
        // 로그인 완료
        dispatch(authAction.LOGIN());
        dispatch(authAction.SET_TYPE(res.type));
        localStorage.setItem('QART_ACCESS', res.value);
        getInfo(res.type)
        navigate('/');
    }

    const getInfo = async (type) => {
        let res;
        if (type === 2) {
            res = await auth.getAgencyInfo()
            dispatch(authAction.SET_GALLERY_INFO(res))
        } else {
            res = await auth.getArtistInfo()
            dispatch(authAction.SET_ARTIST_INFO(res.data))
        }
    }

    const kakaoOnFailure = (error) => {
        swal('잠시후에 시도해주세요.')
        console.log(error)
    };

    return(
        <>
          <KakaoLogin
              ref={kakaoRef}
              token={process.env.REACT_APP_KAKAO_CLIENT_ID}
              onSuccess={kakaoOnSuccess}
              onFail={kakaoOnFailure}
              render={(renderProps) => (
                <div className="login-link__icon_kakao" onClick={renderProps.onClick}>
                <span className="ir_pm">카카오</span>
            </div>
              )}
          />
          
        </>
    )
}

export default LoginWithKakao
