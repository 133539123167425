
import client from '../index'

/**
 * 내 아티클 전체 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {Stirng} search 검색
*/
const getMyList = async(currentPage, row, search) => {
  const setData = {
    page : currentPage,
    count : row,
    search: search
  }
    const res = await client.json.get('/user/work/my-article', {params: setData});
    return res.data;
}

/**
  아티클 삭제
   * @param {Array} pkList pk
*/
  const deleteList = async(pkList) => {
    const setData = {
      pk : pkList
    }
  const res = await client.json.delete(`/user/work/my-article/`, {params: setData});
  return res.data;
}


/**
  (작가)아티클 추가
   * @param {File} main_image 메인이미지
   * @param {FileArray} sub_image 서브이미지 최대 10개
   * @param {String} title 제목
   * @param {String} date 발행일
   * @param {String} country 국가
   * @param {String} source 출처
   * @param {String} producer 발행 회사
   * @param {String} publisher 발행자
   * @param {String} reference 상세 설명
*/
const addByArtist = async(main_image, sub_image, title, date, country, source, producer, publisher, reference) => {
    
    const formData = new FormData();
    formData.append("MAIN_IMAGE", main_image); 
    formData.append("SUB_IMAGE", sub_image); 
    formData.append("TITLE", title); 
    formData.append("DATE", date); 
    formData.append("COUNTRY", country); 
    formData.append("SOURCE", source); 
    formData.append("PRODUCER", producer); 
    formData.append("PUBLISHER", publisher); 
    formData.append("REFERENCE", reference);
    
    const res = await client.formData.post(`/user/work/my-article`, formData);
    return res.data;
}


const exhibition = {
    getMyList,
    addByArtist,
}
export default exhibition
