
import client from '../../index'

/**
 * nft 서버 저장
 * @param {String} url minting시 사용한 url
 * @param {String} owner 소유자 지갑주소
 * @param {String} contract minting시 사용한 contract
 * @param {String} tokenId nft tokenId
 * @param {String} selltype 1:판매 2:경매
 * @param {String} price 가격
 * @param {String} startTime 경매시 시작시간
 * @param {String} endTime 경매시 마감시간
*/
const setNFT = async (url, owner, contract, tokenId, selltype, price, startTime, endTime) => {
  let setData;
  if (selltype === '1') {
    setData = {
      URL: url,
      OWNER: owner,
      CONTRACT: contract,
      TOKEN_ID: tokenId,
      SELL_TYPE: selltype,
      PRICE: price
    }
  } else {
    setData = {
      URL: url,
      OWNER: owner,
      CONTRACT: contract,
      TOKEN_ID: tokenId,
      SELL_TYPE: selltype,
      PRICE: price,
      START_TIME: startTime,
      END_TIME: endTime
    }
  }
  const res = await client.json.post('/user/nft', setData);
  return res.data;
}

/**
* nft 경매/판매 취소
* @param {number} pk 
*/
const cancelNFT = async (pk) => {
  const res = await client.json.delete(`/user/nft/my-nft/${pk}`);
  return res.data;
}


/**
  nft json 읽기
   * @param {String} json 이름
*/

const getJson = async (json) => {
  const setData = {
    fileName: json
  }
  const res = await client.json.post('/nft/read/json', setData);
  return res.data;
}

/**
  nft 상세보기
   * @param {number} pk
*/

const getDetail = async (pk) => {
  const res = await client.json.get(`/user/work/my-work/${pk}`);
  return res.data;
}

/**
  nft 이미지 불러오기
   * @param {String} image 이미지 이름
*/

const getImage = async (image) => {
  const setData = {
    imagename: image
  }
  const res = await client.json.get(`/user/image`, { params: setData });
  return res.data;
}

/**
  nft json 만들기
   * @param {Object} jsonData
*/

const createJson = async (jsonData) => {
  const res = await client.json.post('/nft/create/json', jsonData);
  return res.data;
}


/**
  내가 등록한 nft 리스트
*/

const getRegisterList = async () => {
  const res = await client.json.get('/user/nft/my-nft');
  return res.data;
}


/**
  nft 경매 입찰
  * @param {number} pk
  * @param {number} price
*/

const bid = async (pk, price) => {
  const setData = {
    PRICE : price
  }

  const res = await client.json.post(`/user/nft/bid/${pk}`, setData);
  return res.data;
}

/**
  nft 최고 입찰 리스트 + 구매 리스트
  * @param {number} page
  * @param {number} count
  * @param {number} isNft 0 실물 1 nft
*/

const myTopBid = async (page, count, isNft) => {

  const setData = {
    page: page,
    count: count,
    isNft: isNft
  }

  // const res = await client.json.get('/user/nft/my-auction', {params: setData});
  const res = await client.json.get('/user/work/market', {params: setData});
  return res.data;
}

const nft = {
  getImage, setNFT, cancelNFT, getJson,
  getRegisterList, getDetail, createJson,
  bid, myTopBid
}

export default nft
