import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import swal from 'sweetalert';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from "react-redux";
import auth from '../../../services/auth/auth';
import * as authAction from '../../../store/reducers/auth/actions';

const LoginWithGoogle = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const googleOnSuccess = async (data) => {
        const verified = data.email_verified
        const email = data.email

        if (!verified) {
            swal('사용불가능한 계정입니다.');
            return
        }
        return checkSignIn(email)
    }

    const checkSignIn = async (email) => {
        const res = await auth.oauthLogin(email, 'GOOGLE');
        if(res.available === 2){
            swal('탈퇴한 계정입니다.')
            return;
        }

        if (res.message === 'already'){
            swal('이미 가입된 이메일입니다.')
            return
        }
        if (res.message === 'empty') {
            // 회원가입 필요
            navigate('/signUp', {
                state: {
                    email: email,
                    token: res.Email_token,
                    type: 'GOOGLE'
                }
            })
            return
        }
        // 로그인 완료
        dispatch(authAction.LOGIN());
        dispatch(authAction.SET_TYPE(res.type));
        localStorage.setItem('QART_ACCESS', res.value);
        getInfo(res.type)
        navigate('/');
    }

    const getInfo = async (type) => {
        let res;
        if (type === 2) {
            res = await auth.getAgencyInfo()
            dispatch(authAction.SET_GALLERY_INFO(res))
        } else {
            res = await auth.getArtistInfo()
            dispatch(authAction.SET_ARTIST_INFO(res.data))
        }
    }

    const googleOnFailure = (error) => {
        swal('잠시후에 시도해주세요.')
        console.log(error)
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => res.data);

            googleOnSuccess(userInfo)
        },
        onError: err => googleOnFailure(err),
        flow: 'implicit',
    });

    return (
        <>
            <div className="login-link__icon_google" onClick={handleGoogleLogin}>
                <span className="ir_pm">구글</span>
            </div>
        </>
    )
}

export default LoginWithGoogle
