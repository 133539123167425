import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import useMobileDetect from 'use-mobile-detect-hook';
import './QartsTalkList.css'

import defaultImage from '../../../assets/default.png'

import WorkHeader from '../../../components/WorkHeader/WorkHeader'

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import marketPick from '../../../services/public/market/pick';
import { qTalkImage } from '../../../services/imageRoute';
import { setBidTime } from '../../../hook/utils/getDateStr';

function QartsTalkList(){

  const detectMobile = useMobileDetect();

  const { rowWorkTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)

  const [result, setResult] = React.useState([])

  const PAGE_PER_ITEM = 9
  const [TOTAL_PAGE, SET_TOTAL_PAGE] = useState(0)
  const [dotArr, setDotArr] = useState([])

  const[currentPage, setCurrentPage] = useState(0)
  const[total, setTotal] = useState(0)

  const [qTalk, setQTalk] = React.useState([]);

  React.useEffect(() => {
    SET_TOTAL_PAGE(Math.ceil(total / PAGE_PER_ITEM))
  }, [total])


  React.useEffect(() => {
    ShowPaginationNumbers(currentPage + 1)
  }, [currentPage, total, TOTAL_PAGE])

  React.useEffect(() => {
    getQTalk(currentPage,9);
  },[currentPage])

  const getQTalk = async (page, count) => {
    // const count = detectMobile.isMobile() ? 4 : 5
    const res = await marketPick.getQTalk(page, count);
    const data = res.data.content;
    const total = res.data.totalElements;
    setQTalk(data);
    setTotal(total);
  }

  function ShowPaginationNumbers(currentPage) {
    try {
      let startPage
      let endPage

      if (TOTAL_PAGE <= 5) {
        startPage = 1
        endPage = TOTAL_PAGE
      } else {
        if (currentPage <= 3) {
          startPage = 1
          endPage = 5
        } else if (currentPage + 1 >= TOTAL_PAGE) {
          startPage = TOTAL_PAGE - 4
          endPage = TOTAL_PAGE
        } else {
          startPage = currentPage - 2
          endPage = currentPage + 2
        }
      }

      let numArr = []

      if (startPage === endPage) {
        numArr.push(1)
      } else {
        for (var i = startPage; i <= endPage; i++) {
          numArr.push(i)
        }
      }

      setDotArr(numArr)
    } catch (err) {
      console.log(err)
    }
  }

    React.useEffect(() => {
      if(qTalk.length === 0) return;
        const myItem = []
        for (let i = 0; i < qTalk.length; i++) {
            myItem.push(
            <Link
                key={i}
                to={`/market/qartstalk/${qTalk[i].pk}`}
                className="art-md__list-item"
                style={detectMobile.isMobile() ? {width:'150rem',marginLeft: '0rem',marginRight:'0rem'} : {}}
            >
                <img src={`${qTalkImage}/${qTalk[i].thumbNail}`} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }/>
                <h2 style={detectMobile.isMobile() ? {marginTop:'6rem'} : {marginTop:'24rem'}}>{qTalk[i].title}</h2>
                <h3>{setBidTime(qTalk[i].createTime)}</h3>
            </Link>
            )
        }
        setResult(myItem)
    }, [qTalk])


    return(
      !detectMobile.isMobile() ?

      <div className="qarts-talk-list">
        <WorkHeader
          type={1}
          title_e={'큐톡!'}
          title_k={`Qart's Talk`}
        ></WorkHeader>
         <div style={{paddingTop:'40rem'}}></div> 
         <div className="art-d">
          <section className="container art-d-slider">
            <div className="art-d-slider__list">
              <button
                className="slick-first"
                onClick={() => {
                  setCurrentPage(0)
                }}
              >
                <span className="ir_pm">처음으로</span>
              </button>

              <button
                className="slick-prev"
                onClick={() => {
                  if (currentPage !== 0) {
                    setCurrentPage(currentPage - 1)
                  }
                }}
              >
                <span className="ir_pm">이전</span>
              </button>

              <div className="slick-dots">
                <ul className="slick-dots-container">
                  {dotArr.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => setCurrentPage(item - 1)}
                        style={{ display: 'inline-block' }}
                        className={
                          dotArr[index] === currentPage + 1 ? 'slick-active' : ''
                        }
                      >
                        <div className="dots-number">{item}</div>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <button
                className={'slick-arrow slick-next'}
                onClick={() => {
                  if (currentPage !== TOTAL_PAGE - 1) {
                    setCurrentPage(currentPage + 1)
                  }
                }}
              >
                <span className="ir_pm">다음</span>
              </button>

              <button
                className="slick-last"
                onClick={() => {
                  setCurrentPage(TOTAL_PAGE - 1)
                }}
              >
                <span className="ir_pm">끝으로</span>
              </button>
              <div className="art-d-slider__item-my-row">
                {result}
              </div>
            </div>
          </section>
        </div>
      </div>
      : 
      <div className='art-md'>
        <WorkHeader
          type={1}
          title_e={'큐톡!'}
          title_k={`Qart's Talk`}
        ></WorkHeader>
        <section className="art-md--content" style={{height:'500rem',overflow:'scroll'}}>
          <div className="container">
            <section className="art-md--content__list">
              {result}
            </section>
          </div>
        </section>
      </div>
    )
}

export default QartsTalkList