import React from 'react'
import './SignModal.css'
import SignaturePad from 'react-signature-canvas'

import { Buffer } from 'buffer'


function SignModal({ setModalOpen, setFunc }) {
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }
  const changeState = () => {
    const dataURL = canvas.current.toDataURL('image/png');
    const decodedURL = dataURL.replace(/^data:image\/\w+;base64,/, '');
    const buf = Buffer.from(decodedURL, 'base64');
    const blob = new Blob([buf], { type: 'image/png' });
    const file = new File([blob], `sign.png`, { type: 'image/png' });

    setFunc(file)
    setModalOpen(false)
  }
  const canvas = React.useRef()


  const importSign = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]
    setFunc(file)
    setModalOpen(false)
  }

  return (
    <div className="sign-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1>인증 등록 및 서명 하기</h1>
          <div className="sign-content" style={{
            border: "1px solid rgb(0, 0, 0)",
            margin: "10rem",
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            height: "fit-content",
            padding: '0'
          }}>
          <SignaturePad penColor='black' maxWidth='10'
            ref={canvas}
            clearOnResize={false}
            className= 'sigPad'
            canvasProps={{ className: 'sigPad'}} />
          </div>
         

          <div className="sign-btn" style={{marginTop : '10px', width: '100%'}}>
            <button onClick={closeModal}>취소</button>
            {/* <form method="post" encType="multipart/form-data"> */}
                <div className="sign-import">
                  <label className='sign-import' htmlFor="sign">인장 등록</label>
                </div>
                <input
                  type="file"
                  id="sign"
                  name="sign"
                  accept="image/*"
                  onChange={importSign}
                  style={{ display: 'none' }}
                />
              {/* </form> */}
            <button onClick={changeState}>확인</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignModal
