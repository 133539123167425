import React, { useState } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import './MarketHistoryInfo.css'
import { handleImgError } from '../../services/imageRoute'
import ModalPortal from '../Modal/ModalPortal'
import ShowHistoryModal from '../Modal/ShowHistoryModal'

function MarketHistoryInfo(props) {
  const [isShowMore1, setIsShowMore1] = useState(false) // 더보기 열고 닫는 스위치
  const [isShowMore2, setIsShowMore2] = useState(false) // 더보기 열고 닫는 스위치
  const [menu, setMenu] = useState(0)

  const [showMenuHistory, setShowMenuHistory] = useState([])
  const [showMenuSale, setShowMenuSale] = useState([])

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);


  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState();


  React.useEffect(() => {
    setMenu(0)
  },[props.id])
  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const InfoItem = items => {
    return Object.values(items).map((info, i) => {
      return (
        <li className="art-detail__item" key={i}>
          <span className="art-detail__name">{info.title}</span>
          <span className="art-detail__content">{info.content}</span>
        </li>
      )
    })
  }

  React.useEffect(() => {
    if (props.historyItem === null || props.historyItem === undefined) {
      return
    }
    let item = []
    const myLength = props.historyItem.length;
    const checkLength = myLength > 5 ? 5 : myLength

    for (let i = 0; i < checkLength; i++) {
      item.push(props.historyItem[i])
    }
    setShowMenuHistory(item)
  }, [isShowMore1, props.historyItem])



  React.useEffect(() => {
    if (props.saleItem === null || props.saleItem === undefined) {
      return
    }
    let item = []
    const myLength = props.saleItem.length;
    const checkLength = myLength > 5 ? 5 : myLength
    for (let i = 0; i < checkLength; i++) {
      item.push(props.saleItem[i])
    }
    setShowMenuSale(item)
  }, [isShowMore2, props.saleItem])


  const MyArtistItem = () => {
    if (props.artistItem === null || props.artistItem === undefined) {
      return
    }

    return (
      <section className="art-detail-section" style={matches ? { padding: '0rem 30rem', flexDirection: 'column' } : {}}>

        {!matches && <img
          className="art-detail__image"
          src={props.artistItem.image}
          onError={handleImgError}
          alt=''
          onContextMenu={
            (e) => {
              e.preventDefault();
            }
          }
        ></img>}
        <ul className="art-detail__list">
          {
            matches
              ?
              <>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img
                    className="art-detail__image"
                    src={props.artistItem.image}
                    onError={handleImgError}
                    alt=''
                    onContextMenu={
                      (e) => {
                        e.preventDefault();
                      }
                    }
                  ></img>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', width: '100%' }}>
                    <li className="art-detail__item">{props.artistItem.artist}</li>
                    <li className="art-detail__content">{props.artistItem.text[0].content}</li>
                  </div>
                </div>
                <span className="detail-info__comment" style={{ paddingTop: '10rem', width: '100%' }}>{props.artistItem.text[1].content}</span>
              </>
              :
              <div>

                <li className="art-detail__item">{props.artistItem.artist}</li>
                {InfoItem(props.artistItem.text)}
              </div>
          }
        </ul>


      </section>
    )
  }


  const MyHistoryItem = () => {
    if (props.historyItem === null || props.historyItem === undefined) {
      return
    }
    if (isShowMore1) {
      return (
        props.historyItem.map((item, i) => {
          return <li key={i} style={ item?.modal ? { cursor: 'pointer' } : {}} 
          onClick={() => {
            if(item?.modal) {
              setModalData(item.data)
              setModalOpen(true)
            }
          }}>
            <span className="display-history__item-period">
              {item.period}
            </span>
            <span className="display-history__item-category">
              [{item.category}]
            </span>
            <span className="display-history__item-title">
              {item.title}
            </span>
          </li>
        })
      )
    }
    return (
      showMenuHistory.map((item, i) => {
        return <li key={i} style={ item?.modal ? { cursor: 'pointer' } : {}} 
        onClick={() => {
          if(item?.modal) {
            setModalData(item.data)
            setModalOpen(true)
          }
        }}>
          <span className="display-history__item-period">
            {item.period}
          </span>
          <span className="display-history__item-category">
            [{item.category}]
          </span>
          <span className="display-history__item-title">
            {item.title}
          </span>
        </li>
      })
    )
  }

  const MyHistoryItemButton = () => {
    if (props.historyItem === null || props.historyItem === undefined) {
      return
    }
    if (props.historyItem.length > 5) {
      return (
        <button
          className="display-history__more"
          onClick={() => setIsShowMore1(!isShowMore1)}
        >
          <p>
            <span>{!isShowMore1 ? '더보기' : '닫기'}</span>
            <span className={!isShowMore1 ? "more-arrow ir_pm" : 'colse-arrow ir_pm'}>더보기버튼</span>
          </p>
        </button>
      )
    }
  }


  const MySaleItem = () => {
    if (props.saleItem === null || props.saleItem === undefined) {
      return
    }
    if (isShowMore2) {
      return (
        props.saleItem.map((item, i) => {
          return <li key={i}>
            <span className="display-history__item-period">
              {item.period}
            </span>
            <span className="display-history__item-category">
              [{item.category}]
            </span>
            <span className="display-history__item-title">
              {item.title}
            </span>
          </li>
        })
      )
    }
    return (
      showMenuSale.map((item, i) => {
        return <li key={i}>
          <span className="display-history__item-period">
            {item.period}
          </span>
          <span className="display-history__item-category">
            [{item.category}]
          </span>
          <span className="display-history__item-title">
            {item.title}
          </span>
        </li>
      })
    )
  }

  const MyEduItemButton = () => {
    if (props.saleItem === null || props.saleItem === undefined) {
      return
    }
    if (props.saleItem.length > 5) {
      return (
        <button
          className="display-history__more"
          onClick={() => setIsShowMore2(!isShowMore2)}
        >
          <p>
            <span>{!isShowMore2 ? '더보기' : '닫기'}</span>
            <span className={!isShowMore2 ? "more-arrow ir_pm" : 'colse-arrow ir_pm'}>더보기버튼</span>
          </p>
        </button>
      )
    }
  }


  return (
    <section>
      {modalOpen &&
        <ModalPortal>
          <ShowHistoryModal setModalOpen={setModalOpen} data={modalData}/>
        </ModalPortal>
      }
      <SectionHeader
        title={props.title}
        moreList={props.moreList}
        setMenu={setMenu}
        active={menu}
      ></SectionHeader>
      <ul className="display-history__list">



        {menu === 0 ?
          <div className="container">
            <MyArtistItem />
          </div> :
          menu === 1 ?
            <div className="container">
              <MyHistoryItem />
              <MyHistoryItemButton />
            </div>
            :
            <div className="container">
              <MySaleItem />
              <MyEduItemButton />
            </div>
        }
      </ul>
    </section>
  )
}
export default MarketHistoryInfo