import React, { useState } from 'react'
import '../../../node_modules/slick-carousel/slick/slick.css'
// import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import './PageSlide.css'
import { Link, useLocation } from 'react-router-dom'
import { WalletState } from '../../context/walletStateProvider'
import metamask from '../../services/web3/metamask'
import { LoadingState } from '../../context/loadingStateProvider'
function PageSlide(props) {
  const walletState = React.useContext(WalletState);
  const loadingState = React.useContext(LoadingState);

  const PAGE_PER_ITEM = props.rows * props.slidesPerRow
  const TOTAL_PAGE = Math.ceil(props.total / PAGE_PER_ITEM)
  const [dotArr, setDotArr] = useState([])

  const location = useLocation();
  const [inside, setInside] = useState(false)
  const [outside, setOutside] = useState(false)

  
  React.useEffect(() => {
    ShowPaginationNumbers(props.currentPage + 1)
  }, [props.currentPage, props.total])

  const slider_items = imgList => {
    return imgList.map((item, i) => {
      return props.divItem ? (
        item
      ) : (
        <div key={`pageSlide_${i}`} className="page-slider__item">
          <div className="page-slider-item-row">
            <Link
              to={item.link}
              state={{
                item: item,
              }}
            >
              <img
                className="page-item-image"
                alt='itemInfo'
                src={require('../../' + item.info)}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
            </Link>

            <div>
              {item.name ? (
                <h2 className="page-item-author">
                  {item.name} <span>{item.name_e}</span>
                </h2>
              ) : (
                <></>
              )}
              {item.author ? (
                <h2 className="page-item-author">
                  {item.author} <span>{item.author_e}</span>
                </h2>
              ) : (
                <></>
              )}
              {item.title ? (
                <h3 className="page-item-email">{item.title}</h3>
              ) : (
                <></>
              )}
              <h3 className="page-item-email">{item.email}</h3>
              <button className="page-item-button">팔로우</button>
            </div>
          </div>
        </div>
      )
    })
  }

  function ShowPaginationNumbers(currentPage) {
    try {
      let startPage
      let endPage

      if (TOTAL_PAGE <= 5) {
        startPage = 1
        endPage = TOTAL_PAGE
      } else {
        if (currentPage <= 3) {
          startPage = 1
          endPage = 5
        } else if (currentPage + 1 >= TOTAL_PAGE) {
          startPage = TOTAL_PAGE - 4
          endPage = TOTAL_PAGE
        } else {
          startPage = currentPage - 2
          endPage = currentPage + 2
        }
      }

      let numArr = [];

      if (startPage === endPage) {
        numArr.push(1)
      } else {
        for (var i = startPage; i <= endPage; i++) {
          numArr.push(i)
        }
      }

      setDotArr(numArr)
    } catch (err) {
      console.log(err)
    }
  }

  React.useEffect(() => {
    const path = location.pathname;
    if (
      path === '/artdb/artist' ||
      path === '/artdb/artist/' ||
      path === '/artdb/venue' ||
      path === '/artdb/venue/') {
      setInside(false);
      setOutside(true);
    } else {
      setInside(true);
      setOutside(false);
    }
  }, [location])

  const PageList = () => {
    return (
      <div style={props.total === 0 ? { display: 'none' } : {}}>
        <button
          className="slick-first"
          onClick={() => {
            props.setCurrentPage(0)
          }}
        >
          <span className="ir_pm">처음으로</span>
        </button>

        <button
          className={"slick-arrow slick-prev"}
          onClick={() => {
            if (props.currentPage !== 0) {
              props.setCurrentPage(props.currentPage - 1)
            }
          }}
        >
          <span className="ir_pm">이전</span>
        </button>

        <div className="slick-dots">
          <ul className="slick-dots-container">
            {dotArr.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => props.setCurrentPage(item - 1)}
                  style={{ display: 'inline-block' }}
                  className={dotArr[index] === props.currentPage + 1 ? 'slick-active' : ''}
                >
                  <div className='dots-number'>
                    {item}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>

        <button
          className={"slick-arrow slick-next"}
          onClick={() => {
            if (props.currentPage !== TOTAL_PAGE - 1) {
              props.setCurrentPage(props.currentPage + 1)
            }
          }}
        >
          <span className="ir_pm">다음</span>
        </button>

        <button
          className="slick-last"
          onClick={() => {
            props.setCurrentPage(TOTAL_PAGE - 1);
          }}
        >
          <span className="ir_pm">끝으로</span>
        </button>
      </div>
    );
  }

  return (
    <section className="container page-slider" >
      <div className="page-slider__list" >
        {props.nft && !props.notLoading ?
          walletState.address === null ?
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <span style={{ fontSize: '3vh' }}>지갑연결을 먼저 해주세요.</span>
              <button className='my-button' onClick={() => {
                metamask.connect(walletState);
                loadingState.setOpen(true);
                loadingState.setText('연결된 지갑의 NFT목록을 가져오는중입니다.\n잠시만 기다려주세요.');
              }}>지갑 연결하기</button>
            </div>
            :
            <div>
              {slider_items(props.items)}
            </div>
          :
          <div>
            {slider_items(props.items)}
          </div>
        }
        {inside && <PageList />}
      </div>
      {outside && <PageList />}

    </section>
  )
}
export default PageSlide
