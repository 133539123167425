import React from 'react'
import './AddressModal.css'
import DaumPostcode from 'react-daum-postcode';


function AddressModal({ setModalOpen, setAddress }) {
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }

  const onCompletePost = (data) => {
      const fullAddr = data.address;
      // setAddress(`${fullAddr} (${data.bname})`);
      setAddress(fullAddr);
      closeModal()
  };

  const postCodeStyle = {
    display: 'block',
    position: 'relative',
    top: '0%',
    width: '100%',
    height: '100%',
  };

  return (
    <div className="address-modal">
      <div className="modal-container">
        <div className="modal-body" onClick={e => e.stopPropagation()}>
        <button className='close-button' onClick={closeModal} />
          <DaumPostcode style={postCodeStyle} autoClose  onComplete={onCompletePost} />
        </div>
      </div>
    </div>
  )
}

export default AddressModal
