import React from "react";

const WalletState = React.createContext(true);

const WalletStateProvider = (props) => {
    const [wallet, setWallet] = React.useState(null);  // 지갑 종류
    const [address, setAddress] = React.useState(null); // 지갑 주소
    const [provider, setProvider] = React.useState(null); // 사용한 provider
    const [currentHash, setCurrentHash] = React.useState(null); // 발생 hash
    const [nftList, setNftList] = React.useState([]); // nft 보유 항목

    // const check = () => {
    //     // if(window.ethereum){
    //     if(false){
    //         setCheckMeta(true)
    //     }
    //     else{
    //       const options = {
    //         injectProvider: false,
    //         communicationLayerPreference: 'webrtc',
    //       };
    //       const MMSDK = new MetaMaskSDK(options);
    //       const provider = MMSDK.getProvider();

    //       console.log('11')
    //       console.log(provider)
    //     //   setCheckMeta(true)
    //     }
    // }

    // React.useEffect(() => {
    //     check()
    // },[])
    
    if(window.ethereum){
        window.ethereum.on('accountsChanged', function (accounts) {
            setAddress(accounts[0])
            setNftList([])
          })
    }

    
    return (
        <WalletState.Provider
            value={{
                wallet, setWallet,
                address, setAddress,
                provider, setProvider,
                currentHash, setCurrentHash,
                nftList, setNftList
            }}>
            {props.children}
        </WalletState.Provider>
    )
}

export { WalletState, WalletStateProvider };