import React from 'react';
import swal from 'sweetalert';
import { LoadingState } from '../../context/loadingStateProvider';
import { getToday } from '../../hook/utils/getToday';
import work from '../../services/myArchive/work/work';
import './PinModal.css';

function COAModal({ setModalOpen, item, setItem, items }) {

  const loadingState = React.useContext(LoadingState);

  const [password1, setPassword1] = React.useState('');
  const [alphabet1, setAlphabet1] = React.useState('');
  // const [lock, setLock] = React.useState(false);
  const [full, setFull] = React.useState('');
  const nextFocus1 = React.useRef(null);


  React.useEffect(() => {
    console.log('a')
    setPassword1('')
    setAlphabet1('')
    setFull('')
  },[])

  const [next, setNext] = React.useState(false);

  // 모달 끄기
  const closeModal = () => {
    // if(lock){
    //   swal('잠시만 기다려주세요.')
    //   return
    // }
    setModalOpen(false)
  }

  const goNext = () => {
    if (password1.length === 4 && alphabet1.toUpperCase().length === 1) {
      setFull(`${password1}${alphabet1.toUpperCase()}`)
      setPassword1('')
      setAlphabet1('')
      setNext(true)
    }else{
      swal("정확한 간편패스워드를 입력해주세요.")
    }  
  }


  const createCOA = async() => {
    
    // setLock(true)
    if(`${password1}${alphabet1.toUpperCase()}` !== full){
      swal('동일한 비밀번호를 입력해주세요.')
      return
    }

    loadingState.setText('Q-CoA 생성중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await work.createCOA(`${password1}${alphabet1.toUpperCase()}`, item.unique_KEY)

    setPassword1('')
    setAlphabet1('')
    if(!res.resultCode){
      // if(res.message === "실패 4단계"){
      //   swal("관리자에게 문의해주세요.")
      //   closeModal()
      //   loadingState.setOpen(false);
      //   loadingState.setText('');
      //   return
      // }
      // if(res.message === "Already Exist"){
      //   swal("관리자에게 문의해주세요.")
      //   closeModal()
      //   loadingState.setOpen(false);
      //   loadingState.setText('');
      //   return 
      // }
      // swal("QCoA 생성에 실패했습니다.")
      if(res.message === 'No Sign'){
        swal("서명을 다시 등록해주세요.")
      }else{
        swal("Q-CoA 생성에 실패했습니다.\n관리자에게 문의해주세요.")
      }

      closeModal()
      loadingState.setOpen(false);
      loadingState.setText('');
      return
    }
    swal("Q-CoA 생성에 성공했습니다.")

    setItem(items.map(
      data => data.pk === item.pk
      ? {...data, coa: 'true', coa_CREATE_TIME: getToday()}
      : data
    ))
    closeModal()
    loadingState.setOpen(false);
    loadingState.setText('');
  }

  return (
    <div className="pin-modal">
      <div className="modal-container">
      <div className="modal-body" onClick={e => e.stopPropagation()}>
      <h1 style={{whiteSpace: 'pre-line', wordBreak: 'auto-phrase', textAlign: 'center'}}>            {
              !next ? 'Q-CoA 생성을 위해 간편패스워드를 입력해주세요.' : '동일한 간편패스워드를 한번더 입력해주세요.'
            }
          </h1>

            <>
            <div className="pin-content">
            4 Number + 1 Alphabet
          </div>
          <div className='pin__input'>
            <input
            className='pin-input num'
            style={password1.length !== 0 ? {letterSpacing: '20rem'} : {letterSpacing: '35rem'}}

            type="password"
            placeholder="****"
            maxLength={4}
            minLength={4}
            pattern="[0-9]+"
            value={password1}
            onKeyPress={(e) => {
              // 정규표현식 /[0-9]/ 이 아니면 press X
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setPassword1(e.target.value);
              if (e.target.value.length === 4) {
                nextFocus1.current.focus();
              }
            }}
            required
            />
            <input
            className='pin-input alp'
            ref={nextFocus1}
            type="password"
            value={alphabet1}
            placeholder="*"
            maxLength={1}
            minLength={1}
            pattern="[A-Za-z]+"
            onKeyPress={(e) => {
              // 정규표현식 ^[A-Za-z]+$/i 이 아니면 press X
              if (!/^[A-Za-z]+$/i.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setAlphabet1(e.target.value.toUpperCase());
            }}
            required
            />
            </div>
            </>
           
          <div className="pin-btn">
            {
              !next ? 
              <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
              <button onClick={closeModal}>취소</button>
              <button onClick={goNext}>다음</button>
              </div>
              :
              <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
              <button onClick={closeModal}>취소</button>
              <button onClick={createCOA}>생성</button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default COAModal
