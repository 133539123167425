import React, { useState } from 'react'
import swal from 'sweetalert'
import VenueDetailInfo from '../../../components/VenueDetailInfo/VenueDetailInfo'
import WorkHeader from '../../../components/WorkHeader/WorkHeader'

import { Link, useNavigate, useParams } from 'react-router-dom'
import HistoryInfo from '../../../components/HistoryInfo/HistoryInfo'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'

import { shallowEqual, useSelector } from 'react-redux'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import PageSlide2 from '../../../components/PageSlide2/PageSlide2'
import { artViewDate, artViewPrice } from '../../../hook/utils/artdbView'
import { getTitle } from '../../../hook/utils/getTitle'
import { articleMainImage, handleImgError, userImage, workMainImage } from '../../../services/imageRoute'
import publicGallery from '../../../services/public/artDB/publicGallery'
import './VenueDetail.css'


function VenueDetail() {
  
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { login } = useSelector(v => v.auth, shallowEqual);
  const navigate = useNavigate()

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  const [menu, setMenu] = useState(0)
  const { venuId } = useParams();
  const [agency, setAgency] = React.useState();
  const [company, setCompany] = React.useState();
  const [article, setArticle] = React.useState();
  const [exhibition, setExhibition] = React.useState();
  const [work, setWork] = React.useState([]);
  const [sale, setSale] = React.useState([]);
  const [datail, setDetail] = React.useState(); // 기관 정보
  const [exHistory, setExHistory] = React.useState(); // 전시 이력
  const [history, setHistory] = React.useState(); // 히스토리 정보
  const [news, setNews] = React.useState(); // 소식
  const [other, setOther] = React.useState([]); // 작품
  const [market, setMarket] = React.useState([]); // 작품

  const [heart, setHeart] = React.useState(false); // 좋아요

    React.useEffect(() => {
    needLogin()
  },[])

  const needLogin = async() => {
    if (!login) {
      await swal('로그인이 필요한 서비스입니다.')
      navigate('/login', { replace: true })
      return
    }
  }

  const setLike = async (setData, pk) => {

    const res = await publicGallery.setLike(pk)
    if (!res.resultCode) {
      return
    }
    setData(res.message === 'ADD')
  }

  React.useEffect(() => {getData(venuId)},[venuId])
  const getData = async (pk) => {
    const res = await publicGallery.getDetail(pk)
    setAgency(res.agemcy)
    setCompany(res.agency_history)
    setExhibition(res.exhibitionList)
    setArticle(res.article)
    setWork(res.work_info)
    setSale(res.market_work)
    console.log(res.agemcy.heart)
    setHeart(res.agemcy.heart)
  }

  // 기관 정보
  React.useEffect(() => {
    if(agency === undefined){
      return
    }

    const infoItem = {
      image: `${userImage}/${agency.image}`,
      status: agency.status,
      author: {
        image: `${userImage}/${agency.image}`,
        name: lang === 'ko' ? agency.name : agency.eng_name,
        email: agency.email,
      },
      infos: [
        {
          title: '설립일',
          content: agency.since,
        },
        {
          title: '대표자',
          content: lang === 'ko' ? agency.manager_name : agency.manager_eng_name,
        },
        {
          title: '홈페이지',
          content: agency.home_page,
        },
        {
          title: '주소',
          content: agency.address,
        },
        {
          title: 'Contact1',
          content: agency.contact1,
        },
        {
          title: 'Contact2',
          content: agency.contact2,
        },
        {
          title: '기관소개',
          content: agency.introduce ==='null' ? '' : agency.introduce
        },
      ],
    }

    setDetail(infoItem)

  },[agency, lang])


  React.useEffect(() => {
    if(exhibition === undefined){
      return
    }

    const myItem = []

    for(let i = 0; i < exhibition.length; i ++){
      const item = {
        modal: true,
        data: exhibition[i],
        period: `${exhibition[i].date} ~ ${exhibition[i].end}`,
        category: exhibition[i].type,
        title: `${exhibition[i].title}, ${exhibition[i].place}`,
      }
      myItem.push(item)
    }
    setExHistory(myItem)
    
  },[exhibition])


  React.useEffect(() => {
    if(company === undefined){
      return
    }

    const myItem = []

    for(let i = 0; i < company.length; i ++){
      const item = {
        period: `${company[i].date}`,
        category: `${company[i].content}`,
        title: company[i].detail
      }
      myItem.push(item)
    }
    setHistory(myItem)
  },[company])

  var moreList = ['전시이력', '히스토리']
  var detailList = [`전체작품 (${work.length})`, `마켓플레이스(${sale.length})`]

  React.useEffect(() => {
    const result = []
    if(work === undefined){
      setOther(result)
      return
    }
    work.map((item, i) => {
      result.push(
        <div key={i} className="page-slider__item">
          <div className="page-slider-item-row">
            <Link
              to={`/artdb/work/${item.pk}`}
            >
              <img
                alt=''
                className="page-item-image"
                src={`${workMainImage}/${item.image}`}
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
            </Link>
            <div>
              {/* <h2 className="page-item-author">{item.title}</h2>
              <h3 className="page-item-email">{item.eng_TITLE}</h3> */}
              <h2 className="page-item-author">{getTitle(lang, item.title, item.eng_TITLE)}</h2>
              <h3 className="page-item-email">{artViewDate(item.start_CREATE, item.end_CREATE)}</h3>
              {/* <button className="page-item-button">팔로우</button> */}
            </div>
          </div>
        </div>
      )
    })
    
    setOther(result)
  }, [work])

  React.useEffect(() => {
    const result = []
    if(sale === undefined){
      setOther(result)
      return
    }
    sale.map((item, i) => {
      result.push(
        <div key={i} className="page-slider__item">
          <div className="page-slider-item-row">
            <Link
              to={`/market/${item.pk}`}
            >
              <img
                alt=''
                className="page-item-image"
                src={`${workMainImage}/${item.image}`}
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
            </Link>
            <div>
              {/* <h2 className="page-item-author">{item.title}</h2>
              <h3 className="page-item-email">{item.eng_TITLE}</h3> */}
              <h2 className="page-item-author">{getTitle(lang, item.title, item.eng_TITLE)}</h2>
              <h3 className="page-item-email">{artViewDate(item.start_CREATE, item.end_CREATE)}</h3>
              <span className='page-item-price'>{artViewPrice(item.price_UNIT, item.price)}</span>
              {/* <button className="page-item-button">팔로우</button> */}
            </div>
          </div>
        </div>
      )
    })
    
    setMarket(result)
  }, [sale])
  
  React.useEffect(() => {
    const result = []
    if(article === undefined){
      setNews(result)
      return
    }

    const findHttp = (temp) => {
      if(temp === '' || temp === null || temp === undefined){
        return "javascript:void(0)";
      }
      if(temp.indexOf('http') === -1){
        return `http://${temp}`
      }
      return temp
    }

const hyperlink = (item) => {
      const json = {
        text : item.replaceAll(' ', ''),
        link: findHttp(item.replaceAll(' ', ''))
      }
      return json
  }
    article.map((item, i) => {
      result.push(
        <li className="artist-d-item" key={i}>
          <a href={hyperlink(item.source).link} target={hyperlink(item.source).link === "javascript:void(0)" ? '_self' :'_black'}>
        <img src={`${articleMainImage}/${item.image}`} alt='' onError={handleImgError} onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              } />
        <h2>{item.title}</h2>
        <p>
          {item.reference}
        </p>
      </a>
      </li>
      )
    })
    
    setNews(result)
  }, [article])
  
  
  return (
    <section className="venue-detail">
      <Header active="0" colored="black" detail={true} />
      <WorkHeader  kyc={datail === undefined ? '' : datail.status} title_k={datail === undefined ? '' : datail.author.name} follow={true} followState={heart} followFunc={() => setLike(setHeart, venuId)}></WorkHeader>
      <VenueDetailInfo item={datail} />
      <HistoryInfo
        title={'기관이력'}
        schoolItem={history}
        historyItem={exHistory}
        moreList={moreList}
      />
      <section className="venue-detail-info">
        <SectionHeader title={'소식'} />
          <div className="container" >
            <div style={matches? {padding: '0 20rem'} :{padding: '0 320rem'}} > 
              <ul style={matches? {overflow: 'auto', padding: '20rem 0'} :{overflow: 'auto', padding: '60rem 0'}}>
            {news}
          </ul>
              </div> 
          </div>
      </section>
      <WorkHeader
        moreListChange={setMenu}
        moreMenu={menu}
        moreList={detailList}
        moreActive={1}
        title_k={'소장 작품'}
      ></WorkHeader>
      <div className="container artist-d-page-slide">
      {menu === 0 ? 
          <PageSlide2
            items={other}
            divItem={true}
            rows={3}
            slidesPerRow={3}
            breakRows={4}
            breakSlidesPerRow={2}
          />
          :
          <PageSlide2
            items={market}
            divItem={true}
            rows={3}
            slidesPerRow={3}
            breakRows={4}
            breakSlidesPerRow={2}
          />
  }
      </div>
      <Footer />
    </section>
  )
}
export default VenueDetail
