import React from 'react'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import './SupportAboutNew.css'

function SupportAbout() {
  return (
    <div className="support-about">
      <div className="container">
        <article className="support-01">
            <h2>
                <span className='bold blue'>큐아트(Qart)</span><span >는</span>
            </h2>
            <p>
                블록체인 기술을 기반으로 <span className='bold'>투명한 미술생태계</span>를 지향합니다.<br></br>
                판매 수익의 일부를 원작자에게 지급하여 <span className='bold'>건강한 창작생태계를</span> 함께 만듭니다.
            </p>
        </article>
        <article className="support-01">
            <h2>
                큐아트의 <span className='bold blue'>작품관리 통합솔루션</span>은<br></br>

            </h2>
            <p>
            미술 아카이브, 아트마켓과 블록체인 분야의 전문가가 기획단계부터 함께 만들어 <span className='bold'>전문적</span>입니다.<br></br>
                작가, 갤러리, 미술관, 소장가에게 꼭 필요한 기능을 반영하여  <span className='bold'>편의성</span>이 높습니다.
            </p>
        </article>
        <article className="support-01">
            <h2>
                큐아트의 <span className='bold blue'>작품이력증명시스템</span>은<br></br>

            </h2>
            <p>
                독자적인 특허기술을 기반으로 한 <span className='bold'>보안성</span> 높은 인증 시스템입니다.<br></br>
                작품의 창작자부터 전시이력과 소유권 이전을 포함한 모든 이력이 Q-CoA에 기록되므로,<br></br> 
                기존 종이 보증서에 비해 <span className='bold'>안전성</span>과 <span className='bold'>공신력</span> 높은 <span className='bold'>작품이력증서</span>의 가치가 있습니다.
            </p>
      <div className="support-img-01"></div>
        </article>
        
      </div>
      
    </div>
  )
}
export default SupportAbout
