import React from 'react'
import GalleryDirection from '../../../components/GalleryDirection/GalleryDirection'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import './ArtDB.css'
import useMobileDetect from 'use-mobile-detect-hook';

const ArtDB = () => {

  const detectMobile = useMobileDetect();
  const [items, setItems] = React.useState([]);
  
  const setImage = () => {
    setItems([
      {
      image: detectMobile.isMobile() || window.innerWidth < 480 ? 'assets/artdb/app_artist.png' : 'assets/artdb/web_artist.png',
        link: '/artdb/artist',
        section: 'Artist',
        title: '큐아트 작가 정보 바로가기',
      },
      {
        image: detectMobile.isMobile() || window.innerWidth < 480 ? 'assets/artdb/app_work.png' : 'assets/artdb/web_work.png',
        link: '/artdb/work',
        section: 'Work',
        title: '큐아트 작품 정보 바로가기',
        order: -1,
      },
      {
        image: detectMobile.isMobile() || window.innerWidth < 480 ? 'assets/artdb/app_collection.png' : 'assets/artdb/web_collection.png',
        link: '/artdb/venue',
        section: 'Collection',
        title: '소장처별 바로가기',
      },
    ])
  }
  
  React.useEffect(() => {
    setImage();
    window.addEventListener('resize', setImage);
    return () => {
      window.removeEventListener('resize', setImage);
    }
  }, []);

  return (
    <section className="artbase">
      <SectionHeader title="Art Database" />
      <GalleryDirection items={items} />
      
    </section>
  )
}
export default ArtDB
