import React, { useState } from 'react'
import HeaderSmall from '../../../components/Header/HeaderSmall/HeaderSmall'

function ArtDbWork(props) {
  
  const menuList = {
    menu: [
      {
        title: 'Art Work',
        link: '/artdb/work/',
      },
      {
        title: 'NFT',
        link: '/artdb/work/nft',
      },
    ],
  }
  return (
    <div>
      <HeaderSmall
        moreList={menuList}
        detail={1}
        active={1}
        choice={props.active}
      />
      {props.children}
    </div>
  )
}
export default ArtDbWork
