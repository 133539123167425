import { getOrderNo } from '../../hook/utils/makeOrderNo';
import client from '../index';

/**
  쿠키 페이 토큰 발급  CROS
  */
  const getToken = async (data) => {
    
  const res = await client.cookie.post('/payAuth/token', data);
  return res.data;
}

/**
  결제 인증 CROS
   * @param {String} token
   * @param {String} tid
*/
const paycert = async(token, tid) => {
  const res = await client.cookie.post(`/api/paycert`, {tid: tid}, {headers: {TOKEN: token}});
  return res.data;
}


/**
  구독
  * @param {String} type 1: 베이직, 2: 프리미엄
  * @param {boolean} isHana 하나카드 확인
  * @param {String} cardNo 카드 번호
  * @param {String} cardPwd 카드 비밀번호 앞자리 2개
  * @param {String} cardExpired 카드 비밀번호 앞자리 2개
  * @param {String} cardAuth 카드 소유자 생년월일 // 
  */
  const subscribe = async (type, isHana, cardNo, cardPwd, cardExpired, cardAuth) => {
    const ApiId = process.env.REACT_APP_BILLING_COOKEYPAY_ID;
    const ApiKey = process.env.REACT_APP_BILLING_COOKEYPAY_SECRET;

    const productName = type === 1 ? "베이직 멤버십" : "프리미엄 멤버십";
    const price = type === 1 ? "99000" : "199000";
    
    const postData = {
      API_ID: ApiId,
      ORDERNO: getOrderNo(),
      PRODUCTNAME: productName,
      PRODUCTCODE: type,
      AMOUNT: price,
      // AMOUNT: '10',
      USEHANACARD: isHana ? 'Y' : 'N',
      CARDNO: cardNo, // 카드 번호
      EXPIREDT: cardExpired, //카드유효기간 (YYMM)
      CARDPWD:  cardPwd, //카드비밀번호 앞 2자리
      CARDAUTH: cardAuth,// 생년월일 YYMMDD 혹은 사업자번호 10자리
      QUOTA:"00",
      TAXFREECD: "Y",
      PRODUCTTYPE:"2"
    }
  const res = await client.json.post('/cookie/membership', postData);
  return res.data;
}


/**
  구독 취소(다음 결제부터 기본멤버십)
  */
  const unsubscribe = async () => {
  const res = await client.json.delete('/cookie/membership');
  return res.data;
}

/** 구독권 다운그래이드(다음 결제부터 적용)
 *  프리미엄 -> 베이직
*/
const downgrade = async() => {

  const postData = {
    userLevel: 1
  }

const res = await client.json.post('/user/membership', postData);
  return res.data;
}

/** 구독권 업그래이드 비용 확인
 *  베이직 -> 프리미엄
*/
const checkUpgradeFee = async() => {
  
const res = await client.json.get('/user/membership/premium');
  return res.data;
}

/** 구독권 업그래이드(바로 적용, 추가결제)
 *  베이직 -> 프리미엄
*/
const upgrade = async() => {
  const postData = {
    userLevel: 2
  }

const res = await client.json.post('/user/membership/premium', postData);
  return res.data;
}


/** 다운그레이드 취소
*/
const cancelDowngrade = async() => {
  
const res = await client.json.delete('/user/membership');
  return res.data;
}





const pay2pay = {
  getToken, paycert,
  subscribe, unsubscribe,
  upgrade, checkUpgradeFee,
  downgrade, cancelDowngrade
}

export default pay2pay
