import cookie from 'js-cookie'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useMobileDetect from 'use-mobile-detect-hook'
import AllSlide from '../../components/BannerSlide/AllSlide'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import HeaderSmall from '../../components/Header/HeaderSmall/HeaderSmall'
import CommingModal from '../../components/Modal/CommingModal'
import ModalPortal from '../../components/Modal/ModalPortal'
import UseBModal from '../../components/Modal/PopupModal'
import SearchHeader from '../../components/SearchHeader/SearchHeader'
import { bannerImage } from '../../services/imageRoute'
import ArtDB from './ArtDB/ArtDB'
import New from './New/New'
import PopupModal from '../../components/Modal/PopupModal'


const Main = () => {

  const detectMobile = useMobileDetect();

  const [banner, setBanner] = React.useState([])
  const { mainBannerList } = useSelector(v => v.setting, shallowEqual)
  const [modalOpen, setModalOpen] = React.useState(false);

  const moreList = {
    menu: [
      {
        title: 'Artist',
        link: '/artdb/artist/',
      },
      {
        title: 'Work',
        link: '/artdb/work/',
      },
      {
        title: 'Collection',
        link: '/artdb/venue/',
      },
    ],
  }

  const setImage = () => {
    let _banner = [];

    mainBannerList.map(item => {
      _banner.push({
        info: detectMobile.isMobile() || window.innerWidth < 480 ? `${bannerImage}/${item.mobile}` : `${bannerImage}/${item.web}`,
        link: item.address,
        type: item.type
      });
    });
    setBanner(_banner)
  }

  React.useEffect(() => {
    setImage();
    window.addEventListener('resize', setImage);
    return () => {
      window.removeEventListener('resize', setImage);
    }
  }, [mainBannerList]);


  return (
    <>

      <ModalPortal>
        {/* <CommingModal setModalOpen={setModalOpen} modalOpen={modalOpen} /> */}
        <PopupModal />
      </ModalPortal>

      <div className="main">

        <div style={{ display: 'none' }}>
          <a href="/">
            <h1>Art Database</h1>
          </a>
          <a href="/qcoa">
            <h1>Q-CoA</h1>
          </a>
          <a href="/market">
            <h1>Marketplace</h1>
          </a>
          <a href="/support">
            <h1>Support</h1>
          </a>
        </div>
        <Header colored="black" active={0} />

        <SearchHeader
          title="Art Database"
          placeholder={'작가, 작품, 콜렉션을 검색하세요'}
          active={true}
        />
        <HeaderSmall moreList={moreList} active={1} />
        <AllSlide items={banner} />
        <ArtDB />
        <New />
        {/* <NFT /> */}
        <Footer />
      </div>
    </>
  )
}
export default Main
