import React, { useState } from 'react'
import DropDown from '../DropDown/DropDown'
import './HistoryModal.css'
import { shallowEqual, useSelector } from 'react-redux'
import { checkDate, handleInputMaxLength } from '../../hook/utils/validation'
import { v4 as uuidv4 } from 'uuid';


function ShowHistoryModal({ setModalOpen, data }) {

  console.log(data)

  // const { country, date, end, hoster, place, planner, publisher, reference, title, type } = data;

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }

  return (
    <div className="history-modal">
      <div className="modal-container">
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <header className="history-header">
            <h1>전시이력</h1>
            {/* <button className="history-close" onClick={closeModal}></button> */}
          </header>
          <ul className="history-content">
            <li>
              <h2>전시명</h2>
              <input type="text" value={data?.title ?? data?.exhi_NAME} readOnly></input>
            </li>
            <li>
              <h2>개최장소</h2>
              <input type="text" value={data?.place} readOnly></input>
            </li>
            <li>
              <h2>국가</h2>
              <input type="text" value={data?.country} readOnly></input>
            </li>
            <li>
              <h2>전시유형</h2>
              <input type="text" value={data?.type} readOnly></input>
            </li>
            <li>
              <h2>개최기간</h2>
              <input
                id="date"
                className="date-input"
                value={data?.date ?? data?.start_DATE}
                readOnly
              ></input>
              <span className="date-bar">-</span>
              <input
                id="date"
                className="date-input"
                value={data?.end ?? data?.end_DATE}
                readOnly
              ></input>
            </li>
            <li>
              <h2>주최</h2>
              <input type="text" value={data?.hoster} readOnly></input>
            </li>
            <li>
              <h2>주관</h2>
              <input type="text" value={data?.publisher} readOnly></input>
            </li>
            <li>
              <h2>기획자</h2>
              <input type="text" value={data?.planner} readOnly></input>
            </li>
            <li>
              <h2>전시 설명</h2>
              <textarea
                readOnly
                maxLength={500}
                value={data?.reference ?? data?.note}
              ></textarea>
            </li>
          </ul>
          <button className="history-add" onClick={closeModal}>
            <span>닫기</span>
          </button>
        </div>
      </div>
    </div>
  )
}
export default ShowHistoryModal
