
import React from 'react'

const LoginWithNaver = ({ setGetToken, setUserInfo }) => {

    const naverRef = React.useRef()
    const { naver } = window

    const naverLogin = new naver.LoginWithNaverId({
        clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
        callbackUrl: process.env.REACT_APP_NAVER_CALLBACK_URL,
        // 팝업창으로 로그인을 진행할 것인지?           
        isPopup: false,
        // 버튼 타입 ( 색상, 타입, 크기 변경 가능 )
        loginButton: { color: 'green', type: 3, height: 58 },
        callbackHandle: false,
    })

    

    const initializeNaverLogin = () => {
        naverLogin.init()
    }

    

    const beforeNaverLogin = () => {
        try{
             initializeNaverLogin()
            return true
        }catch(err){
            return false
        }
    }


    const handleNaverLogin = async() => {
        const res = beforeNaverLogin()
        if(!res) return
        naverRef.current.children[0].click()
    }

    return (
        <>
            <div id="naverIdLogin" ref={naverRef} style={{ display: 'none' }}>
            </div>
            <div className="login-link__icon_naver" onClick={handleNaverLogin}>
                <span className="ir_pm">네이버</span>
            </div>
        </>
    )
}

export default LoginWithNaver