import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DetailInfo from '../../../components/DetailIfo/DetailInfo';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import MarketHistoryInfo from '../../../components/MarketHistoryInfo/MarketHistoryInfo';
import WorkHeader from '../../../components/WorkHeader/WorkHeader';
import { artViewDate, artViewPrice, artViewSize } from '../../../hook/utils/artdbView';
import { checkUseName } from '../../../hook/utils/checkUseName';
import { getTitle } from '../../../hook/utils/getTitle';
import { userImage, workMainImage, workSubImage } from '../../../services/imageRoute';
import market from '../../../services/public/market/market';
import WorkTopArrowSlider from '../../ArtDatabase/Work/Slider/WorkTopArrowSlider';
import './MarketItem.css';
import swal from 'sweetalert';

function MarketItem() {
  const navigate = useNavigate();
  const { marketId } = useParams();
  const { rowWorkTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { login } = useSelector(v => v.auth, shallowEqual)



  React.useEffect(() => {

    getData(marketId)
  }, [marketId])

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const [data, setData] = React.useState(null);
  const [work, setWork] = React.useState(null);
  const [workType, setWorkType] = React.useState();
  const [moreList, setMoreList] = React.useState([]);
  const [artist, setArtist] = React.useState(null);
  const [history, setHistory] = React.useState([]);
  const [sale, setSale] = React.useState([]);
  const [another, setAnother] = React.useState([]);

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  const getData = async (pk) => {
    const res = await market.getDetail(pk)
    setData(res)
  }

  React.useEffect(() => {
    if(data?.work?.market === 0){
      swal('등록되지 않은 작품입니다.')
      navigate(-1);
      return;
    }

    if(data?.work?.market === 2){
      navigate(`/market/work/auction/${marketId}`)
      return;
    }
  },[data])

  const setAnotherItem = (items) => {
    let itemList = []
    if (items.length === 0) {
      setAnother(itemList)
      return
    }
    for (let i = 0; i < items.length; i++) {
      itemList.push({
        info: `${workMainImage}/${items[i].image}`,
        author: getTitle(lang, items[i].title, items[i].eng_TITLE),
        title: artViewDate(items[i].start_CREATE, items[i].end_CREATE),
        link: `/market/${items[i].pk}`,
        date: items[i].start_CREATE,
        price: artViewPrice(items[i].price_UNIT, items[i].price),
        // pk: items[i].pk,
        // info: `${workMainImage}/${items[i].image}`,
        // author: checkUseName('ko', items[i].use_NAME, items[i].ko_NAME, items[i].ko_NAME, items[i].ko_NICK_NAME, items[i].ko_NICK_NAME),
        // author_e: checkUseName('en', items[i].use_NAME, items[i].eng_NAME, items[i].eng_NAME, items[i].eng_NICK_NAME, items[i].eng_NICK_NAME),
        // title: lang === 'ko' ? items[i].title : items[i].eng_TITLE,
        // date: artViewDate(items[i].start_CREATE, items[i].end_CREATE),
        // price: artViewPrice(items[i].price_UNIT, items[i].price),
        // heart: items[i].heart,
        // link: `/market/${items[i].pk}`,
      })
    }
    setAnother(itemList)
  }

  const setHistoryItem = (items) => {
    let item = []
    if (items.length === 0) {
      setHistory(item)
      return
    }
    for (let i = 0; i < items.length; i++) {
      item.push({
        modal: true,
        data: items[i],
        period: `${items[i].start_DATE} ~ ${items[i].end_DATE}`,
        category: items[i].type,
        title: `${items[i].exhi_NAME}, ${items[i].place}`,
      })
    }
    setHistory(item)
  }

  const setArtistItem = (items) => {
    if (items === null) {
      return
    }

    // const date = () => {
    //   if (items.user_TYPE === '1') {
    //     return `${items.birth_SINCE === '' || items.birth_SINCE === null ?
    //       '미상' : `${items.birth_SINCE}`}`
    //   } else {
    //     return items.birth_SINCE
    //   }
    // }

    const infos = [
      {
        title: items.user_TYPE === 2 ? '주소' : '국적',
        content: items.country,
      },
      // {
      //   title: items.user_TYPE === '1' ? '출생연도' : '설립일',
      //   content: date(),
      // },
      {
        title: '소개',
        content: items.introduce === 'null' ? '' : items.introduce,
      },
    ]
    const origin = {
      // artist: checkUseName(lang, items.use_NAME, `${items.ownerDto.first_NAME}${items.ownerDto.name}`, `${items.ownerDto.first_ENG_NAME} ${items.ownerDto.eng_NAME}`, items.ownerDto.nickname, items.ownerDto.eng_NICKNAME),
      artist: checkUseName(lang, items.use_NAME, `${items.first_NAME}${items.name}`, `${items.first_ENG_NAME} ${items.eng_NAME}`, items.nickname, items.eng_NICKNAME),
      image: `${userImage}/${items.image}`,
      text: infos
    }
    setArtist(origin)
  }

  React.useEffect(() => {
    if (data === null) {
      return
    }
    // 작품
    setWork(data.work)
    const checkWorkType = rowWorkTypeList.filter(t => t.ko === data.work.work_TYPE)
    // menu
    // setMoreList([data.work.user_TYPE === 1 ? '작가정보' : '기관정보', '전시이력', '최근 거래'])
    setMoreList([data.work.user_TYPE === 1 ? '작가정보' : data.work.user_TYPE === 2 ? '기관정보' : '콜렉션정보', '전시이력'])
    setWorkType(checkWorkType.length === 0 ? [{ ko: data.work.work_TYPE, eng: data.work.work_TYPE }] : checkWorkType)
    // 작가
    setArtistItem(data.work.ownerDto)
    // 히스토리
    setHistoryItem(data.exhibition_history)
    // 또다른 작품
    setAnotherItem(data.another_work)
  }, [data])


  const moveOrigin = () => {
    if (work.user_TYPE === 2) {
      navigate(`/artdb/venue/${work.user_ID}`)
    } else {
      navigate(`/artdb/artist/${work.user_ID}`)
    }
  }

  const payment = async() => {

    if (!login) {
      await swal('로그인이 필요한 서비스입니다.');
      navigate('/login', { replace: true })

      return
    }

    navigate('/market/payment', { state: { item: work, sellType: '판매' } })
  }

  const request = async() => {

    if (!login) {
      await swal('로그인이 필요한 서비스입니다.');
      navigate('/login', { replace: true })

      return
    }

    const res = await market.priceOver(work.unique_KEY)

    if(!res.resultCode){
      swal('관리자에게 문의해주세요.')
      return;
    }
    swal('관리자에게 구매 문의를 완료했습니다.\n잠시만 기다려주세요.')
  }

  return (
    <section className="market-detail">
      <Header active="2" colored="black" detail={true} />
      {work !== null &&
        <>
          <WorkHeader
            title_k={getTitle(lang, work.title, work.eng_TITLE)}  // 작품명
            title_e={lang === 'ko' ? work.artist : work.eng_ARTIST} //작가명
          />
          <div className="container">
            <div className="market-art">
              <section className="market-art-image">
                <img
                  className="market-image__image"
                  src={`${workMainImage}/${work.image}`}
                  alt=''
                  onContextMenu={
                    (e) => {
                        e.preventDefault();
                    }
                  }
                ></img>
                <div className="market-image__list">
                  {
                    work.sub_IMAGE.map(item => {
                      return (
                        <img
                          key={item.image}
                          className="market-image__item"
                          src={`${workSubImage}/${item.image}`}
                          alt=''
                          onContextMenu={
                            (e) => {
                                e.preventDefault();
                            }
                          }
                        />
                      )
                    })
                  }
                </div>
              </section>

              {
                matches ? (
                  <section className="market-art-info">
                    <div className="market-art-sell">
                      <p className="market-sell__price">
                        <span>판매가</span>
                        <span>{artViewPrice(work.price_UNIT, work.price)}</span>
                      </p>

                      <div className='market-info__warn' style={{ color: '#2f80ed' }}>
                        큐아트는 판매 수수료의 일부를 원작자에게 지급하여, 건강한 창작생태계를 지원합니다.
                      </div>

                      <div className="market-sell__button">
                        <button style={{ display: 'none' }}>
                          <span className="ir_pm">판매자 문의하기</span>
                        </button>

                        {
                            work.price > 5_000_000
                              ? <button onClick={request}>
                                별도문의
                              </button>
                              : <button onClick={payment}>
                                구매하기
                              </button>
                          }
                        {/* <Link
                      to={'/market/payment'}
                      state={{
                        item: work,
                      }}
                    >
                      구입하기
                    </Link> */}
                      </div>
                    </div>

                    <ul className="market-info__list" >
                      <div className="market-info__gallery" to="#" onClick={moveOrigin} >
                        {checkUseName(lang, work.owner_USE_NAME, `${work.ownerDto.first_NAME}${work.ownerDto.name}`, `${work.ownerDto.first_ENG_NAME} ${work.ownerDto.eng_NAME}`, work.owner_KO_NICK_NAME, work.owner_ENG_NICK_NAME)}
                        <span className="info__arrow"></span>
                      </div>
                      <li>
                        <span>작품 번호</span>
                        <span style={{ whiteSpace: 'nowrap' }}>{work.unique_KEY.slice(0, 13)}</span>
                      </li>
                      <li>
                        <span>장르</span>
                        <span>{workType[0][`${lang}`]}</span>
                      </li>
                      <li>
                        <span>에디션</span>
                        <span>{work.edition_TOTAL === 0 ? '없음' : `${work.edition}/${work.edition_TOTAL}`}</span>
                      </li>
                      <li>
                        <span>작품 크기</span>
                        <span>{artViewSize(work.size_X, work.size_Y, work.size_W, work.unit)}</span>
                      </li>
                      <li>
                        <span>재질</span>
                        <span>{work.material}</span>
                      </li>
                      <li>
                        <span>제작 연도</span>
                        <span>{work.start_CREATE !== '미상' ? `${work.start_CREATE.includes('-') ? work.start_CREATE.split('-')[0] : work.start_CREATE}년` : '미상'}</span>
                      </li>
                      <div className='market-info__warn_2'>
                        *큐아트에서 거래되는 모든 작품은 블록체인 기반 거래이력 인증서인 Q-CoA가 발급됩니다.
                      </div>
                    </ul>


                  </section>
                ) :
                  (
                    <section className="market-art-info">
                      <span className="market-info__gallery" to="#" onClick={moveOrigin}>
                      {checkUseName(lang, work.owner_USE_NAME, `${work.ownerDto.first_NAME}${work.ownerDto.name}`, `${work.ownerDto.first_ENG_NAME} ${work.ownerDto.eng_NAME}`, work.owner_KO_NICK_NAME, work.owner_ENG_NICK_NAME)}
                        <span className="info__arrow"></span>
                      </span>
                      <ul className="market-info__list">
                        <li>
                          <span>작품 번호</span>
                          <span style={{ whiteSpace: 'nowrap' }}>{work.unique_KEY.slice(0, 13)}</span>
                        </li>
                        <li>
                          <span>장르</span>
                          <span>{workType[0][`${lang}`]}</span>
                        </li>
                        <li>
                          <span>에디션</span>
                          <span>{work.edition_TOTAL === 0 ? '없음' : `${work.edition}/${work.edition_TOTAL}`}</span>
                        </li>
                        <li>
                          <span>작품 크기</span>
                          <span>{artViewSize(work.size_X, work.size_Y, work.size_W, work.unit)}</span>
                        </li>
                        <li>
                          <span>재질</span>
                          <span>{work.material}</span>
                        </li>
                        <li>
                          <span>제작 연도</span>
                          <span>{work.start_CREATE !== '미상' ? `${work.start_CREATE.includes('-') ? work.start_CREATE.split('-')[0] : work.start_CREATE}년` : '미상'}</span>
                        </li>
                      </ul>
                      <div className='market-info__warn_2'>
                        *큐아트에서 거래되는 모든 작품은 블록체인 기반 거래이력 인증서인 Q-CoA가 발급됩니다.
                      </div>
                      <div className="market-art-sell">
                        <p className="market-sell__price">
                          <span>판매가</span>
                          <span>{artViewPrice(work.price_UNIT, work.price)}</span>
                        </p>

                        <div className='market-info__warn' style={{ color: '#2f80ed', marginTop: '30rem' }}>
                          큐아트는 판매 수수료의 일부를 원작자에게 지급하여, 건강한 창작생태계를 지원합니다.
                        </div>

                        <div className="market-sell__button">
                          <button style={{ display: 'none' }}>
                            <span className="ir_pm">판매자 문의하기</span>
                          </button>
                          {
                            work.price > 5_000_000
                              ? <button onClick={request}>
                                별도문의
                              </button>
                              : <button onClick={payment}>
                                구매하기
                              </button>
                          }

                          {/* <Link
                          to={'/market/payment'}
                          state={{
                            item: work,
                          }}
                        >
                          구입하기
                        </Link> */}
                        </div>

                      </div>
                    </section>
                  )

              }


            </div>
          </div>
        </>
      }

      <MarketHistoryInfo
        id={marketId}
        title={'기본 정보'}
        artistItem={artist}
        historyItem={history}
        saleItem={sale}
        moreList={moreList}
      />

      {work !== null &&
        <DetailInfo item={work.detail_NOTE} />
      }
      {/* <TopArrowSlider title={'작가의 다른 작품'} item={another} /> */}
      <WorkTopArrowSlider title={work?.ownerDto.user_TYPE === 1 ? '판매중인 작가의 다른 작품' : work?.ownerDto.user_TYPE === 2 ? '판매중인 기관의 다른 작품' : '판매중인 콜렉션의 다른 작품'} item={another} />

      <Footer />
    </section>
  )
}
export default MarketItem
