import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Restore.css'

import { checkCapsLock } from '../../hook/utils/checkCapsLock'
import { emailCheck, passCheck } from '../../hook/utils/validation'
import auth from '../../services/auth/auth'
import { FindAccountStepper } from './FindAccountStepper'
import swal from 'sweetalert'


function RestorePassword() {
  const navigate = useNavigate()

  const [emailLock, setEmailLock] = useState(false)
  const [active, setActive] = useState(0);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('')
  const [codeLock, setCodeLock] = useState(false)
  const [token, setToken] = useState("")
  const [pin, setPin] = useState("")
  const [rePin, setRePin] = useState("")
  const [showPin, setShowPin] = useState(false)
  const [showRePin, setShowRePin] = useState(false)


  const [isCapsLockOn1, setIsCapsLockOn1] = React.useState(false);
  const [isCapsLockOn2, setIsCapsLockOn2] = React.useState(false);

  const passwordType = 
  [
    { label: '이메일 인증', active: active === 0, completed: active > 0 },
    { label: '비밀번호 설정', active: active === 1, completed: active > 1 }
  ]


  const sendEmail = async () => {

    if (!emailCheck(email)) {
      return
    }
    if (emailLock) {
      return
    }
    setEmailLock(true)
    const res = await auth.sendEmailPassword(email)
    if (res.resultCode) {
      swal("이메일로 보낸 인증코드를 입력해주세요.");
    } else {
      if(res.message === 'not local'){
        swal("타 플랫폼으로 가입한 계정은 불가능합니다.");
      }else{
        swal('가입되지 않은 계정입니다.')
      }
      setEmailLock(false)
    }
  }

  const checkCode = async () => {
    const res = await auth.checkCodePassword(email, code)
    if (!emailLock) {
      swal("먼저 이메일을 입력해주세요.")
      return
    }
    if (codeLock) {
      swal('인증이 완료되었습니다.')
      return
    }
    if (res.resultCode) {
      swal('이메일이 인증되었습니다.')
      setCodeLock(true)
      setToken(res.token)
    } else {
      swal("인증번호를 확인해주세요.")
    }
  }

  const changePassword = async() => {
    if (pin === '' || rePin === '') {
      swal('비밀번호를 입력해주세요.')
      return
    }
    if (pin !== rePin){
      swal('동일한 비밀번호를 입력해주세요.')
      return
    }
    if(!passCheck(pin)){
      setPin('')
      setRePin('')
      return
    }

    const res = await auth.changePassword(token, pin);

    if(!res.resultCode){
      swal('관리자에게 문의해주세요.');
      return
    }
    swal('비밀번호 변경이 되었습니다.\n변경된 비밀번호로 로그인해주세요.');
    navigate('/login');
  }


  const next = () => {
    if(!emailLock){
      swal('이메일 인증을 먼저 해주세요.');
      return;
    }
    if(!codeLock){
      swal('인증번호 확인을 먼저 눌러주세요.');
      return;
    }
    setActive(1);
  }

  return (
    <div className="restore">
      <section className="container restore-container">

        <FindAccountStepper type={passwordType} active={active} />
        <div className="auth-content">
          {active === 0 ? (
            <>
              <h2 className="email-title">이메일</h2>
              <div className="email-input-container">
                <input
                  autoComplete='off'
                  type="text"
                  className="email-input-num"
                  value={email}
                  readOnly={emailLock}
                  onChange={e => setEmail(e.target.value)}
                  required></input>
                <button onClick={sendEmail}>인증하기</button>
              </div>
              <h2 className="email-title">인증번호</h2>
              <div className="email-input-container">
                <input
                  autoComplete='off'
                  type="text"
                  className="email-input-num"
                  value={code}
                  readOnly={codeLock || !emailLock}
                  onChange={e => setCode(e.target.value.toUpperCase())}
                  required></input>
                <button onClick={checkCode}>확인하기</button>
              </div>
            </>
          ) : (
            <>
            <h2 className="email-title">비밀번호</h2>
            <div className="login-password">
            <input
            autoComplete='off'
              type={showPin ? "text" : "password"}
              className="login-input"
              placeholder="영문, 특수문자, 숫자 포함 8~20자 이내"
              value={pin}
              onKeyUp={e => checkCapsLock(e, setIsCapsLockOn1)}
              onChange={e => setPin(e.target.value)}
              required
            ></input>
                        {
                    isCapsLockOn1 &&
                    <div className="capslock__icon">
                      <span className="ir_pm">CapsLock</span>
                    </div>
                  }
            <button className="password__icon" onClick={() => setShowPin(!showPin)}>
              <span className="ir_pm">가리기</span>
            </button>
          </div>
            <h2 className="email-title">비밀번호 재입력</h2>
            <div className="login-password">
            <input
            autoComplete='off'
              type={showRePin ? "text" : "password"}
              className="login-input"
              placeholder="영문, 특수문자, 숫자 포함 8~20자 이내"
              value={rePin}
              onKeyUp={e => checkCapsLock(e, setIsCapsLockOn2)}
              onChange={e => setRePin(e.target.value)}
              required
            ></input>
            {
                    isCapsLockOn2 &&
                    <div className="capslock__icon">
                      <span className="ir_pm">CapsLock</span>
                    </div>
                  }
            <button className="password__icon" onClick={() => setShowRePin(!showRePin)}>
              <span className="ir_pm">가리기</span>
            </button>
          </div>
          </>
          )}
        </div>
        {active === 0 ?
          <div className="auth-next">
            <button onClick={next} className={codeLock ? "auth-next-button" : ''}>다음</button>
          </div>
          : <div className="auth-next">
          <button onClick={changePassword} className={codeLock ? "auth-next-button" : ''}>비밀번호 변경</button>
        </div>
        }

      </section>
    </div>
  )
}
export default RestorePassword
