import client from "../../index";

/**
 * 기관 검색 가져오기
  * @param {number} currentPage 현재 페이지
  * @param {number} row 요청 갯수
  * @param {Stirng} search 검색
*/
const getGalleryList = async(currentPage, row, search) => {
  const setData = {
    page : currentPage,
    count : row,
    search: search
  }
    const res = await client.json.get('/user/agency', {params: setData});
    return res.data;
}

/**
 * 기관 디테일
 * @param {number} pk 해당 작가 user_id
*/
const getDetail = async(pk) => {

  const res = await client.json.get(`/user/agency/${pk}`);
  return res.data;
}


/**
 * 기관 하트
  * @param {number} pk 기관 pk
*/
const setLike = async(pk) => {
  const res = await client.json.put(`/user/agency/heart/${pk}`,);
  return res.data;
}




const publicGallery = {
  getGalleryList, getDetail, setLike
}

export default publicGallery
