import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

import auth from '../../services/auth/auth'

import { useDispatch } from "react-redux"
import * as authAction from '../../store/reducers/auth/actions'

import swal from 'sweetalert'
import { checkCapsLock } from '../../hook/utils/checkCapsLock'
import { passCheck } from '../../hook/utils/validation'
import './Login.css'
import LoginWithGoogle from './platform/GoogleLogin'
import LoginWithKakao from './platform/KakaoLogin'
import LoginWithNaver from './platform/NaverLogin'

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isCapsLockOn, setIsCapsLockOn] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState('');
  const [save, setSave] = React.useState(true);

  React.useEffect(() => {
    localStorage.removeItem('QART_ACCESS')
    dispatch(authAction.LOGOUT())
  },[])

  React.useEffect(() => {
    const savedEmail = localStorage.getItem('QART_EMAIL');

    if(savedEmail === null){
      setSave(false)
      return
    }

    setEmail(savedEmail)
  },[])


  const login = async() => {

    if(email === ''){
      swal('이메일을 입력해주세요.')
      return
    }

    if(email.indexOf('artsynapse.art') === -1){
      if(!passCheck(password)){
        return;
      }
    }
    
    if(password === ''){
      swal('비밀번호를 입력해주세요.')
      return
    }

    const res = await auth.login(email, password);

    if(!res.resultCode){

      if(res.available === 2){
        swal('탈퇴한 계정입니다.')
        return;
      }
      if(res.message === 'not local'){
        const type = res.type === 'NAVER' ? "'네이버'" : res.type === 'GOOGLE' ? "'구글'" : "'카카오'";

        swal(`해당 이메일은 ${type}로 가입되어있습니다.\n아래에 ${type}버튼으로 로그인해주세요.`);
        setPassword('');
        return;
      }

      swal("이메일 또는 비밀번호를 잘못 입력했습니다.");
      setPassword('')
      return
    }
    
    dispatch(authAction.LOGIN());
    dispatch(authAction.SET_TYPE(res.type));
    if(save){
      localStorage.setItem('QART_EMAIL', email);
    }else{
      localStorage.removeItem('QART_EMAIL')
    }
    localStorage.setItem('QART_ACCESS', res.value);
    getInfo(res.type)

    if(res.email_need){
      return navigate('/user/take-over/account', {state : {
       need: res.need 
      }});
    }
    if(res.need){
      return navigate('/user/take-over/password');
    }
    return navigate('/');
  }

  const getInfo = async (type) => {
    let res;
    if(type === 2){
      res = await auth.getAgencyInfo()
      dispatch(authAction.SET_GALLERY_INFO(res))
    }else{
      res = await auth.getArtistInfo()
      dispatch(authAction.SET_ARTIST_INFO(res.data))
    }
  }


  return (
    <div className="login-main">
      <Header detail={true} />
      <div className="container login-container">
        <h1>로그인</h1>
        <section className="login-form">
          <input
          autoComplete='off'
            className="login-input"
            placeholder="이메일 주소"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          ></input>
          <div className="login-password">
            <input
            autoComplete='off'
              type={showPassword ? "text" : "password"}
              className="login-input"
              placeholder="비밀번호"
              value={password}
              onKeyUp={e => checkCapsLock(e, setIsCapsLockOn)}
              onChange={e => setPassword(e.target.value)}
              required
            ></input>
            {
              isCapsLockOn &&
            <div className="capslock__icon">
              <span className="ir_pm">CapsLock</span>
            </div>
            }
            <button className="password__icon" onClick={() => setShowPassword(!showPassword)}>
              <span className="ir_pm">가리기</span>
            </button>
          </div>
          <label className="login-option">
            <input autoComplete='off' type="checkbox" checked={save} onChange={e => setSave(e.target.checked)} />
            <span className="login-option__check" />
            아이디 저장
          </label>

          <button className="login-button" onClick={login}>로그인</button>
          <div className="login-more">
            <Link to="/restore">아이디/비밀번호 찾기</Link>
            <span></span>
            <Link to="/signUp" state={{ type: 'LOCAL' }}>회원가입</Link>
          </div>
        </section>
        <section className="login-link">
          <LoginWithGoogle />
          <LoginWithKakao />
          <LoginWithNaver />
        </section>
      </div>
      <Footer />
    </div>
  )
}
export default Login
