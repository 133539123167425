
export const apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEZhNWM1NTI5YTU3NkVEZTg4NjE5QzQ4NjM1ODBhZEMzMTk4MTIyMWQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY4MzEzNzIyMTI2NiwibmFtZSI6IlFhcnRfZGV2In0.8WUmFA0JYU-A6VIW-8GLkm8IdSTmdZEybUL9jZMMzUc' // <GIT_HUBE> man

const mainNet = {
   chainName: 'Polygon Mainnet',
   chainId: 137,
   symbol: 'MATIC',
   decimals: 18,
   rpc: 'https://polygon-rpc.com',
   block: 'https://polygonscan.com',
   contractAddress: '',
   abi: ''
}

const testNet = {
   chainName: 'Mumbai',
   chainId: 80001,
   symbol: 'MATIC',
   decimals: 18,
   rpc: 'https://polygon-mumbai-pokt.nodies.app',
   block: 'https://mumbai.polygonscan.com',
   contractAddress: '0x0ea7aC1Cd3b1ff28DcF3DF7Fa28db3E4ff707D1C',
   abi: [
      {
         "inputs": [],
         "stateMutability": "nonpayable",
         "type": "constructor"
      },
      {
         "anonymous": false,
         "inputs": [
            {
               "indexed": true,
               "internalType": "address",
               "name": "owner",
               "type": "address"
            },
            {
               "indexed": true,
               "internalType": "address",
               "name": "approved",
               "type": "address"
            },
            {
               "indexed": true,
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            }
         ],
         "name": "Approval",
         "type": "event"
      },
      {
         "anonymous": false,
         "inputs": [
            {
               "indexed": true,
               "internalType": "address",
               "name": "owner",
               "type": "address"
            },
            {
               "indexed": true,
               "internalType": "address",
               "name": "operator",
               "type": "address"
            },
            {
               "indexed": false,
               "internalType": "bool",
               "name": "approved",
               "type": "bool"
            }
         ],
         "name": "ApprovalForAll",
         "type": "event"
      },
      {
         "anonymous": false,
         "inputs": [
            {
               "indexed": true,
               "internalType": "address",
               "name": "previousOwner",
               "type": "address"
            },
            {
               "indexed": true,
               "internalType": "address",
               "name": "newOwner",
               "type": "address"
            }
         ],
         "name": "OwnershipTransferred",
         "type": "event"
      },
      {
         "anonymous": false,
         "inputs": [
            {
               "indexed": true,
               "internalType": "address",
               "name": "from",
               "type": "address"
            },
            {
               "indexed": true,
               "internalType": "address",
               "name": "to",
               "type": "address"
            },
            {
               "indexed": true,
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            }
         ],
         "name": "Transfer",
         "type": "event"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "to",
               "type": "address"
            },
            {
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            }
         ],
         "name": "approve",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "owner",
               "type": "address"
            }
         ],
         "name": "balanceOf",
         "outputs": [
            {
               "internalType": "uint256",
               "name": "",
               "type": "uint256"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            }
         ],
         "name": "getApproved",
         "outputs": [
            {
               "internalType": "address",
               "name": "",
               "type": "address"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "owner",
               "type": "address"
            },
            {
               "internalType": "address",
               "name": "operator",
               "type": "address"
            }
         ],
         "name": "isApprovedForAll",
         "outputs": [
            {
               "internalType": "bool",
               "name": "",
               "type": "bool"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "string",
               "name": "tokenURI",
               "type": "string"
            }
         ],
         "name": "mintNFT",
         "outputs": [
            {
               "internalType": "uint256",
               "name": "",
               "type": "uint256"
            }
         ],
         "stateMutability": "nonpayable",
         "type": "function"
      },
      {
         "inputs": [],
         "name": "name",
         "outputs": [
            {
               "internalType": "string",
               "name": "",
               "type": "string"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [],
         "name": "owner",
         "outputs": [
            {
               "internalType": "address",
               "name": "",
               "type": "address"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            }
         ],
         "name": "ownerOf",
         "outputs": [
            {
               "internalType": "address",
               "name": "",
               "type": "address"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [],
         "name": "renounceOwnership",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "from",
               "type": "address"
            },
            {
               "internalType": "address",
               "name": "to",
               "type": "address"
            },
            {
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            }
         ],
         "name": "safeTransferFrom",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "from",
               "type": "address"
            },
            {
               "internalType": "address",
               "name": "to",
               "type": "address"
            },
            {
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            },
            {
               "internalType": "bytes",
               "name": "data",
               "type": "bytes"
            }
         ],
         "name": "safeTransferFrom",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "operator",
               "type": "address"
            },
            {
               "internalType": "bool",
               "name": "approved",
               "type": "bool"
            }
         ],
         "name": "setApprovalForAll",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "bytes4",
               "name": "interfaceId",
               "type": "bytes4"
            }
         ],
         "name": "supportsInterface",
         "outputs": [
            {
               "internalType": "bool",
               "name": "",
               "type": "bool"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [],
         "name": "symbol",
         "outputs": [
            {
               "internalType": "string",
               "name": "",
               "type": "string"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "uint256",
               "name": "index",
               "type": "uint256"
            }
         ],
         "name": "tokenByIndex",
         "outputs": [
            {
               "internalType": "uint256",
               "name": "",
               "type": "uint256"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "owner",
               "type": "address"
            },
            {
               "internalType": "uint256",
               "name": "index",
               "type": "uint256"
            }
         ],
         "name": "tokenOfOwnerByIndex",
         "outputs": [
            {
               "internalType": "uint256",
               "name": "",
               "type": "uint256"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            }
         ],
         "name": "tokenURI",
         "outputs": [
            {
               "internalType": "string",
               "name": "",
               "type": "string"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [],
         "name": "totalSupply",
         "outputs": [
            {
               "internalType": "uint256",
               "name": "",
               "type": "uint256"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "from",
               "type": "address"
            },
            {
               "internalType": "address",
               "name": "to",
               "type": "address"
            },
            {
               "internalType": "uint256",
               "name": "tokenId",
               "type": "uint256"
            }
         ],
         "name": "transferFrom",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      },
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "newOwner",
               "type": "address"
            }
         ],
         "name": "transferOwnership",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      }
   ]
}


export const web3Net = testNet;