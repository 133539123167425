import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar'
import './Header.css'

import { useDispatch } from "react-redux"
import * as authAction from '../../store/reducers/auth/actions'
import swal from 'sweetalert'
const Header = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login, type } = useSelector(v => v.auth, shallowEqual);
  
  const [isOpen, setOpen] = useState(false)
  const [xPosition, setX] = useState(-243)
  const side = useRef()


  useEffect(() => {
    window.addEventListener('click', handleClose)
    return () => {
      window.removeEventListener('click', handleClose)
    }
  })


  // button 클릭 시 토글
  const toggleMenu = () => {
    if (xPosition < 0) {
      setX(0)
      setOpen(true)
    } else {
      setX(-243)
      setOpen(false)
    }
  }

  // 사이드바 외부 클릭시 닫히는 함수
  const handleClose = async e => {
    let sideArea = side.current
    let sideCildren = side.current.contains(e.target)
    if (isOpen && (!sideArea || !sideCildren)) {
      setX(-243)
      setOpen(false)
    }
  }
  
  const logout = () => {
    swal('로그아웃 되었습니다.')
    localStorage.removeItem('QART_ACCESS')
    dispatch(authAction.LOGOUT())
  }

  const goMyPage = () => {
    switch(type){
      case 0:
        return navigate('/mypage/general')
        case 1:
        return navigate('/mypage/artist')
        case 2:
        return navigate('/mypage/gallery')
      default:
        return
    }
  }

  
  return (
    <header className={props.colored} ref={side}>
      <div className="container header-container">
        {props.detail ? (
          <div className="header__before">
            <a onClick={() => navigate(-1)}>
              <span className="before-icon"></span>
            </a>
          </div>
        ) : (
          <div className="header__before"></div>
        )}
        <div className="header__logo">
          <Link to="/">
            <span className="none">Qart</span>
          </Link>
        </div>
        <ul className="header__menu">
          <li>
            <Link className={props.active === '0' ? 'active' : ''} to="/">
              Art Database
            </Link>
          </li>
          <li>
            <Link className={props.active === '1' ? 'active' : ''} to="/qcoa/">
              Q-CoA
            </Link>
          </li>
          <li>
            <Link className={props.active === '2' ? 'active' : ''} to="/market">
              Marketplace
            </Link>
          </li>
          <li>
            <Link className={props.active === '3' ? 'active' : ''} to="/support/">
              Support
            </Link>
          </li>
        </ul>
        <div className="header__padding">
          {login ? (
            <div className="header-logined">
              <span className="mypage" onClick={goMyPage} style={{cursor: 'pointer'}}>마이페이지</span>
              <span className="mypage-sym">|</span>
              <Link className="logout" to="/login" onClick={logout}>
                <span>Log-out</span>
              </Link>
            </div>
          ) : (
            <Link className="login" to="/login">
              <span>Log-in</span>
            </Link>
          )}

          <Link className={props.active === '0' ? 'active' : ''} to="/search">
            <span className="search-icon ir_pm">검색</span>
          </Link>
          <button
            className={isOpen ? 'logout-button-active' : 'logout-button'}
            to="#"
            onClick={() => toggleMenu()}
          >
            <span className="bar ir_pm">더보기</span>
          </button>
        </div>
      </div>
      {isOpen ? <Sidebar active={props.active}></Sidebar> : <></>}
    </header>
  )
}
export default Header
