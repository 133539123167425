import swal from 'sweetalert';
import work from "../../services/myArchive/work/work";

export const checkWorkCountByMembership = async (to) => {

    const res = await work.getWorkCount();
    const own = res.count;

    if (own <= 10) {
        return true;
    }

    switch (to) {
        case 'STARTER':
            if (own > 10) {
                // swal('현재 등록된 작품이 10점 이상입니다.\n스타터 맴버십사용이 불가능합니다.');
                swal('스타터 회원은 10점까지 작품 등록이 가능합니다.\n아카이브에 등록된 데이터를 변경 후 재시도해 주세요.');
                return false;
            }
            return true;
        case 'BASIC':
            if (own > 30) {
                // swal('현재 등록된 작품이 30점 이상입니다.\n베이직 맴버십사용이 불가능합니다.');
                swal('베이직 회원은 30점까지 작품 등록이 가능합니다.\n아카이브에 등록된 데이터를 변경 후 재시도해 주세요.');
                return false;
            }
            return true;
        case 'PREMIUM':
            if (own > 100) {
                // swal('현재 등록된 작품이 100점 이상입니다.\n프리미엄 맴버십사용이 불가능합니다.');
                swal('프리미엄 회원은 100점까지 작품 등록이 가능합니다.\n아카이브에 등록된 데이터를 변경 후 재시도해 주세요.');
                return false;
            }
            return true;
        default:
            swal('알 수 없는 값입니다.');
            return false;
    }

};