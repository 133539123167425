import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropDown from '../../../../../components/DropDown/DropDown';
import Footer from '../../../../../components/Footer/Footer';
import Header from '../../../../../components/Header/Header';
import { checkDate, emailCheck } from '../../../../../hook/utils/validation';
import auth from '../../../../../services/auth/auth';
import setting from '../../../../../services/setting';


import './Cert.css';
import swal from 'sweetalert';

function Cert() {

  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const { phoneList } = useSelector(v => v.setting, shallowEqual)
  const { artist, gallery, type } = useSelector(v => v.auth,shallowEqual)

  const [kycToken, setKycToken] = React.useState('')
  const navigate = useNavigate();
  const [doEKyc, setDoEKyc] = React.useState(false)

  const [email, setEmail] = React.useState(type === 2 ? gallery.email : artist.email)

  const [firstName, setFirstName] = React.useState('')
  const [name, setName] = React.useState('')
  const [birth, setBirth] = React.useState('')


  const [locale, setLocale] = React.useState('+82')
  const [phone, setPhone] = React.useState('')

  const [postData, setPostData] = React.useState(null);
  const [success, setSuccess] = React.useState(false)

  const iframeRef = React.useRef(null);
  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);


  React.useEffect(() => {
    getToken()
  }, [])

  React.useEffect(() => {
    window.addEventListener("message", e => { action(e) })
    return () => {
      window.removeEventListener("message", e => { action(e) })
    };
  }, [])

  const action = async (e) => {

    if (e.origin === "https://kyc.useb.co.kr") {
      const decodedData = decodeURIComponent(atob(e.data));
      const json = JSON.parse(decodedData);

      // iframe 닫을시
      if (json.result === 'close' || json.result === 'complete') {
        setDoEKyc(false)
        // navigate(-1)
      }

      //인증 완료시
      if (json.result === 'success') {
        const data = json.review_result;
        // console.log(data);
        // const idCard1 = data.id_card.id_card_image; // 주민 뒷자리 가림(base64)
        // const idCard2 = data.id_card.id_card_origin; // 주민 원본(base64)
        // const idCard3 = data.id_card.id_crop_image; // id 카드에 있는 얼굴(base64)
        // const userFace = data.face_check.selfie_image; //idCard3이랑 비교했던 사용자 얼굴(base64)

        const accountCompany = data.account.finance_company; // 은행
        const accountNumber = data.account.account_number; // 계좌번호

        const myJson = {
          ACCOUNT_COMPANY: accountCompany,
          ACCOUNT_NUMBER: accountNumber
        }
        setPostData(myJson)
        setSuccess(true)
      }
      return
    }

  }

  const getToken = async () => {
    const res = await setting.getToken()
    setKycToken(res)
  }

  const doKyc = async (e) => {
    e.preventDefault();


    if (success) {
      const myData = {
        EMAIL: e.target.email.value,
        FIRST_NAME: e.target.firstName.value,
        NAME: e.target.name.value,
        BIRTHDAY: e.target.birth.value,
        PHONE_NUMBER: e.target.phone.value,
        COUNTRY_NUMBER: locale,
        ...postData
      }

      
      const res = await auth.afterCertifiedKYC(myData)
      if (!res.resultCode) {
        swal('서버오류')
        return
      }

      // dispatch(authAction.SET_KYC(type));
      swal('e-KYC 인증이 완료되었습니다.')

      switch(type){
        case 0 :
          navigate('/mypage/general');
          break;
          case 1 :
          navigate('/mypage/artist');
          break;
          case 2 :
          navigate('/mypage/gallery');
          break;
          default:
            break;
      }
      return
    }

    if (!emailCheck(email)) return
    // if (phone.length < 8) {
    //   swal('정확한 휴대폰 번호를 입력해주세요.')
    //   return
    // }

    setDoEKyc(true)

    // 아이프레임
    const KYC_TARGET_ORIGIN = "https://kyc.useb.co.kr";
    const KYC_URL = "https://kyc.useb.co.kr/auth";

    const params = {
      "access_token": kycToken,
      "name": `${firstName}${name}`,
      "birthday": birth,
      "phone_number": phone.toString().replaceAll('-', ''),
      "email": email
    };

    const kycIframe = iframeRef.current;
    kycIframe.onload = function () {
      let encodedParams = btoa(encodeURIComponent(JSON.stringify(params)));
      kycIframe.contentWindow.postMessage(encodedParams, KYC_TARGET_ORIGIN);
      // eslint-disable-next-line no-undef
      hideLoadingUI();
      // eslint-disable-next-line no-undef
      startKYC();
      kycIframe.onload = null;
    };
    kycIframe.src = KYC_URL;
  }


  return (
    <div className="cert">
      <Header />

      <div style={{ width: '100%', height: '80vh', display: doEKyc ? 'block' : 'none' }}>
        <iframe
          title='kyc'
          allow="camera"
          ref={iframeRef}
          width="100%" height="100%"
        />
      </div>

      <div className="container cert-container" style={{ display: !doEKyc ? 'flex' : 'none' }}>

        <div className="cert-content" style={{ width: matches ? '320rem' : '596rem', height: matches ? '240rem' : '359rem', marginTop: 10 }}>

          <>
          <h1>
            <span className='blue' style={{ fontSize: matches ? '20rem' : '32rem' }}>
              KYC 인증
            </span>
          </h1>
          <p>
                {/* <h3 style={{textAlign: 'center'}}>큐아트는 누구에게나 열린 플랫폼입니다.</h3><br/> */}
                큐아트는 누구에게나 열린 플랫폼입니다.<br />
                <br />
                아트 아카이브에 신뢰성 있는 정보를 등록하고,<br />
                거래 이력 증명서를 발행하고,<br />
                마켓플레이스를 통한 안전한 작품 거래와 정산을 위해서는<br />
                금융위원회에서 지정한 비대면 실명확인 가이드 라인을 충족하는<br />
                사용자의 신원인증(e-kyc)이 필요합니다.<br />
                <br />
                큐아트는 금융위원회의 지정 비대면 실명확인 가이드라인 중<br />
                1. 신분증 사본 제출(신분증 OCR + 진위확인)<br />
                2. 계좌 활용(1원 계좌인증)<br />
                을 통한 실명확인(e-kyc인증)을 진행합니다.<br />
                <br />
                1회의 인증 후에는<br />
                안전한 플랫폼 환경에서 자유롭고 편안하게<br />
                서비스를 사용하실 수 있습니다.<br />
              </p>
          {/* <p>
            *KYC(Know Your Customer) 인증은 가입자가 온라인으로 제출하신 신분증
            및 자격증을 제 3 인증기관을 통해 진위여부를 확인하는 기술입니다.
            <br />
            <br />
            *KYC (Know Your Customer) verification is a technology that
            verifies the authenticity of the ID and license submitted by the
            subscriber online through a third-party certification authority.
            <br />
          </p> */}
          </>
          
        </div>


        <form onSubmit={e => doKyc(e)} style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="cert-edit-mb" >
            <input
              id='email'
              autoComplete='off'
              className="login-input"
              style={{ height: matches ? "38rem" : "72rem" }}
              placeholder="이메일"
              value={email}
              readOnly={success}
              onChange={e => setEmail(e.target.value)}
              required
            ></input>
            <input
              id='firstName'
              autoComplete='off'
              className="login-input"
              style={{ height: matches ? "38rem" : "72rem" }}
              placeholder="성"
              value={firstName}
              readOnly={success}
              onChange={e => setFirstName(e.target.value)}
              required
            ></input>
            <input
              id='name'
              autoComplete='off'
              className="login-input"
              style={{ height: matches ? "38rem" : "72rem" }}
              placeholder="이름"
              value={name}
              readOnly={success}
              onChange={e => setName(e.target.value)}
              required
            ></input>
            <input
              id='birth'
              autoComplete='off'
              className="login-input"
              style={{ height: matches ? "38rem" : "72rem" }}
              data-placeholder={success ? birth : matches ? "" : "생년월일 (YYYY-MM-DD)"}
              value={birth}
              type='date'
              min={min}
              max={today}
              readOnly={success}
              onChange={e => checkDate(setBirth, e)}
              required
            ></input>
            <div className="ap-drop" style={ {width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <DropDown id='locale' disabled={success} myClass="my__drop" select={phoneList.indexOf(locale)} options={phoneList} setValue={setLocale} />
              <input
                id='phone'
                autoComplete='off'
                className="login-input"
                style={{ border: '1rem solid #cccccc', width: matches ? "200rem" : '445rem', height: matches ? "38rem" : "68rem", margin: 0}}
                placeholder="휴대폰번호 (010-0000-0000)"
                value={phone}
                type="text"
                onKeyPress={(e) => {
                  // 정규표현식 /[0-9]/ 이 아니면 press X
                  if (/[^0123456789-]/g.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                // maxLength={15}
                // onInput={(e) => {
                //   if (e.target.value.length > e.target.maxLength)
                //     e.target.value = e.target.value.slice(0, e.target.maxLength);
                // }}
                readOnly={success}
                onChange={e => setPhone(e.target.value)}
                required
              ></input>
            </div>
            {
              success
                ? <button className="cert-button" type='submit' style={{ marginTop: 18, marginBottom: 18, }} >인증 완료</button>  
                : <div style={{display: 'flex', gap: '10rem'}}>
                    {/* <button className="cert-button" type='button' onClick={nav}style={{ marginTop: 18, marginBottom: 18, background: '#cccccc' }}>Skip</button> */}
                    <button className="cert-button" type='submit' style={{ marginTop: 18, marginBottom: 18, }} >KYC 인증 진행하기</button>
                  </div>
            }
          </div>
        </form>


      </div>
      <Footer />
    </div>
  )
}
export default Cert
