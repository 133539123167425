export const FIRST_SCAN = 'FIRST_SCAN'; // 1단계 작품 QR 저장
export const FIRST_PASS = 'FIRST_PASS'; // 1단계 간편 패스워드 저장
export const FIRST_RETURN = 'FIRST_RETURN'; // 1단계 토큰 저장


export const SECOND_SCAN = 'SECOND_SCAN'; // 2단계 유저 QR 저장
export const SECOND_SALE_ALLOW = 'SECOND_SALE_ALLOW'; // 2단계에 필요한 데이터 저장 (대여 판매 승인)
export const SECOND_DATE = 'SECOND_DATE'; // 2단계에 필요한 데이터 저장 (대여 기간)
export const SECOND_PRICE = 'SECOND_PRICE'; // 2단계에 필요한 데이터 저장 (판매 가격)
export const SECOND_RETURN = 'SECOND_RETURN'; // 2단계 토큰 저장

export const CLEAR = 'CLEAR'; // 값 초기화

