
import client from '../index'

/**
 * 내 아티클 전체 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {Stirng} search 검색
*/
const getMyList = async(currentPage, row, search) => {
  const setData = {
    page : currentPage,
    count : row,
    search: search
  }
    const res = await client.json.get('/user/work/my-article', {params: setData});
    return res.data;
}

/**
  아티클 자세히 보기
   * @param {number} pk pk
*/
const getDetail = async(pk) => {
  const res = await client.json.get(`/user/work/my-article/${pk}`);
  return res.data;
}


/**
  아티클 삭제
   * @param {number} pk pk
*/
  const deleteArticle = async(pk) => {
  const res = await client.json.delete(`/user/work/my-article/${pk}`);
  return res.data;
}

/**
  아티클 추가
   * @param {File} main_image 메인이미지
   * @param {FileArray} sub_image 서브이미지 최대 10개
   * @param {String} title 자료명
   * @param {String} date 발행일
   * @param {String} country 국가
   * @param {String} source 출처
   * @param {String} producer 발행 회사
   * @param {String} publisher 발행자
   * @param {String} reference 상세 설명
*/
const addArticle = async(main_image, sub_image, title, date, country, source, producer, publisher, reference) => {
    
    const formData = new FormData();
    formData.append("MAIN_IMAGE", main_image); 
    for(let i = 0; i < sub_image.length; i ++){
      formData.append("SUB_IMAGE", sub_image[i]); 
    }
    formData.append("TITLE", title); 
    formData.append("DATE", date); 
    formData.append("COUNTRY", country); 
    formData.append("SOURCE", source); 
    formData.append("PRODUCER", producer); 
    formData.append("PUBLISHER", publisher); 
    formData.append("REFERENCE", reference);
    
    const res = await client.formData.post(`/user/work/my-article`, formData);
    return res.data;
}

/**
  아티클 수정
   * @param {number} pk 아티클 pk
   * @param {File || ''} main_image 메인이미지
   * @param {FileArray} newSubImage 새로 추가한 서브이미지
   * @param {String} oldSubImage 삭제한 서브 이미지
   * @param {String} title 자료명
   * @param {String} date 발행일
   * @param {String} country 국가
   * @param {String} source 출처
   * @param {String} producer 발행 회사
   * @param {String} publisher 발행자
   * @param {String} reference 상세 설명
*/
const editArticle = async(pk, main_image, newSubImage, oldSubImage, title, date, country, source, producer, publisher, reference) => {
    
  const formData = new FormData();
  formData.append("PK", pk); 
  formData.append("MAIN_IMAGE", main_image); 
  if(newSubImage.length === 0){
    formData.append("NEW_SUB", '');
  } else {
    for(let i = 0; i < newSubImage.length; i++){
      formData.append("NEW_SUB", newSubImage[i]);
    } 
  }
  formData.append("OLD_SUB", oldSubImage);
  formData.append("TITLE", title); 
  formData.append("DATE", date); 
  formData.append("COUNTRY", country); 
  formData.append("SOURCE", source); 
  formData.append("PRODUCER", producer); 
  formData.append("PUBLISHER", publisher); 
  formData.append("REFERENCE", reference);
  
  const res = await client.formData.post(`/user/work/my-article/edit`, formData);
  return res.data;
}


const article = {
    getMyList, getDetail, deleteArticle,
    addArticle, editArticle
}
export default article
