import React from 'react'
import { Link } from 'react-router-dom'
import WorkHeader from '../WorkHeader/WorkHeader'
import MarketHeader from '../../pages/Marketplace/Header/MarketHeader'
import './ArtTopic.css'
import { handleImgError } from '../../services/imageRoute'

function ArtTopic(props) {
  return (
    <section className="art-topic">
      { props.market ? (
        <MarketHeader title_e={props.title_e} title_k={props.title_k}></MarketHeader>
      ) : (
        <WorkHeader title_e={props.title_e} title_k={props.title_k}></WorkHeader>
      )

      } 
      <section className="container art-topic-content">
        {
          props.items.length >= 1 &&
          <Link
          to={`${props.items[0].link}/${props.items[0].id}`}
          className="art-topic-item"
        >
          <img
            alt=''
            src={props.items[0].info}
            className="art-topic--main"
            onError={handleImgError}
            onContextMenu={
              (e) => {
                  e.preventDefault();
                  
              }
            }
           />
          {props.hover ? (
            <div className="art-topic__hover">
              
              <h2 className="art-topic-item--author">
                {props.items[0].author}
              </h2>
              <h2 className="art-topic-item--author">
                {props.items[0].title}
              </h2>
              <h2 className="art-topic-item--title">
                {props.items[0].created}
              </h2>
              <h1 className="art-topic-item--price">{props.items[0].price}</h1>
            </div>
          ) : (
            <></>
          )}
        </Link>
        }
        <div className="art-topic--sub">
        {
          props.items.length >= 2 &&
          <Link
            to={`${props.items[1].link}/${props.items[1].id}`}
            className="art-topic-item"
          >
            <img
            alt=''
            src={props.items[1].info}
            className="art-topic--main"
            onError={handleImgError}
            onContextMenu={
              (e) => {
                  e.preventDefault();
                  
              }
            }
           />
            {props.hover ? (
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[1].author}
                </h2>
                <h2 className="art-topic-item--author">
                {props.items[1].title}
              </h2>
                <h2 className="art-topic-item--title">
                  {props.items[1].created}
                </h2>
                <h1 className="art-topic-item--price">
                  {props.items[1].price}
                </h1>
              </div>
            ) : (
              <></>
            )}
          </Link>
        }
        {
          props.items.length >= 3 &&
          <Link
            to={`${props.items[2].link}/${props.items[2].id}`}
            className="art-topic-item"
          >
            <img
            alt=''
            src={props.items[2].info}
            className="art-topic--main"
            onError={handleImgError}
            onContextMenu={
              (e) => {
                  e.preventDefault();
                  
              }
            }
           />
            {props.hover ? (
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[2].author}
                </h2>
                <h2 className="art-topic-item--author">
                {props.items[2].title}
              </h2>
                <h2 className="art-topic-item--title">
                  {props.items[2].created}
                </h2>
                <h1 className="art-topic-item--price">
                  {props.items[2].price}
                </h1>
              </div>
            ) : (
              <></>
            )}
          </Link>
        }
          
        </div>
      </section>
    </section>
  )
}
export default ArtTopic
