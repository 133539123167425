import client from '../index'

/**
 * 학력 정보 조회
*/
const school = async () => {
  const res = await client.json.get('/user/edu');
  return res.data;
}

/**
 * 학력 정보 추가
 * @param {String} school 학교
 * @param {Stirng} country 국가
 * @param {Stirng} admission 입학시기
 * @param {Stirng} graduated 졸업시기
 * @param {Stirng} degreeType 학위 종류
 * @param {Stirng} etc note
 * @param {number} status 0 재학중, 1 수료, 2 졸업
*/
const addSchool = async (school, country, admission,
                         graduated, degreeType, etc, status) => {
  const setData = {
    SCHOOL: school,
    COUNTRY: country,
    ADMISSION: admission,
    // GRADUATED: '2023-05-10',
    GRADUATED: graduated,
    DEGREE_TYPE: degreeType,
    ETC: etc,
    STATUS: status

  }

  // console.log(`addSchool :: ${JSON.stringify(setData)}`)
  const res = await client.json.post('/user/edu', setData);
  // console.log(`addSchool :: ${JSON.stringify(res.data)}`)
  return res.data;
}

/**
  학력 정보 삭제
   * @param {number} pkDelete pk
*/
const deleteSchool = async (pkDelete) => {
  const res = await client.json.delete(`/user/edu/${pkDelete}`);
  return res.data;
}

/**
 * 학력 정보 수정
 * @param {String} school 학교
 * @param {Stirng} country 국가
 * @param {Stirng} admission 입학시기
 * @param {Stirng} graduated 졸업시기
 * @param {Stirng} degreeType 학위 종류
 * @param {Stirng} etc note
 * @param {number} status 0 재학중, 1 수료, 2 졸업
 * @param {number} pkChange pk
*/
const changeSchool = async (school, country, admission, graduated, degreeType, etc, status, pkChange) => {
  const setData = {
    SCHOOL: school,
    COUNTRY: country,
    ADMISSION: admission,
    GRADUATED: graduated,
    DEGREE_TYPE: degreeType,
    ETC: etc,
    STATUS: status
  }
  const res = await client.json.patch(`/user/edu/${pkChange}`, setData);

  return res.data;
}

/**
 * 기관 이력 확인
*/
const agency = async () => {
  const res = await client.json.get('/user/agency/my-agency');
  return res.data;
}

/**
* 기관 이력 추가
* @param {Stirng} alias 회사명
* @param {Stirng} manager 대표자
* @param {Stirng} updatedat 변경 날짜
* @param {String} address 주소
* @param {String} addressDetail 상세 주소
*/
const addAgency = async (alias, manager, updatedat, address, addressDetail) => {
  const setData = {
    ALIAS: alias,
    MANAGER: manager,
    UPDATEDAT: updatedat,
    ADDRESS: address,
    ADDRESS1: addressDetail
  }
  const res = await client.json.post('/user/agency/my-agency', setData);
  return res.data;
}

/**
기관 이력 삭제
 * @param {number} pkDelete pk
*/
const deleteAgency = async (pkDelete) => {
  const res = await client.json.delete(`/user/agency/my-agency/${pkDelete}`);
  return res.data;
}

/**
* 기관 이력 수정
* @param {Stirng} alias 회사명
* @param {Stirng} manager 대표자
* @param {Stirng} updatedat 변경 날짜
* @param {String} address 주소
* @param {String} addressDetail 상세 주소
* @param {number} pkChange pk
*/
const changeAgency = async (alias, manager, updatedat, address, addressDetail, pkChange) => {
  const setData = {
    ALIAS: alias,
    MANAGER: manager,
    UPDATEDAT: updatedat,
    ADDRESS: address,
    ADDRESS1: addressDetail
  }
  const res = await client.json.patch(`/user/agency/my-agency/${pkChange}`, setData);
  return res.data;
}






/**
 * 기관 이력 확인 v2_
*/
const v2_agency = async () => {
  const res = await client.json.get('/user/agency/history');
  return res.data;
}

/**
* 기관 이력 추가 v2_
* @param {Stirng} content 내용
* @param {Stirng} date 일자
* @param {Stirng} detail 상세내용
*/
const v2_addAgency = async (content, date, detail) => {
  const setData = {
    CONTENT: content,
    DATE: date,
    DETAIL: detail,
  }
  const res = await client.json.post('/user/agency/history', setData);
  return res.data;
}

/**
기관 이력 삭제 v2_
 * @param {number} pkDelete pk
*/
const v2_deleteAgency = async (pkDelete) => {
  const res = await client.json.delete(`/user/agency/history/${pkDelete}`);
  return res.data;
}

/**
* 기관 이력 수정 v2_
* @param {Stirng} content 내용
* @param {Stirng} date 일자
* @param {Stirng} detail 상세내용
* @param {number} pkChange pk
*/
const v2_changeAgency = async (content, date, detail, pkChange) => {
  const setData = {
    CONTENT: content,
    DATE: date,
    DETAIL: detail,
  }
  const res = await client.json.patch(`/user/agency/history/${pkChange}`, setData);
  return res.data;
}





/**
 * 전시회 조회
*/

const exhibition = async () => {
  const res = await client.json.get('/user/my-exhibition');
  return res.data;
}


/**
  전시회 추가
   * @param {File} image 메인이미지
   * @param {String} title 전시명
   * @param {String} place 개최 장소
   * @param {String} country 국가
   * @param {String} type 전시 형태
   * @param {String} date 개최 일시
   * @param {String} end 폐막 일시
   * @param {String} publisher 주관자
   * @param {String} planner 기획자
   * @param {String} hoster 주최자
   * @param {String} reference 상세 설명
*/
const addExhibiton = async (image, title, place, country, type,
                            date, end, publisher, planner, hoster,
                            reference) => {

  const formData = new FormData();
  formData.append("IMAGE", image);
  formData.append("TITLE", title);
  formData.append("PLACE", place);
  formData.append("COUNTRY", country);
  formData.append("TYPE", type);
  formData.append("DATE", date);
  formData.append("END", end);
  formData.append("PUBLISHER", publisher);
  formData.append("REFERENCE", reference);
  // formData.append("WORK", work);
  formData.append("PLANNER", planner);
  formData.append("HOSTER", hoster);


  const res = await client.formData.post('/user/exhibition', formData);
  // console.log(`addExhibiton :: ${JSON.stringify(res.data)}`)
  return res.data;
}

/**
전시회 삭제
  * @param {number} deletepk
*/
const deleteExhibition = async (deletepk) => {
  const res = await client.json.delete(`/user/exhibition/${deletepk}`);
  return res.data;
}

/**
* 전시회 수정
 * @param {File} image 메인이미지
 * @param {String} title 전시명
 * @param {String} place 개최 장소
 * @param {String} country 국가
 * @param {String} type 전시 형태
 * @param {String} date 개최 일시
 * @param {String} end 폐막 일시
 * @param {String} publisher 주관자
 * @param {String} planner 기획자
 * @param {String} hoster 주최자
 * @param {String} reference 상세 설명
 * @param {String} work
 * @param {number} changepk
*/
const changeExhibition = async (image, title, place, country,
                                type, date, end,
                                publisher, planner, hoster,
                                reference, work, changepk) => {
  const formData = new FormData();
  formData.append("IMAGE", image);
  formData.append("TITLE", title);
  formData.append("PLACE", place);
  formData.append("COUNTRY", country);
  formData.append("TYPE", type);
  formData.append("DATE", date);
  formData.append("END", end);
  formData.append("PUBLISHER", publisher);
  formData.append("REFERENCE", reference);
  formData.append("WORK", work);
  formData.append("PK", changepk);
  formData.append("PLANNER", planner);
  formData.append("HOSTER", hoster);


  const res = await client.formData.post('/user/exhibition/edit', formData);
  return res.data;
}


/**
팀에 초대할 유저검색
 * @param {String} email 
*/
const searchTeam = async (email) => {
  const setData = {
    EMAIL: email
  }
  const res = await client.json.post('/user/search', setData);
  return res.data;
}
/**
유저검색
 * @param {String} email 
*/
const search = async (email) => {
  const setData = {
    email: email
  }
  const res = await client.json.post('/user/artist/emailCheck', setData);
  return res.data;
}

/**
팀원 추가
 * @param {String} email 
 * @param {String} job 역활
*/
const addTeam = async (email, job) => {
  const setData = {
    EMAIL: email,
    JOB: job
  }
  const res = await client.json.post('/user/my-team', setData);

  return res.data;
}
/**
팀원 삭제
 * @param {number} pk
*/
const deleteTeam = async (pk) => {

  const res = await client.json.delete(`/user/my-team/${pk}`);
  return res.data;
}

/**
팀 탈퇴
*/
const withdrawalTeam = async () => {

  const res = await client.json.delete(`/user/my-team`);
  return res.data;
}

/**
  팀원 리스트
*/
const getTeam = async () => {
  const res = await client.json.get('/user/my-team');

  return res.data;
}


/**
  구독 서비스
  * @param {String} date 1년 -> YEAR, 1달 -> MONTH
  * @param {String} type  BASIC / PREMIUM
*/
const subscribe = async (date, type) => {
  const postData = {
    DATE : date,
    TYPE : type
  }
  const res = await client.json.post('/user/level/upgrade', postData);

  return res.data;
}


const profile = {
  searchTeam, search, addTeam, getTeam, deleteTeam,withdrawalTeam,
  // 작가 학력정보 CRUD
  school, addSchool, deleteSchool, changeSchool,
  // 기관 이력 CRUD
  agency, addAgency, deleteAgency, changeAgency,
  v2_agency, v2_addAgency, v2_deleteAgency, v2_changeAgency,
  // 전시회 CRUD
  exhibition, addExhibiton, deleteExhibition, changeExhibition,

  subscribe
}
export default profile
