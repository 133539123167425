import React from 'react'
import swal from 'sweetalert'

import { Link, useNavigate } from 'react-router-dom'
import GalleryInfo from '../../../components/GalleryInfo/GalleryInfo'
import SearchSlide from '../../../components/SearchSlide/SearchSlide'
import BigTopArrow from '../../../components/TopArrowSlider/BigTopArrow/BigTopArrow'
import { handleImgError, newsImage, userImage } from '../../../services/imageRoute'
import publicArtist from '../../../services/public/artDB/publicArtist'
import './ArtDbArtist.css'


import { shallowEqual, useSelector } from 'react-redux'
import useMobileDetect from 'use-mobile-detect-hook'
import { checkUseName } from '../../../hook/utils/checkUseName'
import setting from '../../../services/setting'

import { KYCImageArtist } from '../../../components/KYC/artist'
import { LoadingState } from '../../../context/loadingStateProvider'

function ArtDbArtist() {
  const { login } = useSelector(v => v.auth, shallowEqual)
  const loadingState = React.useContext(LoadingState);

  const navigate = useNavigate()
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const detectMobile = useMobileDetect();

  const rows = 2
  const slidesPerRow = detectMobile.isMobile() ? 3 : 5

  const [items, setItems] = React.useState([])
  const [tableItem, setTableItem] = React.useState([])
  const [rowItem, setRowItem] = React.useState([])
  const [total, setTotal] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [checkLang, setCheckLang] = React.useState('ko');
  const [todayArtist, setTodayArtist] = React.useState([])
  const [qArtist, setQArtist] = React.useState([])

  const [newItem, setNewItem] = React.useState([])
  const [news, setNews] = React.useState([])


  React.useEffect(() => {
    getList(currentPage, rows * slidesPerRow, search)
  }, [currentPage, search])

  React.useEffect(() => {
    getTodayWorkList()
    getNewsList()
  }, [])

  const getList = async (currentPage, row, search) => {
    //loadingState.setText('잠시만 기다려주세요.');
    //loadingState.setOpen(true);
    const res = await publicArtist.getArtistList(currentPage, row, search)
    //loadingState.setOpen(false);
    //loadingState.setText('');
    setTotal(res.total)
    setItems(res.artist_list)
  }

  const getTodayWorkList = async () => {
    const res = await setting.getTodayList(10, 1)
    setTodayArtist(res.user_list)
  }

  const getNewsList = async () => {
    const res = await setting.getList(1)
    setNewItem(res.list)
  }

  React.useEffect(() => {
    if (todayArtist.length === 0) {
      return
    }

    const myItem = []

    // for (let i = 0; i < todayArtist.length; i++) {
    for (let i = 0; i < todayArtist.length; i++) {
      myItem.push(
        {
          pk: todayArtist[i].user_ID,
          status : todayArtist[i].status,
          info: `${userImage}/${todayArtist[i].picture}`,
          link: '/artdb/artist',
          title: checkUseName(lang, todayArtist[i].use_NAME, `${todayArtist[i].first_NAME}${todayArtist[i].name}`, `${todayArtist[i].first_ENG_NAME} ${todayArtist[i].eng_NAME}`, todayArtist[i].nickname, todayArtist[i].eng_NICKNAME),
          date: `${todayArtist[i].birthday === '' ||
            todayArtist[i].birthday === null
            ? '미상' : `${todayArtist[i].birthday.includes('-') ? todayArtist[i].birthday.split('-')[0] : todayArtist[i].birthday}년`
            } `,
        }
      )
      const shuffledArray = myItem.sort(() => Math.random() - 0.5);

      setQArtist(shuffledArray)
    }
  }, [todayArtist])


  const setLike = async (data, setData, pk) => {
    if (!login) {
      await swal('로그인이 필요한 서비스입니다.')
      navigate('/login', { replace: true })
      return
    }

    const res = await publicArtist.setLike(pk)
    if (!res.resultCode) {
      return
    }
    setData(
      data.map(
        it => it.user_ID === pk
          ? { ...it, heart: res.message === 'ADD' }
          : it
      )
    )
  }

  React.useEffect(() => {
    let myRowItem = []

    items.map((item, i) => {
      const checkKoName = `${item.first_NAME}${item.name}` !== '';
      const checkEnName = `${item.first_ENG_NAME}${item.eng_NAME}` !== '';
      myRowItem.push(
        <div key={`pageSlide_${i}`} className="page-slider__item">
          <div className="page-slider-item-row">
            <Link
              to={`/artdb/artist/${item.user_ID}`}
            >
              <div style={{ position: 'relative', zIndex: 1 }}>
                {
                  item.status === 2 &&
              <KYCImageArtist/>
                }
              <img
                className="page-item-image"
                alt='itemInfo'
                src={`${userImage}/${item.picture}`}
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
              </div>
            </Link>

            <div>
              {item.use_NAME === '0' ? (
                checkLang === 'ko' ?
                  <h2 className="page-item-author" style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {/* {`${item.first_NAME}${item.name}`} <span>{`${item.first_NAME}${item.name}` === `${item.first_ENG_NAME} ${item.eng_NAME}` ? "" : `${item.first_ENG_NAME} ${item.eng_NAME}`}</span> */}
                    {checkKoName ? `${item.first_NAME}${item.name}` : `${item.first_ENG_NAME} ${item.eng_NAME}`} <span>{`${item.first_NAME}${item.name}` === `${item.first_ENG_NAME} ${item.eng_NAME}` ? "" : checkKoName ? `${item.first_ENG_NAME} ${item.eng_NAME}` : ''}</span>
                  </h2> :
                  <h2 className="page-item-author" style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {/* {`${item.first_ENG_NAME} ${item.eng_NAME}`} <span>{`${item.first_NAME}${item.name}` === `${item.first_ENG_NAME} ${item.eng_NAME}` ? "" : `${item.first_NAME}${item.name}`}</span> */}
                    {checkEnName ? `${item.first_ENG_NAME} ${item.eng_NAME}`: `${item.first_NAME}${item.name}`} <span>{`${item.first_NAME}${item.name}` === `${item.first_ENG_NAME} ${item.eng_NAME}` ? "" : checkEnName ? `${item.first_NAME}${item.name}` : ''}</span>
                  </h2>
              ) : (
                <></>
              )}
              {item.use_NAME === '1' ? (
                checkLang === 'ko' ?
                <h2 className="page-item-author" style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {item.nickname === '' ? item.eng_NICKNAME : item.nickname} <span>{item.nickname === '' ? '' : item.nickname === item.eng_NICKNAME ? "" : item.eng_NICKNAME}</span>
                  </h2> :
                  <h2 className="page-item-author" style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {item.eng_NICKNAME === '' ? item.nickname: item.eng_NICKNAME} <span>{item.eng_NICKNAME === '' ? '' : item.nickname === item.eng_NICKNAME ? "" : item.nickname}</span>
                  </h2>
              ) : (
                <></>
              )}
              
              <div style={{ display: 'flex', flex: 'row', justifyContent: 'space-between', }}>
                {item.birthday ? (
                  <h3 className="page-item-email" style={{ paddingLeft: 0 }}>{item.birthday.includes('-') ? item.birthday.split('-')[0] : item.birthday}년</h3>
                ) : (
                  <></>
                )}
                <button onClick={() => setLike(items, setItems, item.user_ID)} className={item.heart ? "artist-item--heart_active" : "artist-item--heart"} style={{ marginTop: 4 }}>
                  <span className="ir_pm">좋아요</span>
                </button>
              </div>

              {/* <h3 className="page-item-email">{item.email}</h3> */}
              {/* <button className="page-item-button">팔로우</button> */}
            </div>
          </div>
        </div>
      )
    })
    setRowItem(myRowItem)
  }, [items])

  React.useEffect(() => {
    let colItem = []
    colItem.push(
      <div className="page-slider-my-row"
        style={{ display: 'flex', flexWrap: 'wrap' }}>
        {rowItem}
      </div>
    )
    setTableItem(colItem)
  }, [rowItem])

  React.useEffect(() => {
    if (newItem.length === 0) {
      return
    }

    const myItem = []
    for (let i = 0; i < newItem.length; i++) {
      const json = {
        pk: newItem[i].pk,
        info: `${newsImage}/${newItem[i].sumbnail}`,
        title: newItem[i].title,
        link: 'news',
        detail: newItem[i].content,
        detailM: newItem[i].content_M,
      }
      myItem.push(json)
    }
    setNews(myItem)

  }, [newItem])

  return (
    <div className="artdb-artist">
      <BigTopArrow
        title_e={'Today’s Artists'}
        title_k={'오늘의 작가'}
        items={qArtist}
        kyc={true}
      />
      <SearchSlide
        title_e={'Discover Artist'}
        title_k={'작가 탐방하기'}
        option_title={'작가명'}
        rows={rows}
        slidesPerRow={slidesPerRow}
        items={tableItem}
        breakRows={3}
        breakSlidesPerRow={3}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setCheckLang={setCheckLang}
        search={search}
        setSearch={setSearch}
      />
      <GalleryInfo title_e={'News On'} title_k={'새소식'} items={news} />
    </div>
  )
}
export default ArtDbArtist