import React, { useContext, useState } from 'react'
import { Link, json, useNavigate } from 'react-router-dom'
import TableSlide from '../../../../../components/TableSlide/TableSlide'
import './MyNftList.css'
import metamask from '../../../../../services/web3/metamask'
import { DebounceInput } from 'react-debounce-input';
import { WalletState } from '../../../../../context/walletStateProvider'
import nft from '../../../../../services/myArchive/work/nft'
import { getDateTimeStr } from '../../../../../hook/utils/getDateStr'
import { LoadingState } from '../../../../../context/loadingStateProvider'
function MyNftList() {
  const loadingState = React.useContext(LoadingState);
  const navigate = useNavigate();

  const walletState = useContext(WalletState);
  const [nfts, setNfts] = useState([]); //가공안된 토탈 nftList
  const [items, setItems] = useState([]); // 가공된 토탈 nftList
  const [search, setSearch] = useState(''); // 검색어

  const [searchItems, setSearchItems] = useState([]);  // 검색한 후 결과 공백도 포함
  const [tableItem, setTableItem] = useState([]); // 보여줄 list


  React.useEffect(() => {
    if(walletState.address === null) return
    getList(walletState)
  }, [walletState])

  const getList = async (state) => {
    const res = await metamask.getNFTList(state)
    setNfts(res)
  }
  const getRegisterList = async () => {
    const res = await nft.getRegisterList()
    return res.my_nft
  }
  const responseNft = async(uri) => {
    const filename = uri.split("/").pop(); // 파일 이름
    const res = await nft.getJson(filename);
  
    const json = JSON.parse(res.data);
    return json;
  }

  const parseAttributes = async (att) => {
    let attJson = {};
    for (let i = 0; i < att.length; i++) {
      attJson[att[i].trait_type] = att[i].value
    }
    return attJson
  }


  const parseNft = async (data, registerList) => {
    const res = await responseNft(data.uri)
    const jsonData = {
      uri: data.uri,
      tokenId: data.tokenId,
      name: res.name,
      image: res.image,
      description: res.description,
    };
    
    const attData = await parseAttributes(res.attributes);
    const register = checkIsRegister(registerList, data.tokenId, attData.contract);
    const nftJson = Object.assign(jsonData, attData, register);
    return nftJson
  }


  const checkIsRegister = (registerList, tokenId, contract) => {

    for(let i = 0; i < registerList.length; i ++){
      if(+tokenId === registerList[i].token_ID){
        if(contract === registerList[i].contract){
          return {
            isSale: registerList[i].sell_TYPE,
            start : getDateTimeStr(registerList[i].start_TIME),
            end: getDateTimeStr(registerList[i].end_TIME),
            price: registerList[i].price,
            pk: registerList[i].nft_PK
          }
      }
    }
  }
  return {
    isSale: '0',
    start : '',
    end: '',
    price: '',
    pk: ''
  };
}


  const showNft = async () => {
    const resList = [];
    if (nfts.length === 0) {
      setItems(resList)
      return
    }
    const registerList = await getRegisterList()
    for (let i = 0; i < nfts.length; i++) {
      const res = await parseNft(nfts[i], registerList)
      resList.push(res)
    }
    setItems(resList.reverse())
    loadingState.setOpen(false)
    loadingState.setText('')
  }

  React.useEffect(() => {
    showNft()
  }, [nfts])


  React.useEffect(() => {
    // 검색결과 return
    const filteredItmes = items.filter(data => data.name.includes(search))
    setSearchItems(filteredItmes)
  }, [items, search])



  React.useEffect(() => {

    const list = []
    if (searchItems.length === 0) {
      setTableItem(list)
      return
    }
    searchItems.map((item, i) => {
      list.push(
        <div className="table-slider-item" key={i}>
          <ul>
            <li>{i + 1}</li>
            <li>
              <img src={item.image} alt='NFT' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
            </li>
            <li>{item.name}</li>
            <li>{item.tokenId ?? ''}</li>
            <li>{item.issue ?? ''}</li>
            <li>
                <p className={item.isSale === '0' ? "sell-status sell-compelete" : "sell-status"}>
                  {
                    item.isSale === '0' ? "비매" : item.isSale === '1' ? "판매중" : "경매중"
                  }
                  </p>
            </li>
            <li>
              <button onClick={() => navigate('/mypage/artist/archive/nft/manage', {state: {item : item}})} className="edit-btn">관리</button>
            </li>
          </ul>
          <div className="work-item-mb" key={i}>
            <div className="work-item-mb-container">
              <div className="work-item-mb-content">
                <div className="work-item-mb__info">
                  <img src={item.info} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
                  <div className="work-item-mb__detail">
                    <h4>2000.00.00</h4>
                    <h1>지식의 기념비</h1>
                    <h3>
                      <span>판매/발행현황</span>000,000
                    </h3>
                    <h3>
                      <span>판매수/발행수</span>000,000
                    </h3>
                  </div>
                  <button className="work-item-mb__delete"></button>
                </div>
                <div className="work-item-mb__state">
                  <button>판매중</button>
                  <Link>관리</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
    setTableItem(list)
  }, [searchItems])



  const titleItem = []

  titleItem.push(
    <div className="table-header-search">
      <h2>
        My NFT List 
        {/* <span className="download-button"></span> */}
      </h2>
      <div className="th-search-container">
        <section className="search__block">
          <DebounceInput
            minLength={1}
            debounceTimeout={500}
            type={'text'}
            placeholder={'NFT를 검색해주세요'}
            value={search}
            onChange={e => { setSearch(e.target.value); }}
            className="search__block__input"
          />
          <button className="search__block__button">
            <span className="ir_pm">검색</span>
          </button>
        </section>
        {/* <section className="th-mb-search-button">
          <Link to="./nft">
            <button>Add</button>
          </Link>
        </section> */}
      </div>
    </div>
  )
  const tableSlideHeader = []
  tableSlideHeader.push(
    <ul className="container table-slider-header-container">
      <li>번호</li>
      <li>대표 이미지</li>
      <li>NFT 명</li>
      <li>NFT ID</li>
      <li>발행일</li>
      <li>마켓플레이스</li>
      <li>판매 관리</li>
    </ul>
  )
  return (
    <div className="my-nft-list">
      <TableSlide
        title={titleItem}
        slideHeader={tableSlideHeader}
        items={tableItem}
        rows={9}
        breakRows={5}
        nft={true}
      />
    </div>
  )
}
export default MyNftList
