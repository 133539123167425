import React from 'react'
import { Link } from 'react-router-dom'
import { artViewPrice } from '../../hook/utils/artdbView'
import MarketHeader from '../../pages/Marketplace/Header/MarketHeader'
import { handleImgError, workMainImage } from '../../services/imageRoute'
import './ArtTopic.css'

function ArtHotTopic(props) {
  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const notMobile = () => {
    return (
      <>
        {
          props.items.length >= 1 &&
          <Link
            to={props.items[0].link}
            className="art-topic-item"
          >
            <img
              alt=''
              src={props.items[0].img}
              className="art-topic--main hot-click-main-size"
              onError={handleImgError}
              onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }
            />
            <div className="art-topic__hover">
              <h2 className="art-topic-item--author">
                {props.items[0].title}
              </h2>
              <h2 className="art-topic-item--author">
                {props.items[0].sub}
              </h2>
              {props.price &&
                <h1 className="art-topic-item--price">{props.items[0].price}</h1>
              }
            </div>
          </Link>
        }
        <div className="hot-click-sub">
          {
            props.items.length >= 2 &&
            <Link
              to={props.items[1].link}
              className="art-topic-item"
              style={{ height: 'auto' }}
            >
              <img
                alt=''
                src={props.items[1].img}
                className="art-topic--main hot-click-sub-size"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[1].title}
                </h2>
                <h2 className="art-topic-item--author">
                  {props.items[1].sub}
                </h2>
                {props.price &&
                  <h1 className="art-topic-item--price">{props.items[1].price}</h1>

                }
              </div>
            </Link>
          }
          {
            props.items.length >= 3 &&
            <Link
              to={props.items[2].link}
              className="art-topic-item"
              style={{ height: 'auto' }}
            >
              <img
                alt=''
                src={props.items[2].img}
                className="art-topic--main hot-click-sub-size"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[2].title}
                </h2>
                <h2 className="art-topic-item--author">
                  {props.items[2].sub}
                </h2>
                {props.price &&
                  <h1 className="art-topic-item--price">{props.items[2].price}</h1>

                }
              </div>
            </Link>
          }
          {
            props.items.length >= 4 &&
            <Link
              to={props.items[3].link}
              className="art-topic-item"
              style={{ height: 'auto' }}
            >
              <img
                alt=''
                src={props.items[3].img}
                className="art-topic--main hot-click-sub-size"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[3].title}
                </h2>
                <h2 className="art-topic-item--author">
                  {props.items[3].sub}
                </h2>
                {props.price &&
                  <h1 className="art-topic-item--price">{props.items[3].price}</h1>

                }
              </div>
            </Link>
          }
          {
            props.items.length >= 5 &&
            <Link
              to={props.items[4].link}
              className="art-topic-item"
              style={{ height: 'auto' }}
            >
              <img
                alt=''
                src={props.items[4].img}
                className="art-topic--main hot-click-sub-size"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[4].title}
                </h2>
                <h2 className="art-topic-item--author">
                  {props.items[4].sub}
                </h2>
                {props.price &&
                  <h1 className="art-topic-item--price">{props.items[4].price}</h1>

                }
              </div>
            </Link>
          }
        </div>
      </>
    )
  }


  const mobile = () => {
    return (
      <>
        <div className="hot-click-sub">
          {
            props.items.length >= 1 &&
            <Link
              to={props.items[0].link}
              className="art-topic-item"
              style={{ height: 'auto' }}
            >
              <img
                alt=''
                src={props.items[0].img}
                className="art-topic--main hot-click-sub-size"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[0].title}
                </h2>
                <h2 className="art-topic-item--author">
                  {props.items[0].sub}
                </h2>
                {props.price &&
                  <h1 className="art-topic-item--price">{props.items[0].price}</h1>

                }
              </div>
            </Link>
          }
          {
            props.items.length >= 2 &&
            <Link
              to={props.items[1].link}
              className="art-topic-item"
              style={{ height: 'auto' }}
            >
              <img
                alt=''
                src={props.items[1].img}
                className="art-topic--main hot-click-sub-size"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[1].title}
                </h2>
                <h2 className="art-topic-item--author">
                  {props.items[1].sub}
                </h2>
                {props.price &&
                  <h1 className="art-topic-item--price">{props.items[1].price}</h1>
                }
              </div>
            </Link>
          }
          {
            props.items.length >= 3 &&
            <Link
              to={props.items[2].link}
              className="art-topic-item"
              style={{ height: 'auto' }}
            >
              <img
                alt=''
                src={props.items[2].img}
                className="art-topic--main hot-click-sub-size"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[2].title}
                </h2>
                <h2 className="art-topic-item--author">
                  {props.items[2].sub}
                </h2>
                {props.price &&
                  <h1 className="art-topic-item--price">{props.items[2].price}</h1>
                }
              </div>
            </Link>
          }
          {
            props.items.length >= 4 &&
            <Link
              to={props.items[3].link}
              className="art-topic-item"
              style={{ height: 'auto' }}
            >
              <img
                alt=''
                src={props.items[3].img}
                className="art-topic--main hot-click-sub-size"
                onError={handleImgError}
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
              <div className="art-topic__hover">
                <h2 className="art-topic-item--author">
                  {props.items[3].title}
                </h2>
                <h2 className="art-topic-item--author">
                  {props.items[3].sub}
                </h2>
                {props.price &&
                  <h1 className="art-topic-item--price">{props.items[3].price}</h1>
                }
              </div>
            </Link>
          }
        </div>
      </>
    )
  }


  return (
    <section className="art-topic">
      <MarketHeader title_e={props.title_e} title_k={props.title_k} move={props.move}></MarketHeader>
      <section className="container art-topic-content" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          matches ?
            mobile() : notMobile()
        }
      </section>
    </section >
  )
}
export default ArtHotTopic
