import React from 'react'
import Slider from 'react-slick'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../node_modules/slick-carousel/slick/slick.css'
import './BannerSlide.css'
function AllSlide(props) {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4500,
    appendDots: dots => (
      <div
        style={{
          width: '100%',
          position: 'absolute',
          bottom: '21rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: 'dots_custom',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          autoplay: true,
          autoplaySpeed: 4500,
        },
      },
    ],
  }


  const onClick = (item) => {
    if(item.link === '') return;
    window.open(
      item.link,
      "_blank"
    );
  }

  const slider_items = items => {
    return items.map((item, i) => {
      return (
        <div className="slider__item" key={i} onClick={() => onClick(item)}>
            {
                item.type === 'img'
                ? <img className="item-image" src={item.info} alt='' onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }/>
                : <video className="item-image" muted autoPlay loop>
                <source  src={item.info} type="video/mp4" />
              </video>
            }
          <div className="item-info">
            {/* <h1>{item.title}</h1>
            <h2>Exhibition</h2>
            <p>{item.detail}</p>
            <Link
              to={`${item.link}/${item.id}`}
              state={{
                item: item,
              }}
              className="item-button"
            >
              Explore Now
            </Link> */}
          </div>
        </div>
      )
    })
  }
  return (
    <div className="banner">
      <section className="container">
        <Slider {...settings} className="banner-slider">
          {slider_items(props.items)}
        </Slider>
      </section>
    </div>
  )
}
export default AllSlide
