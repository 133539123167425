import * as ActionTypes from './actionTypes';

export const CLEAR = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.CLEAR, data: data})
    }
}




export const FIRST_SCAN = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.FIRST_SCAN, data: data})
    }
}

export const FIRST_PASS = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.FIRST_PASS, data: data})
    }
}

export const FIRST_RETURN = (data, info) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.FIRST_RETURN, data: data, info: info})
    }
}





export const SECOND_SCAN = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SECOND_SCAN, data: data})
    }
}

export const SECOND_SALE_ALLOW = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SECOND_SALE_ALLOW, data: data})
    }
}

export const SECOND_DATE = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SECOND_DATE, data: data})
    }
}

export const SECOND_PRICE = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SECOND_PRICE, data: data})
    }
}

export const SECOND_RETURN = (data) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.SECOND_RETURN, data: data})
    }
}