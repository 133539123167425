import cookie from 'js-cookie';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './PopupModal.css';
import { sanitize } from 'dompurify'


function PopupModal() {
  const { popupList } = useSelector(v => v.setting, shallowEqual)
  const [checked, setChecked] = React.useState([]);
  const [items, setItems] = React.useState([]);


  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  React.useEffect(() => {
    setItems([...popupList]);
    let checkList = []
    for (let i = 0; i < popupList.length; i++) {
      checkList[i] = false;
    }
    setItems([...popupList]);
    setChecked([...checkList]);
  }, [popupList])

  const setCookie = (key, value, expireDays) => {
    cookie.set(key, value, {
      expires: expireDays,
      path: ''
    })
  }


  const closeModal = (pk, index) => {
    setItems(
      items.map(data =>
        data.pk === pk ? { ...data, open: false } : data
      )
    )
    if (checked[index]) {
      setCookie(`QPOPUP${pk}`, 'Y', 1)
    }
  }

  const setCheckedList = (check, index) => {
    let newArr = [...checked];
    newArr[index] = check;
    setChecked(newArr)
  }

  return (
    items.map((item, i) => {
      return (
        item.open &&
        <div className="useb-modal">
          <div className="modal-container" onClick={() => closeModal(item.pk, i)}>
            <div className="modal-body" onClick={e => e.stopPropagation()}>
              <div style={{ marginBottom: '10rem', display: 'flex', gap: '25rem', flexDirection: 'column', padding: '12px 15px' }}>
                <h3 className="useb-modal-title">
                  {item.title}
                </h3>
                <div
                  className="useb-modal-detail__comment ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(item === undefined ? "" :
                      matches ?
                        item.contentM : item.content)
                  }}
                />
              </div>
              <div className='info-bottom'>
                <label className="info-option" onChange={e => setCheckedList(e.target.checked, i)}>
                  <input autoComplete='off' type="checkbox" checked={checked[i]} />
                  <span className="info-option__check" />
                  <span>
                    하루 동안 보지않기
                  </span>
                </label>
                <label className="info-option">
                  <span onClick={() => closeModal(item.pk, i)}>
                    닫기
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      )
    })
  )

}

export default PopupModal