import React from "react";
import { useScript } from "./useScript";
import { getOrderNo } from "./utils/makeOrderNo";

/**
 * pay2pay 실행
  * @param {number} unit 결제 타입  1: 원화, 2 : USD
  * @param {number} amount 가격
  * @param {String} email 구매자 email
  * @param {String} name 구매자 이름
  * @param {String} address 구매자 주소
  * @param {String} phone 구매자 휴대폰
  * @param {String} title 작품명
  * @param {String} uniqueKey 상품코드
 */

export const usePay2pay = (unit, amount, email, name, address, phone, title, uniqueKey,) => {

  const cookiePaymentsStatus = useScript(`${process.env.REACT_APP_COOKEYPAY_URL}/js/cookiepayments-1.1.3.js`);
  const cookiepay = window.cookiepayments;
  const returnURL = process.env.REACT_APP_URL;

  const payType = unit === 1 ? 1 : 7;
  const eng = unit === 1 ? 'N' : 'Y';

  const ApiId = unit === 1 
    ? process.env.REACT_APP_KR_COOKEYPAY_ID 
    : process.env.REACT_APP_EN_COOKEYPAY_ID;

  const ApiKey = unit === 1 
    ? process.env.REACT_APP_KR_COOKEYPAY_SECRET 
    : process.env.REACT_APP_EN_COOKEYPAY_SECRET;

  const [start, setStart] = React.useState(false);


  React.useEffect(() => {
      cookiepayInit()
  }, [cookiePaymentsStatus])

  const cookiepayInit = async() => {
    if (cookiePaymentsStatus === 'loading') return
    await cookiepay.init({
      api_id: ApiId,
      api_key: ApiKey
    });

    return setStart(true);
  }

  const cookiepaymentsParams = {
    ORDERNO: getOrderNo(), //주문번호 필수
    PAY_TYPE: payType, // 일반 : 1 , 해외원화결제 : 7
    PAYMETHOD: 'CARD', //결제 수단 선택 default :CARD
    AMOUNT: amount, //결제 금액
    BUYERNAME: name, //고객명
    BUYEREMAIL: email, //고객 이메일
    BUYERID: email, //고객 아이디
    BUYERADDRESS: address, //고객 주소
    BUYERPHONE: phone, //고객 휴대폰
    RETURNURL: returnURL, //결제 결과값을 받을 url 필수
    PRODUCTCODE: uniqueKey, //상품코드 선택
    PRODUCTNAME: title, // 상품명
    ENG_FLAG: eng
    // ETC1: 'temp1', //추가 필드1 선택사항
    // ETC2: 'temp2', //추가 필드2 선택사항
    // ETC3: 'temp3', //추가 필드3 선택사항
    // ETC4: 'temp4', //추가 필드4 선택사항
    // ETC5: 'temp5', //추가 필드5 선택사항
  }

  React.useEffect(() => {
    if (!start) return;

    // console.log(cookiepaymentsParams)
    cookiepay.payrequest({
      ...cookiepaymentsParams,
    });
  }, [start])
}


export default usePay2pay;