import React, { useEffect, useRef, useState } from 'react'
import ModalPortal from '../Modal/ModalPortal'
import './DropDown.css'
import DropModal from '../Modal/DropModal'

function DropDown(props) {
  const [show, setShow] = useState(false)
  const [select, setSelect] = useState(0)
  const el = useRef()

  const changeSelect = e => {
    if(props.beforeAction){
      props.beforeAction();
    }
    props.setSelect ? props.setSelect(e) : setSelect(e)
  }
  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])
  const handleClickOutside = ({ target }) => {
    if (!show && !el.current.contains(target)) {
      setShow(false)
    }
  }

  // 모달창 노출 여부 state
  const [modalOpen, setModalOpen] = useState(false)

  // 모달창 노출
  const showModal = () => {
    setModalOpen(true)
    // document.body.style.overflow = 'hidden' // 모달 창 오픈 시 스크롤 불가
  }
  const getOptions = () => {
    return props.options.map((option, i) => {
      return (
        <li
          key={i}
          value={i}
          onClick={e => {
              if(!props.done){
                changeSelect(i)
                if(option === '직접입력 '){
                  props.setValue('')
                }else{
                  props.setValue(option)
                }
              }
              // 선택 후 닫기
              setShow(false)
          }}
        >
          {option}
        </li>
      )
    })
  }

  return (
    <div ref={el} className="drop-container" 
      style={props.pointer ? {cursor: 'pointer'} : {}} 
      >
      <div
        className={!props.pointer ? `drop-select ${props.myClass}` :"drop-select-not"}
        onClick={() => {
          if (!props.disabled) setShow(!show)
          if (!props.disabled) showModal()
        }}
      >
        {props.options[props.select ? props.select : select]}
      </div>
      <ul ref={el} className={show ? 'show-drop-list' : 'drop-list'}>
        {getOptions()}
      </ul>
      {props.disabled ? <></> :modalOpen && (
        <ModalPortal>
          <DropModal
            done={props.done}
            setModalOpen={setModalOpen}
            setFunc={changeSelect}
            select={select}
            setValue={props.setValue}
            options={props.options}
          />
        </ModalPortal>
      )}
    </div>
  )
}
export default DropDown
