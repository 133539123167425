import React, { useState } from 'react';
import swal from 'sweetalert';
import './NoticeList.css';
// import '../../MyPage/Artist/MyArchive/QCoA/MyQCoAList.css'

import { shallowEqual, useSelector } from 'react-redux';
import AllowModal from '../../../components/Modal/AllowModal';
import DateModal from '../../../components/Modal/DateModal';
import DeliveryInfoModal from '../../../components/Modal/DeliveryInfoModal';
import ModalPortal from '../../../components/Modal/ModalPortal';
import PinModal from '../../../components/Modal/PinModal';
import TableSlide from '../../../components/TableSlide/TableSlide';
import { LoadingState } from '../../../context/loadingStateProvider';
import { setBidTime } from '../../../hook/utils/getDateStr';
import { getTitle } from '../../../hook/utils/getTitle';
import { workMainImage } from '../../../services/imageRoute';
import delivery from '../../../services/myArchive/work/delivery';
import trade from '../../../services/myArchive/work/trade';


function NoticeList() {

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  const loadingState = React.useContext(LoadingState);
  const { type, artist, gallery } = useSelector(v => v.auth, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)

  const [menu, setMenu] = useState(0)
  const rows = matches ? 5 : 6
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [rantalItems, setRantalItems] = useState([])
  const [rantalExtensionItems, setRantalExtensionItems] = useState([])
  const [returnItems, setReturnItems] = useState([])
  const [tradeItems, setTradeItems] = useState([])
  const [tableItem, setTableItem] = useState([])

  const [modal, setModal] = useState(false)
  const [data, setData] = useState()

  // 대여 연장/반납 선택 모달
  const [rentalExtensionOrReturnModal, setRentalExtensionOrReturnModal] = useState(false)

  // 반납 핀 모달
  const [rentalReturnPinModal, setRentalReturnPinModal] = useState(false)
  // 대여 요청 승인 거부 모달
  const [rentalModal, setRentalModal] = useState(false)

  const [rentalPinModal, setRentalPinModal] = useState(false)
  const [pin, setPin] = useState('')
  const [date, setDate] = useState('')
  const [reason, setReason] = React.useState('')


  // 대여 연장 승인 거부 모달
  const [rentalExtensionModal, setRentalExtensionModal] = useState(false)

  // 대여 연장 신청 모달
  const [returnModal, setReturnModal] = useState(false)

  const [processItem, setProcessItem] = useState(null)

  const checkBySelf = () => {
    if (type === 0) {
      return artist.user_ID
    }
    if (type === 1) {
      return artist.user_ID
    }
    if (type === 2) {
      return gallery.user_id
    }
  }

  const toTimeStamp = (str) => {
    const date = new Date(str);
    const timestamp = date.getTime();
    return timestamp.toString()
  }

  const rentalAdmission = async (myPin) => {
    const check = await trade.sell.checkPin(processItem.unique_key)
    if (check.data) {
      swal('간편 패스워드 초기화 먼저 해주세요.')
      setRentalPinModal(false)
      return
    }
    loadingState.setText('대여 승인중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await trade.rental.admission(processItem.pk, myPin)
    if (!res.data) {
      if (res.message === 'no match') {
        swal('간편패스워드가 틀렸습니다.')
      } else if (res.message === '판매 중인 작품') {
        swal('판매중인 작품입니다.\n판매 취소 후, 승인해주세요.')
      } else if (res.message === 'trash') {
        swal('반납일이 지난 요청입니다.')
      } else {
        swal('관리자에게 문의해주세요.')
      }
      loadingState.setOpen(false);
      loadingState.setText('');
      setRentalPinModal(false);
      return;
    }
    await getRequestList(currentPage)
    swal('대여 승인 완료했습니다.')
    setRentalPinModal(false);
    loadingState.setOpen(false);
    loadingState.setText('');
  }

  const rentalDeny = async () => {
    // const check = await trade.sell.checkPin(processItem.unique_key)
    // if(check.data){
    //   swal('간편 패스워드 초기화 먼저 해주세요.')
    //   return
    // }
    loadingState.setText('대여 거부중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await trade.rental.deny(processItem.requestor_id, processItem.unique_key)
    if (!res.data) {
      if (res.message === 'requestOwnershipTransfer Wrong!') {
        swal('간편패스워드가 틀렸습니다.')
        loadingState.setOpen(false);
        loadingState.setText('');
        return
      }
      swal('관리자에게 문의해주세요.')
      loadingState.setOpen(false);
      loadingState.setText('');
      return;
    }
    await getRequestList(currentPage)
    setRentalModal(false)
    swal('대여 거부 완료했습니다.')
    loadingState.setOpen(false);
    loadingState.setText('');
  }


  const rentalExtensionRequst = async () => {

    if (date === '' || date === undefined) {
      swal('반납일은 선택해주세요.')
      setReturnModal(true)
      return
    }

    if (reason === '' || reason === undefined) {
      swal('이유를 입력해주세요.')
      setReturnModal(true)
      return
    }

    if (processItem.workRentalRequest.request_date > toTimeStamp(date)) {
      swal('반납일 이후를 선택해주세요.')
      return
    }

    loadingState.setText('대여연장 신청입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);


    const res = await trade.rental.extensionRequst(processItem.workRentalRequest.pk, processItem.workRentalRequest.requestor_id, toTimeStamp(date), processItem.workRentalRequest.owner_id, processItem.workRentalRequest.unique_key, reason)
    if (!res.data) {
      swal('관리자에게 문의해주세요.')
      loadingState.setOpen(false);
      loadingState.setText('');
      return;
    }
    setReturnModal(false)
    swal('대여연장 신청 완료했습니다.')
    loadingState.setOpen(false);
    loadingState.setText('');

  }

  const rentalExtensionAdmission = async () => {
    loadingState.setText('대여 연장 승인중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await trade.rental.extensionAdmission(processItem.unique_key)
    if (!res.data) {
      if (res.message === 'requestOwnershipTransfer Wrong!') {
        swal('간편패스워드가 틀렸습니다.')
        loadingState.setOpen(false);
        loadingState.setText('');
        return
      }
      swal('관리자에게 문의해주세요.')
      loadingState.setOpen(false);
      loadingState.setText('');
      return;
    }
    await getExtensionRequestList(currentPage)
    swal('대여 연장 승인했습니다.')
    loadingState.setOpen(false);
    loadingState.setText('');
  }

  const rentalExtensionDeny = async () => {
    loadingState.setText('대여 연장 거부중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await trade.rental.extensionDeny(processItem.unique_key)
    if (!res.data) {
      if (res.message === 'requestOwnershipTransfer Wrong!') {
        swal('간편패스워드가 틀렸습니다.')
      }
      else if (res.message === 'trash') {
        swal('반납일이 지난 요청입니다.')
      } else {
        swal('관리자에게 문의해주세요.')
      }
      loadingState.setOpen(false);
      loadingState.setText('');
      setRentalExtensionModal(false)
      return;
    }
    await getExtensionRequestList(currentPage)
    setRentalExtensionModal(false)
    swal('대여 연장 거부했습니다.')
    loadingState.setOpen(false);
    loadingState.setText('');
  }


  const rentalReturn = async (newPin) => {
    // console.log(processItem)
    // return
    loadingState.setText('대여한 작품을 반납중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await trade.rental.rentalReturn(
      processItem.workRentalRequest.work_id,
      processItem.workRentalRequest.unique_key,
      processItem.workRentalRequest.requestor_id,
      newPin
    )
    if (!res.data) {
      if (res.message === 'requestOwnershipTransfer Wrong!') {
        swal('간편패스워드가 틀렸습니다.')
        loadingState.setOpen(false);
        loadingState.setText('');
        return
      }
      swal('관리자에게 문의해주세요.')
      loadingState.setOpen(false);
      loadingState.setText('');
      return;
    }
    setRentalReturnPinModal(false)
    await getMyList(currentPage)
    swal('대여한 작품을 반납완료했습니다.')
    loadingState.setOpen(false);
    loadingState.setText('');
  }



  const getRequestList = async (page) => {
    const res = await trade.rental.requstList(page, rows)
    setRantalItems(res.data.data)
    setTotal(res.data.total)
  }

  const getExtensionRequestList = async (page) => {
    const res = await trade.rental.extensionRequstList(page, rows, checkBySelf())
    setRantalExtensionItems(res.data.data)
    setTotal(res.data.total)
  }

  const getMyList = async (page) => {
    const res = await trade.rental.returnList(page, rows)
    setReturnItems(res.data.list)
    setTotal(res.data.total)
  }

  const getTradeList = async (page) => {
    const res = await trade.tradeList(page, rows);
    setTradeItems(res.transaction.content);
    setTotal(res.transaction.totalElements)
  }


  const deliverDone = async (data) => {
    setModal(true);
    setData(data)
  }

  const returnDeny = async (data) => {
    const res = await delivery.denyReturned(data.transactionPK)

    if (!res.resultCode) {
      swal('관리자에게 문의해주세요.')
      return;
    }

    swal('반품거부되었습니다.');
    getTradeList(currentPage);
  }

  const returnDone = async (data) => {
    const res = await delivery.allowReturned(data.transactionPK)

    if (!res.resultCode) {
      swal('관리자에게 문의해주세요.')
      return;
    }

    swal('반품승인되었습니다.');
    getTradeList(currentPage);
  }

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  React.useEffect(() => {
    setCurrentPage(0)
  }, [menu])
  React.useEffect(() => {
    if (menu === 0) {
      getRequestList(currentPage)
    } else if (menu === 1) {
      getExtensionRequestList(currentPage)
    } else if (menu === 2) {
      getMyList(currentPage)
    } else {
      getTradeList(currentPage)
    }
  }, [currentPage, menu])

  const onClickItem = (temp, item) => {
    setProcessItem(item)
    if (temp === 'rental') {
      if (item.status === 1) {
        swal('이전에 대여 승인한 작품입니다.');
        return;
      }
      if (item.status === 2) {
        swal('이전에 대여 거부한 작품입니다.');
        return;
      }
      if (item.status === 10) {
        swal('반납완료된 작품입니다.');
        return;
      }
      setRentalModal(true)
    } else if (temp === 'rentalExtension') {
      if (item.status === 1) {
        swal('이전에 연장 승인한 작품입니다.');
        return;
      }
      if (item.status === 2) {
        swal('이전에 연장 거부한 작품입니다.');
        return;
      }
      setRentalExtensionModal(true)
    } else if (temp === 'return') {
      if(item.workRentalRequest.status !== 1){
        swal('반납 처리된 작품입니다.');
        return;
      }
      setRentalExtensionOrReturnModal(true)
    }
  }

  // 대여 승인
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    const changeItem = []

    if (rantalItems.length === 0) {
      setTableItem(changeItem)
      return
    }

    rantalItems.map((item, i) => {
      changeItem.push(
        <div className="table-slider-item" onClick={() => onClickItem('rental', item)} >
          <ul>
            <li>{(total) - (rows * currentPage + i)}</li>
            <li>
              <img src={`${workMainImage}/${item.image}`} alt='' onContextMenu={
                (e) => {
                  e.preventDefault();

                }
              }></img>
            </li>
            <li>{item.artist_name}</li>
            <li>{item.work_name}</li>
            <li>{setBidTime(item.create_time)}</li>
            <li>{item.rental_place}<br/>{item.exhibition_name}<br/>{item.exhibition_place}</li>
            <li>{item.status === 1 && <span style={{ color: '#992017' }}>-대여 중-</span>}{setBidTime(item.start_date)}<br />{setBidTime(item.request_date)}</li>

          </ul>
          <div className="work-item-mb" >
            <div className="work-item-mb-container">
              <div className="work-item-mb-content">
                <div className="work-item-mb__info">
                  <div style={{display: 'flex', alignItems: 'center'}}>
                  <img src={`${workMainImage}/${item.image}`} alt='' onContextMenu={
                    (e) => {
                      e.preventDefault();

                    }
                  }></img>
                  </div>
                  <div className="work-item-mb__detail">
                    <h1>{item.work_name}</h1>
                    <h3>작가명: {item.artist_name}</h3>
                    <h3>요청일: {setBidTime(item.create_time)}</h3>
                    <div style={{display: 'flex'}}>
                      <h3>대여처:</h3>
                      <h3>{item.rental_place}<br/>{item.exhibition_name}<br/>{item.exhibition_place}</h3>
                    </div>
                    <h3>대여일: {setBidTime(item.start_date)}</h3>
                    <h3 style={{ position: 'relative' }}>반납일: {setBidTime(item.request_date)} {item.status === 1 && <span style={{ color: '#992017', fontSize: '10rem', position: 'absolute', bottom: 0, right: '40rem', width: 'auto' }}>-대여 중-</span>}</h3>
                  </div>
                  {/* <button className="work-item-mb__delete"></button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })

    setTableItem(changeItem)
  }, [rantalItems])

  // 대여 연장 승인
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    const changeItem = []

    if (rantalExtensionItems.length === 0) {
      setTableItem(changeItem)
      return
    }

    rantalExtensionItems.map((item, i) => {
      changeItem.push(
        <div className="table-slider-item" onClick={() => onClickItem('rentalExtension', item)}>
          <ul>
            <li>{(total) - (rows * currentPage + i)}</li>
            <li>
              <img src={`${workMainImage}/${item.image}`} alt='' onContextMenu={
                (e) => {
                  e.preventDefault();

                }
              }></img>
            </li>
            <li>{item.artist_name}</li>
            <li>{item.work_name}</li>
            <li>{setBidTime(item.request_date)}</li>
            <li>{item.rental_place}<br/>{item.exhibition_name}<br/>{item.exhibition_place}</li>
            <li>{item.status === 1 && <span style={{ color: '#992017' }}>-대여 중-</span>}{setBidTime(item.extension_date)}</li>

          </ul>
          <div className="work-item-mb">
            <div className="work-item-mb-container">
              <div className="work-item-mb-content">
                <div className="work-item-mb__info">
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img src={`${workMainImage}/${item.image}`} alt='' onContextMenu={
                    (e) => {
                      e.preventDefault();

                    }
                  }></img>
                  </div>
                  <div className="work-item-mb__detail">
                    <h1>{item.work_name}</h1>
                    <h3>작가명: {item.artist_name}</h3>
                    <h3>요청일: {setBidTime(item.request_date)}</h3>
                    <div style={{display: 'flex'}}>
                      <h3>대여처:</h3>
                      <h3>{item.rental_place}<br/>{item.exhibition_name}<br/>{item.exhibition_place}</h3>
                    </div>
                    <h3 style={{ position: 'relative' }}>반납일: {setBidTime(item.extension_date)} {item.status === 1 && <span style={{ color: '#992017', fontSize: '10rem', position: 'absolute', bottom: 0, right: '40rem', width: 'auto' }}>-대여 중-</span>}</h3>
                  </div>
                  {/* <button className="work-item-mb__delete"></button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })

    setTableItem(changeItem)
  }, [rantalExtensionItems])

  // 대여 연장/반납 요청
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    const changeItem = []
    if (returnItems.length === 0) {
      setTableItem(changeItem)
      return
    }


    returnItems.map((item, i) => {
      // console.log(item.workRentalRequest.create_time)
      changeItem.push(
        <div className="table-slider-item" onClick={() => onClickItem('return', item)}>
          <ul>
            <li>{(total) - (rows * currentPage + i)}</li>
            <li>
              <img src={`${workMainImage}/${item.workInfo.image}`} alt='' onContextMenu={
                (e) => {
                  e.preventDefault();

                }
              }></img>
            </li>
            <li>{lang === 'ko' ? item.workInfo.artist : item.workInfo.eng_ARTIST}</li>
            <li>{getTitle(lang, item.workInfo.title, item.workInfo.eng_TITLE)}</li>
            <li>{setBidTime(item.workRentalRequest.create_time)}</li>
            <li>{item.collection}<br/>{item.workRentalRequest.exhibition_name}<br/>{item.workRentalRequest.exhibition_place}</li>
            <li>{item.workRentalRequest.status === 1 && <span style={{ color: '#992017' }}>-대여 중-</span>}{setBidTime(item.workRentalRequest.start_date)}<br />{setBidTime(item.workInfo.rental_EXPIRED)}</li>

          </ul>
          <div className="work-item-mb">
            <div className="work-item-mb-container">
              <div className="work-item-mb-content">
                <div className="work-item-mb__info">
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img src={`${workMainImage}/${item.workInfo.image}`} alt='' onContextMenu={
                    (e) => {
                      e.preventDefault();

                    }
                  }></img>
                  </div>
                  <div className="work-item-mb__detail">
                    {/* <h4>[ {item.collection} ]</h4> */}
                    <h1>{getTitle(lang, item.workInfo.title, item.workInfo.eng_TITLE)}</h1>
                    <h3>작가명: {lang === 'ko' ? item.workInfo.artist : item.workInfo.eng_ARTIST}</h3>
                    <h3>요청일: {setBidTime(item.workRentalRequest.create_time)}</h3>
                    <div style={{display: 'flex'}}>
                      <h3>대여처:</h3>
                      <h3>{item.collection}<br/>{item.workRentalRequest.exhibition_name}<br/>{item.workRentalRequest.exhibition_place}</h3>
                    </div>
                    <h3>대여일: {setBidTime(item.workRentalRequest.start_date)}</h3>
                    <h3 style={{ position: 'relative' }}>반납일: {setBidTime(item.workInfo.rental_EXPIRED)} {item.workRentalRequest.status === 1 && <span style={{ color: '#992017', fontSize: '10rem', position: 'absolute', bottom: 0, right: '40rem', width: 'auto' }}>-대여 중-</span>}</h3>
                  </div>
                  {/* <button className="work-item-mb__delete"></button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })

    setTableItem(changeItem)
  }, [returnItems])

  // 배송관리
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    const changeItem = []
    if (tradeItems.length === 0) {
      setTableItem(changeItem)
      return
    }
    tradeItems.map((item, i) => {
      matches ? (
        changeItem.push(
          <div className='table-slider-item2-mb'>
            <h2>{[setBidTime(item.deliveryTb.purchaseTime)]}</h2>
            <h1>{getTitle(lang, item.deliveryTb.workInfo.title, item.deliveryTb.workInfo.eng_TITLE) + '(' + item.deliveryTb.workInfo.unique_KEY.slice(0, 13) + ')'}</h1>
            <li style={{ paddingTop: '10rem' }}>
              <h2>연락처</h2>
              <h2>{item.deliveryTb.phone}</h2>
            </li>
            <li>
              <h2>이메일</h2>
              <h2>{item.deliveryTb.buyer_EMAIL}</h2>
            </li>
            <li style={{ gap: '20rem' }}>
              <h2>주소</h2>
              <h2>{(item.deliveryTb.address) + '\n' + (item.deliveryTb.address_DETAIL)}</h2>
            </li>
            {
              item.state === '3'
                ? <div style={{ display: 'flex', flexDirection: 'row', gap: '10rem' }}>
                  <button className='do-delivery-deny' onClick={() => returnDeny(item)}>반품거부</button>
                  <button className='do-delivery' onClick={() => returnDone(item)}>반품승인</button>
                </div>
                :
                item.state === '5'
                  ? <button className='done-delivery'>반품 처리중</button>
                  : item.state === '6'
                    ? <button className='done-delivery'>반품완료</button>
                    : item.sendDeliveryInfo !== null
                      ? <button className='done-delivery'>발송완료</button>
                      : <button className='do-delivery' style={{ width: '320rem' }} onClick={() => deliverDone(item)}>발송완료</button>
            }
            <div>

              <div className='rectangle'></div>
            </div>
          </div>
        )
      ) : (
        changeItem.push(
          <div className="table-slider-item2" >
            <ul>
              <li>{(total) - (rows * currentPage + i)}</li>
              <li>{setBidTime(item.deliveryTb.purchaseTime)}</li>
              <li>{getTitle(lang, item.deliveryTb.workInfo.title, item.deliveryTb.workInfo.eng_TITLE) + '\n(' + item.deliveryTb.workInfo.unique_KEY.slice(0, 13) + ')'}</li>
              <li>{item.deliveryTb.phone}</li>
              <li style={{ whiteSpace: 'pre-wrap' }}>{(item.deliveryTb.buyer_EMAIL.split('@')[0]) + '\n@' + (item.deliveryTb.buyer_EMAIL.split('@')[1])}</li>
              <li style={{ whiteSpace: 'pre-wrap' }}>{(item.deliveryTb.address) + '\n' + (item.deliveryTb.address_DETAIL)}</li>
              <div>
                {
                  item.state === '3'
                    ? <div style={{ display: 'flex', flexDirection: 'column', gap: '10rem' }}>
                      <button className='do-delivery-deny' onClick={() => returnDeny(item)}>반품거부</button>
                      <button className='do-delivery' onClick={() => returnDone(item)}>반품승인</button>
                    </div>
                    :
                    item.state === '5'
                      ? <button className='done-delivery'>반품 처리중</button>
                      : item.state === '6'
                        ? <button className='done-delivery'>반품완료</button>
                        : item.sendDeliveryInfo !== null
                          ? <button className='done-delivery'>발송완료</button>
                          : <button className='do-delivery' onClick={() => deliverDone(item)}>발송완료</button>
                }

              </div>
            </ul>
          </div>
        )
      )
    })

    setTableItem(changeItem)
  }, [tradeItems])



  const tableSlideHeader = []
  tableSlideHeader.push(
    <ul className="container table-slider-header-container">
      <li>번호</li>
      <li>대표 이미지</li>
      <li>작가명</li>
      <li>작품명</li>
      <li>요청일</li>
      <li>대여처</li>
      <li>대여일<br />반납일</li>
    </ul>
  )
  const extensionTableSlideHeader = []
  extensionTableSlideHeader.push(
    <ul className="container table-slider-header-container">
      <li>번호</li>
      <li>대표 이미지</li>
      <li>작가명</li>
      <li>작품명</li>
      <li>요청일</li>
      <li>대여처</li>
      <li>반납일</li>
    </ul>
  )
  const deliverySlideHeader = []
  deliverySlideHeader.push(
    <ul className="container table-slider-header-container">
      <li>번호</li>
      <li>구매일자</li>
      <li>작품명(고유번호)</li>
      <li>구매자 휴대전화</li>
      <li>구매자 메일</li>
      <li>구매자 주소</li>
      <li>상태</li>
    </ul>
  )
  return (
    <div className="my-notice-list">
      {
        // 대여 승인 거부 모달
        rentalModal &&
        <ModalPortal>
          <AllowModal
            setModalOpen={setRentalModal}
            admissionFunc={() => setRentalPinModal(true)}
            denyFunc={rentalDeny}
            title={'대여'}
            content={processItem.status === 3 ? '이전에 대여 승인한 작품입니다.\n대여승인을 유지하시겠습니까?' : '대여 승인하시겠습니까?'}
          />
        </ModalPortal>
      }
      {
        // 대여 연장 승인 거부 모달
        rentalExtensionModal &&
        <ModalPortal>
          <AllowModal
            setModalOpen={setRentalExtensionModal}
            admissionFunc={rentalExtensionAdmission}
            denyFunc={rentalExtensionDeny}
            title={'대여 연장'}
            content={`이유:\n${processItem.reason}\n\n대여 연장 승인하시겠습니까?`}
          />
        </ModalPortal>
      }
      {
        // 대여 연장 반납 선택 모달
        rentalExtensionOrReturnModal &&
        <ModalPortal>
          <AllowModal
            setModalOpen={setRentalExtensionOrReturnModal}
            admissionFunc={() => setReturnModal(true)}
            denyFunc={() => setRentalReturnPinModal(true)}
            title={'대여 연장/반납'}
            content={'대여 연장/반납을 선택해주세요.'}
            returnType={true}
          />
        </ModalPortal>
      }
      {
        // 대여 연장 요청 모달
        returnModal &&
        <ModalPortal>
          <ModalPortal>
            <DateModal
              title={'연장하실 기간 및 이유를 입력해주세요.'}
              setModalOpen={setReturnModal}
              date={date}
              setDate={setDate}
              afterAction={true}
              action={rentalExtensionRequst}
              extension={true}
              reason={reason}
              setReason={setReason}
            />
          </ModalPortal>
        </ModalPortal>
      }
      {
        // 대여 승인 핀 모달
        rentalPinModal &&
        <ModalPortal>
          <PinModal
            time={1}
            title={'대여해줄 작품의 간편패스워드를 입력해주세요.'}
            setModalOpen={setRentalPinModal}
            setMyPin={setPin}
            afterAction={true}
            action={rentalAdmission}
          />
        </ModalPortal>
      }
      {
        // 반납 핀 모달
        rentalReturnPinModal &&
        <ModalPortal>
          <PinModal
            time={1}
            title={'반납할 작품의 간편패스워드를 입력해주세요.'}
            setModalOpen={setRentalReturnPinModal}
            setMyPin={setPin}
            afterAction={true}
            action={rentalReturn}
          />
        </ModalPortal>
      }
      {
        // 배송완료 모달
        modal &&
        <ModalPortal>
          <DeliveryInfoModal
            setModalOpen={setModal}
            data={data}
            item={tradeItems}
            setItem={setTradeItems}
            showCancel={modal}
          ></DeliveryInfoModal>
        </ModalPortal>
      }
      <TableSlide
        menu={menu}
        changeMenu={setMenu}
        slideHeader={menu === 1 ? extensionTableSlideHeader : menu !== 3 ? tableSlideHeader : deliverySlideHeader}
        notice={true}
        items={tableItem}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}
export default NoticeList
