import React from 'react'
import swal from 'sweetalert'
import { setHypen } from '../../hook/utils/setHypen'
import market from '../../services/public/market/market'
import AddressModal from './AddressModal'
import './DeliveryModal.css'
import ModalPortal from './ModalPortal'

function DeliveryAddModal({ setModalOpen, setAddModalOpen, deliveryother, setDeliveryother, orderName, orderEmail, orderPhone }) {
  // 모달 끄기
  const closeModal = () => {
    setAddModalOpen(false)
    setModalOpen(true)
    document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }

  const [name, setName] = React.useState('');
  const [giver, setGiver] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [addressDetail, setAddressDetail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [memo, setMemo] = React.useState('');

  const [addressOpen, setAddressOpen] = React.useState(false);

  const AddNewDelivery = async() => {
    console.log(orderName)
    if (name === '') {
      swal("배송지 이름을 입력해주세요.")
      return;
    }
    if (giver === '') {
      swal("받으실 분을 입력해주세요.")
      return;
    }
    if (address === '') {
      swal("주소를 입력해주세요.")
      return;
    }
    if (addressDetail === '') {
      swal("상세주소를 입력해주세요.")
      return;
    }
    if (phone.length !== 13) {
      swal("전화번호를 입력해주세요.")
      return;
    }

    const res = await market.addAddress(address, addressDetail, name, orderEmail, orderName, orderPhone, giver, memo, phone);
    if(!res.resultCode){
      swal('관리자에게 문의해주세요.');
      return;
    }
    // 성공시, 배열에 추가
    setDeliveryother([...deliveryother, res.data])
    // setInfo(v => (
    //   {
    //     ...v,
    //     otherAdress: [...v.otherAdress, res.data]
    //   }));

    closeModal();
  }

  return (
    <div className="del-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <header className="del-header">
            <h1>배송지 추가</h1>
            <button className="del-close" onClick={closeModal}></button>
          </header>
          <ul className="del-content-add">

            <div style={{ display: 'flex', flexDirection: 'column', gap: '15rem' }}>
              <input
                className="del-tag-input"
                placeholder='배송지 이름 *'
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <div className="del-info-add">
                <span className="del-tlt-add">받으실 분 *</span>
                <input
                  className="del-add-input"
                  value={giver}
                  onChange={e => setGiver(e.target.value)}
                />
              </div>
              <div className="del-info-add">
                <span className="del-tlt-add">주소 *</span>
                <input
                  className="del-add-input"
                  value={address}
                  readOnly
                />
                <button className="search__block__button" onClick={() => setAddressOpen(true)}>
                  <span className="ir_pm">검색</span>
                </button>
                {addressOpen && (
                  <ModalPortal>
                    <AddressModal setModalOpen={setAddressOpen} setAddress={setAddress} />
                  </ModalPortal>
                )}
              </div>
              <div className="del-info-add">
                <span className="del-tlt-add">상세주소 *</span>
                <input
                  className="del-add-input"
                  value={addressDetail}
                  onChange={e => setAddressDetail(e.target.value)}
                />
              </div>
              <div className="del-info-add">
                <span className="del-tlt-add">전화번호 *</span>
                <input
                  className="del-add-input"
                  value={phone}
                  onChange={e => setHypen(e, setPhone)}
                />
              </div>
              {/* <div className="del-info-add" style={{ flexDirection: 'column', alignItems: 'start', gap: '10rem' }}>
                <span className="del-tlt-add">메모</span>
                <textarea
                  className="del-add-textarea"
                  value={memo}
                  onChange={e => setMemo(e.target.value)}
                />
              </div> */}
            </div>
          </ul>
          <button className="del-add" onClick={AddNewDelivery}>
            {/* <span className="del-add-sym"></span> */}
            <span>저장</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeliveryAddModal
