import React, { useContext, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import useMobileDetect from 'use-mobile-detect-hook'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import AddressModal from '../../../components/Modal/AddressModal'
import DeliveryModal from '../../../components/Modal/DeliveryModal'
import ModalPortal from '../../../components/Modal/ModalPortal'
import PinModal from '../../../components/Modal/PinModal'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import WorkHeader from '../../../components/WorkHeader/WorkHeader'
import { LoadingState } from '../../../context/loadingStateProvider'
import { WalletState } from '../../../context/walletStateProvider'
import { useScript } from '../../../hook/useScript'
import { artViewPrice } from '../../../hook/utils/artdbView'
import { checkUseName } from '../../../hook/utils/checkUseName'
import { checkNumber, onChangePoints, setComma } from '../../../hook/utils/comma'
import { getOrderNo } from '../../../hook/utils/makeOrderNo'
import { workMainImage } from '../../../services/imageRoute'
import market from '../../../services/public/market/market'
import setting from '../../../services/setting'
import metamask from '../../../services/web3/metamask'
import * as payAction from '../../../store/reducers/pay/actions'

import ReactTextareaAutosize from 'react-textarea-autosize'
import DeliveryAddModal from '../../../components/Modal/DeliveryAddModal'
import { getTitle } from '../../../hook/utils/getTitle'
import { handleInputMaxLength } from '../../../hook/utils/validation'
import './Payment.css'

function Payment() {
  const loadingState = React.useContext(LoadingState);
  const dispatch = useDispatch();
  const isMobile = useMobileDetect().isMobile();

  const cookiePaymentsStatus = useScript(`${process.env.REACT_APP_COOKEYPAY_URL}/js/cookiepayments-1.1.3.js`);

  const navigate = useNavigate()
  const walletState = useContext(WalletState);

  const { item, nft, bid_nft, sellType } = useLocation().state;
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { type, artist, gallery, login } = useSelector(v => v.auth, shallowEqual)

  const [dAddress, setDAddress] = React.useState('my')  // my || new
  const [same, setSame] = React.useState(false);
  const [wonType, setWonType] = React.useState(0);
  const [myPoint, setMyPoint] = React.useState('')
  const [usePoint, setUsePoint] = React.useState('0')

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);
  

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  React.useEffect(() => {
    needLogin();

    if (type === 1) {
      swal('작가는 작품 판매만 가능합니다.')
      navigate(-1)
      return
    }
    if (nft) {
      if (item.user_EMAIL === orderEmail) {
        swal('본인이 올린 작품은 구매가 불가능합니다.')
        navigate(-1)
        return
      }
    } else {
      if (item.email === orderEmail) {
        swal('본인이 올린 작품은 구매가 불가능합니다.')
        navigate(-1)
        return
      }
    }
  }, [])

  const needLogin = async() => {
    if (!login) {
      await swal('로그인이 필요한 서비스입니다.')
      navigate('/login', { replace: true })
      return
    }

  }

  React.useEffect(() => {
        // 일반일떄
        if (type === 0) {
          if (artist.status !== 2) {
            swal('e-KYC 인증이 필요합니다.');
            navigate('/mypage/general');
            return
          }
        }
    // 작가일떄
    if (type === 1) {
      if (artist.status !== 2) {
        swal('e-KYC 인증이 필요합니다.');
        navigate('/mypage/artist');
        return
      }
    }
    // 기관일떄
    if (type === 2) {
      if (gallery.status !== 2) {
        swal('e-KYC 인증이 필요합니다.');
        navigate('/mypage/gallery');
        return
      }
    }

  }, [])

  const [orderName, setOrderName] = React.useState('');
  const [orderPhone, setOrderPhone] = React.useState('');
  const [orderEmail, setOrderEmail] = React.useState('');

  const [deliveryName, setDeliveryName] = React.useState('');
  const [deliveryUser, setDeliveryUser] = React.useState('');
  const [deliveryPhone, setDeliveryPhone] = React.useState('');
  const [deliveryAddress, setDeliveryAddress] = React.useState('');
  const [deliveryAddressDetail, setDeliveryAddressDetail] = React.useState('');
  const [deliveryMemo, setDeliveryMemo] = React.useState('');

  const [deliveryother, setDeliveryother] = React.useState([]);

  React.useEffect(() => {
    setInit()
  }, [])

  const setInit = () => {
    switch (type) {
      case 0:
        setOrderName(lang === 'ko' ? `${artist.first_NAME}${artist.name}` : `${artist.first_ENG_NAME}${artist.eng_NAME}`)
        setOrderPhone(artist.phone_NUMBER.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`))
        setOrderEmail(artist.email)
        break;
      case 1:
        setOrderName(lang === 'ko' ? `${artist.first_NAME}${artist.name}` : `${artist.first_ENG_NAME}${artist.eng_NAME}`)
        try{
          setOrderPhone(artist.phone_NUMBER.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`))
        }catch{
          setOrderPhone(artist.phone_NUMBER)
        }
        
        setOrderEmail(artist.email)
        break;
      case 2:
        setOrderName(lang === 'ko' ? gallery.name : gallery.eng_NAME)
        try{
          setOrderPhone(gallery.phone_num.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`))
        }catch{
          setOrderPhone(gallery.phone_num)
        }
        setOrderEmail(gallery.email)
        break;
      default:
        return;
    }
  }

  React.useEffect(() => {
    getAddress()
    getPoint()
  }, [])


  const getPoint = async () => {
    const res = await setting.getPoint()
    setMyPoint(res.point)
  }

  const getAddress = async () => {

    if (nft) return

    const res = await market.getAddress()
    const data = res.delivery_list;

    let find = false;

    setDeliveryother(data)

    if (data.length !== 0) {
      // setDellOpen(true)
      setDAddress('my')

      for (let i = 0; i < data.length; i++) {
        if (data[i].delivery_TYPE === "1") {
          setDeliveryName(data[i].address_NAME)
          setDeliveryUser(data[i].giver)
          setDeliveryPhone(data[i].phone)
          setDeliveryAddress(data[i].address)
          setDeliveryAddressDetail(data[i].address_DETAIL)
          find = true;
          break;
        }
      }

      if (!find) {
        setDellOpen(true)
      }
    } else {
      if (matches) {
        setDellAddOpen(true)
      }
      setDAddress('new')
    }
  }

  const checkOnlyOne01 = type => {
    setDAddress(type)
    if (type === 'my') {
      setSame(false)
      if (deliveryother.length === 0) {
        swal('저장된 주소가 없습니다.')
      }
    } else {
      setDeliveryName('')
      setDeliveryUser('')
      setDeliveryPhone('')
      setDeliveryAddress('')
      setDeliveryAddressDetail('')
    }
  }

  // 모달창 노출 여부 state
  const [delOpen, setDellOpen] = useState(false)
  const [delAddOpen, setDellAddOpen] = useState(false)

  // 모달창 노출
  const showDelModal = () => {
    document.body.style.overflow = 'hidden' // 모달 창 오픈 시 스크롤 불가
    setDellOpen(true)
  }

  const setPhone = (set, e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {

      const value = e.target.value.replace(/\D+/g, "");
      const numberLength = 11;

      let result;
      result = "";

      for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
          case 3:
            result += "-";
            break;
          case 7:
            result += "-";
            break;

          default:
            break;
        }
        result += value[i];
      }

      set(result)
    }
  }


  // pin 모달창 노출 여부 state
  const [pinOpen, setPinOpen] = React.useState(false)
  const [myPin, setMyPin] = React.useState('')
  // pin 완료 모달창 노출
  const showPinModal = () => {
    setPinOpen(!pinOpen)
  }

  const [addressOpen, setAddressOpen] = React.useState(false);

  React.useEffect(() => {
    if (same) {
      setDAddress('new')
      if (deliveryName === '') {
        setDeliveryName(orderName)
      }
      setDeliveryUser(orderName)
      setDeliveryPhone(orderPhone)
    } else {
      setDAddress('my')
    }
  }, [same])

  const buy = async () => {

    if (myPoint < usePoint) {
      swal('보유 포인트를 확인해주세요.')
      await getPoint()
      return
    }

    if(orderPhone === '' || orderName === ''){
      swal("주문자정보를 입력해주세요.")
      return;
    }

    if (
      deliveryPhone === '' ||
      deliveryPhone === undefined ||
      deliveryAddress === '' ||
      deliveryAddress === undefined ||
      deliveryAddressDetail === '' ||
      deliveryAddressDetail === undefined
    ) {
      swal('배송정보를 입력해주세요.');
      return;
    }

    const buyType = wonType === 0;

    const cookiepay = window.cookiepayments;
    // const returnURL = `${window.location.href}/result`;
    const returnURL = `${process.env.REACT_APP_BASE_URL}/user/work/transaction/result`;

    const payType = buyType ? 1 : 7;
    const eng = buyType ? 'N' : 'Y';

    const ApiId = buyType
      ? process.env.REACT_APP_KR_COOKEYPAY_ID
      : process.env.REACT_APP_EN_COOKEYPAY_ID;

    const ApiKey = buyType
      ? process.env.REACT_APP_KR_COOKEYPAY_SECRET
      : process.env.REACT_APP_EN_COOKEYPAY_SECRET;

    await cookiepay.init({
      api_id: ApiId,
      api_key: ApiKey
    });

    const orderNo = getOrderNo();

    const data = {
      delivery: {
        ADDRESS_NAME: deliveryName,
        ADDRESS: deliveryAddress,
        ADDRESS_DETAIL: deliveryAddressDetail,
        GIVER: deliveryUser,
        PHONE: deliveryPhone,
        MEMO: deliveryMemo
      },
      order: {
        BUYER_NAME: orderName,
        BUYER_EMAIL: orderEmail,
        BUYER_PHONE: orderPhone
      },
      token: {
        pay2_id: ApiId,
        pay2_key: ApiKey
      },
      orderNo: orderNo,
      item: item,
      nft: nft,
      bid_nft: bid_nft,
      sellType: sellType,
      point: usePoint
    };

    console.log(orderNo)

    if (bid_nft ? item.bid_PRICE - usePoint : item.price - usePoint < 0) {
      swal('결제 가능한 금액을 입력해주세요.');
      return;
    }else if (bid_nft ? item.bid_PRICE - usePoint : item.price - usePoint === 0) {
      data.type = 'only_point';
      dispatch(payAction.PAY(data));

      navigate('/market/payment/result')
    }else{
      data.type = 'pay';
      const cookiepaymentsParams = {
        ORDERNO: orderNo, //주문번호 필수
        PAY_TYPE: payType, // 일반 : 1 , 해외원화결제 : 7
        PAYMETHOD: 'CARD', //결제 수단 선택 default :CARD
        AMOUNT: item.price - usePoint, //결제 금액
        BUYERNAME: orderName, //고객명
        BUYEREMAIL: orderEmail, //고객 이메일
        BUYERID: orderEmail, //고객 아이디
        BUYERPHONE: orderPhone.replaceAll('-', ''), //고객 휴대폰
        
        PRODUCTCODE: item.unique_KEY, //상품코드 선택
        PRODUCTNAME: getTitle(lang, item.title, item.eng_TITLE),// 상품명
        ENG_FLAG: eng,
        ETC1: orderEmail,
        ETC2: item.unique_KEY
      }

      dispatch(payAction.PAY(data));
  
      if(isMobile && buyType){
        cookiepaymentsParams.MTYPE= 'M';
        cookiepaymentsParams.HOMEURL = returnURL;
      }else{
        cookiepaymentsParams.RETURNURL = returnURL;
      }
  
      cookiepay.payrequest({
        ...cookiepaymentsParams,
      });
      return
    }

    
  }

  const buyNft = async () => {
    // 이니시스
    loadingState.setText('결제중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)
    let res;
    if (item.sell_TYPE === '2') {
      res = await market.nftBidBuy(item.nft_PK, walletState.address, +usePoint);
    } else {
      res = await market.nftBuy(item.nft_PK, walletState.address, +usePoint);
    }

    loadingState.setOpen(false)
    loadingState.setText('')

    if (!res.resultCode) {
      swal('관리자에게 문의해주세요.')
      return
    }
    // setPay(1)
  }


  // React.useEffect(() => {
  //   console.log(orderEmail)
  // },[orderEmail])


  return (
    <>
      {pinOpen && (
        <ModalPortal>
          <PinModal
            time={2}
            title={'사용할 작품의 간편패스워드를 등록해주세요.'}
            setModalOpen={showPinModal}
            setMyPin={setMyPin}
            afterAction={true}
          // action={buy}
          />
        </ModalPortal>
      )}
      {addressOpen && (
        <ModalPortal>
          <AddressModal setModalOpen={setAddressOpen} setAddress={setDeliveryAddress} />
        </ModalPortal>
      )}


      <div className="payment">
        <Header active="2" colored="black" detail={true} />
        <WorkHeader title_k={'주문/결제'} />
        <div className="container">
          <section className="payment-item">
            <img src={
              nft
                ? item.image
                : `${workMainImage}/${item.image}`
            } alt=''
              onContextMenu={
                (e) => {
                  e.preventDefault();

                }
              }></img>
            <ul className="payment-item-info">
              <li>
                <span>작품명</span>
                <span>
                  {nft
                    ? item.name
                    // ? `${item.name.split('(')[0]}\n(${item.name.split('(')[1]}`
                    : lang === 'ko' ? item.title : item.eng_TITLE
                  }

                </span>
              </li>
              <li>
                <span>작가명</span>
                <span>
                  {
                    nft
                      ? checkUseName(lang, item.origin_USE_NAME, item.origin_KO_NAME, item.origin_ENG_NAME, item.origin_KO_NICK_NAME, item.origin_ENG_NICK_NAME)
                      : lang === 'ko' ? item.artist : item.eng_ARTIST
                  }
                </span>
              </li>
              {/* <li>
              <span>소장처</span>
              <span>{item.collection}</span>
            </li> */}
              <li>
                <span>판매가</span>
                <span>{artViewPrice(item.price_UNIT, bid_nft ? item.bid_PRICE : item.price)}</span>
              </li>
              {!nft &&
                <li>
                  <span>배송비</span>
                  <span>0원</span>
                </li>
              }
              <li>
                <span>사용 포인트</span>
                <span>{setComma(usePoint)}</span>
              </li>
              <li>
                <span>결제금액</span>
                <span>{artViewPrice(item.price_UNIT, bid_nft ? item.bid_PRICE : +item.price - usePoint)}</span>
              </li>
              {/* <li>
              <span>적립포인트</span>
              <span>000,000원</span>
            </li> */}
            </ul>
          </section>
        </div>

        <div className="payment-checkout">
          <div className="payment-delivery-moblie">
            <div className="pd-m-header">
              <div className="container">
                <h2>배송지</h2>
                <button onClick={showDelModal}>배송지 추가/변경</button>
                {delOpen && (
                  <ModalPortal>
                    <DeliveryModal
                      setModalOpen={setDellOpen}
                      setAddModalOpen={setDellAddOpen}
                      deliveryother={deliveryother}
                      setDeliveryother={setDeliveryother}
                      setDeliveryName={setDeliveryName}
                      setDeliveryUser={setDeliveryUser}
                      setDeliveryPhone={setDeliveryPhone}
                      setDeliveryAddress={setDeliveryAddress}
                      setDeliveryAddressDetail={setDeliveryAddressDetail}
                    />
                  </ModalPortal>
                )}
                {delAddOpen && (
                  <ModalPortal>
                    <DeliveryAddModal
                      setModalOpen={setDellOpen}
                      setAddModalOpen={setDellAddOpen}
                      deliveryother={deliveryother}
                      setDeliveryother={setDeliveryother}
                      orderName={orderName}
                      orderEmail={orderEmail}
                      orderPhone={orderPhone}
                    />
                  </ModalPortal>
                )}
              </div>
            </div>

            {/* mobile */}
            <div className="pd-m-content">
              <div className="container">
                <ul className="pd-m-content-01">
                  <li>
                    <span>수령인</span>
                    <span>{deliveryUser}</span>
                  </li>
                  <li>
                    <span>휴대폰 번호</span>
                    <span>{deliveryPhone}</span>
                  </li>
                  <li>
                    <span>주소</span>
                    <p>
                      <span>{deliveryAddress}</span>
                      <span>{deliveryAddressDetail}</span>
                    </p>
                  </li>
                  <li>
                    <span style={{width: '100%'}}>배송메모 ({deliveryMemo === null ? '0/100' : `${deliveryMemo.length}/100`})</span>
                  </li>
                  <li>
                  <ReactTextareaAutosize
                      cacheMeasurements
                      minRows={2}
                      value={deliveryMemo}
                      style={{ width: '100%' }}
                      maxLength={100}
                      onChange={e => handleInputMaxLength(setDeliveryMemo, e)}
                    />
                  </li>
                </ul>
                {/* <DropDown options={options} /> */}
              </div>
            </div>

          </div>

          {!nft &&
            <>
              <div className="payment-order">
                <SectionHeader title={'주문자 정보'} />
                <div className="container">
                  <ul className="checkout-location">
                    <li>
                      <span>이름 *</span>
                      <input
                        autoComplete="off"
                        type="text"
                        className="checkout-input"
                        value={orderName}
                        onChange={e => { setOrderName(e.target.value) }}></input>
                    </li>
                    <li>
                      <span>전화번호 *</span>
                      <input
                        autoComplete="off"
                        type="text"
                        className="checkout-input"
                        id='order'
                        name='phone'
                        value={orderPhone}
                        // maxLength={13}
                        onChange={e => setPhone(setOrderPhone, e)}></input>
                    </li>
                    <li>
                      <span>이메일</span>
                      <input
                        autoComplete="off"
                        type="text"
                        className="checkout-input"
                        id='order'
                        name='email'
                        value={orderEmail}
                        readOnly
                        // onChange={e => setOrderEmail(e.target.value)}
                        ></input>
                    </li>
                  </ul>
                </div>
              </div>
              {!isMobile &&
                <div className="payment-delivery">
                  <SectionHeader title={'배송지 정보'} />
                  <div className="container">
                    <ul className="checkout-location">
                      <li>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginRight: '155rem' }}>배송지 선택</span>
                          <label className="check-option">
                            <input
                              type="checkbox"
                              checked={same}
                              name='same'
                              onChange={() => setSame(!same)}
                            />
                            <span className="check-option__check" />
                            주문자와 동일정보
                          </label>
                        </div>
                        <div className="checkout-location__checkbox">
                          <label className="check-option">
                            <input
                              type="checkbox"
                              checked={dAddress === 'my'}
                              name='my'
                              onChange={() => checkOnlyOne01('my')}
                              onClick={showDelModal}
                            />
                            {/* {delOpen && (
                            <ModalPortal>
                              <DeliveryModal setModalOpen={setDellOpen} info={info} setInfo={setInfo} />
                            </ModalPortal>
                          )} */}
                            <span className="check-option__check" />
                            회원정보 주소
                          </label>
                          <label className="check-option">
                            <input
                              type="checkbox"
                              checked={dAddress === 'new'}
                              name='new'
                              onChange={() => checkOnlyOne01('new')}
                            />
                            <span className="check-option__check" />
                            신규배송지
                          </label>
                        </div>
                      </li>
                      <li>
                        <span>배송지명</span>
                        <input
                          type="text"
                          value={deliveryName}
                          id="newAddress"
                          name="address_NAME"
                          onChange={e => {
                            setDAddress('new')
                            setDeliveryName(e.target.value)
                          }}
                          className="checkout-input"
                        ></input>
                      </li>
                      <li>
                        <span>수령인</span>
                        <input
                          type="text"
                          value={deliveryUser}
                          id="newAddress"
                          name="giver"
                          onChange={e => {
                            setDAddress('new')
                            setDeliveryUser(e.target.value)
                          }}
                          className="checkout-input"
                        ></input>
                      </li>
                      <li>
                        <span>연락처 *</span>
                        <input
                          type="text"
                          value={deliveryPhone}
                          id="newAddress"
                          name="phone"
                          onChange={e => {
                            setDAddress('new')
                            setPhone(setDeliveryPhone, e)
                          }}
                          className="checkout-input"
                        ></input>
                      </li>
                      <li>
                        <span>배송지 주소 *</span>
                        <div className="search_pay_block">
                          <input
                            type="text"
                            value={deliveryAddress}
                            className="search_pay_block checkout-input"
                            readOnly
                          ></input>
                          {
                            dAddress === 'new' &&
                            <button className="search_pay_block__button" onClick={() => setAddressOpen(true)}>
                              <span className="ir_pm">검색</span>
                            </button>
                          }
                        </div>
                      </li>
                      <li>
                        <span>상세주소 *</span>
                        <input
                          type="text"
                          value={deliveryAddressDetail}
                          id="newAddress"
                          name="address_DETAIL"
                          onChange={e => {
                            setDAddress('new')
                            setDeliveryAddressDetail(e.target.value)
                          }}
                          className="checkout-input"
                        ></input>
                      </li>
                      <li>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span>배송메모</span>
                        <span>({deliveryMemo === null ? '0/100' : `${deliveryMemo.length}/100`})</span>
                        </div>
                        <ReactTextareaAutosize
                          cacheMeasurements
                          minRows={3}
                          value={deliveryMemo}
                          style={{ width: '100%' }}
                          className="checkout-input"
                          maxLength={100}
                          onChange={e => handleInputMaxLength(setDeliveryMemo, e)}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              }
            </>
          }
          <div className="payment-pay">
            <SectionHeader title={'결제'} />
            <div className="container">
              <ul className="checkout-location">
                <li>
                  <div className="checkout-location__checkbox">
                    <label className="check-option">
                      <input
                        type="checkbox"
                        name="pay"
                        // onChange={e => checkOnlyOne02(e.target)}
                        onChange={e => setWonType(0)}
                        checked = {wonType === 0}
                      />
                      <span className="check-option__check" />
                      국내 카드
                    </label>
                  </div>
                </li>
                <li>
                  <div className="checkout-location__checkbox">
                    <label className="check-option">
                      <input
                        type="checkbox"
                        name="pay"
                        // onChange={e => checkOnlyOne02(e.target)}
                        onChange={e => setWonType(1)}
                        checked = {wonType === 1}
                      />
                      <span className="check-option__check" />
                      해외원화 카드 결제(팝업허용 필수)
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="checkout-location">
                <li>
                  <span>포인트 사용(보유: {setComma(myPoint)})</span>
                  <input
                    type="text"
                    onKeyPress={checkNumber}
                    value={setComma(usePoint)}
                    onChange={e => setUsePoint(onChangePoints(e))}
                    placeholder={`최대 ${setComma(myPoint)}`}
                    className="checkout-input"
                  ></input>
                </li>
              </ul>
            </div>
            <div className="checkout-button">
              {/* walletState */}
              {nft
                ?
                walletState.address === null ?
                  <button className="checkout-button" onClick={() => metamask.connect(walletState)}>
                    지갑 연결하기
                  </button>
                  :
                  <button className="checkout-button" onClick={buyNft}>
                    {/* 000,000,000원 결제하기 */}
                    NFT {artViewPrice(item.price_UNIT, bid_nft ? item.bid_PRICE - usePoint : item.price - usePoint)} 결제하기
                  </button>
                :
                <button className="checkout-button" onClick={buy} disabled={cookiePaymentsStatus === 'loading'}>
                  {/* 000,000,000원 결제하기 */}
                  {artViewPrice(item.price_UNIT, bid_nft ? item.bid_PRICE - usePoint : item.price - usePoint)} 결제하기
                </button>
              }

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Payment
