import React, { useState } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import './HistoryInfo2.css'

function HistoryInfo2(props) {
  const [isShowMore1, setIsShowMore1] = useState(false) // 더보기 열고 닫는 스위치
  const [isShowMore2, setIsShowMore2] = useState(false) // 더보기 열고 닫는 스위치
  const [menu, setMenu] = useState(0)
  

  const [showMenuHistory, setShowMenuHistory] = useState([])
  const [showMenuSchoolItem, setShowMenuSchoolItem] = useState([])

  React.useEffect(() => {
    if(props.historyItem === null){
      return
    }
    let item = []
    const myLength = props.historyItem.length;
    const checkLength = myLength > 5 ? 5 : myLength

    for(let i = 0; i < checkLength; i ++){
      item.push(props.historyItem[i])
    }
    setShowMenuHistory(item)
  } ,[isShowMore1, props.historyItem])



  React.useEffect(() => {
    if(props.schoolItem === null || props.schoolItem === undefined){
      return
    }
    let item = []
    const myLength = props.schoolItem.length;
    const checkLength = myLength > 5 ? 5 : myLength
    for(let i = 0; i < checkLength; i ++){
      item.push(props.schoolItem[i])
    }
    setShowMenuSchoolItem(item)
  } ,[isShowMore2, props.schoolItem])


  return (
    <section>
      <SectionHeader
        title={props.title}
        moreList={props.moreList}
        setMenu={setMenu}
        active={menu}
      ></SectionHeader>
      <ul className="display-history__list">
        { menu === 0 ?
        <div className="container">
          {props.historyItem ? (
            isShowMore1 ?
            props.historyItem.map((item, i) => {
              // return item.divItem ? (
              //   item.divItem
              // ) : 
              (
                <li key={i}>
                  <span className="display-history__item-period">
                    {item.period}
                  </span>
                  <span className="display-history__item-category">
                    [{item.category}]
                  </span>
                  <span className="display-history__item-title">
                    {item.title}
                  </span>
                </li>
              )
            }) :
            showMenuHistory.map((item1, i) => {
              // return item.divItem ? (
              //   item.divItem
              // ) : 
              (
                <li key={i}>
                  <span className="display-history__item-period">
                    {item1.period}
                  </span>
                  <span className="display-history__item-category">
                    [{item1.category}]
                  </span>
                  <span className="display-history__item-title">
                    {item1.title}
                  </span>
                </li>
              )
            })
          ) : (
            <></>
          )}
          {
            props.historyItem === null ? <></>
            : props.historyItem.length > 5 ?
            <button
            className="display-history__more"
            onClick={() => setIsShowMore1(!isShowMore1)}
          >
            <p>
              <span>{!isShowMore1 ? '더보기' : '닫기' }</span>
              <span className={!isShowMore1 ? "more-arrow ir_pm" : 'colse-arrow ir_pm'}>더보기버튼</span>
            </p>
          </button> : <></>
          }
        </div> :

<div className="container">
{props.schoolItem ? (
  isShowMore2 ?
  props.schoolItem.map((item, i) => {
    // return item.divItem ? (
    //   item.divItem
    // ) : 
    (
      <li key={i}>
        <span className="display-history__item-period">
          {item.period}
        </span>
        <span className="display-history__item-category">
          [{item.category}]
        </span>
        <span className="display-history__item-title">
          {item.title}
        </span>
      </li>
    )
  }) :
  showMenuSchoolItem.map((item1, i) => {
    // return item.divItem ? (
    //   item.divItem
    // ) : 
    (
      <li key={i}>
        <span className="display-history__item-period">
          {item1.period}
        </span>
        <span className="display-history__item-category">
          [{item1.category}]
        </span>
        <span className="display-history__item-title">
          {item1.title}
        </span>
      </li>
    )
  })
) : (
  <></>
)}
{
  props.schoolItem === null ? <></>
  : props.schoolItem.length > 5 ?
  <button
  className="display-history__more"
  onClick={() => setIsShowMore2(!isShowMore2)}
>
  <p>
    <span>{!isShowMore2 ? '더보기' : '닫기' }</span>
    <span className={!isShowMore2 ? "more-arrow ir_pm" : 'colse-arrow ir_pm'}>더보기버튼</span>
  </p>
</button> : <></>
}
</div>
}
      </ul>
    </section>
  )
}
export default HistoryInfo2