import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Link, useLocation } from 'react-router-dom';
import TableSlide from '../../../../../components/TableSlide/TableSlide';

import './MyWorkList.css';

import { shallowEqual, useSelector } from 'react-redux';
import useMobileDetect from 'use-mobile-detect-hook';
import { LoadingState } from '../../../../../context/loadingStateProvider';
import { artViewDate } from '../../../../../hook/utils/artdbView';
import { handleImgError, workMainImage } from '../../../../../services/imageRoute';
import excel from '../../../../../services/myArchive/excel';
import work from '../../../../../services/myArchive/work/work';
import swal from 'sweetalert';

function MyWorkList(props) {

  const pathname = useLocation().pathname
  const { artist, gallery, type } = useSelector(v => v.auth,shallowEqual )
  const loadingState = React.useContext(LoadingState);

  const [items, setItems] = useState([]);
  const [tableItem, setTableItem] = useState([]);
  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState('');

  const detectMobile = useMobileDetect();
  const row = detectMobile.isMobile() ? 4 : 9;

  React.useEffect(() => {
    getList(currentPage, row, search)
  }, [row, currentPage, search])

  const excelExport = async() => {
    loadingState.setText('잠시만 기다려주세요.');
    loadingState.setOpen(true);
    if(pathname === '/mypage/gallery/archive'){
      await excel.ownExport('work', gallery.name, "작가별", gallery.since)
      loadingState.setOpen(false);
      loadingState.setText('');
      return
    }
    await excel.ownExport('work', artist.name, "", artist.birthday)

    loadingState.setOpen(false);
    loadingState.setText('');
    return
  }

  const getList = async (currentPage, row, search) => {
    const res = await work.getMyList(currentPage, row, search)
    setTotal(res.total)
    setItems(res.work)
  }

  const deleteWork = async (item) => {
    if(item.coa === "true"){
      swal('Q-CoA가 생성된 작품은 삭제가 불가합니다.')
      return
    }
    const res = await work.deleteWork(item.pk)
    if(!res.resultCode){
      swal('삭제에 실패했습니다.');
      return
    }
    swal('삭제 완료했습니다.');
    getList(currentPage, row, search)
  }

  const linkToType = (item) => {
    if(pathname === '/mypage/gallery/archive'){
      return `/mypage/gallery/archive/edit/${item.pk}`
    }
    if(pathname === '/mypage/artist/archive'){
      return `/mypage/artist/archive/edit/${item.pk}`
    }
    return `/mypage/general/archive/edit/${item.pk}`
  }

  React.useEffect(() => {
    const changeItem = []
    if(items === undefined){
      return
    }
    items.map((item, i) => {
      changeItem.push(
        <div className="table-slider-item" key={item.pk}>
          <ul>
            {/* <li>
              <label className="login-option">
                <input
                  type="checkbox"
                  onChange={e => checkHandler(e.target.checked, i)}
                  checked={checkItems.indexOf(i) >= 0 ? true : false}
                />
                <span className="login-option__check" />
              </label>
            </li> */}
            <li>{(total) - (row * currentPage + i)}</li>
            <li>
              <img src={`${workMainImage}/${item.image}`} onError={handleImgError} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
            </li>
            <li>{item.title === '' ? item.eng_TITLE : item.title}</li>
            <li>{item.material}</li>
            <li>{item.start_CREATE}<br />{item.end_CREATE}</li>
            <li>{item.coa === 'null' ? item.collection : item.ownerDto.user_CODE}</li>
            <li>
              {item.coa !== 'null' ? (
                <span className="regis-status regis-compelete">등록완료</span>
              ) : (
                <span className="regis-status ">미등록</span>
              )}
            </li>
            <li>
              {item.market === 2 ? (
                // 마켓플레이스로 이동
                <p className="sell-status">경매</p>
              ) :item.market === 1 ? (
                // 마켓플레이스로 이동
                <p className="sell-status">판매</p>
              ) : (
                <p className="sell-status sell-compelete">비매</p>
              )}
            </li>
            <li>
              <Link to={linkToType(item)}>
                <button className="edit-btn">Edit</button>
              </Link>
            </li>
            <li>
                <button className="edit-btn" onClick={() => deleteWork(item)}>Delete</button>
            </li>
          </ul>
          <div className="work-item-mb">
            <div className="work-item-mb-container">
              {/* <label className="login-option">
                <input
                  type="checkbox"
                  onChange={e => checkHandler(e.target.checked, i)}
                  checked={checkItems.indexOf(i) >= 0 ? true : false}
                />
                <span className="login-option__check" />
              </label> */}
              <div className="work-item-mb-content">
                <div className="work-item-mb__info">
                  <img src={`${workMainImage}/${item.image}`} onError={handleImgError} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
                  <div className="work-item-mb__detail">
                    <h4>[ {item.coa === 'null' ? item.collection : item.ownerDto.user_CODE} ]</h4>
                    <h1>{item.title === '' ? item.eng_TITLE : item.title}</h1>
                    <h3>{item.material} | {artViewDate(item.start_CREATE,item.end_CREATE)}</h3>
                    {item.coa !== 'null' ? (
                      <h3 className="regis-status regis-compelete">Q-CoA 등록완료</h3>
                    ) : (
                      <h3 className="regis-status ">Q-CoA 미등록</h3>
                    )}
                  </div>
                  <button className="work-item-mb__delete" onClick={() => deleteWork(item)}></button>
                </div>
                <div className="work-item-mb__state">
                {item.market === 2 ? (
                // 마켓플레이스로 이동
                <button className="sell-status">경매</button>
              ) :item.market === 1 ? (
                // 마켓플레이스로 이동
                <button className="sell-status">판매</button>
              ) : (
                <button className="sell-status sell-compelete">비매</button>
              )}
                  <Link to={linkToType(item)}>Edit</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
    setTableItem([...changeItem])
  }, [items])

  const titleItem = []

  titleItem.push(
    <div className="table-header-search">
      <h2>
        { props.type === 'general' ? "My Collection List" : "My Work List"} <span className="download-button" style={{cursor: 'pointer'}} onClick={excelExport}></span>
      </h2>
      <div className="th-search-container">
        <section className="search__block">

          <DebounceInput
            minLength={1}
            debounceTimeout={500}
            type={'text'}
            placeholder={'작품을 검색해주세요'}
            value={search}
            onChange={e => { setSearch(e.target.value); setCurrentPage(0) }}
            className="search__block__input"
          />
          <button className="search__block__button">
            <span className="ir_pm">검색</span>
          </button>
        </section>
        {/* {type !== 0 && */}
        <section className="th-search-button">
          {/* <Link to="/mypage/excel"> */}
            <button className="long-button not-ready">Add to Excel</button>
          {/* </Link> */}
          <Link to="./work">
            <button className="last-button">Add</button>
          </Link>
        </section>
        {/* } */}
        <section className="th-mb-search-button">
          {/* <label className="login-option">
            <input
              type="checkbox"
              onChange={e => checkAllHandler(e.target.checked)}
              checked={
                checkItems.length === 0
                  ? false
                  : checkItems.length === tableItem.length
                    ? true
                    : false
              }
            />
            <span className="login-option__check" />
            모두선택
          </label> */}
          <Link to="./work">
            <button>Add</button>
          </Link>
        </section>
      </div>
    </div>
  )
  const tableSlideHeader = []
  tableSlideHeader.push(
    <ul className="container table-slider-header-container">
      {/* <li>
        <label className="login-option">
          <input
            type="checkbox"
            onChange={e => checkAllHandler(e.target.checked)}
            checked={
              checkItems.length === 0
                ? false
                : checkItems.length === tableItem.length
                  ? true
                  : false
            }
          />
          <span className="login-option__check" />
        </label>
      </li> */}
      <li>번호</li>
      <li>대표 이미지</li>
      <li>작품명</li>
      <li>재질</li>
      <li>제작일</li>
      <li>소장처</li>
      <li>Q-CoA</li>
      <li>마켓플레이스</li>
      <li>편집</li>
      <li>삭제</li>
    </ul>
  )
  return (
    <div className="my-work-list">
      <TableSlide
        title={titleItem}
        slideHeader={tableSlideHeader}
        items={tableItem}
        rows={row}
        breakRows={5}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}
export default MyWorkList
