import React from "react";
import "./DicModal.css";
function ModalBasic({ setModalOpen, search, searchClick }) {
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = "unset"; // 모달 창 종료 시 스크롤 가능
  };


  const koList = [
    {
      label: 'ㄱ',
      value: 'ㄱ'
    },
    {
      label: 'ㄴ',
      value: 'ㄴ'
    },
    {
      label: 'ㄷ',
      value: 'ㄷ'
    },
    {
      label: 'ㄹ',
      value: 'ㄹ'
    },
    {
      label: 'ㅁ',
      value: 'ㅁ'
    },
    {
      label: 'ㅂ',
      value: 'ㅂ'
    },
    {
      label: 'ㅅ',
      value: 'ㅅ'
    },
    {
      label: 'ㅇ',
      value: 'ㅇ'
    },
    {
      label: 'ㅈ',
      value: 'ㅈ'
    },
    {
      label: 'ㅊ',
      value: 'ㅊ'
    },
    {
      label: 'ㅋ',
      value: 'ㅋ'
    },
    {
      label: 'ㅍ',
      value: 'ㅍ'
    },
    {
      label: 'ㅌ',
      value: 'ㅌ'
    },
    {
      label: 'ㅎ',
      value: 'ㅎ'
    },
    {
      label: '기타',
      value: '1'
    }
  ]

  const enList = [
    {
      label: 'A',
      value: 'A'
    },
    {
      label: 'B',
      value: 'B'
    },
    {
      label: 'C',
      value: 'C'
    },
    {
      label: 'D',
      value: 'D'
    },
    {
      label: 'E',
      value: 'E'
    },
    {
      label: 'F',
      value: 'F'
    },
    {
      label: 'G',
      value: 'G'
    },
    {
      label: 'H',
      value: 'H'
    },
    {
      label: 'I',
      value: 'I'
    },
    {
      label: 'J',
      value: 'J'
    },
    {
      label: 'K',
      value: 'K'
    },
    {
      label: 'L',
      value: 'L'
    },
    {
      label: 'M',
      value: 'M'
    },
    {
      label: 'N',
      value: 'N'
    },
    {
      label: 'O',
      value: 'O'
    },
    {
      label: 'P',
      value: 'P'
    },
    {
      label: 'Q',
      value: 'Q'
    },
    {
      label: 'R',
      value: 'R'
    },
    {
      label: 'S',
      value: 'S'
    },
    {
      label: 'T',
      value: 'T'
    },
    {
      label: 'U',
      value: 'U'
    },
    {
      label: 'V',
      value: 'V'
    },
    {
      label: 'W',
      value: 'W'
    },
    {
      label: 'X',
      value: 'X'
    },
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'Z',
      value: 'Z'
    },
    {
      label: 'etc',
      value: '2'
    }
  ]

  return (
    <div className="modal-container" onClick={closeModal}>
      <div className="modal-body" onClick={(e) => e.stopPropagation()}>
        <div className="modal-item__dic">
          <ul>
{koList.map((ko, i) => {
                return (
                  <li key={ko.value}>
                    <button
                      className={search === ko.value ? "click" : ""}
                      onClick={() => {searchClick(ko, 'ko'); closeModal();}}
                    >{ko.label}</button>
                  </li>
                )
              })}
          </ul>
          <ul>
          {enList.map((en, i) => {
                return (
                  <li key={en.value}>
                    <button 
                      className={search === en.value ? "click" : ""}
                      onClick={() => {searchClick(en, 'en'); closeModal();}}
                      >{en.label}</button>
                  </li>
                )
              })}
          </ul>
        </div>
        <button className="modal-close" onClick={closeModal}>
          <span className="ir_pm">카테고리 닫기</span>
        </button>
      </div>
    </div>
  );
}
export default ModalBasic;
