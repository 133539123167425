import React from 'react';
import swal from 'sweetalert';
import { handleInputMaxLength } from '../../hook/utils/validation';
import './ReturnNoticeModal.css';

function ReturnNoticeModal({ setModalOpen, callFunc, memo, setMemo, returnType }) {

  const [type, setType] = React.useState(0);

  React.useEffect(() => {
    setMemo('')
  },[]);
  
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
  }

  const action = async () => {
    if (memo.length === 0) {
      swal('취소/반품 사유를 입력해주세요.');
      return;
    }
    await callFunc();
  }

  return (
    <div className="return-notice-info-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()} >
          <h1 style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>취소/반품 신청</h1>
          {type === 0 &&
            <>

              <ul>
                <li>
                  <h2>단순 변심에 의한 취소는 불가합니다.</h2>
                </li>
                <li>
                  <h2>큐아트는 통신판매중개자로, 취소 및 반품 절차는 판매자와 구매자 간의 합의후에 최종적인 환불이 가능합니다.</h2>
                </li>
              </ul>
              <div className="return-notice-btn">
                <button onClick={closeModal}>취소</button>
                <div style={{ width: 12 }}></div>
                <button onClick={() => setType(1)}>확인</button>
              </div>

            </>
          }
          {type === 1 &&
            <>

                  <h1>사유</h1>

                  <div className='content-text' style={{width: '100%'}}>
                  <textarea
                    style={{ width: '100%', resize: 'none', margin: '20rem 0 10rem 0' }}
                    className='my__textarea'
                    maxlength="255"
                    placeholder='255자 이내로 입력해주세요.'
                    autoComplete='off'
                    value={memo}
                    onChange={e => handleInputMaxLength(setMemo, e)}
                    ></textarea>
                        <span>{memo === null ? '0/255' : `${memo.length}/255`}</span>
                    {/* <span>{content.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length}/2000</span> */}
                  </div>

                 
                  


              <div className="return-notice-btn">
                <button onClick={closeModal}>취소</button>
                <div style={{ width: 12 }}></div>
                <button onClick={action}>{returnType === 'not-delivery' ? "최종 신청" : "다음"}</button>
              </div>

            </>
          }
        </div>
      </div>
    </div>
  )
}

export default ReturnNoticeModal
