import swal from 'sweetalert';
import client from '../../index';
import work from './work';


/**
  대여 요청(대여자)
  * @param {String} pin 사용할 pin 
  * @param {date} start_date 시작일
  * @param {date} date 반납일
  * @param {number} type 요청자 type
  * @param {number} pk 작품 pk
  * @param {number} key 작품 uniqueKey
  * @param {number} upk 유저 pk
  * @param {String} exhibitionName 전시명
  * @param {String} exhibitionPlace 전시장소
*/
const requst= async(pin, start_date, end_date, type, pk, key, upk, exhibitionName, exhibitionPlace) => {

  const postData = {
    new_pin: pin,
    start_date: start_date,
    request_date: end_date,
    requestor_type: type,
    work_id: pk,
    unique_key: key,
    requestor_id: upk,
    exhibition_name: exhibitionName,
    exhibition_place: exhibitionPlace
  }

    const res = await client.json.post('/user/work/rental/request', postData);
    return res.data;
}

/**
  대여 요청 리스트(주인)
  * @param {number} page
  * @param {number} count
*/
const requstList = async(page, count) => {
  const postData = {
    page: page,
    count: count
  }
    const res = await client.json.get('/user/work/rental/getRequest', {params: postData});
    return res.data;
}

/**
  대여 승인(주인)
  * @param {number} pk 요청리스트 pk
  * @param {String} pin 현재 pin
*/
const admission= async(pk, pin) => {
  const data = {
    pk: pk,
    pin : pin
  }
    const res = await client.json.post('/user/work/rental/admission', data);
    return res.data;
}

/**
  대여 거부(주인)
  * @param {number} requestorId 요청아이디
  * @param {number} key 작품의 uniqueKey
*/
const deny= async(requestorId, key) => {
  const data = {
    requestor_id: requestorId,
    unique_key : key
  }
    const res = await client.json.post('/user/work/rental/deny', data);
    return res.data;
}





/**
  대여 연장 요청(대여자)
  * @param {number} pk 요청 pk
  * @param {number} requestorId 요청자id
  * @param {date} date 반납일
  * @param {number} ownerId 주인id
  * @param {number} key 작품 uniqueKey
  * @param {String} reason 연장 이유
*/
const extensionRequst= async(pk, requestorId, date, ownerId, key, reason) => {
  const postData = {
    request_pk: pk,
    borrower_id: requestorId,
    extension_date: date,
    owner_id: ownerId,
    unique_key: key,
    reason: reason
  }
    const res = await client.json.post('/user/work/rental/extension', postData);
    return res.data;
}

/**
  대여 연장 요청 리스트(주인)
  * @param {number} page
  * @param {number} count
  * @param {number} uPk
*/
const extensionRequstList = async(page, count, uPk) => {
  const postData = {
    page: page,
    count: count,
    owner_id: uPk
  }

  console.log(postData)
  
    const res = await client.json.get('/user/work/rental/extension/getListByOwner', {params: postData});
    return res.data;
}

/**
  대여 연장 승인(주인)
   * @param {number} key 작품 uniqueKey
*/
const extensionAdmission= async(key) => {
  const postData = {
    unique_key: key
  }
    const res = await client.json.post('/user/work/rental/extension/admission', postData);
    return res.data;
}

/**
  대여 연장 거부(주인)
   * @param {number} key 작품 uniqueKey
*/
const extensionDeny= async(key) => {
  const postData = {
    unique_key: key
  }
    const res = await client.json.post('/user/work/rental/extension/deny', postData);
    return res.data;
}



/**
  내가 대여 중인 리스트(주인)
  * @param {number} page
  * @param {number} count
*/
const returnList = async(page, count) => {
  const postData = {
    page: page,
    count: count
  }
    const res = await client.json.get('/user/work/rental', {params: postData});
    return res.data;
}


/**
  오프라인 판매
  * @param {String} email 구매자 이메일
  * @param {String} key 작품의 uniqueKey
  * @param {String} price 판매 가격(작품 QR 정보)
  * @param {String} pin 현재 작품의 간편패스워드
*/
const offlineSell = async(email, key, price, pin) => {

  const enc = await work.getEnc(key)

  if(!enc.data){
    swal('관리자에게 문의해주세요.')
    return
  }
  const postData = {
    D_EMAIL : email,
    ENC_SERIAL: enc.data,
    PRICE: price,
    SHORT_PASSWORD: pin
  }
    const res = await client.json.post('/user/work/offline/sellProc', postData);
    return res.data;
}


/**
  선물하기
  * @param {String} email 구매자 이메일
  * @param {String} key 작품의 uniqueKey
  * @param {String} pin 현재 작품의 간편패스워드
*/
const pRequst = async(email, key, pin) => {

  const postData = {
    D_EMAIL : email,
    UNIQUE_KEY: key,
    SHORT_PASSWORD: pin
  }
    const res = await client.json.post('/user/work/present', postData);
    return res.data;
}

/**
    배송이 확정된 후 선물받기 확정(블록체인 상 소유권 이전)
   * @param {number} pk
   * @param {String} pin 사용할 pin
*/

const pConfirmed = async(pk, pin) => {
  const postData = {
      NEW_SHORT_PASSWORD: pin
  }
  const res = await client.json.post(`/user/work/market/present/${pk}`, postData);
  return res.data;
}


/**
  (구매) 핀 초기화 여부 판단
  * @param {String} key 작품의 uniqueKey
*/
const checkPin = async(key) => {
  const postData = {
    unique_key: key
  }

    const res = await client.json.post('/user/work/offline/pinCheck', postData);
    return res.data;
}


/**
  (구매) 핀 재 설정
  * @param {String} pk 작품의 pk
  * @param {String} key 작품의 uniqueKey
  * @param {String} pin 새로운 간편 패스워드
*/
const registerPin = async(pk, key, pin) => {
  const postData = {
    work_id: pk,
    unique_key: key,
    pin: pin
  }
    const res = await client.json.post('/user/work/offline/pinSetting', postData);
    return res.data;
}


/**
  (구매) 즉시 반납
  * @param {String} pk 작품의 pk
  * @param {String} key 작품의 uniqueKey
  * @param {String} uPk 반납 유저의 pk
  * @param {String} pin 간편 패스워드
*/
const rentalReturn = async(pk, key, uPk, pin) => {
  const postData = {
    work_id: pk,
    unique_key: key,
    requestor_id: uPk,
    pin: pin
  }
    const res = await client.json.post('/user/work/return/request', postData);
    return res.data;
}


/**
  경매로 낙찰된 작품, 마켓으로 구매한 작품, 구매한 작품 리스트 조회
  * @param {number} page  요청할 페이지
  * @param {number} count  요청할 데이터 갯수
*/
const tradeList = async(page, count) => {
  const postData = {
    page: page,
    count: count,
  }
    const res = await client.json.get('/user/notification/delivery', {params: postData} );
    return res.data;
}



const rental = {
    requst, requstList, admission, deny,
    extensionRequst, extensionRequstList,extensionAdmission, extensionDeny,

    rentalReturn,
    returnList,
}

const sell = {
  offlineSell, checkPin, registerPin
}

const present = {
  pConfirmed, pRequst
}


const trade = {rental, sell, tradeList, present}
export default trade
