import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { LoadingState } from '../../context/loadingStateProvider';
import auth from '../../services/auth/auth';
import profile from '../../services/auth/profile';
import pay2pay from '../../services/pay2pay/pay';
import * as authAction from '../../store/reducers/auth/actions';
import './CardInfoModal.css';

function CardInfoModal(props) {

  const loadingState = React.useContext(LoadingState);

  const { setModalOpen, selected, isSignup } = props;
  const { type } = useSelector(v => v.auth, shallowEqual)

  const [cardNo, setCardNo] = useState('')
  const [cardAuth, setCardAuth] = useState('')
  const [cardExpiredMM, setCardExpiredMM] = useState('')
  const [cardExpiredYY, setCardExpiredYY] = useState('')
  const [cardPwd, setCardPwd] = useState('')
  const [isHana, setIsHana] = useState(false)


  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
  }

  const action = async () => {

    if (cardNo.length !== 16) {
      swal('카드번호를 16자리를 입력해주세요');
      return;
    }

    const cardExpired = `${cardExpiredYY}${cardExpiredMM}`
    if (cardExpired.length !== 4) {
      swal('카드 유효기간을 입력해주세요.');
      return;
    }

    if (cardAuth.length <= 5) {
      swal('주민번호/사업자번호를 입력해주세요.');
      return;
    }

    if (cardPwd.length !== 2) {
      swal('카드 비밀번호 2자리를 입력해주세요.');
      return;
    }

    if (selected === 'STARTER') {
      if (isSignup) {
        return navigate('/');
      } else {
        return window.location.reload();
      }
    }
    loadingState.setText('결제중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);
    
    const orderTyp = selected === 'BASIC' ? 1 : selected === "PREMIUM" ? 2 : 0;

    const res = await pay2pay.subscribe(orderTyp, isHana, cardNo, cardPwd, cardExpired, cardAuth);

    if(res.RESULTCODE !== '0000'){
      swal(res.RESULTMSG.replaceAll(' ', ''));
      loadingState.setOpen(false);
      loadingState.setText('');
      return;
    }

    await profile.subscribe("YEAR", selected ).then(async () => {
      if (type === 2) {
        const agency = await auth.getAgencyInfo()
        dispatch(authAction.SET_GALLERY_INFO(agency))
      } else {
        const artist = await auth.getArtistInfo()
        dispatch(authAction.SET_ARTIST_INFO(artist.data))
      }

      swal('결제가 완료되었습니다.');
      loadingState.setOpen(false);
      loadingState.setText('');

      if (isSignup) {
        return navigate('/');
      } else {
        return window.location.reload();
      }
    });
  }

  return (
    <div className="delivery-info-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()} >
          <h1 style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>{selected === 'BASIC' ? "베이직" : "프리미엄"} 정기 결제</h1>
          <ul>
            <li style={{ flexDirection: 'column' }}>
              <div className='item__s'>
                <h2>카드번호</h2>
                <input
                  type="text"
                  maxLength={16}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="-를 제외하고 입력해주세요."
                  value={cardNo}
                  onChange={e => setCardNo(e.target.value)}
                ></input>
              </div>
            </li>
            <li style={{ flexDirection: 'column' }}>
              <div className='item__s'>
                <h2>주민번호</h2>
                <input
                  type="text"
                  maxLength={10}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  placeholder='생년월일 6자리'
                  value={cardAuth}
                  onChange={e => setCardAuth(e.target.value)}
                ></input>
              </div>
              <span className='sub__message'>사업자일 경우, 사업자번호 10자리</span>
            </li>
            <li>
              <h2>유효기간</h2>
              <div className='two__input'>
                <input
                  autoComplete='off'
                  maxLength={2}
                  type="text"
                  className='two__input_el'
                  placeholder='MM'
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={cardExpiredMM}
                  onChange={e => setCardExpiredMM(e.target.value)}
                ></input>
                <input
                  autoComplete='off'
                  maxLength={2}
                  type="text"
                  className='two__input_el'
                  placeholder='YY'
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={cardExpiredYY}
                  onChange={e => setCardExpiredYY(e.target.value)}
                ></input>
              </div>
            </li>
            <li>
              <h2>비밀번호</h2>
              <div className='two__input'>
                <input
                  autoComplete='off'
                  maxLength={2}
                  type="password"
                  className='two__input_el'
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={cardPwd}
                  onChange={e => setCardPwd(e.target.value)}
                ></input>
                <h2>**</h2>
              </div>
            </li>
          </ul>

          <div className='item__s'>
            <input
              autoComplete='off'
              type="checkbox"
              className='info-option__check_card'
              checked={isHana}
              onChange={e => setIsHana(e.target.checked)}
            />
            <span className='is_hana'>하나카드일 경우 체크해주세요.</span>
          </div>

          <div className="delivery-btn">
            <button onClick={closeModal}>취소</button>
            <div style={{ width: 12 }}></div>
            <button onClick={action}>확인</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardInfoModal
