
export const setHypen = (e, setPhone) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {

      const value = e.target.value.replace(/\D+/g, "");
      const numberLength = 11;
  
      let result;
      result = "";  
  
      for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
          case 3:
            result += "-";
            break;
          case 7:
            result += "-";
            break;
  
          default:
            break;
        }
        result += value[i];
      }
      setPhone(result);
    }
  }