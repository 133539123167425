import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../pages/MyPage/General/Like/GeneralLike.css'
import DropDown from '../DropDown/DropDown'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import './ArtDetailOption.css'


function ArtDetailOption(props) {
  const dispatch = useDispatch()

  const {
    title_k,
    title_e,
    menu,
    setMenu,
    options,
    total,
    rows, slidersPerRow,
    currentPage,
    setCurrentPage,
    items,
    isAuction
  } = props

  const PAGE_PER_ITEM = rows * slidersPerRow
  const [TOTAL_PAGE, SET_TOTAL_PAGE] = useState(0)
  const [dotArr, setDotArr] = useState([])

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    SET_TOTAL_PAGE(Math.ceil(total / PAGE_PER_ITEM))
  }, [total])

  React.useEffect(() => {
    ShowPaginationNumbers(currentPage + 1)
  }, [currentPage, total, TOTAL_PAGE])


  function ShowPaginationNumbers(currentPage) {
    try {
      let startPage
      let endPage

      if (TOTAL_PAGE <= 5) {
        startPage = 1
        endPage = TOTAL_PAGE
      } else {
        if (currentPage <= 3) {
          startPage = 1
          endPage = 5
        } else if (currentPage + 1 >= TOTAL_PAGE) {
          startPage = TOTAL_PAGE - 4
          endPage = TOTAL_PAGE
        } else {
          startPage = currentPage - 2
          endPage = currentPage + 2
        }
      }

      let numArr = []

      if (startPage === endPage) {
        numArr.push(1)
      } else {
        for (var i = startPage; i <= endPage; i++) {
          numArr.push(i)
        }
      }

      setDotArr(numArr)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="art-d">
      {props.detail ? (
        <Header active="0" colored="black" detail={true} />
      ) : (
        <></>
      )}
      <header className="art-d-header">
        <div>
          <div className="container art-d-header-container">
            <div className="art-d-header__info">
              {title_e ? <h4 style={matches ? { paddingTop: '8rem' } : {}}>{title_e}</h4> : <></>}
              <h2 style={matches ? { fontSize: '16rem' } : {}}>{title_k}</h2>
            </div>
            {options !== undefined &&
              <div className="art-d-header__drop">
                <DropDown
                  options={options}
                  select={options.indexOf(menu)}
                  setValue={setMenu}
                ></DropDown>
              </div>
            }
          </div>
        </div>
      </header>

      {
        isAuction && items.length == 0 ?
          <section className='art-d--content'>

            <div className='empty_img' />
          </section>
          :

          <section className="container art-d-slider">
            <div className="art-d-slider__list">
              <button
                className="slick-first"
                onClick={() => {
                  setCurrentPage(0)
                }}
              >
                <span className="ir_pm">처음으로</span>
              </button>

              <button
                className="slick-prev"
                onClick={() => {
                  if (currentPage !== 0) {
                    setCurrentPage(currentPage - 1)
                  }
                }}
              >
                <span className="ir_pm">이전</span>
              </button>

              <div className="slick-dots">
                <ul className="slick-dots-container">
                  {dotArr.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => setCurrentPage(item - 1)}
                        style={{ display: 'inline-block' }}
                        className={
                          dotArr[index] === currentPage + 1 ? 'slick-active' : ''
                        }
                      >
                        <div className="dots-number">{item}</div>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <button
                className={'slick-arrow slick-next'}
                onClick={() => {
                  if (currentPage !== TOTAL_PAGE - 1) {
                    setCurrentPage(currentPage + 1)
                  }
                }}
              >
                <span className="ir_pm">다음</span>
              </button>

              <button
                className="slick-last"
                onClick={() => {
                  setCurrentPage(TOTAL_PAGE - 1)
                }}
              >
                <span className="ir_pm">끝으로</span>
              </button>
              <div className="art-d-slider__item-my-row  slick-list">
                {items}
              </div>
            </div>
          </section>
      }
      {props.detail ? <Footer /> : <></>}
    </div>
  )
}
export default ArtDetailOption
