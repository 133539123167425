import React from 'react'
import WorkHeader from '../WorkHeader/WorkHeader'
import './GalleryInfo.css'
import setting from '../../services/setting'
import useMobileDetect from 'use-mobile-detect-hook'
import { sanitize } from 'dompurify'
import { useNavigate } from 'react-router-dom'


function GalleryInfo(props) {
  
  const detectMobile = useMobileDetect();
  const navigate = useNavigate();
  
  const getContent = (temp1, temp2) => {
    const isMobile = detectMobile.isMobile() || window.innerWidth < 480;

    let content;
    if(isMobile){
      content = temp2
    }else{
      content = temp1
    }

    const returnData = content
    .replaceAll('<img src="data:image', '<img style="display: none;" src="data:image')
    .replaceAll('<iframe class="ql-video', '<iframe style="display: none; class="ql-video')    
    return returnData
  }


  const gallery_items = (imgList) => {
    return imgList.map( (item, i) => {
      // setDetail([...detail, 
        return(
          <div className="gallery__item" onClick={() => navigate(`/artdb/${item.link}/${item.pk}`)}>
            <img
              className="gallery__item-image" 
              alt=''
              src={item.info}
              onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }
            />
            <div
              className="gallery__item-info"
              style={{ order: `${i % 2 === 0 ? 0 : -1}` }}
            >
              {/* <a href="#"> */}
                <h3>{item.title}</h3>
                <div
                className='html_item'
                dangerouslySetInnerHTML={{__html: sanitize(getContent(item.detail, item.detailM))}}
              />
                {/* <p>{getContent(item.pk)}</p> */}
              {/* </a> */}
            </div>
          </div>
        )
        // )]);
    })
  }

  return (
    <section className="gallery_section">
      <WorkHeader title_e={props.title_e} title_k={props.title_k} />
      {gallery_items(props.items)}

    </section>
  )
}
export default GalleryInfo
