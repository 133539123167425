import React from 'react'
import { Link, useParams } from 'react-router-dom'
import './QartsTalkDetail.css'

import { sanitize } from 'dompurify'
import useMobileDetect from 'use-mobile-detect-hook'
import BigTopArrow from '../../../../components/TopArrowSlider/BigTopArrow/BigTopArrow'
import WorkHeader from '../../../../components/WorkHeader/WorkHeader'
import { setBidTime } from '../../../../hook/utils/getDateStr'
import { qTalkImage } from '../../../../services/imageRoute'
import marketPick from '../../../../services/public/market/pick'
import { LoadingState } from '../../../../context/loadingStateProvider'

function QartsTalkDetail() {
  const detectMobile = useMobileDetect();
  const loadingState = React.useContext(LoadingState);

  const { qartstalkid } = useParams();

  React.useEffect(() => { getData(qartstalkid) }, [qartstalkid])
  const [result, setResult] = React.useState([])
  const [mobileResult, setMobileResult] = React.useState([])
  const [data, setData] = React.useState();
  const [list, setList] = React.useState();


  const getData = async (pk) => {
    loadingState.setText('잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await marketPick.qTalkDetail(pk);
    setData(res.data);
    setList(res.another_list);
    loadingState.setOpen(false);
    loadingState.setText('');
  }

  React.useEffect(() => {
    if (list === undefined) return;
    const myItem = []
    for (let i = 0; i < list.length; i++) {
      myItem.push(
        {
          pk: list[i].pk,
          link: `/market/qartstalk`,
          info: `${qTalkImage}/${list[i].thumbNail}`,
          title: list[i].title,
          date: setBidTime(list[i].createTime)
        }
      )
    }
    setResult(myItem)
  }, [list])

  React.useEffect(() => {
    if (list === undefined) return;

    const myItem = [];
    for (let i = 0; i < list.length; ++i) {
      myItem.push(
        <Link
          key={i}
          to={`/market/qartstalk/${list[i].pk}`}
          className="art-md__list-item"
          style={detectMobile.isMobile() ? { width: '150rem', marginLeft: '8rem', marginRight: '0rem' } : {}}
        >
          <img alt='' src={`${qTalkImage}/${list[i].thumbNail}`} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
          <h2 style={detectMobile.isMobile() ? { marginTop: '6rem' } : { marginTop: '24rem' }}>{list[i].title}</h2>
          <h3>{setBidTime(list[i].createTime)}</h3>
        </Link>
      )
    }

    setMobileResult(myItem);
  }, [list])

  return (
    <div className='qarts-talk-detail'>
      <WorkHeader
        type={1}
        title_k={data === undefined ? "" : data.title}
        title_e={data === undefined ? "" : setBidTime(data.createTime)}
        isMobile={false}
      />
      <section className="container detail-box">
        <ul>
          <li>
            <div
              className="qarts-talk-detail__comment ql-editor"
              dangerouslySetInnerHTML={{
                __html: sanitize(data === undefined ? "" :
                  detectMobile.isMobile() ?
                    data.contentM : data.content)
              }
              }
            />

          </li>
        </ul>
      </section>

      {
        detectMobile.isMobile() ?

          <WorkHeader
            type={1}
            title_k={'다른 게시물'}
            isMobile={false}
          />
          :

          <BigTopArrow
            title_k={'다른 게시물'}
            items={result}
            i_style={{height: '800rem'}}
          />
      }
      {
        detectMobile.isMobile() ?
          <section className="art-md--content" style={{ height: '500rem', overflow: 'scroll' }}>
            <div className="container">
              <section className="art-md--content__list" style={{ paddingLeft: 0 }}>
                {mobileResult}
              </section>
            </div>
          </section>
          :
          <></>
      }


    </div>
  )
}

export default QartsTalkDetail