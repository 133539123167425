import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import DropDown from '../../../../../components/DropDown/DropDown';
import Footer from '../../../../../components/Footer/Footer';
import Header from '../../../../../components/Header/Header';
import SectionHeader from '../../../../../components/SectionHeader/SectionHeader';
import { checkNumber, onChangePoints, setComma } from '../../../../../hook/utils/comma';
import work from '../../../../../services/myArchive/work/work';

import './MyWorkAdd2.css';

import HistoryModal from '../../../../../components/Modal/HistoryModal';
import ModalPortal from '../../../../../components/Modal/ModalPortal';
import { LoadingState } from '../../../../../context/loadingStateProvider';
import { checkMainImageSize, checkSubImageSize, workImageExtension, workImageExtensionList } from '../../../../../hook/utils/fileSize';
import { checkDate, handleInputMaxLength } from '../../../../../hook/utils/validation';


function MyWorkAdd2() {

  const navigate = useNavigate();
  const loadingState = React.useContext(LoadingState);

  const { undefinedList, undefinedDateList, timeSetList, rowWorkTypeList, editionList, archiveList, unitList, saleTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const [workTypeList, setWorkList] = useState([])

  React.useEffect(() => {
    const item = [];
    for (let i = 0; i < rowWorkTypeList.length -1; i++) {
      item.push(rowWorkTypeList[i][`${lang}`])
    }
    setWorkList(item)
  }, [rowWorkTypeList])



  const [mainImage, setMainImage] = useState(null)
  const [mainImageShow, setMainImageShow] = useState(null)
  const [beforeImg, setBeforeImg] = useState(null)

  const [subImage, setSubImage] = useState([])
  const [subImageShow, setSubImageShow] = useState([])

  const [koTitle, setKoTitle] = useState('')
  const [enTitle, setEnTitle] = useState('')



  const [knowArtistName, setKnowArtistName] = useState(undefinedList[0])
  const [knowArtistBirth, setKnowArtistBirth] = useState('')
  const [koArtist, setKoArtist] = useState('')
  const [enArtist, setEnArtist] = useState('')
  const [artistBirth, setArtistBirth] = useState('')
  const [artistDeath, setArtistDeath] = useState('')



  const [timeSet, setTimeSet] = useState()
  const [workType, setWorkType] = useState()
  const [know, setKnow] = useState(undefinedDateList[0])
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [material, setMaterial] = useState('')
  const [unit, setUnit] = useState('cm')
  const [edition, setEdition] = useState('없음')
  const [totalEdition, setTotalEdition] = useState('')
  const [totalIssue, setTotalIssue] = useState('')
  const [vertical, setVertical] = useState('')
  const [horizontal, setHorizontal] = useState('')
  const [width, setWidth] = useState('')
  const [collection, setCollection] = useState('')
  const [detail, setDetail] = useState('')
  const [saleType, setSaleType] = useState('원화 (원)')
  const [price, setPrice] = useState('')

  const [view, setView] = useState('공개')
  const [history, setHistory] = useState([])
  const [lock, setLock] = useState(false)

  const [modalOpen, setModalOpen] = React.useState(false)
  const [exhibition, setExhibition] = React.useState([])
  const [exhibitionTable, setexhibitionTable] = React.useState(null)
  const [exhibitionTableMobile, setexhibitionTableMobile] = React.useState(null)


  React.useEffect(() => {
    const table = []
    const tableMobile = []
    if (exhibition.length === 0) {
      setexhibitionTable(table)
    setexhibitionTableMobile(tableMobile)
      return
    }
    // console.log(`exhi :: ${JSON.stringify(exhibition)}`)
    exhibition.map((item, index) => {
      table.push(
        <li className="ap-list" key={index}>
          <span>{index + 1}</span>
          <span>{item.exhi_NAME}</span>
          <span>{item.place}</span>
          <span>{item.country} </span>
          <span>{item.start_DATE}<br />{item.end_DATE}</span>
          <span>{item.type}</span>

          <div className="gp-liat--button">
           <button onClick={() => {setExhibition(exhibition.filter(data => data.pk !== item.pk))}}>삭제</button>
          </div>
        </li>
      )
      tableMobile.push(
        <li className="ap-list-mb">
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전시명</span>
            <span>{item.exhi_NAME}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">개최장소</span>
            <span>
              {item.place}
            </span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">국가</span>
            <span>{item.country}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전시일</span>
            <span>{item.start_DATE}~{item.end_DATE}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전시유형</span>
            <span>{item.type}</span>
          </div>
          <div className="ap-list-mb-button">
          <button onClick={() => {setExhibition(exhibition.filter(data => data.pk !== item.pk))}}>삭제</button>
          </div>
        </li>
      )
    })
    setexhibitionTable(table)
    setexhibitionTableMobile(tableMobile)
  }, [exhibition])

  React.useEffect(() => {
    setKnowArtistBirth(knowArtistName)
  }, [knowArtistName])

  React.useEffect(() => {
    if (price !== '') {
      setPrice('')
    }
  }, [saleType])

  const saveImgFile = e => {
    e.preventDefault();

    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkMainImageSize(file.size)
    if(!check) return

    setMainImage(file);
    setMainImageShow(URL.createObjectURL(file));
  }

  const saveSubImgFile = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkSubImageSize(file.size)
    if(!check) return

    setSubImage([...subImage, file]);
    setSubImageShow([...subImageShow, URL.createObjectURL(file)]);
  }

  const deleteSubImgFile = (e, i) => {
    e.preventDefault();
    setSubImage(subImage.filter((img, index) => index !== i))
    setSubImageShow(subImageShow.filter((img, index) => index !== i))
  }

  // const excelToList = (e) => {
  //   e.preventDefault();
  //   const fileList = e.target.files
  //   const file = fileList[fileList.length - 1]


  //   const data = []
  //   readXlsxFile(file).then((rows) => {
  //     for (let i = 1; i < rows.length; i++) {
  //       if (rows[i][0] !== null) {
  //         const json = {
  //           exhi_NAME: rows[i][0],
  //           country: rows[i][2],
  //           place: rows[i][1],
  //           type: rows[i][3],
  //           start_DATE: getDateStr(rows[i][4], '-'),
  //           end_DATE: getDateStr(rows[i][5], '-'),
  //           hoster: rows[i][6],
  //           planner: rows[i][7],
  //           publisher: rows[i][8],
  //           note: rows[i][9]
  //         }
  //         data.push(json)
  //       }
  //     }
  //     setHistory([...history, ...data])
  //   })
  // }

    
  React.useEffect(() => {
    setStart('')
    setEnd('')
  },[know])

  const addWork = async () => {

    if (lock) {
      return
    }

    if (mainImage === null) {
      swal('대표 이미지를 설정해주세요.')
      return
    }
    if (koTitle === '' && enTitle === '') {
      swal('작품명을 입력해주세요.')
      return
    }


    if (knowArtistName === undefinedList[0]) {
      if (koArtist === '' && enArtist === '') {
        swal('작가이름을 입력해주세요.')
        return
      }
    }
    const sendKoArtist = knowArtistName === undefinedList[0] ? koArtist : undefinedList[1]
    const sendEnArtist = knowArtistName === undefinedList[0] ? enArtist : 'unknown'

    if (knowArtistBirth === undefinedList[0]) {
      if (artistBirth === '') {
        swal('작가 츨생연도을 입력해주세요.')
        return
      }
    }
    const sendArtistBirth = knowArtistBirth === undefinedList[0] ? artistBirth : undefinedList[1]

    const checkKnow = know ?? undefinedDateList[0]
    if (checkKnow !== undefinedDateList[2]) {      if (start === '') {
        swal('제작 연도를 선택해주세요.')
        return
      }
    }

    const sendStart = know === undefinedDateList[2] ? undefinedDateList[2] : start 
    const sendEnd = know === undefinedDateList[2] ? '' : end

    if (material === '') {
      swal('재질을 입력해주세요.')
      return
    }

    if(unit  === unitList[4]){
      setVertical('0');
      setHorizontal('0');
      setWidth('0');
    }else if(unit === unitList[3]){
      if(
        !(vertical !== '' || vertical !== '0' ||
        horizontal !== '' || horizontal !== '0' ||
        width !== '' || width !== '0')){
          swal('시, 분, 초 중 한 가지 이상을 입력해 주시기 바랍니다.')
          return;
        }
    }else{
      if (vertical === '' || horizontal === '') {
        swal('사이즈를 입력해주세요.')
        return
      }
    }
  


    const sendTimeSet = timeSet ?? timeSetList[0]
    const sendTimeSetIndex = timeSetList.indexOf(sendTimeSet) + 1

    const sendMyWorkType = workType ?? workTypeList[0]
    const setSendWorkTypePk = rowWorkTypeList[workTypeList.indexOf(sendMyWorkType)].pk
    const sendSaleType = saleType === saleTypeList[0] ? 1 : 2
    const sendView = archiveList.indexOf(view)
    const sendSale = 0 // 마켓플레이스 미등록
    const sendEdition = edition === editionList[0]
    let sendTotalEdition = totalEdition
    let sendTotalIssue = totalIssue

    if (sendEdition) {
      sendTotalEdition = '0'
      sendTotalIssue = '0'
    } else {
      if (totalEdition === '') {
        swal('에디션 번호를 입력해주세요.')
        return
      }
      if (totalIssue === '') {
        swal('총 발행 수를 입력해주세요.')
        return
      }
    }

    // if (price === '') {
    //   swal('가격을 입력해주세요.')
    //   return
    // }

    const sendWidth = width === '' ? '0' : width
    // setLock(true)
    // const res = await work.addByAgency(mainImage, subImage, koTitle, enTitle, sendKoArtist, sendEnArtist, artistDeath, sendArtistBirth, sendTimeSetIndex, setSendWorkTypePk, sendStart, sendEnd, material, unit, vertical, horizontal, sendWidth, collection, detail, sendSaleType, price, sendView, sendSale, history, sendTotalEdition, sendTotalIssue)

    loadingState.setText('작품 생성중입니다.\n잠시만 기다려주세요.');
    loadingState.setOpen(true);

    const res = await work.addByAgency(mainImage, subImage, koTitle, enTitle, sendKoArtist, sendEnArtist, sendArtistBirth, artistDeath, sendTimeSetIndex, setSendWorkTypePk, sendStart, sendEnd, material, unit, vertical, horizontal, sendWidth, collection, detail, sendSaleType, price, sendView, sendSale, exhibition, sendTotalEdition, sendTotalIssue)
    // setLock(false)
    if (!res.resultCode) {
      if(res.message === 'WORK OVER'){
        swal(`${res.membership}은 ${res.count}개까지 등록가능합니다.`)
      }else{
        swal('저장에 실패했습니다.')
      }
      loadingState.setOpen(false);
      loadingState.setText('');
      return
    }
    swal('작품 등록이 완료되었습니다.')
    loadingState.setOpen(false);
      loadingState.setText('');
    navigate(-1)
  }

  return (
      <div className="ma-work-add">
        <Header login={true} colored="black" detail={true} />
        <nav className="search">
          <div className="container search__container">
            <h2 className="search__title">My Archive</h2>
          </div>
        </nav>
        <SectionHeader title={'Add Work'} />
        <div className="ma-edit-image">
          <div className="container ma-edit-image-container">
            <h2 className="ma-img-tlt">대표 이미지 *
            <span className="ma-img-tlt--sub">*5MB 이하<br />({workImageExtensionList.join()} 사용가능)</span>
            </h2>
            <div className="main-image-container">
              <img
                  className={mainImage ? 'ma-img-show' : 'ma-img-hide'}
                  src={mainImageShow}
                  alt="선택 이미지"
                  onContextMenu={
                    (e) => {
                        e.preventDefault();
                        
                    }
                  }
              />
              <button
                  className={mainImage ? 'edit-photo' : 'edit-photo ma-img-hide'}
                  onClick={() => { setMainImage(null); setMainImageShow(null) }}
              ></button>
              <form method="post" encType="multipart/form-data">
                <div className="button">
                  <label className="image-button" htmlFor="mainImage"></label>
                </div>
                <input
                    value={''}
                    type="file"
                    id="mainImage"
                    name="mainImage"
                    accept={workImageExtension}
                    onChange={saveImgFile}
                    style={{ display: 'none' }}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="ma-edit-image-02">
          <div className="container ma-edit-image-container">
            <div className="ma-img-tlt-container">
              <h2 className="ma-img-tlt">
                서브 이미지
                <span className="ma-img-tlt--sub">*최대 8개까지 가능</span>
                <span className="ma-img-tlt--sub">*3MB 이하<br />({workImageExtensionList.join()} 사용가능)</span>
              </h2>
            </div>
            <div className="ma-sub-img-container">
              {
                subImage.map((item, i) => {
                  return (
                      <div className="sub-image-container">
                        <img
                            className={subImage[i] ? 'ma-img-show' : 'ma-img-hide'}
                            src={subImageShow[i]}
                            alt="선택 이미지"
                            onContextMenu={
                              (e) => {
                                  e.preventDefault();
                                  
                              }
                            }
                        />
                        <button
                            className={subImage[i] ? 'edit-photo' : 'edit-photo ma-img-hide'}
                            onClick={e => deleteSubImgFile(e, i)}
                        ></button>
                      </div>
                  )
                })
              }
              {
                  subImage.length < 8 &&
                  <div className="sub-image-container">
                    <img
                        className={subImage[subImage.length] ? 'ma-img-show' : 'ma-img-hide'}
                        src={''}
                        alt="선택 이미지"
                        onContextMenu={
                          (e) => {
                              e.preventDefault();
                              
                          }
                        }
                    />
                    <form method="post" encType="multipart/form-data">
                      <div className="button">
                        <label className="image-button" htmlFor={`subImage_${subImage.length}`}></label>
                      </div>
                      <input
                          value={''}
                          type="file"
                          id={`subImage_${subImage.length}`}
                          name={`subImage_${subImage.length}`}
                          accept={workImageExtension}
                          onChange={saveSubImgFile}
                          style={{ display: 'none' }}
                      />
                    </form>
                  </div>
              }
            </div>
          </div>
        </div>
        <ul className="ma-add-info ma-add-info">
          <div className="container">
            <li>
              <h2>작품명 *</h2>
              <input autoComplete='off' type="text" placeholder='한글' value={koTitle} onChange={e => setKoTitle(e.target.value)}></input>
            </li>
            <li>
              <h2> </h2>
              <input autoComplete='off' type="text" placeholder='ENG' value={enTitle} onChange={e => setEnTitle(e.target.value)}></input>
            </li>
            <li>
              <h2>작가명 *</h2>
              <DropDown options={undefinedList} setValue={setKnowArtistName} />
            </li>
            {
                knowArtistName === undefinedList[0] &&
                <>
                  <li>
                    <h2> </h2>
                    <input autoComplete='off' type="text" placeholder='한글' value={koArtist} onChange={e => setKoArtist(e.target.value)}></input>
                  </li>
                  <li>
                    <h2> </h2>
                    <input autoComplete='off' type="text" placeholder='ENG' value={enArtist} onChange={e => setEnArtist(e.target.value)}></input>
                  </li>
                </>
            }

            <li>
              <h2>작가 생몰연도 *</h2>
              <DropDown options={undefinedList} select={undefinedList.indexOf(knowArtistBirth)} setValue={setKnowArtistBirth} />
            </li>
            {
                knowArtistBirth === undefinedList[0] &&
                <>
                <li>
                <h2> </h2>
                <div className="ma-add-date-mb" style={{ flex: 1 }}>
                  <input autoComplete='off'
                        type="text"
                        onKeyPress={checkNumber}
                        inputMode="numeric"
                        maxLength="4"
                        placeholder='출생연도 *'
                         className="edtion-input"
                         value={artistBirth}
                          onChange={e => setArtistBirth(e.target.value)}
                         required
                  ></input>
                                    <span className="date-bar">~</span>
                  <input autoComplete='off'
                         type="text"
                         onKeyPress={checkNumber}
                         inputMode="numeric"
                         placeholder='사망연도'
                         maxLength="4"
                         className="edtion-input"
                         value={artistDeath}
                          onChange={e => setArtistDeath(e.target.value)}
                         required
                  ></input>
                </div>
              </li>
              </>
            }

            <li>
              <h2>구분 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={timeSetList} setValue={setTimeSet} />
              </div>
            </li>
            <li>
              <h2>유형 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={workTypeList} setValue={setWorkType} />
              </div>
            </li>
            <li>
              <h2>제작 연도 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={undefinedDateList} setValue={setKnow} />
              </div>
            </li>
            {know === undefinedDateList[0] &&
            <li>
              <h2> </h2>
              <div className="ma-add-date-mb" style={{ flex: 1 }}>
                <input autoComplete='off'
                  type="date"
                  id="date"
                  maxDetail="decade"
                  min={min}
                  max={today}
                  className="date-input"
                  value={start}
                  onChange={e => checkDate(setStart, e)}
                ></input>
                <input
                  autoComplete='off'
                  type="date"
                  id="date"
                  min={min}
                  max={today}
                  className="date-input"
                  value={end}
                  onChange={e => checkDate(setEnd, e)}
                ></input>
              </div>
            </li>
          }
          {know === undefinedDateList[1] &&
            <li>
              <h2> </h2>
              <div className="ma-add-date-mb" style={{ flex: 1 }}>
                <input autoComplete='off'
                  onKeyPress={checkNumber}
                  inputMode="numeric"
                  maxLength="4"
                  placeholder='YYYY *'
                  className="date-input"
                  value={start}
                  onChange={e => setStart(e.target.value)}
                ></input>
                <input
                  autoComplete='off'
                  onKeyPress={checkNumber}
                  inputMode="numeric"
                  maxLength="4"
                  placeholder= 'YYYY'
                  min={min}
                  max={today}
                  className="date-input"
                  value={end}
                  onChange={e => setEnd(e.target.value)}
                ></input>
              </div>
            </li>
          }
            <li>
              <h2>재질 *</h2>
              <input autoComplete='off' type="text" value={material} onChange={e => setMaterial(e.target.value)}></input>
            </li>
            <li>
              <h2>단위 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={unitList} setValue={setUnit} />
              </div>
            </li>
            {unit !== '가변' && <>
            <li>
              <h2>사이즈 *</h2>
              <div className="ma-info-02-input">
                <input autoComplete='off'
                       style={{ textAlign: 'end' }}
                       type="text"
                       onKeyPress={checkNumber}
                      //  inputMode="numeric"
                       placeholder={unit === unitList[3] ? '시' : '높이(세로) *'}
                       value={setComma(vertical)}
                       onChange={e => setVertical(onChangePoints(e))}></input>
                <span>{unit === unitList[3] ? 'H' : unit}</span>
              </div>
            </li>
            <li>
              <h2> </h2>
              <div className="ma-info-02-input">
                <input autoComplete='off'
                       style={{ textAlign: 'end' }}
                       type="text"
                       onKeyPress={checkNumber}
                      //  inputMode="numeric"
                       placeholder={unit === unitList[3] ? '분' : '너비(가로) *'}
                       value={setComma(horizontal)}
                       onChange={e => setHorizontal(onChangePoints(e))}></input>
                <span>{unit === unitList[3] ? 'M' : unit}</span>
              </div>
            </li>
            <li>
              <h2> </h2>
              <div className="ma-info-02-input">
                <input autoComplete='off'
                       style={{ textAlign: 'end' }}
                       type="text"
                       onKeyPress={checkNumber}
                      //  inputMode="numeric"
                       placeholder={unit === unitList[3] ? '초' : '폭'}
                       value={width === '0' ? '' :setComma(width)}
                       onChange={e => setWidth(onChangePoints(e))}></input>
                <span>{unit === unitList[3] ? 'S' : unit}</span>
              </div>
            </li>
            </>}
            <li>
              <h2>에디션 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={editionList} setValue={setEdition} />
              </div>
            </li>
            {edition === editionList[1] &&
            <>
              <li>
                <h2> </h2>
                <div className="ma-add-date-mb" style={{ flex: 1 }}>
                  <input autoComplete='off'
                    type="text"
                    onKeyPress={checkNumber}
                    inputMode="numeric"
                    maxLength="5"
                    className="edtion-input"
                    placeholder='2'
                    value={setComma(totalEdition)}
                      onChange={e => setTotalEdition(onChangePoints(e))}
                    required
                  ></input>
                  <span className="date-bar">/</span>
                  <input autoComplete='off'
                    type="text"
                    onKeyPress={checkNumber}
                    inputMode="numeric"
                    maxLength="5"
                    className="edtion-input"
                    placeholder='10'
                    value={setComma(totalIssue)}
                    onChange={e => setTotalIssue(onChangePoints(e))}
                  ></input>
                </div>
              </li>
              <li>
                <h2> </h2>
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#2f80ed'}}>
                  예시) 총 에디션 10개중 2번째 작품은 2/10으로 입력
                </div>
              </li>
            </>
          }
            <li>
              <h2>소장처</h2>
              <input autoComplete='off' type="text" value={collection} onChange={e => setCollection(e.target.value)}></input>
            </li>
            <li>
              <h2>상세 설명</h2>
              <div className='content-text'>
                    <textarea
                      maxLength={500}  placeholder="상세한 설명입니다." value={detail} onChange={e =>handleInputMaxLength(setDetail,e)}></textarea>
                        <span>{detail === null ? '0/500' : `${detail.length}/500`}</span>
                    {/* <span>{content.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length}/2000</span> */}
                  </div> 
            </li>
          </div>
        </ul>
        {/* <ul className="ma-add-info ma-add-info-02">
          <div className="container">
            <li>
              <h2>가격 *</h2>
              <div className="ma-add-date-mb">
                <DropDown options={saleTypeList} setValue={setSaleType} />
              </div>
            </li>
            <li>
              <h2> </h2>
              <div className="ma-info-02-input">
                <input autoComplete='off'
                       style={{ textAlign: 'end' }}
                       type="text"
                       onKeyPress={checkNumber}
                       inputMode="numeric"
                       placeholder="1,000,000"
                       value={setComma(price)}
                       onChange={e => setPrice(onChangePoints(e))}></input>
                <span>{saleType === saleTypeList[0] ? '원' : 'USD'}</span>
              </div>
            </li>
          </div>
        </ul> */}
        <ul className="ma-add-info-03 ma-add-info">
          <div className="container">
            <li>
              <h2>아트 아카이브 공개 *</h2>
              <DropDown options={archiveList} setValue={setView} select={archiveList.indexOf(view)}/>
            </li>
            {/* <li className="ma-info-03-drop-03">
              <h2>전시이력</h2>
              <div className="ma-add-date-mb">
                <a href='/excel/workExhibitionExcel.xlsx'>
                  <DropDown options={['엑셀폼 다운받기']} disabled={true} pointer={true} />
                </a>
                <label className='excel' htmlFor="excel">
                  엑셀 추가하기
                </label>
                <input
                    type="file"
                    id="excel"
                    name="excel"
                    accept=".xls,.xlsx"
                    onChange={excelToList}
                    style={{ display: 'none' }}
                />
              </div>
            </li>
            <li className="history-container">
              <h2></h2>
              <div className="history-list">
                {
                  history.map((item, i) => {
                    return (
                        <div className="history-item" key={i}>
                          <h4>{`[${item.type}]${item.exhi_NAME},${item.place}`}</h4>
                          <button onClick={() => setHistory(history.filter((item, index) => index !== i))}></button>
                        </div>
                    )
                  })
                }
              </div>
            </li> */}
          </div>
        </ul>
        {modalOpen && (
                  <ModalPortal>
                    <HistoryModal setModalOpen={setModalOpen} data={exhibition} setData={setExhibition} />
                  </ModalPortal>
                )}
        <section className="gallery-profile-exhibition">
              <div className="container gallery-profile-exhibition-container">
                <div className="gp-edit-header">
                  <div className="ap-edit-header-mb">
                    <h1>전시이력</h1>
                     <button onClick={() => setModalOpen(true)}>전시 추가</button>
                  </div>
                </div>
                <ul className="gp-exhibition-list gp-history-list">
                  <li className="gp-list--header">
                    <span>번호</span>
                    <span>전시명</span>
                    <span>개최장소</span>
                    <span>국가</span>
                    <span>개최기간</span>
                    <span>전시유형</span>
                    <div className="gp-liat--button"></div>
                  </li>
                  {exhibitionTable}
                  {exhibitionTableMobile}
                </ul>
              </div>
            </section>
        <div className="ma-add--button">
          <div className="container">
            <button onClick={() => navigate(-1)}>취소</button>
            <button onClick={() => addWork()}>저장</button>
          </div>
        </div>
        <Footer />
      </div>
  )
}
export default MyWorkAdd2
