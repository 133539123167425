import React from 'react'
import { Link } from 'react-router-dom'
import './WorkHeader.css'
import { KYCImageArtist } from '../KYC/artist'

const WorkHeader = props => {


  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);
  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const moreListChange = i => {
    props.moreListChange(i)
  }

  const moreListChange2 = i => {
    props.moreListChange2(i)
  }

  return (
    <header
      className={
        props.moreList && props.moreActive && props.moreList2 && props.moreActive2
          ? 'work-header work-header-d work-header-d2'
          : props.moreList && props.moreActive
            ? 'work-header work-header-d'
            : 'work-header'
      }
      style={props.color === false ? { background: 'none' } : {}}
    >
      <div className="container" >
        <div className="work-header__container" style={props?.notice && matches ? {width: '100%'} : {}}>
          <div className="work-header-info__container">
            <session className='work-header__info' style={props.self && props.artdb ? { display: 'flex', flex: 'auto', justifyContent: 'space-between', alignItems: 'center' } : {}} >
              <div style={{display: 'flex', gap: '10rem', alignItems: 'center'}}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h2>
                  {props.title_k}
                </h2>
                { props.title_e && 
                <h2 style={{ paddingTop: '5rem' }}>
                  <h4>{props.title_e}</h4>
                </h2>}
              </div>
              {props.kyc === 2 &&
              <KYCImageArtist disable={true} />
              }
              </div>
              {
                props.self &&
                props.artdb &&
                props.coa !== 'null' &&
                <button className='cert-button' style={{
                  width: props.isMobile ? '60rem' : '146rem',
                  height: props.isMobile ? '20rem' : '48rem',
                  fontSize: props.isMobile ? '10rem' : '20rem',
                  margin: '0',
                  lineHeight: 0,
                  backgroundColor: props.isPossibleRental ? '#2f80ed' : '#E0E0E0'
                }}
                  disabled={!props.isPossibleRental}
                  onClick={props.rentalFunc}>
                    {/* {props.workState === 1 ? "판매중" : props.workState === 2 ? "경매중" : props.workState === 3 ? "대여중" : '대여 신청'} */}
                    {props.workState === 1 ? "대여 신청" : props.workState === 2 ? "경매중" : props.workState === 3 ? "대여중" : props.workState === 4 ? "거래중" : '대여 신청'}
                </button>
              }
            </session>

            {/* <section
              className={
                props.title_e ? 'work-header__info' : 'work-header__info-big'
              }
            >
              <div>
              <h2>{props.title_k}</h2>
              </div>
              <div>
                {props.title_e ? <h4>{props.title_e}</h4> : <></>} 
              </div>
              
              {
                // type === 2 && 
                props.self &&
                props.artdb &&
                <div style={{ display: 'flex', flex: 'auto', justifyContent: 'end', gap: '5px' }}>
                  <h2 style={{cursor: 'pointer'}} onClick={props.buyFunc}>구매</h2>
                    <h2> / </h2>
                  <h2 style={{ cursor: 'pointer' }} onClick={props.rentalFunc}>대여 신청</h2>
                </div>
              }
            </section> */}
            <p className="work-header__move">
              {props.follow ? (
                // 팔로우 주석
                // <button className="work-header__follow">팔로우</button>
                <button onClick={props.followFunc} className={props.followState ? "artist-item--heart_active" : "artist-item--heart"}>
                  <span className="ir_pm">좋아요</span>
                </button>
              ) : props.move ? (
                <Link
                  to={props.move}
                  state={{
                    type: props.type,
                    title_e: props.title_e,
                    title_k: props.title_k,
                    moreList: props.moreList,
                    moreActive: props.moreActive,
                    options: props.options,
                    breakRows: props.breakRows,
                    breakSlidersPerRow: props.breakSlidersPerRow,
                    moreMenu: props.moreMenu,
                    // moreListChange: props.moreListChange
                  }}
                >
                  <span>더보기</span>
                  <button className="work-header__move-icon"></button>
                </Link>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
        {props.moreList && props.moreActive ? (
          <ul className="work-header__detail">
            {props.moreList.map((item, i) => {
              return (
                <li key={i}>
                  <button
                    className={i === props.moreMenu ? 'choice' : ''}
                    onClick={() => { moreListChange(i) }}
                  >
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {item}
                    </span>
                  </button>
                </li>
              )
            })}
          </ul>
        ) : (
          <></>
        )}
        {props.moreList2 && props.moreActive2 ? (
          <ul className="work-header__detail">
            {props.moreList2.map((item, i) => {
              return (
                <li key={i}>
                  <button
                    className={i === props.moreMenu2 ? 'choice' : ''}
                    onClick={() => { moreListChange2(i) }}
                  >
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {item}
                    </span>
                  </button>
                </li>
              )
            })}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </header>
  )
}
export default WorkHeader
