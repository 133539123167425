import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from "./auth/reducer";
import settingReducer from "./settings/reducer";
import payReducer from './pay/reducer';

const config = {
  key: "root",
  storage,
  whitelist: ["auth","pay"],
}

const reducer = combineReducers({
    auth: authReducer,
    setting: settingReducer,
    pay: payReducer
})

export default persistReducer(config, reducer)