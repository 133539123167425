import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropDown from '../../../../components/DropDown/DropDown';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import SectionHeader from '../../../../components/SectionHeader/SectionHeader';
import './ArtistExhibition.css';

import { checkMainImageSize } from '../../../../hook/utils/fileSize';
import { checkDate, handleInputMaxLength } from '../../../../hook/utils/validation';
import profile from '../../../../services/auth/profile';
import swal from 'sweetalert';

function ArtistExhibition() {

  const navigate = useNavigate();
  const { countryList } = useSelector(v => v.setting, shallowEqual)
  const { typeList } = useSelector(v => v.setting.exhibition, shallowEqual)
  const { min, today } = useSelector(v => v.setting.date, shallowEqual)


  const [image, setImage] = useState(null)
  const [imageShow, setImageShow] = useState(null)
  const [title, setTitle] = useState('')
  const [ place, setPlace] = useState('')
  const [ country, setCountry] = useState('대한민국')
  const [ type, setType] = useState(null)
  const [ date, setDate] = useState('')
  const [ end, setEnd] = useState('')
  const [ publisher, setPublisher] = useState('') // 주관자
  const [ hoster, setHoster] = useState('') // 주최자
  const [ planner, setPlanner] = useState('') // 기획자
  const [ reference, setReference] = useState('')
  const [ work, setWork] = useState('')

  const saveImgFile = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkMainImageSize(file.size)
    if(!check) return

    setImage(file);
    setImageShow(URL.createObjectURL(file));
  }

  const done = async () => {
    // if (image === null) {
    //   swal('대표 이미지를 설정해주세요.')
    //   return
    // }
    if (title === '') {
      swal('전시명을 입력해주세요.')
      return
    }
    if (place === '') {
      swal('개최장소를 입력해주세요.')
      return
    }

    if (date === '' || end === '') {
      swal('개최기간을 입력해주세요.')
      return
    }
    // if (publisher === '') {
    //   swal('주관을 입력해주세요.')
    //   return
    // }
    // if (hoster === '') {
    //   swal('주최를 입력해주세요.')
    //   return
    // }
    // if (planner === '') {
    //   swal('기획자를 입력해주세요.')
    //   return
    // }
    // if (reference === '') {
    //   swal('설명을 입력해주세요.')
    //   return
    // }
    const sendCountry = country ?? '대한민국'
    const sendType = type ?? typeList[0]

    const res = await profile.addExhibiton(image, title, place, sendCountry,
      sendType, date, end, publisher, planner, hoster, reference, [])
    if (!res.resultCode) {
      swal('저장에 실패했습니다.')
      return
    }
    swal('전시회 추가가 완료되었습니다.')
    navigate(-1)
  }

  return (
    <div className="artist-exhibition">
      <Header login={true} colored="black" detail={true} />
      <SectionHeader title={'전시회 추가'} />
      {/* <div className="a-ex-edit-image">
        <div className="container a-ex-edit-image-container">
          <h2 className="a-ex-img-tlt">대표 이미지 *</h2>
          <img
            className={imageShow ? 'a-ex-img-show' : 'a-ex-img-hide'}
            src={imageShow}
            alt="선택 이미지"
            onContextMenu={
              (e) => {
                  e.preventDefault();   
              }
            }
          />
          <button
            className={image ? 'edit-photo' : 'edit-photo a-ex-img-hide'}
            onClick={() => {setImage(null); setImageShow(null)}}
          ></button>
          <form method="post" enctype="multipart/form-data">
            <div className="button">
              <label className="image-button" for="chooseFile"></label>
            </div>
            <input
              type="file"
              id="chooseFile"
              name="chooseFile"
              accept="image/*"
              onChange={saveImgFile}
            />
          </form>
                  <span className="ma-img-tlt--sub image-size-check">*5MB 이하</span>
        </div>
      </div> */}
      <section className="container a-exhibition-content">
        <ul className="a-ex-edit-info">
          <li>
            <h2>전시명 *</h2>
            <input type="text" value={title}  onChange={e => setTitle(e.target.value)}></input>
          </li>
          <li>
            <h2>개최장소 *</h2>
            <input type="text" value={place}  onChange={e => setPlace(e.target.value)}></input>
          </li>
          <li>
            <h2>국가 *</h2>
            <DropDown options={countryList} select={countryList.indexOf(country)}  setValue={setCountry} onChange={e => setCountry(e.target.value)}/>
          </li>
          <li>
            <h2>전시유형 *</h2>
            <DropDown options={typeList} setValue={setType} onChange={e => setType(e.target.value)}/>
          </li>
          <li>
            <h2>개최기간 *</h2>
            <div className="mb-date-container" >
              <input
                type="date"
                id="date"
                className="date-input"
                min={min}
                max={today}
                value={date}
                onChange={e => checkDate(setDate, e)}
              ></input>
              <span className="date-bar">-</span>
              <input
                type="date"
                id="date"
                className="date-input"
                min={min}
                value={end}
                onChange={e => setEnd(e.target.value)}
              ></input>
            </div>
          </li>
          <li>
            <h2>주최</h2>
            <input type="text" value={hoster} onChange={e => setHoster(e.target.value)}></input>
          </li>
          <li>
            <h2>주관</h2>
            <input type="text" value={publisher} onChange={e => setPublisher(e.target.value)}></input>
          </li>
          <li>
            <h2>기획자</h2>
            <input type="text" value={planner} onChange={e => setPlanner(e.target.value)}></input>
          </li>
          <li>
            <h2>전시 설명</h2>
            <div className='content-text'>
                    <textarea
                      maxLength={'500'}
                      value={reference}
                      onChange={e => handleInputMaxLength(setReference,e)}></textarea>
                        <span>{reference === null ? '0/500' : `${reference.length}/500`}</span>
                    {/* <span>{content.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length}/2000</span> */}
                  </div>
          </li>
        </ul>
        <div className="a-ex-edit--button">
          <button onClick={() => navigate(-1) } >취소</button>
          <button onClick={done}>저장</button>

        </div>
      </section>
      <Footer />
    </div>
  )
}
export default ArtistExhibition
