import React, { useState } from 'react'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import './ArtMarketDetail.css'
import DropDown from '../DropDown/DropDown'
import Footer from '../Footer/Footer'


function ArtMarketDetail(props) {
  // const location = useLocation().state
  // const dispatch = useDispatch()
  const {
    rows,
    slidersPerRow,
    total,
    options,
    order,
    setOrder,
    items,
    currentPage,
    setCurrentPage,
  } = props

  const PAGE_PER_ITEM = rows * slidersPerRow
  const [TOTAL_PAGE, SET_TOTAL_PAGE] = useState(0)
  const [dotArr, setDotArr] = useState([])

  React.useEffect(() => {
    SET_TOTAL_PAGE(Math.ceil(total / PAGE_PER_ITEM))
  }, [total])


  React.useEffect(() => {
    ShowPaginationNumbers(currentPage + 1)
  }, [currentPage, total, TOTAL_PAGE])

  function ShowPaginationNumbers(currentPage) {
    try {
      let startPage
      let endPage

      if (TOTAL_PAGE <= 5) {
        startPage = 1
        endPage = TOTAL_PAGE
      } else {
        if (currentPage <= 3) {
          startPage = 1
          endPage = 5
        } else if (currentPage + 1 >= TOTAL_PAGE) {
          startPage = TOTAL_PAGE - 4
          endPage = TOTAL_PAGE
        } else {
          startPage = currentPage - 2
          endPage = currentPage + 2
        }
      }

      let numArr = []

      if (startPage === endPage) {
        numArr.push(1)
      } else {
        for (var i = startPage; i <= endPage; i++) {
          numArr.push(i)
        }
      }

      setDotArr(numArr)
    } catch (err) {
      console.log(err)
    }
  }


  return (
    <div className="art-d">
      <header className="art-d-header">
        <div
          className="container art-d-header-container"
          style={{ height: '144rem', justifyContent: 'flex-end' }}
        >
          <div className="art-d-header__drop">
            <DropDown
              options={options}
              select={options.indexOf(order)}
              setValue={setOrder}
            ></DropDown>
          </div>
        </div>
      </header>
      <section className="container art-m-slider">
        <div className="art-d-slider__list">
          <button
            className="slick-first"
            onClick={() => {
              setCurrentPage(0)
            }}
          >
            <span className="ir_pm">처음으로</span>
          </button>

          <button
            className="slick-prev"
            onClick={() => {
              if (currentPage !== 0) {
                setCurrentPage(currentPage - 1)
              }
            }}
          >
            <span className="ir_pm">이전</span>
          </button>

          <div className="slick-dots">
            <ul className="slick-dots-container">
              {dotArr.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => setCurrentPage(item - 1)}
                    style={{ display: 'inline-block' }}
                    className={
                      dotArr[index] === currentPage + 1 ? 'slick-active' : ''
                    }
                  >
                    <div className="dots-number">{item}</div>
                  </li>
                )
              })}
            </ul>
          </div>

          <button
            className={'slick-arrow slick-next'}
            onClick={() => {
              if (currentPage !== TOTAL_PAGE - 1) {
                setCurrentPage(currentPage + 1)
              }
            }}
          >
            <span className="ir_pm">다음</span>
          </button>

          <button
            className="slick-last"
            onClick={() => {
              setCurrentPage(TOTAL_PAGE - 1)
            }}
          >
            <span className="ir_pm">끝으로</span>
          </button>
          <div className="art-d-slider__item-my-row">
            {items}
          </div>
        </div>
      </section>
      {props.detail ? <Footer /> : <></>}
    </div>
  )
}
export default ArtMarketDetail
