import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ArtDetailOption from '../../../components/ArtDetailOption/ArtDetailOption'
import BannerSlide from '../../../components/BannerSlide/BannerSlide'
import { bannerImage, handleImgError, userImage } from '../../../services/imageRoute'
import './MarketPlatform.css'
import useMobileDetect from 'use-mobile-detect-hook';
import market from '../../../services/public/market/market'
import { setComma } from '../../../hook/utils/comma'
import AllSlide from '../../../components/BannerSlide/AllSlide'
import { checkUseName } from '../../../hook/utils/checkUseName'
import { KYCImageGallery } from '../../../components/KYC/gallery'
import { KYCImageArtist } from '../../../components/KYC/artist'
import { LoadingState } from '../../../context/loadingStateProvider'

function MarketPlatform() {
  const detectMobile = useMobileDetect();
  const loadingState = React.useContext(LoadingState);

  const { lang } = useSelector(v => v.setting, shallowEqual)
  const [banner, setBanner] = React.useState([])

  const { platformBannerList } = useSelector(v => v.setting, shallowEqual)

  const [items, setItems] = React.useState([])
  const [platfromQ, setPlatfromQ] = React.useState([])
  const options = ['가나다순', 'ABC순']
  const [menu, setMenu] = React.useState(options[0])
  const [rows, setRows] = React.useState(4)
  const [slidersPerRow, setSlidersPerRow] = React.useState(3)
  const [total, setTotal] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)

  React.useEffect(() => {
    if (detectMobile.isMobile()) {
      setRows(4)
      setSlidersPerRow(2)
    }
  }, [detectMobile.isMobile()])

  const setImage = () => {
    let _banner = [];

    platformBannerList.map(item => {
      _banner.push({
        info: detectMobile.isMobile() || window.innerWidth < 480 ? `${bannerImage}/${item.mobile}` : `${bannerImage}/${item.web}`,
        link: item.address,
        type: item.type
      });
    });
     
    setBanner(_banner)
  }
  
  React.useEffect(() => {
    setImage();
    window.addEventListener('resize', setImage);
    return () => {
      window.removeEventListener('resize', setImage);
    }
  }, [platformBannerList]);


  React.useEffect(() => {
    
    const type = options.indexOf(menu) + 1
    getPlatfromQ(currentPage, rows * slidersPerRow, type)
    
  },[currentPage, rows, slidersPerRow, menu])


  const getPlatfromQ = async(page, count, type) => {
    //loadingState.setText('잠시만 기다려주세요.');
    //loadingState.setOpen(true);
    const res = await market.getPlatfromQ(page, count, type)
    //loadingState.setOpen(false);
    //loadingState.setText('');
    setTotal(res.total)
    const data = res.platformQ;
    const item = [];
    for(let i = 0; i < data.length; i ++){
      item.push({
        pk: data[i].user_ID,
        info: `${userImage}/${data[i].picture}`,
        // name: lang === 'ko' ? `${data[i].first_NAME}${data[i].name}` : `${data[i].first_ENG_NAME}${data[i].eng_NAME}`,
        name: checkUseName(lang, data[i].use_NAME, `${data[i].first_NAME}${data[i].name}`, `${data[i].first_ENG_NAME} ${data[i].eng_NAME}`, data[i].nickname, data[i].eng_NICKNAME),
        link: `/artdb/${data[i].user_TYPE === 2 ? 'venue' : data[i].user_TYPE === 1 ? 'artist' : 'collection'}`,
        category: data[i].user_TYPE === 1 ? '작가' : data[i].user_TYPE === 2 ? '기관' : '콜렉션'
      })
    }
    setItems(item)
  }


  React.useEffect(() => {
    const result = []
    if(items.length === 0){
      setPlatfromQ(result)
      return
    }

    for (let i = 0; i < items.length; i++) {
      result.push(
        <div className="slider__item" key={`${i}${items[i].pk}`}>
        <Link
          to={`${items[i].link}/${items[i].pk}`}
        >
          <div>
          <div style={{ position: 'relative', zIndex: 1 }}>
                {
                  <KYCImageArtist />
                }
                <img
              className="item-image"
              src={items[i].info}
              alt="item"
              onError={handleImgError}
              onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }
            ></img>
              </div>
            
            <div className="item-info">
              <div>
                <h2>{items[i].name}</h2>
                <h4>{items[i].category}</h4>
              </div>
            </div>
          </div>
        </Link>
      </div>
      )
    }
    setPlatfromQ(result)
  },[items])

  // var item = []
  // for (var i = 0; i < 36; i++) {
  //   item.push({
  //     id: 1,
  //     info: 'qart_test/회화/김동선, 곰순이, 종이에 수묵, 64×48.5cm, 2010년대.JPG',
  //     profile:
  //       'qart_test/회화/김동선, 곰순이, 종이에 수묵, 64×48.5cm, 2010년대.JPG',
  //     name: '60화랑',
  //     category: '아트',
  //     link: '.',
  //   })
  // }
  // const result = []

  // for (var i = 0; i < 36; i++) {
  //   result.push(
  //     <div className="slider__item">
  //       <Link
  //         to={`${item[i].link}/${item[i].id}`}
  //         state={{
  //           item: item[i],
  //         }}
  //       >
  //         <div>
  //           <img
  //             className="item-image"
  //             src={require('../../../' + item[i].info)}
  //             alt="item"
  //           ></img>
  //           <div className="item-info">
  //             <img
  //               src={require('../../../' + item[i].profile)}
  //               alt="item"
  //             ></img>
  //             <div>
  //               <h2>{item[i].name}</h2>
  //               <h4>{item[i].category}</h4>
  //             </div>
  //           </div>
  //         </div>
  //       </Link>
  //     </div>
  //   )
  // }



  return (
    <div className="market-platform">
      <AllSlide items={banner} />
      <div className="market-platform-slider">
        <ArtDetailOption
          title_k={`모든 판매자(${setComma(total)})`}
          title_e={'All Sellers'}
          options={options}
          menu={menu}
          setMenu={setMenu}
          rows={rows}
          slidersPerRow={slidersPerRow}
          total={total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          items={platfromQ}
        />
      </div>
    </div>
  )
}
export default MarketPlatform
