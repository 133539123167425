import produce from 'immer';
import * as ActionTypes from './actionTypes';

const initialState = {
    login: false,
    type: null,
    // general:[],
    artist: [],
    gallery: [],
    galleryTeam: null,
    kyc: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN:
            return produce(state, draft => {
                draft.login = true;
                draft.type = action.data;
                // draft.token = action.data
            })
        case ActionTypes.LOGOUT:
            return produce(state, draft => {
                draft.artist = [];
                draft.gallery = [];
                draft.general = [];
                draft.login = false;
                draft.type = null;
                // draft.token = '';
            })
        // case ActionTypes.SET_GENERAL_INFO:
        //     return produce(state, draft => {
        //         draft.general = action.data;
        //     })
        case ActionTypes.SET_ARTIST_INFO:
            return produce(state, draft => {
                draft.artist = action.data;
            })
        case ActionTypes.SET_GALLERY_INFO:
            return produce(state, draft => {
                const data = action.data
                draft.gallery = data.data;
                
                const user_id = data.user_id
                const owner = data.data.user_id === user_id
                draft.galleryTeam = {
                    owner: owner,
                    user_id: user_id
                }
            })
        case ActionTypes.SET_TYPE:
            return produce(state, draft => {
                draft.type = action.data;
                // draft.token = action.data
            })
        case ActionTypes.SET_KYC:
            return produce(state, draft => {
                draft.token = action.data
            })
        default:
            return state
    }
}

export default authReducer;
