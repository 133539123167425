import client from "../index";



/**
 * 최신 작품 리스트
 * @param {number} row 요청 갯수
*/
const getNewWorkList = async(row) => {
  const setData = {
    count : row,
  }
  const res = await client.json.get('/user/work/recent', {params: setData});
  return res.data;
}


/**
  경매 작품 상세보기
   * @param {number} pk
*/
const getAuctionDetail = async(pk) => {
  const res = await client.json.get(`/user/auction/detail/${pk}`);
  return res.data;
}

/**
  작품 상세보기
   * @param {number} pk
*/
const getDetail = async(pk) => {
  const res = await client.json.get(`/user/work/${pk}`);
  return res.data;
}


/**
 * Q-CoA 생성된 작품들 가져오기
  * @param {number} currentPage 현재 페이지
  * @param {number} row 요청 갯수
  * @param {Stirng} search 검색
*/
const getQcoAList = async(currentPage, row, search) => {
  const setData = {
    page : currentPage,
    count : row,
    search: search
  }
    const res = await client.json.get('/user/work/coa', {params: setData});
    return res.data;
}

/**
 * 작품 유형별 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {Stirng} work_type 작품 유형 pk, 0 전체
 * @param {Stirng} date_type 0:전체, 1:고미술, 2:근현대
*/
const getWorkListByType = async(currentPage, row, work_type, date_type) => {
  const setData = {
    page : currentPage,
    count : row,
    work_type: work_type,
    date_type: date_type
  }
    const res = await client.json.get('/user/work', {params: setData});
    return res.data;
}


/**
 * 작품 하트
  * @param {number} pk 작품 pk
*/
const setLike = async(pk) => {
  const res = await client.json.patch(`/user/heart/${pk}`,);
  return res.data;
}

// /**
//  * 작품 검색
//   * @param {number} currentPage 현재 페이지
//   * @param {number} row 요청 갯수
//   * @param {number} order 0: 최신순, 1: 작품명순, 2: 작가명순, 3: 제작연도순
//   * @param {String} search 검색어
//   * @param {number} searchType 1: 작가명, 2: 작품명, 3: 기관명
// */
// const search = async(currentPage, row, order, search, searchType) => {

//   const setData = {
//     page : currentPage,
//     count : row,
//     order: order,
//     search: search,
//     search_type: searchType 
//   }
  
//   const res = await client.json.get('/user/work/search', {params: setData});
//   return res.data;
// }



/**
 * 작품 검색
  * @param {number} currentPtage 현재 페이지
  * @param {number} row 요청 갯수
  * @param {number} order 0: 가나다순 1: ABC순
  * @param {String} search 검색어
  * @param {number} searchType 1: 작가, 2: 작품, 3: 기관
*/
const search = async(currentPage, row, order, search, searchType) => {

  const setData = {
    page : currentPage,
    count : row,
    order: order,
    search: search,
    search_type: searchType 
  }
  
  const res = await client.json.get('/user/work/search', {params: setData});
  return res.data;
}


const publicWork = {
  getAuctionDetail,
  getNewWorkList, getQcoAList,
  getWorkListByType, getDetail, setLike,
  search
}

export default publicWork
