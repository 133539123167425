  /**
   *  쿠키페이 order no 만들기
   */
  export const getOrderNo = () => {
    const date = new Date()
    let sYear = date.getFullYear();
    let sMonth = date.getMonth() + 1;
    let sDate = date.getDate();
    sMonth = sMonth > 9 ? sMonth : "0" + sMonth;
    sDate = sDate > 9 ? sDate : "0" + sDate;
    const time = date.getTime();

    return `ORDER_NO_${sYear}${sMonth}${sDate}${time}`;
  }


