export const getDateStr = (timestamp, temp) => {
    const date = new Date(timestamp)

    let sYear = date.getFullYear();
    let sMonth = date.getMonth() + 1;
    let sDate = date.getDate();

    sMonth = sMonth > 9 ? sMonth : "0" + sMonth;
    sDate = sDate > 9 ? sDate : "0" + sDate;
    return `${sYear}${temp}${sMonth}${temp}${sDate}`;
}

export const getDateTimeStr = (timestamp) => {
    const date = new Date(timestamp)

    let sYear = date.getFullYear();
    let sMonth = date.getMonth() + 1;
    let sDate = date.getDate();
    const hours = date.getHours();
    let sHours = hours < 10 ? `0${hours}` : hours;
    const minutes = date.getMinutes();
    let sMinutes = minutes < 10 ? `0${minutes}` : minutes;

    sMonth = sMonth > 9 ? sMonth : "0" + sMonth;
    sDate = sDate > 9 ? sDate : "0" + sDate;
    return `${sYear}-${sMonth}-${sDate}T${sHours}:${sMinutes}`;
}


export const setBidTime = (timestamp) => {
    if(timestamp === null){
        return ''
    }
    const date = new Date(timestamp)

    let sYear = date.getFullYear();
    let sMonth = date.getMonth() + 1;
    let sDate = date.getDate();
    const hours = date.getHours();
    let sHours = hours < 10 ? `0${hours}` : hours;
    const minutes = date.getMinutes();
    let sMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const seconds = date.getSeconds();
    let sSeconds = seconds < 10 ? `0${seconds}` : seconds;

    sMonth = sMonth > 9 ? sMonth : "0" + sMonth;
    sDate = sDate > 9 ? sDate : "0" + sDate;
    //return `${sYear}-${sMonth}-${sDate} ${sHours}:${sMinutes}:${sSeconds}`;
    return `${sYear}-${sMonth}-${sDate}`;
}
