import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import './Membership.css';
import MembershipList from './MembershipList';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ModalPortal from '../../../components/Modal/ModalPortal';
import OkCancelModal from '../../../components/Modal/OkCancelModal';
import { getDateStr } from '../../../hook/utils/getDateStr';
import auth from '../../../services/auth/auth';
import pay2pay from '../../../services/pay2pay/pay';

import { checkWorkCountByMembership } from '../../../hook/utils/checkWorkCountByMembership';
import * as authAction from '../../../store/reducers/auth/actions';

function Membership() {
    const mediaMatch = window.matchMedia('(max-width: 480px)');
    const dispatch = useDispatch();
    const [matches, setMatches] = useState(mediaMatch.matches);

    const [state, setStates] = useState(0); // 현 멤버십
    const [status, setStatus] = useState(""); // 해지 상태 
    const [sDate, setSDate] = useState("");
    const [nDate, setNDate] = useState("");
    const [eDate, setEDate] = useState("");
    const [afterLevel, setAfterLevel] = useState(null); // 다음 결제 부터 어떤 결제 할지
    const [changePage, setChangePage] = useState(0);
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [cancelModal2, setCancelModal2] = useState(false)

    const { type, artist, gallery } = useSelector(v => v.auth, shallowEqual)

    React.useEffect(() => {
        getInfo()
    }, [])

    const setInfo = async() => {
        if (type === 2) {
            const agency = await auth.getAgencyInfo()
            dispatch(authAction.SET_GALLERY_INFO(agency))
          } else {
            const artist = await auth.getArtistInfo()
            dispatch(authAction.SET_ARTIST_INFO(artist.data))
          }
    }

    const getInfo = async () => {
        setInfo().then(async() => {
            if (type === 2) {
            if (gallery.userLevelTb === null) {
                setStates(0)
                return
            }
            setAfterLevel(gallery.userLevelTb.membershipRequest)
            setStates(gallery.userLevelTb.userLevel)
            setStatus(gallery.userLevelTb.state)
            const startTimestamp = gallery.userLevelTb.startDate;
            const endTimestamp = gallery.userLevelTb.levelExpired;
            setSDate(getDateStr(startTimestamp, '.'))
            const endDate = new Date(endTimestamp);
            const nextTimestamp = endDate.setDate(endDate.getDate() - 2);
            setNDate(getDateStr(nextTimestamp, '.'))
            setEDate(getDateStr(endTimestamp, '.'))
        } else {
            if (artist.userLevelTb === null) {
                setStates(0)
                return
            }
            setAfterLevel(artist.userLevelTb.membershipRequest)
            setStates(artist.userLevelTb.userLevel)
            setStatus(artist.userLevelTb.state)
            const startTimestamp = artist.userLevelTb.startDate;
            const endTimestamp = artist.userLevelTb.levelExpired;
            setSDate(getDateStr(startTimestamp, '.'))
            const endDate = new Date(endTimestamp);
            const nextTimestamp = endDate.setDate(endDate.getDate() - 2);
            setNDate(getDateStr(nextTimestamp, '.'))
            setEDate(getDateStr(endTimestamp, '.'))
        }})
    }

    const next = async () => {
        setModal(false)
        const checkCount = await checkWorkCountByMembership('STARTER');
        if(!checkCount){
            return;
        }
        
        const res = await pay2pay.unsubscribe();

        if (!res.resultCode) {
            swal('관리자에게 문의해주세요.');
            return
        }
        await getInfo()
        setModal2(true)
    }

    const next2 = () => {
        setModal2(false)
        window.location.reload();
    }

    const cancelNext = async (back) => {
        setCancelModal(false)

        if(back){
            const res = await pay2pay.unsubscribe();
            if(!res.resultCode){
                return swal('관리자에게 문의해주세요.')
            }
        }else{
            const res = await pay2pay.cancelDowngrade()
            if (!res.resultCode) {
                swal('관리자에게 문의해주세요.');
                return
            }
        }

        await getInfo()
        setCancelModal2(true)
    }

    const cancelNext2 = () => {
        setCancelModal2(false)
        window.location.reload();
    }


    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    return (
        changePage === 0 ?
            <div className='member-ship'>
                <div className='container'>
                    <h1>나의 멤버십</h1>
                    <div className='member-ship-state-menu'>
                        {
                            state === 0 ?
                                <div className='member-ship-state-on-item'>
                                    스타터
                                </div> :
                                <div className='member-ship-state-off-item'>
                                    스타터
                                </div>
                        }
                        {
                            state === 1 ?
                                <div className='member-ship-state-on-item'>
                                    베이직
                                </div> :
                                <div className='member-ship-state-off-item'>
                                    베이직
                                </div>
                        }
                        {
                            state === 2 ?
                                <div className='member-ship-state-on-item'>
                                    프리미엄
                                </div> :
                                <div className='member-ship-state-off-item'>
                                    프리미엄
                                </div>
                        }
                        {
                            state === 3 ?
                                <div className='member-ship-state-on-item'>
                                    비즈니스
                                </div> :
                                <div className='member-ship-state-off-item'>
                                    비즈니스
                                </div>
                        }
                    </div>
                    <div className='member-ship-div-item' ></div>
                    <h2>멤버십 시작일<span className='black'>      {sDate} </span></h2>
                        <h2>다음 결제일<span className='black'>       {status === "1" && `  ${nDate}`} {afterLevel !== null && afterLevel.userLevel === 1 && "(베이직 변경 예정)"} {state !== 0 && status === "0" && "무료 변경 예정"}</span></h2>
                    <h2>멤버십 만료일<span className='black'>      {eDate}</span></h2>
                    <h2>결제 상세정보<span className='black'>      카드(정기)</span></h2>
                    <div className='member-ship-button-menu'>
                        {
                            afterLevel === null &&
                            <button className='change-member-ship' onClick={() => setChangePage(1)}>멤버십 변경</button>
                        }
                        {status === '1' &&
                            <div>
                                <button className='cancel-member-ship' onClick={() => setModal(true)}>멤버십 해지</button>
                                {modal && (
                                    <ModalPortal>
                                        <OkCancelModal
                                            setModalOpen={setModal}
                                            setFunc={next}
                                            title={'멤버십 해지'}
                                            // content={'본인이 맞으십니까?'}
                                            content={'정말로 멤버십을 해지하시겠습니까?\n무료 서비스 외의 기능은 비활성화됩니다.'}
                                            showCancel={modal}
                                        ></OkCancelModal>
                                    </ModalPortal>
                                )}
                                {modal2 && (
                                    <ModalPortal>
                                        <OkCancelModal
                                            setModalOpen={setModal2}
                                            setFunc={next2}
                                            title={'해지 완료'}
                                            // content={'본인이 맞으십니까?'}
                                            content={'해지가 완료되었습니다.'}
                                            showCancel={modal}
                                        ></OkCancelModal>
                                    </ModalPortal>
                                )}
                            </div>
                        }
                    </div>
                    <div className='member-ship-button-menu'>
                        {status === '1' && afterLevel !== null && afterLevel.userLevel === 1 &&
                            <>
                                <button className='cancel-member-ship' onClick={() => { setCancelModal(true) }}>변경 취소</button>
                                {cancelModal && (
                                    <ModalPortal>
                                        <OkCancelModal
                                            setModalOpen={setCancelModal}
                                            setFunc={cancelNext}
                                            type={false}
                                            title={'멤버십 변경 취소'}
                                            content={'멤버십 변경을 취소하시겠습니까?'}
                                            showCancel={cancelModal}
                                        ></OkCancelModal>
                                    </ModalPortal>
                                )}
                                {cancelModal2 && (
                                    <ModalPortal>
                                        <OkCancelModal
                                            setModalOpen={setCancelModal2}
                                            setFunc={cancelNext2}
                                            title={'멤버십 변경 취소'}
                                            content={'취소가 완료되었습니다.'}
                                            showCancel={cancelModal}
                                        ></OkCancelModal>
                                    </ModalPortal>
                                )}
                            </>
                        }
                        {status === '0' &&
                            <>
                                <button className='cancel-member-ship' onClick={() => { setCancelModal(true) }}>변경 취소</button>
                                {cancelModal && (
                                    <ModalPortal>
                                        <OkCancelModal
                                            setModalOpen={setCancelModal}
                                            setFunc={cancelNext}
                                            type={true}
                                            title={'멤버십 변경 취소'}
                                            content={'멤버십 변경을 취소하시겠습니까?'}
                                            showCancel={cancelModal}
                                        ></OkCancelModal>
                                    </ModalPortal>
                                )}
                                {cancelModal2 && (
                                    <ModalPortal>
                                        <OkCancelModal
                                            setModalOpen={setCancelModal2}
                                            setFunc={cancelNext2}
                                            title={'멤버십 변경 취소'}
                                            content={'취소가 완료되었습니다.'}
                                            showCancel={cancelModal}
                                        ></OkCancelModal>
                                    </ModalPortal>
                                )}
                            </>
                        }
                    </div>
                    <div className='member-ship-div-item' style={{ marginTop: '40rem' }}></div>
                    <h3 style={{ marginTop: '30rem' }}>*멤버십 변경 시 사용기한은 변경일을 기준으로 새롭게 적용됩니다.</h3>
                    <h3 style={{ marginTop: '10rem' }}>*멤버십 해지 시에도 기존 멤버십의 잔여 기간 동안은 큐아트 서비스는 그대로 사용할 수 있습니다.</h3>
                    <h3 style={{ marginTop: '10rem' }}>*결제 실패 시, 만료일까지 결제 요청을 하며, 만료일이 지나면 자동 해지됩니다.</h3>
                    <h3 style={{ marginTop: '10rem' }}>*멤버십 업그레이드는 즉시 적용, 다운그레이드는 기존 멤버십 만료 후 적용됩니다.</h3>
                </div>
            </div> :
            <MembershipList isSignup={false} level={state} />
    )
}

export default Membership