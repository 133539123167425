import React from 'react'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import HeaderSmall from '../../../components/Header/HeaderSmall/HeaderSmall'
import './MyPageGallery.css'

function MyPageGallery(props) {
  const moreList = {
    menu: [
      {
        title: '프로필',
        link: '/mypage/gallery',
      },
      {
        title: 'My Archive',
        link: '/mypage/gallery/archive',
      },
      {
        title: '구매목록',
        link: '/mypage/gallery/wallet',
      },
      {
        title: '관심목록',
        link: '/mypage/gallery/like',
      },
      // {
      //   title: '거래하기',
      //   link: '/mypage/gallery/trade',
      // },
      {
        title: '알림',
        link: '/mypage/gallery/notice',
      },
      {
        title: '멤버십',
        link: '/mypage/gallery/membership',
      }

    ],
  }

  return (
    <div className="mypage-artist">
      <Header login={true} colored="black" />
      <nav className="search">
        <div className="container search__container">
          <h2 className="search__title">마이페이지</h2>
        </div>
      </nav>
      <HeaderSmall moreList={moreList} active={true} choice={props.active} />
      {props.children}
      <Footer />
    </div>
  )
}
export default MyPageGallery
