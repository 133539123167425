import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Restore.css'

import { shallowEqual, useSelector } from 'react-redux'
import swal from 'sweetalert'
import { setBidTime } from '../../hook/utils/getDateStr'
import auth from '../../services/auth/auth'
import DropDown from '../DropDown/DropDown'


function RestorePassword() {
  const navigate = useNavigate()
  const { phoneList } = useSelector(v => v.setting, shallowEqual)

  const [emailLock, setEmailLock] = useState(false)
  const [active, setActive] = useState(0);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('')
  const [codeLock, setCodeLock] = useState(false)
  const [token, setToken] = useState("")
  const [locale, setLocale] = useState('+82')
  const [accounts, setAccounts] = useState([])
  const [list, setList] = useState([])

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);


  const emailType =
    [
      { label: '휴대폰 인증', active: active === 0, completed: active > 0 },
      { label: '', active: active === 1, completed: active > 1 }
    ]

  const sendEmail = async () => {

    if (emailLock) {
      return
    }

    await auth.sendSMS(locale, email.replaceAll('-',''))

    swal("휴대폰으로 보낸 인증코드를 입력해주세요.");

    setEmailLock(true)
  }

  const checkCode = async () => {
    const res = await auth.checkMyEmail(email.replaceAll('-',''), code)
    if (!emailLock) {
      swal("휴대폰 인증을 먼저 해주세요.")
      return
    }
    if (res.message === '인증 성공!') {
      swal('휴대폰 인증되었습니다.')
      setCodeLock(true);
      setAccounts(res.data);
    } else {
      swal("인증번호를 확인해주세요.")
    }
  }

  React.useEffect(() => {
    if (accounts.length === 0) return;

    let items = []

    accounts.map((item, index) => {
      items.push(
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <h3>
            {item.add_EMAIL}
          </h3>
          <h3>
            {setBidTime(item.creation_TIME)}
          </h3>
        </div>
      )
    });
    setList(items);
  }, [accounts])

  const next = () => {
    if (!codeLock) {
      swal('휴대폰 인증을 먼저 해주세요.');
      return;
    }
    setActive(1);
  }

  return (
    <div className="restore">
      <section className="container restore-container">
        {/* <FindAccountStepper type={emailType} active={active} /> */}
        {active === 0 ? (
          <>
            <div className="auth-content">
              <h2 className="email-title">휴대폰 번호</h2>
              <div className="ap-drop1">
                <DropDown id='locale' disabled={emailLock} myClass="my__drop1" select={phoneList.indexOf(locale)} options={phoneList} setValue={setLocale} />
                <input
                  id='phone'
                  autoComplete='off'
                  className="login-input"
                  style={{ border: '1rem solid #cccccc', width: matches ? "212rem" : '445rem', height: matches ? "40rem" : "72rem" }}
                  placeholder="010-0000-0000"
                  value={email}
                  type="text"
                  onKeyPress={(e) => {
                    // 정규표현식 /[0-9]/ 이 아니면 press X
                    if (/[^0123456789-]/g.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  // maxLength={15}
                  // onInput={(e) => {
                  //   if (e.target.value.length > e.target.maxLength)
                  //     e.target.value = e.target.value.slice(0, e.target.maxLength);
                  // }}
                  readOnly={emailLock}
                  onChange={e => setEmail(e.target.value)}
                  required
                ></input>
              </div>

              {!emailLock ?
                <div className="email-input-container" style={{ display: 'flex', justifyContent: 'center', margin: '40rem 0' }}>
                  <button onClick={sendEmail}>인증하기</button>
                </div>
                :
                <>
                  <h2 style={{ marginTop: '30rem' }} className="email-title">인증번호</h2>
                  <div className="email-input-container">
                    <input
                      autoComplete='off'
                      type="text"
                      className="email-input-num"
                      value={code}
                      readOnly={codeLock || !emailLock}
                      onChange={e => setCode(e.target.value)}
                      required></input>
                    <button onClick={checkCode}>확인하기</button>
                  </div>
                </>}
            </div>
            {emailLock &&
              <div className="auth-next">
                <button onClick={next} className={codeLock ? "auth-next-button" : ''}>다음</button>
              </div>
            }
          </>
        ) : (
          <>
            <div className="auth-content">
              <h2 className="email-title"> {list.length === 0 ? 'Qart에 가입되어있지 않습니다.' : `Qart에 가입된 회원은 ${list.length}명 입니다.`}</h2>
              {list.length !== 0 &&
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '30rem 0' }}>
                  <h3>
                    아이디
                  </h3>
                  <h3>
                    가입 날짜
                  </h3>
                </div>
              }
              {list}

            </div>
            <div className="auth-next">
              <button onClick={() => navigate('/login')} className={codeLock ? "auth-next-button" : ''}>로그인</button>
            </div>
          </>
        )}

      </section>
    </div>
  )
}
export default RestorePassword
