import React, { useEffect, useState } from 'react';
import { Stepper } from 'react-form-stepper';

export const SignupStepper = (props) => {

  const { type, active } = props
  const [data, setData] = useState();

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);
  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  // const {type} = props
  // const [active, setActive] = React.useState(0)

  const localStep =
    [
      { label: 'eKYC 인증', active: active === 0, completed: active > 0 },
      { label: '이메일 인증', active: active === 1, completed: active > 1 },
      { label: '휴대폰 인증', active: active === 2, completed: active > 2 },
      { label: '정보 입력', active: active === 3, completed: active > 3 },
    ]

  const oauthStep =
    [
      { label: 'eKYC 인증', active: active === 0, completed: active > 0 },
      { label: '휴대폰 인증', active: active === 1, completed: active > 1 },
      { label: '정보 입력', active: active === 2, completed: active > 2 },
    ]


    React.useEffect(() => {

      if(type === 'LOCAL'){
        setData(localStep)
      }else{
        setData(oauthStep)
      }
    },[type])

  return (
    <>
      {/* {active}
  <button onClick={() => {setActive(e => e - 1)}}>prev</button>
  <button onClick={() => {setActive(e => e + 1)}}>next</button> */}
      <Stepper
        steps={data}
        activeStep={active}
        connectorStateColors={true}
        style={{ width: matches ? '100%' : '50%' }}

        connectorStyleConfig={
          {
            completedColor: '#000000',
            activeColor: '#000000',
            size: 2,
            disabledColor: '#eee',
            // stepSize: '10rem'
          }
        }
        styleConfig={matches ? {
          activeBgColor: '#000000',
          activeTextColor: '#e0e0e0',
          completedBgColor: '#000000',
          completedTextColor: '#e0e0e0',
          size: '40rem',
          fontWeight: 400,
          circleFontSize: 12,
          labelFontSize: 10

        } :
          {
            activeBgColor: '#000000',
            activeTextColor: '#e0e0e0',
            completedBgColor: '#000000',
            completedTextColor: '#e0e0e0',
            size: '80rem',
            fontWeight: 400,
            circleFontSize: 18,
            labelFontSize: 13
          }
        }
      />
    </>
  )
}
