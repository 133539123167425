import client from "../../services";

export const decodeCoA = async(loadingState, setItem, link) => {
  loadingState.setText('잠시만 기다려주세요.');
    loadingState.setOpen(true);
    const res = await client.json.get(`/user/COA/${link}`);
    const data = res.data;
    const decode = `application/pdf;base64,${data.data}`
    const decodeFile = dataURLtoFile(decode, link)

    return setItem(decodeFile);
}


const dataURLtoFile = (dataurl, fileName) => {
 
    var arr = dataurl.split(','),
        // mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], fileName);
}