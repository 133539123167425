import swal from 'sweetalert';


const userSize = 2;
const mainSize = 5;
const subSize = 3;
const unit = 1024 * 1024; // 1MB


//TODO png만 선택 안되는 곳 확인해야함

export const workImageExtensionList = ['jpeg', 'jpg', 'png', 'webp'];
export const workImageExtension = ".jpeg, .jpg, .png, .webp"
// export const workImageExtension = ".png"
export const userImageExtensionList = ['gif', 'jpeg', 'jpg', 'png', 'webp'];
export const userImageExtension = ".gif, .jpeg, .jpg, .png, .webp"
// export const userImageExtension = ".png"

export const checkUserImageSize = (size) => {
    const maxSize = userSize * unit // 2MB

    if(size > maxSize){
        swal(`${userSize}MB 이내로 등록 가능합니다.`)
        return false
    }
    return true
};

export const checkMainImageSize = (size) => {
    const maxSize = mainSize * unit // 5MB
    
    if(size > maxSize){
        swal(`${mainSize}MB 이내로 등록 가능합니다.`)
        return false
    }
    return true
};

export const checkSubImageSize = (size) => {
    const maxSize = subSize * unit // 3MB

    if(size > maxSize){
        swal(`${subSize}MB 이내로 등록 가능합니다.`)
        return false
    }
    return true
};